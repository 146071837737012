import { ChartOptions, ChartData } from "chart.js";

// const params=["07112019","30032021","23032023","22032024","05022025","30052040","14012041"]

export const getChartData = (
  data1,data2,data3,labels
): ChartData => ({
  labels: labels,
  datasets: [
     {
      label: "Sabit Faizli",
      borderColor:  "#280659",
      backgroundColor: "rgba(40,6,89,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data1,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "1",
    },
      {
      label: "Değişken Faizli",
      borderColor:  "#900c3e",
      backgroundColor: "rgba(144,12,62,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data2,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "1",
    },
    {
      label: "Tüfe Endeksli",
      borderColor: "#1D7874",
      backgroundColor: "rgb(29, 120, 116,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data3,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "1",
    }
  ]
});

export const chartOptions: ChartOptions =  {
    interaction:
    {mode:'index'},
  elements: {
    line: {
      tension: 0.1
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
  //stacked100: { enable: true, replaceTooltipLabel: false },
  legend: {display: true,
  position:'bottom'},
    filler: {
      propagate: true
    }
  },
  scales: {
    xAxes: [
      {
        display: true,
        ticks: {
          autoSkip: false,
        }
      },
    ],
    yAxes: {
      suggestedMin: 0,
      suggestedMax: 100,
       ticks: {
          // forces step size to be 50 units
          stepSize: 5
        }
    }
  },

  };

  export const chartOptions1: ChartOptions =  {
    interaction:
    {mode:'index'},
  elements: {
    line: {
      tension: 0.1
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
  tooltip: {
                callbacks: {
                    label: function(context) {
                        var label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y;
                        }
                        return label;
                    },
                    afterBody: (ttItem) => (`Toplam: ${ttItem.reduce((acc, val) => (acc + val.raw), 0)}`)
                }
            },
  //stacked100: { enable: true, replaceTooltipLabel: false },
  legend: {display: true,
  position:'bottom'},
    filler: {
      propagate: true
    }
  },
  scales: {
    xAxes: [
      {
        stacked:true,
        display: true,
        ticks: {
          autoSkip: false,
        }
      },
    ],
    yAxes: [
      {
      stacked:true,
      display: true,
        ticks: {
          beginAtZero: true,
        }
      }
    ]
  },

  };

