
/*********************************************************************/
/*********************************************************************/
/******************************DİKKAT*********************************/
/*********************************************************************/
/*********************************************************************/
/* Burda sıralama önemli yani TP.KTF18 2. sıraya yazılırsa loan grafiğinde KMH hariç ve dahil değerleri karışır. */
/*
<Grid item xs={12} lg={2}>
     <RateWithGraphwithAction title={"Ticari Kredi(TL)"} value={["TP.KTF18","TP.KTF17"]}   />
</Grid>
*/
/*********************************************************************/
/*********************************************************************/
/******************************DİKKAT*********************************/
/*********************************************************************/
/*********************************************************************/

// @mui material components
import Grid from "@mui/material/Grid";

// Interest Rate template  components
import RateWithGraphwithAction from "layouts/financialsector/banking/deposits/components/interestRateGraphswithAction";


function IRTemplate() {
  return (
          <Grid container spacing={3}>
            <Grid item xs={12} lg={2}>
                <RateWithGraphwithAction title={"1 Aya Kadar TL "} value={["TP.TRY.MT01"]} />
            </Grid>
            <Grid item xs={12} lg={2}>
                <RateWithGraphwithAction title={"3 Aya Kadar TL "} value={["TP.TRY.MT02"]} />
            </Grid>
            <Grid item xs={12} lg={2}>
                <RateWithGraphwithAction title={"1 Aya Kadar EUR "} value={["TP.EUR.MT01"]} />
            </Grid>
            <Grid item xs={12} lg={2}>
                <RateWithGraphwithAction title={"3 Aya Kadar EUR "} value={["TP.EUR.MT02"]} />
            </Grid>
            <Grid item xs={12} lg={2}>
                <RateWithGraphwithAction title={"1 Aya Kadar USD "} value={["TP.USD.MT01"]} />
            </Grid>
            <Grid item xs={12} lg={2}>
                <RateWithGraphwithAction title={"3 Aya Kadar USD "} value={["TP.USD.MT02"]} />
            </Grid>
          </Grid>
  );
}

// Typechecking props for the Template
IRTemplate.propTypes = {
};

export default IRTemplate;
