/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
import get_bankbs_data from "layouts/api";


function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}

function convertdata(data) {
  let result = [];
  for (let [key, value] of Object.entries(data)) {
	         if (key != "date") {
			   result.push({ title: key, values: value});
			   }
	    }
  return result;
}


function budgetData(params) {
    const defaultData= {"monthly": {
        "index": [
            "2023-05-31",
            "2023-06-30",
            "2023-07-31",
            "2023-08-31",
            "2023-09-30",
            "2023-10-31",
            "2023-11-30",
            "2023-12-31",
            "2024-01-31",
            "2024-02-29",
            "2024-03-31",
            "2024-04-30"
        ],
        "Aktifler": {
            "Nakit Değerler": [
                207.317,
                296.53,
                284.46,
                262.476,
                253.462,
                251.206,
                254.666,
                263.125,
                241.922,
                260.698,
                267.348,
                326.883
            ],
            "TCMB Alacaklar": [
                776.074,
                820.142,
                1176.886,
                1512.63,
                1885.723,
                1811.436,
                1780.145,
                1919.507,
                1679.695,
                1631.955,
                1604.631,
                1543.032
            ],
            "Para Piyasalarından Alacaklar": [
                62.665,
                85.689,
                93.446,
                109.703,
                103.84,
                208.182,
                87.901,
                302.301,
                437.976,
                98.775,
                89.304,
                71.177
            ],
            "Bankalardan Alacaklar": [
                663.927,
                805.943,
                864.78,
                889.319,
                800.862,
                810.317,
                862.662,
                851.212,
                844.754,
                983.473,
                970.606,
                1044.592
            ],
            "Gerçeğe Uygun Değer Farkı K/Z Yan. Menk. Değ. (Net)": [
                226.898,
                255.462,
                270.848,
                267.032,
                272.19,
                294.76,
                285.554,
                309.381,
                298.748,
                265.696,
                273.349,
                287.937
            ],
            "GUD Farkı Diğer Kapsamlı Gelire Yan. Menk. Değ.": [
                1274.95,
                1439.46,
                1498.231,
                1524.957,
                1550.664,
                1604.405,
                1697.199,
                1788.739,
                1883.298,
                2004.095,
                2139.952,
                2167.681
            ],
            "Zorunlu Karşılıklar": [
                976.199,
                1191.95,
                1127.608,
                1112.855,
                1245.434,
                1285.712,
                1562.328,
                1588.98,
                1633.803,
                1881.813,
                2203.944,
                2187.505
            ],
            "Menkul Kıymet Ödünç Piyasasından Alacaklar": [
                1.355,
                1.099,
                1.564,
                2.233,
                2.233,
                2.233,
                2.897,
                4.258,
                2.741,
                2.379,
                2.343,
                1.13
            ],
            "Ters Repo Alacakları": [
                109.872,
                270.037,
                191.2,
                106.588,
                138.006,
                109.037,
                152.624,
                133.963,
                37.925,
                76.247,
                98.584,
                102.982
            ],
            "Krediler": [
                9237.332,
                10009.615,
                10322.795,
                10449.872,
                10709.542,
                10991.153,
                11326.915,
                11677.135,
                11937.749,
                12365.518,
                12930.367,
                13147.046
            ],
            "Takipteki Alacaklar": [
                166.479,
                168.306,
                169.729,
                172.969,
                168.194,
                173.245,
                175.812,
                191.893,
                195.972,
                196.016,
                197.859,
                204.357
            ],
            "Beklenen Zarar Karşılıkları(": [
                -386.85,
                -414.226,
                -422.239,
                -424.351,
                -434.976,
                -443.387,
                -455.166,
                -468.824,
                -476.259,
                -476.606,
                -479.785,
                -481.809
            ],
            "Faiz (Kar Payı) ve Gelir Tah. Reeskontları": [
                1093.449,
                1372.007,
                1432.192,
                1381.695,
                1397.465,
                1529.728,
                1569.486,
                1581.615,
                1610.029,
                1730.105,
                1835.092,
                1842.161
            ],
            "Finansal Kiralama Alacakları": [
                84.474,
                93.272,
                99.234,
                103.683,
                107.992,
                112.096,
                114.922,
                117.535,
                118.727,
                121.826,
                126.568,
                126.563
            ],
            "İştirakler,  Bağlı  ve Birlikte Kontrol Edilen Ort.": [
                312.543,
                365.839,
                388.724,
                405.31,
                418.853,
                428.134,
                444.604,
                499.27,
                516.542,
                526.862,
                539.497,
                550.437
            ],
            "İtfa Edilmiş Maliyeti Üzerinden Değerlenen Menkul Değerler": [
                1353.087,
                1525.099,
                1604.591,
                1693.859,
                1750.77,
                1799.894,
                1833.002,
                1871.699,
                1933.615,
                1989.504,
                2027.576,
                1919.703
            ],
            "Elden Çıkarılacak Kıymetler (Net)": [
                33.614,
                34.291,
                34.73,
                34.881,
                40.81,
                40.642,
                40.334,
                40.757,
                47.242,
                47.312,
                50.658,
                51.85
            ],
            "Sabit Kıymetler (Net)": [
                137.903,
                142.822,
                144.487,
                147.308,
                148.818,
                151.768,
                158.273,
                222.798,
                228.867,
                234.629,
                255.749,
                271.885
            ],
            "Diğer Aktifler": [
                515.159,
                639.505,
                682.305,
                578.9,
                538.672,
                598.088,
                645.712,
                654.889,
                713.511,
                721.713,
                739.182,
                877.472
            ]
        },
        "Yabancı Kaynaklar": {
            "Mevduat": [
                10355.711,
                11651.281,
                12455.894,
                12931.601,
                13306.491,
                13848.142,
                14130.945,
                14851.97,
                14947.668,
                15140.382,
                15469.91,
                15642.392
            ],
            "TCMB Borçlar": [
                248.657,
                247.243,
                283.39,
                276.71,
                273.341,
                306.664,
                334.806,
                336.409,
                364.134,
                383.519,
                392.633,
                392.688
            ],
            "Para Piyasalarına Borçlar": [
                262.941,
                139.446,
                6.212,
                14.575,
                27.883,
                6.003,
                10.924,
                18.746,
                6.671,
                16.344,
                45.841,
                16.853
            ],
            "Menkul Kıymet Ödünç Piyasasına Borçlar": [
                55.252,
                55.36,
                55.165,
                55.158,
                55.159,
                59.782,
                40.386,
                40.352,
                40.483,
                40.733,
                40.939,
                40.885
            ],
            "Bankalara Borçlar": [
                1578.189,
                2020.101,
                2082.457,
                2058.57,
                2105.238,
                2189.432,
                2332.366,
                2383.696,
                2426.06,
                2552.021,
                2669.883,
                2759.121
            ],
            "Repo İşlemlerinden Sağlanan Fonlar": [
                778.391,
                774.957,
                565.373,
                533.669,
                790.403,
                600.114,
                738.165,
                723.166,
                684.712,
                833.039,
                1227.926,
                1157.247
            ],
            "Fonlar": [
                27.833,
                43.62,
                28.271,
                42.158,
                45.575,
                37.017,
                39.68,
                55.179,
                41.716,
                46.201,
                38.506,
                40.042
            ],
            "İhraç Edilen Menkul Kıymetler": [
                343.996,
                426.375,
                443.779,
                440.947,
                487.935,
                514.777,
                561.17,
                583.902,
                635.176,
                715.243,
                775.359,
                752.872
            ],
            "Kiralama İşlemlerinden Yükümlülükler": [
                24.006,
                24.821,
                25.425,
                26.49,
                27.136,
                27.903,
                28.294,
                29.09,
                31.657,
                33.169,
                34.901,
                35.731
            ],
            "Ödenecek Vergi, Resim, Harç ve Primler": [
                17.938,
                19.217,
                21.012,
                21.129,
                26.587,
                25.888,
                27.816,
                37.553,
                37.05,
                37.758,
                48.691,
                51.059
            ],
            "Sermaye Hesaplamasına Dahil Edilecek Borçlanma Araçları": [
                306.478,
                379.678,
                403.004,
                396.989,
                404.181,
                416.276,
                427.153,
                414.725,
                434.737,
                461.499,
                499.417,
                534.346
            ],
            "Faiz (Kar Payı) ve Gider Reeskontları": [
                254.719,
                444.677,
                416.425,
                360.96,
                404.955,
                420.533,
                432.485,
                499.197,
                514.571,
                548.32,
                648.601,
                688.393
            ],
            "Karşılıklar": [
                190.313,
                207.92,
                225.591,
                233.332,
                263.89,
                275.546,
                270.555,
                310.079,
                321.583,
                316.318,
                330.165,
                344.434
            ],
            "Diğer Pasifler": [
                795.258,
                977.31,
                1154.568,
                1078.921,
                982.532,
                1094.549,
                1196.754,
                1113.333,
                1226.066,
                1306.465,
                1355.777,
                1465.24
            ]
        },
        "Özkaynaklar": {
            "Ödenmiş Sermaye": [
                252.858,
                255.297,
                262.962,
                263.756,
                266.386,
                269.963,
                270.884,
                275.528,
                292.348,
                299.673,
                309.67,
                313.647
            ],
            "Yedek Akçeler": [
                871.75,
                880.498,
                892.284,
                980.087,
                983.594,
                985.445,
                987.835,
                984.289,
                969.647,
                966.12,
                1328.418,
                1354.011
            ],
            "Ödenmiş Sermaye Enflasyon Düzeltme Farkı": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            "Menkul Değerler Değerleme Farkları": [
                111.896,
                121.095,
                178.11,
                185.308,
                129.96,
                116.484,
                93.266,
                129.467,
                114.498,
                124.759,
                126.323,
                114.093
            ],
            "Sabit Kıymet Yeniden Değerleme Farkları": [
                85.772,
                88.35,
                87.567,
                86.125,
                87.243,
                87.164,
                88.67,
                150.554,
                153.61,
                153.057,
                166.486,
                167.872
            ],
            "Dönem Karı veya Zararı": [
                190.344,
                252.536,
                293.417,
                350.589,
                439.045,
                486.189,
                535.471,
                620.487,
                31.987,
                74.734,
                153.524,
                190.721
            ],
            "Geçmiş Yıllar Karı veya Zararı": [
                94.146,
                93.061,
                84.663,
                -5.152,
                -8.979,
                -9.223,
                -7.755,
                -7.489,
                612.481,
                612.658,
                209.853,
                180.939
            ]
        }
    },"weekly": {
        "index": [
            "2024-03-15",
            "2024-03-22",
            "2024-03-29",
            "2024-04-05",
            "2024-04-09",
            "2024-04-19",
            "2024-04-26",
            "2024-05-03",
            "2024-05-10",
            "2024-05-17",
            "2024-05-24",
            "2024-05-31"
        ],
        "Aktifler": {
            "Nakit Değerler": [
                279.39458,
                266.15231,
                268.64789,
                292.72720000000004,
                314.13989000000004,
                299.16679999999997,
                307.72419,
                360.62345,
                307.31989,
                313.6928,
                301.41224,
                309.03489
            ],
            "TCMB Alacaklar": [
                1601.89848,
                1505.1068500000001,
                1606.91763,
                1501.9052900000002,
                1470.69574,
                1639.89291,
                1616.25124,
                1453.42732,
                1399.8161599999999,
                1634.01637,
                1895.8832,
                1836.73162
            ],
            "Para Piyasalarından Alacaklar": [
                71.97489999999999,
                67.24767,
                89.30565,
                88.59274,
                109.60261,
                78.11919,
                70.59142999999999,
                68.29623,
                117.16368,
                245.53216,
                175.3286,
                54.29707
            ],
            "Bankalardan Alacaklar": [
                924.40413,
                980.26982,
                962.79408,
                938.35086,
                889.9137,
                976.55102,
                962.10263,
                987.50936,
                908.07499,
                906.4187900000001,
                937.91323,
                915.93525
            ],
            "Gerçeğe Uygun Değer Farkı K/Z Yan. Menk. Değ. (Net)": [
                265.68104,
                260.94834,
                260.50473,
                259.86701,
                266.29619,
                276.99105,
                278.64054999999996,
                268.91166999999996,
                274.32607,
                273.13248,
                312.53909999999996,
                314.72976
            ],
            "GUD Farkı Diğer Kapsamlı Gelire Yan. Menk. Değ.": [
                2103.3964,
                2143.10006,
                2147.96971,
                2133.89933,
                2137.5314399999997,
                2175.62448,
                2184.36693,
                2180.39661,
                2203.81431,
                2176.68806,
                2191.52275,
                2206.54075
            ],
            "Zorunlu Karşılıklar": [
                2137.5422799999997,
                2132.5384,
                2201.5191600000003,
                2183.89989,
                2201.78001,
                2244.66714,
                2197.92373,
                2186.5759399999997,
                2178.77644,
                2184.2356800000002,
                2218.56716,
                2229.9833
            ],
            "Krediler": [
                12661.75484,
                12710.9215,
                12876.79287,
                12884.45884,
                12897.24632,
                12899.02558,
                13097.65669,
                13133.89957,
                13163.96816,
                13202.019199999999,
                13398.33955,
                13518.62663
            ],
            "Takipteki Alacaklar": [
                195.12671,
                194.83171,
                197.54267000000002,
                198.85667,
                199.90087,
                202.20085999999998,
                203.42709,
                204.87419,
                206.92220999999998,
                208.33635,
                209.41595,
                211.04389
            ],
            "İtfa Edilmiş Maliyeti Üzerinden Değerlenen Menkul Değerler": [
                2027.01847,
                2009.88098,
                2026.34289,
                2020.42612,
                2026.57807,
                2042.18393,
                1918.61249,
                1918.4266,
                1922.76947,
                1923.6764099999998,
                1889.0487,
                1894.6631
            ]
        },
        "Yabancı Kaynaklar": {
            "Mevduat": [
                15541.56581,
                15507.16634,
                15461.31847,
                15409.26031,
                15594.56299,
                15764.96125,
                15822.86607,
                15609.221220000001,
                15613.772289999999,
                15998.02882,
                16125.39949,
                15923.18312
            ],
            "TCMB Borçlar": [
                371.92697,
                373.21918,
                391.50016,
                376.51169,
                373.55493,
                375.17422999999997,
                375.37075,
                377.24778999999995,
                378.04913,
                381.94924,
                381.81352000000004,
                387.81426
            ],
            "Para Piyasalarına Borçlar": [
                27.40774,
                31.01989,
                51.67526,
                28.90826,
                20.59466,
                27.615560000000002,
                23.046689999999998,
                15.28279,
                12.13938,
                18.74327,
                25.11409,
                46.52534
            ],
            "Bankalara Borçlar": [
                3136.7926,
                3131.7743100000002,
                3185.78481,
                3245.2820899999997,
                3206.0891699999997,
                3225.4809,
                3214.2691800000002,
                3354.19261,
                3305.30653,
                3312.13177,
                3345.2529900000004,
                3361.75256
            ],
            "Repo İşlemlerinden Sağlanan Fonlar": [
                1056.0599499999998,
                1058.19592,
                1227.87449,
                1181.73324,
                1103.0576999999998,
                1255.63632,
                1202.4988700000001,
                1226.12173,
                1225.3301399999998,
                1187.29505,
                1522.4152,
                1603.55521
            ],
            "İhraç Edilen Menkul Kıymetler": [
                760.69712,
                774.75297,
                775.8115600000001,
                769.95342,
                777.4205999999999,
                790.02113,
                754.00937,
                737.73187,
                743.78215,
                760.9444599999999,
                774.2592099999999,
                770.3131999999999
            ]
        }
    }
}
    //const [chartData, setChartData] = useState(null);
    const [data, setData] = useState(defaultData);
    //const dataFetchedRef = useRef(false);
    const fetchData = async () => {
        const config={"func":"banks_bs","params":params};
        const response = await get_bankbs_data(config);
        const tmpdata = await response.data;

        const monthly = tmpdata["monthly"];
        const weekly = tmpdata["weekly"];
        setData({monthly:monthly,weekly:weekly});
    }

    useEffect(() => {
            fetchData();
    },[params]);

//const [chartData, setChartData] = useState(defaultData);
//useEffect(() => {
//    data.then((response) => {
//      //const chartDatasets = response.datasets.map((dataset) => ({
//      //  ...dataset,
//      //}));
//      setChartData(response.datasets);
//    });
//});
  return data
}

export default budgetData;

