import { useState } from "react";
import {useEffect } from "react";

import Select from "react-select";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";
import SoftEditor from "components/SoftEditor";
import SoftDropzone from "components/SoftDropzone";

import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Templates
import MainTemplate from "layouts/financialsector/tcmb/analytic/components/MainTemplate";



function Firms() {

  return (
    <DashboardLayout>
      <DashboardNavbar />

        <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="10%"
                  width="30%"
                >
         <SoftBox mb={1} ml={0.5} mt={1} lineHeight={0} display="inline-block">
         </SoftBox>
        </SoftBox>
       <SoftBox>
            <MainTemplate/>
        </SoftBox>
     <Footer />
   </DashboardLayout>
  );
}
export default Firms;
