/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
//import typography from "assets/theme/base/typography";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

function configs(chart, datasets,group) {
 am4core.useTheme(am4themes_animated);
// Themes end

/**
 * Create container for charts
 */
var container = am4core.create("chartdiv", am4core.Container);
container.width = am4core.percent(100);
container.height = am4core.percent(100);
container.layout = "horizontal";

/**
 * Population pyramid chart
 */
var months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

var pyramidChart = container.createChild(am4charts.XYChart);

pyramidChart.cursor = new am4charts.XYCursor();
pyramidChart.cursor.behavior = "none";
//pyramidChart.arrangeTooltips = false;

//pyramidChart.numberFormatter.numberFormat = "#,###.#a";
//pyramidChart.numberFormatter.bigNumberPrefixes = [
//  { "number": 1e+1, "suffix": "M" }
//];
pyramidChart.fontSize = 12;

  pyramidChart.colors.list = [
    am4core.color("#280659"),
    am4core.color("#900c3e"),
    am4core.color("#C70039"),
    am4core.color("#D54800"),
    am4core.color("#FF5733"),
  ];

//pyramidChart.dataSource.url = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/un_population_age_groups.csv";
//pyramidChart.dataSource.parser = new am4core.CSVParser();

pyramidChart.data=datasets.data1
//console.log(datasets.data1)
//pyramidChart.dataSource.parser.options.numberFields = ["col5", "col6", "col7"];
pyramidChart.events.on("ready", function(ev) {
  sourceData = ev.target.data;
  ev.target.data = getCurrentData();
});

function getCurrentData() {
  var currentData = [];
  am4core.array.each(sourceData, function(row, i) {
  //console.log(row.date,currentYear)
    if (row.date == currentYear) {
      currentData.push(row);
    }
  });
  currentData.sort(function(a, b) {
    var a1 = Number(a.distribution.replace(/[^0-9]+.*$>=/, ""));
    var b1 = Number(b.distribution.replace(/[^0-9]+.*$>=/, ""));
    if (a1 > b1) {
      return 1;
    }
    else if (a1 < b1) {
      return -1;
    }
    return 0;
  });
  //console.log(currentData)
  return currentData;
}

function updateData() {
  var data = getCurrentData();
  //console.log(data)
  if (data.length == 0) {
    return;
  }
  am4core.array.each(pyramidChart.data, function(row, i) {
    if (!data[i]) {
    //console.log(data[i])
      pyramidChart.data[i].Aylık12 = 0;
      pyramidChart.data[i].Aylık24 = 0;
    }
    else {
      pyramidChart.data[i].Aylık12 = data[i].Aylık12;
      pyramidChart.data[i].Aylık24 = data[i].Aylık24;
    }
  });
  pyramidChart.invalidateRawData();

  // Set title
  pyramidChart.titles.getIndex(0).text = currentYear;
}

// An adapter which filters data for the current year
//var currentYear = new Date().getFullYear().toString();
var curdate=new Date()
var curyear=curdate.getFullYear()
var curmonth=curdate.getMonth()

var  currentYear = curyear.toString()+ "-" + months[curmonth].toString();
//var currentYear = "2024-05-31"
var sourceData = [];

var pyramidXAxisMale = pyramidChart.xAxes.push(new am4charts.ValueAxis());
pyramidXAxisMale.min = 0;
pyramidXAxisMale.max = 25;
pyramidXAxisMale.strictMinMax = true;

var maleRange = pyramidXAxisMale.axisRanges.create();
maleRange.value = 0;
maleRange.endValue = 25;
maleRange.label.text = "12 Aylık";
maleRange.label.inside = true;
maleRange.label.valign = "top";
maleRange.label.fontSize = 14;
maleRange.label.fill = pyramidChart.colors.getIndex(0);

var pyramidXAxisFemale = pyramidChart.xAxes.push(new am4charts.ValueAxis());
pyramidXAxisFemale.min = 0;
pyramidXAxisFemale.max = 25;
pyramidXAxisFemale.renderer.inversed = true;
pyramidXAxisFemale.strictMinMax = true;

var maleRange = pyramidXAxisFemale.axisRanges.create();
maleRange.value = 0;
maleRange.endValue = 25;
maleRange.label.text = "24 Aylık";
maleRange.label.inside = true;
maleRange.label.valign = "top";
maleRange.label.fontSize = 12;
maleRange.label.fill = pyramidChart.colors.getIndex(1);

pyramidChart.bottomAxesContainer.layout = "horizontal";

var pyramidYAxis = pyramidChart.yAxes.push(new am4charts.CategoryAxis());
pyramidYAxis.dataFields.category = "distribution";
pyramidYAxis.renderer.minGridDistance = 1;
pyramidYAxis.renderer.grid.template.location = 0;
pyramidYAxis.renderer.inside = true;
pyramidYAxis.title.text = "Dağılımlar";
pyramidYAxis.renderer.labels.template.adapter.add("textOutput", function(text, target) {
  if (text == "40.00-40.99") {
    text += "*";
  }
  return text;
});
pyramidYAxis.fontSize = 8;

var pyramidSeriesMale = pyramidChart.series.push(new am4charts.ColumnSeries());
pyramidSeriesMale.dataFields.categoryY = "distribution";
pyramidSeriesMale.dataFields.valueX = "Aylık12";
pyramidSeriesMale.tooltipText = "{valueX}";
pyramidSeriesMale.name = "Aylık12";
pyramidSeriesMale.xAxis = pyramidXAxisMale;
pyramidSeriesMale.clustered = false;
pyramidSeriesMale.columns.template.strokeOpacity = 0;
//console.log(pyramidChart)

var pyramidSeriesFemale = pyramidChart.series.push(new am4charts.ColumnSeries());
pyramidSeriesFemale.dataFields.categoryY = "distribution";
pyramidSeriesFemale.dataFields.valueX = "Aylık24";
pyramidSeriesFemale.tooltipText = "{valueX}";
pyramidSeriesFemale.name = "Aylık24";
pyramidSeriesFemale.xAxis = pyramidXAxisFemale;
pyramidSeriesFemale.clustered = false;
pyramidSeriesFemale.columns.template.strokeOpacity = 0;

var pyramidTitle = pyramidChart.titles.create();
pyramidTitle.text = currentYear;
pyramidTitle.fontSize = 12;
pyramidTitle.marginBottom = 10;

var note = pyramidChart.tooltipContainer.createChild(am4core.Label);
note.text = "* 24 Aylık beklentiler için >=40.00 dağılımı 40.00-40.99 altında gösterilmektedir."
note.fontSize = 12;
note.valign = "bottom";
note.align = "center";

/**
 * Create population chart
 */
var popChart = container.createChild(am4charts.XYChart);
popChart.marginLeft = 15;
popChart.data = [{}];

var popSubtitle = popChart.titles.create();
popSubtitle.text = "";

var popTitle = popChart.titles.create();
popTitle.text = "Enflasyon Beklentileri";
popTitle.fontSize = 20;

//popChart.numberFormatter.numberFormat = "#,###.#a";
//popChart.numberFormatter.bigNumberPrefixes = [
//  { "number": 1e+1, "suffix": "M" }
//];
popChart.fontSize = 12;

  popChart.colors.list = [
    am4core.color("#280659"),
    am4core.color("#900c3e"),
    am4core.color("#C70039"),
    am4core.color("#D54800"),
    am4core.color("#FF5733"),
  ];

popChart.dateFormatter.dateFormat = "yyyy-MM";

var popXAxis = popChart.xAxes.push(new am4charts.DateAxis());
popXAxis.renderer.minGridDistance = 40;

var popYAxis = popChart.yAxes.push(new am4charts.ValueAxis());
popYAxis.renderer.opposite = true;

var popSeriesMale = popChart.series.push(new am4charts.LineSeries());
popSeriesMale.dataFields.dateX = "date";
popSeriesMale.dataFields.valueY = "12 Ay Sonrası Yıllık TÜFE";
popSeriesMale.propertyFields.strokeDasharray = "dash";
popSeriesMale.propertyFields.fillOpacity = "opacity";
popSeriesMale.stacked = false;
popSeriesMale.strokeWidth = 2;
popSeriesMale.fillOpacity = 0.4;
popSeriesMale.name = "12 Ay Sonrası Yıllık TÜFE";

var popSeriesFemale = popChart.series.push(new am4charts.LineSeries());
popSeriesFemale.dataFields.dateX = "date";
popSeriesFemale.dataFields.valueY = "24 Ay Sonrası Yıllık TÜFE";
popSeriesFemale.propertyFields.strokeDasharray = "dash";
popSeriesFemale.propertyFields.fillOpacity = "opacity";
popSeriesFemale.stacked = false;
popSeriesFemale.strokeWidth = 2;
popSeriesFemale.fillOpacity = 0.4;
popSeriesFemale.tooltipText = "[bold][font-size: 14]Enflasyon Beklentileri {dateX}[/]\n[font-size: 14]12 Aylık: {12 Ay Sonrası Yıllık TÜFE}\n24 Aylık: {24 Ay Sonrası Yıllık TÜFE}";
popSeriesFemale.name = "24 Ay Sonrası Yıllık TÜFE";
//popChart.dataSource.url = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/un_population.csv";
//popChart.dataSource.parser = new am4core.CSVParser();
//popChart.dataSource.parser.options.numberFields = ["col4", "col5", "col6"];
popChart.data=datasets.data2
//console.log(datasets.data2)
//popChart.dataSource.data=datasets.data2
//console.log(popChart.dataSource.adapter.object)
/*popChart.dataSource.adapter.add("parsedData", function(data) {
  am4core.array.each(data, function(item) {
  console.log(item)
    if (item.col3.getFullYear() == currentYear) {
      item.dash = "3,3";
      item.opacity = 0.3;
    }
  });
  console.log(data)
  return data;
});*/
popChart.cursor = new am4charts.XYCursor();
popChart.snapToSeries = popSeriesFemale;
popChart.cursor.events.on("cursorpositionchanged", function(ev) {
  var curposition=popXAxis.positionToDate(popXAxis.toAxisPosition(ev.target.xPosition))
  currentYear = curposition.getFullYear().toString()+ "-" +months[curposition.getMonth()].toString();
  //console.log(months[popXAxis.positionToDate(popXAxis.toAxisPosition(ev.target.xPosition)).getMonth()].toString())
  //var currentYear = "2024-05-31"
  updateData();
});

popChart.cursor.events.on("hidden", function(ev) {
  var currentYear = new Date().getFullYear().toString()+ "-" +months[new Date().getMonth()].toString();;
  //var currentYear = "2024-05-31"
  updateData();
});

}

export default configs;
