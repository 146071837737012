/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useRef, useEffect, useState, useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";


// Overview page components
import DefaultCell from "layouts/nationalaccount/currentaccount/components/BopFinance/components/DefaultCell";
import TotalCell from "layouts/nationalaccount/currentaccount/components/BopFinance/components/TotalCell";
import HeadCell from "layouts/nationalaccount/currentaccount/components/BopFinance/components/HeadCell";

//Data
import creditChangeData from "layouts/nationalaccount/currentaccount/components/BopFinance/data/bopfinanceTableData";


function dataTableData(params) {

const [tableData, setTableData] = useState( [
    {
        "index": "Cari İşlemler Hesabı",
        "Son Ay": "-1235",
        "Yılbaşından Bugüne": "-17614",
        "Son 12 Ay": "-25191"
    },
    {
        "index": "Altın ve Enerji Hariç CA",
        "Son Ay": "3263.12",
        "Yılbaşından Bugüne": "8453.91",
        "Son 12 Ay": "39957.07"
    },
    {
        "index": "Altın (Net)",
        "Son Ay": "-1015",
        "Yılbaşından Bugüne": "-5643",
        "Son 12 Ay": "-17731"
    }]);

const table=creditChangeData(params)
useEffect(() => {
 table.then((response) => {
      const tableDatasets = response;
      setTableData(tableDatasets)
   });
 }, []);



//console.log(tableData)

const rowstr=[]
const tmp_data = {};
tableData.forEach(function(data){
    //console.log("------------")
    //console.log(data["index"])
    const tmp_data = {};
    if(data["index"]=="Cari İşlemler Hesabı" || data["index"]=="Finansman" || data["index"]=="Sermaye Hesabı"){
    tmp_data["kalem"] =<TotalCell>{data["index"]}</TotalCell>
    tmp_data["sonay"] = <TotalCell>{data["Son Ay"].toString()}</TotalCell>
    tmp_data["yılbasi"] = <TotalCell>{data["Yılbaşından Bugüne"].toString()}</TotalCell>
    tmp_data["son12ay"] = <TotalCell>{data["Son 12 Ay"].toString()}</TotalCell>
    } else if (data["index"]=="Doğrudan Yatırımlar" || data["index"]=="Portföy Yatırımları (NVE)" || data["index"]=="Hisse Senetleri" || data["index"]=="Borç Senetleri" || data["index"]=="Efektif ve Mevduatlar" || data["index"]=="Krediler" || data["index"]=="Ticari Krediler" || data["index"]=="Diğer Varlıklar ve Yükümlülükler" || data["index"]=="Özel Çekme Hakları, SDR" || data["index"]=="Rezerv Varlıklar" || data["index"]=="Net Hata Noksan")  {
    tmp_data["kalem"] =<HeadCell>{data["index"]}</HeadCell>
    tmp_data["sonay"] = <HeadCell>{data["Son Ay"].toString()}</HeadCell>
    tmp_data["yılbasi"] = <HeadCell>{data["Yılbaşından Bugüne"].toString()}</HeadCell>
    tmp_data["son12ay"] = <HeadCell>{data["Son 12 Ay"].toString()}</HeadCell>
    } else {
    tmp_data["kalem"] =<DefaultCell>{data["index"]}</DefaultCell>
    tmp_data["sonay"] = <DefaultCell>{data["Son Ay"].toString()}</DefaultCell>
    tmp_data["yılbasi"] = <DefaultCell>{data["Yılbaşından Bugüne"].toString()}</DefaultCell>
    tmp_data["son12ay"] = <DefaultCell>{data["Son 12 Ay"].toString()}</DefaultCell>
    }
    rowstr.push(tmp_data)

});

console.log(rowstr)

const dataTableDatatmp={
    columns: [
        { Header: "", accessor: "kalem", align: "left"},
        { Header: "Son Ay", accessor: "sonay", align: "right"},
        { Header: "Yılbaşından Bugüne", accessor: "yılbasi", align: "right" },
        { Header: "Son 12 Ay", accessor: "son12ay", align: "right" }
  ],
  rows:rowstr,
  };
//console.log(dataTableDatatmp)
return dataTableDatatmp
}

// Setting default values for the props of DataTable
dataTableData.defaultProps = {
  noEndBorder: true,
};

// Typechecking props for the DataTable
dataTableData.propTypes = {
  table: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default dataTableData;
