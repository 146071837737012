/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const thinBarChartData = {
  labels: ['2021-08-31', '2021-09-30', '2021-10-31', '2021-11-30', '2021-12-31',
       '2022-01-31', '2022-02-28', '2022-03-31', '2022-04-30', '2022-05-31',
       '2022-06-30', '2022-07-31', '2022-08-31', '2022-09-30', '2022-10-31',
       '2022-11-30', '2022-12-31', '2023-01-31', '2023-02-28', '2023-03-31',
       '2023-04-30', '2023-05-31', '2023-06-30', '2023-07-31', '2023-08-31',
       '2023-09-30', '2023-10-31', '2023-11-30', '2023-12-31', '2024-01-31'],
  datasets: {
    label: "Altın",
    data: [-11733.,  -8710.,  -7116.,  -3986.,  -2016.,  -1637.,  -1114.,
        -1801.,  -2243.,  -3630.,  -4619.,  -6202.,  -8216., -10593.,
       -13645., -16679., -19387., -23655., -27105., -27411., -28075.,
       -29391., -29894., -30666., -31484., -30207., -28427., -27165.,
       -25686., -21826.],
  },
};

export default thinBarChartData;
