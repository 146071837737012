import { useState } from "react";
import {useEffect } from "react";

import Select from "react-select";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";
import SoftEditor from "components/SoftEditor";
import SoftDropzone from "components/SoftDropzone";
import SoftButton from "components/SoftButton";

import breakpoints from "assets/theme/base/breakpoints";

// Templates
import BSTemplate from "layouts/government/budget/components/BSTemplate";
import RevenueTemplate from "layouts/government/budget/components/RevenueTemplate";
import ExpenditureTemplate from "layouts/government/budget/components/ExpenditureTemplate";
import Debt1Template from "layouts/government/budget/components/Debt1Template";
import Debt2Template from "layouts/government/budget/components/Debt2Template";


//Data
//import rasyolarData from "layouts/realsector/firms/data/rasyolar";

const { values } = breakpoints;



function MainTemplate() {
//const data=rasyolarData(stock.stock)
 return (
  <div>
            <SoftBox mb={0} p={1} pt={0} >
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    Bütçe
                <SoftTypography variant="button" textTransform="capitalize"  fontWeight="bold">
                    (Milyon TL)
                 </SoftTypography>
                 </SoftTypography>
            </SoftBox>

            <SoftBox mb={0}>
                 <BSTemplate/>
            </SoftBox>

            <SoftBox mt={10} width="100%">
              <Divider sx={{height: 8}}/>
             </SoftBox>

          <SoftBox mb={0} p={1} pt={0} >
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    Bütçe Gelirleri
                </SoftTypography>
            </SoftBox>

            <SoftBox mb={0}>
                 <RevenueTemplate/>
            </SoftBox>

            <SoftBox mt={10} width="100%">
              <Divider sx={{height: 8}}/>
             </SoftBox>

          <SoftBox mb={0} p={1} pt={0} >
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    Harcamalar
                </SoftTypography>
            </SoftBox>

            <SoftBox mb={0}>
                 <ExpenditureTemplate/>
            </SoftBox>

            <SoftBox mt={10} width="100%">
              <Divider sx={{height: 8}}/>
             </SoftBox>



          <SoftBox mb={0} p={1} pt={0} >
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    Borçlanma-I
                </SoftTypography>
            </SoftBox>
                 <Debt1Template/>
            <SoftBox mb={0}>
            </SoftBox>

            <SoftBox mt={10} width="100%">
              <Divider sx={{height: 8}}/>
             </SoftBox>


          <SoftBox mb={0} p={1} pt={0} >
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    Borçlanma-II
                </SoftTypography>
            </SoftBox>

            <SoftBox mb={0}>
                 <Debt2Template/>

            </SoftBox>


 </div>
);
}
export default MainTemplate;
