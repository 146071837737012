/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// EditProduct page components
//import ProductImage from "layouts/blog/financialflows/components/ProductImage";
//import ProductInfo from "layouts/blog/financialflows/components/ProductInfo";
//import Socials from "layouts/blog/financialflows/components/Socials";
//import Pricing from "layouts/blog/financialflows/components/Pricing";

import rezerv1 from "layouts/blog/financialflows/components/images/rezervler_nisan_aralık.png";
import rezerv2 from "layouts/blog/financialflows/components/images/rezervler_mayıs_nisan.png";

import bop1 from "layouts/blog/financialflows/components/images/bop_nisan_aralık.png";
import bop2 from "layouts/blog/financialflows/components/images/bop_mayıs_nisan.png";
import bopbanka1 from "layouts/blog/financialflows/components/images/bop_banka_nisan_aralık.png";


import swap1 from "layouts/blog/financialflows/components/images/swap_nisan_aralık.png";
import swap2 from "layouts/blog/financialflows/components/images/swap_mayıs_nisan.png";

import forward1 from "layouts/blog/financialflows/components/images/forward_nisan_aralık.png";
import yp1 from "layouts/blog/financialflows/components/images/yp_nisan_aralık.png";
import yp2 from "layouts/blog/financialflows/components/images/yp_mayıs_nisan.png";
import yp_yukumluluk1 from "layouts/blog/financialflows/components/images/yp_yukumluluk_nisan_aralık.png";
import yp_yukumluluk2 from "layouts/blog/financialflows/components/images/yp_yukumluluk_mayıs_nisan.png";
import yp_yukumluluk3 from "layouts/blog/financialflows/components/images/yp_yukumluluk_mayıs_nisan_weekly.png";

import yp_varlıklar1 from "layouts/blog/financialflows/components/images/yp_varlıklar_nisan_aralık.png";
import yp_varlıklar2 from "layouts/blog/financialflows/components/images/yp_varlıklar_mayıs_nisan.png";
import yp_varlıklar3 from "layouts/blog/financialflows/components/images/yp_varlıklar_mayıs_nisan_weekly.png";

import tp_yukumluluk1 from "layouts/blog/financialflows/components/images/tp_yukumluluk_nisan_aralık.png";
import tp_yukumluluk2 from "layouts/blog/financialflows/components/images/tp_yukumluluk_mayıs_nisan.png";

import tp_varlıklar1 from "layouts/blog/financialflows/components/images/tp_varlıklar_nisan_aralık.png";
import tp_varlıklar2 from "layouts/blog/financialflows/components/images/tp_varlıklar_mayıs_nisan.png";

import mmf1 from "layouts/blog/financialflows/components/images/para_piyasası_aralık_nisan.png";
import api1 from "layouts/blog/financialflows/components/images/api_aralık_nisan.png";

import bankalardoviz from "layouts/blog/financialflows/components/images/bankalar_doviz_mevduat.png";
import bankalarmevduat from "layouts/blog/financialflows/components/images/bankalar_mevduatı.png";

function EditProduct() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={12}>
              <SoftTypography variant="h4" fontWeight="medium">
                Ocak-Nisan 2024 Finansal Hareketler
              </SoftTypography>
              <SoftBox mt={1} mb={2}>
                <SoftTypography variant="body2" color="text">
                    Ocak-Nisan aylarında merkez bankası toplam rezervleri 16.5 Milyar USD azalmıştır.
                    Ödemeler dengesi istatistiklerine baktığımızda ise rezerv varlıklardaki çıkış 25.1
                    Milyar USD olarak görülmektedir. Merkez bankası rezervlerinin detayına bakarsak döviz
                    rezervleri 26.6 Milyar USD düşmüş buna karşılık altın rezervi 10 Milyar USD artmıştır.
                    Ödemeler dengesi finans hesabı parasal hareketlerdir yani 25 Milyar USD ‘lik rezerv cari
                    işlemler hesabı ve net hata noksanı karşılamak için azalmıştır. Merkez bankasının altın
                    rezervi ise 10 Milyar USD artmıştır. Altın rezervindeki artışın çoğu değerleme farkından
                    gelmiştir. Ocak-Nisan ayında altın fiyatı yaklaşık %15 artmıştır ve 10 Milyar USD ‘lik
                    artışın 7 Milyar USD artışı değerleme farkından gelmektedir. Geri kalan artış ise merkez
                    bankasının altın alımından kaynaklanmaktadır. World Gold Council’e göre ilk 3 ayda merkez
                    bankası 30 ton altın (~2 Milyar USD) almıştır.
                    <Link href="https://www.gold.org/goldhub/data/gold-reserves-by-country" target="_blank">
                             <SoftTypography variant="button" fontWeight="regular" color="error">
                                (https://www.gold.org/goldhub/data/gold-reserves-by-country)
                             </SoftTypography>
                    </Link>
                     Nisan ayında da alıma devam ettiği anlaşılıyor.
                    Ocak-Nisan arasında toplam rezervler 16.5 Milyar USD azalmasına rağmen net rezervler 20.8
                    Milyar USD azalmıştır. Rzervlerin detayında ise  merkez bankası ile bankalar arasında yapılan swapların
                    8.3 Milyar USD azaldığını görüyoruz.
                </SoftTypography>
                <SoftBox component="img" src={rezerv1} alt="Aralık Nisan Rezervler"  width="70%" my={2}  ml={16}  />
                <SoftBox component="img" src={bop1} alt="Aralık Nisan BOP"  width="70%" my={2}  ml={16}  />
                <SoftTypography variant="body2" color="text">
                    Bankaların TCMB ile yaptığı swaplar azalmasına rağmen BDDK raporlarına göre bankaların
                    toplam right way swap hacmi 6 Milyar USD artmıştır.  Yani TCMB harici yapılan ve bankaların
                    döviz verip TL aldığı swap işlemleri olan right way swap işlemleri 14.3 Milyar USD artmıştır.
                    Bu işlemler aslında carry trade  dediğimiz işlemlerdir. Right way swaplar hem yurt içi yerleşiklerle
                    hem de yurtdışı bankalar ile yapılan işlemleri kapsamakla beraber işlemlerin büyük çoğunluğu yurtdışı
                    kaynaklıdır. Bu dönemde özel sektör de yurtdışı borçlanmalarını artırmıştır. Dolayısıyla yurtiçi
                    yerleşiklerle yapılan swap işlemleri de bir miktar artmış olabilir. Türk bankalarının spotta TL verip
                    döviz aldığı işlemler de 1.6 Milyar USD artmıştır. Bu dönemde nette 12 Milyar USD swap hacmi artışı
                    olmuştur. 4 aylık cari açık ve net hata noksan toplamının 30 Milyar USD olması, carry girişine rağmen
                    merkez bankasının rezervlerinin azalmasına yol açmıştır. Bu arada netteki swap artışı 12 Milyar USD,
                    brüt swap artışı 16 Milyar USD olmasına rağmen carryden bu kadar döviz girişi olmayabilir. Yani döviz
                    girişi toplam swap artışı olan 6 Milyar USD gibi bir tutar olabilir.  Carry trade ile akla ilk gelen
                    yabancının döviz satıp TL alması ve aldığı TL ile tekrar  swap veya yüksek getirili TL varlıklara
                    yatırılması akla gelir. Fakat Türk Lirası convertible bir para birimidir ve farklı maliyetlerle yurtdışından
                    kolayca Türk lirasına ulaşılabilir. En basit olarak TL ile ithalata yapılmaktadır ve TL ödemeli ithalat
                    sonucunda yurtdışında TL oluşmaktadır. 14 Milyar USD’lik artışı yurtdışı hesaplarda birikmiş olan 500
                    Milyar TL’nin tekrar bankalar tarafından alınması olarak da düşünebiliriz. Her ne kadar 14 Milyar USD döviz
                    girişi olmamış olabilir gibi bir tahmin yapsak da sonuç olarak ithalat ödemeleri de olsa yurtdışı hesaplardaki
                    TL’nin tekrar yurtiçi hesaplara aktarılması gerçekleşmese bu kadar tutar spotta döviz talebi yaratabilir ve
                    rezerv kaybı daha fazla olabilirdi. Başlangıçtaki tahminimiz olan 14 Milyar USD carry girişini doğru gibi
                    düşünebiliriz.
                </SoftTypography>
                <SoftBox component="img" src={swap1} alt="Aralık Nisan Swaplar"  width="70%" my={2}  ml={16}  />
                <SoftTypography variant="body2" color="text">
                Swaplar bankaların bilanço dışı işlemlerinde yer almaktadır. Kullandığımız veriler BDDK’dan alınan aylık bülten
                Bilanço Dışı işlemler tablosundandır. TCMB swapları ile ilgili kaynak ise TCMB taraflı swap işlemleri sayfadır.
                Bankaların kur riskini yönetmek ve likidite sağlamak için çoğunlukla swap işlemlerini kullanırlar. Bununla beraber
                forward, opsiyon gibi farklı türev işlemler de vardır.  Forward işlemlerine baktığımız da bankaların vadede döviz
                alım işlemleri 5.7 Milyar USD, vadede döviz satım işlemleri ise 2.3 Milyar USD artmıştır. Bu işlemleri genellikle
                yurt içi şirketler yapmaktadır.
                </SoftTypography>
                <SoftBox component="img" src={forward1} alt="Aralık Nisan Forward"  width="70%" my={2}  ml={16}  />
                <SoftTypography variant="body2" color="text">
                    BDDK bankaların net yabancı para pozisyonunu aylık bültende USD olarak vermediği için net yabacı para pozisyonu
                    USD değeri için haftalık bültende yer alan en yakın tarihleri kullanacağız.
                </SoftTypography>
                  <SoftBox component="img" src={yp1} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />
                  <SoftTypography variant="body2" color="text">
                      Bu tabloya bakınca bankalar yabancı para varlık ve yükümlülüklerini yaklaşık 13 Milyar USD artırmıştır.
                      Bilanço dışı varlık ve yükümlülük de 10 Milyar USD arttığı için net yabancı para pozisyonu değişmemiştir.
                      Bilanço dışı varlıklardaki artışın büyük kısmının swap ve forward işlemlerinden olduğunu biliyoruz fakat
                      Right Way Swap + FX Forward Alım toplamı aralık itibariyle 58 Milyar USD olmasına rağmen bilanço dışı
                      varlıklar 135 Milyar USD görünüyor. Buradaki farklılılığın ana sebebi türev işlemlerin çift kayıt esasında
                      dayanması ve elde edemediğimiz diğer opsiyon (opsiyonlar da delta yerine nominalle kayıt altına alınmaktadır.)
                      gibi ürünler olabilir. Burayı daha fazla analiz edecek veri setimiz şu an mevcut değil. Ama aslı amacımız olan
                      swapları analiz edecek bilgi setine kısmen sahibiz.Buradan bankaların bilanço içi işlemlerine geçelim. Hem
                      varlık hem yükümlülük tarafı 13 Milyar USD artmıştır.  Yükümlülük tarafına baktığımızda Bankalara Borçlar,
                      Repo İşlemlerinden Sağlanan Fonlar ,İhraç Edilen Menkul Kıymetler, Sermaye Hesaplamasına Dahil Edilecek
                      Borçlanma Araçları kalemleri de toplam 13 Milyar USD artmıştır. Bu artışlar aslında bankaların yurtdışından
                      bulduğu  fonlardır.  Bu tutarları ödemeler dengesinde de görebiliyoruz. 3.1 Milyar USD’lik TRY Mevduat
                      net yükümlülük oluşumunu çıkarırsak bankaların yurtdışından bulduğu fonlama, yükümlülük artışıyla uyumludur.
                      Borç senetleri net yükümlülük oluşumu ödemeler dengesinde 6.8 Milyar USD tutarındadır. Bilanço tarafında ise
                      ihraç edilen menkul kıymetler 3.3 Milyar USD görülmektedir. Bu farklılık aslında iki farklı raporlamanın
                      raporlama tanımlarının farklı olması değerleme farkları gibi sebeplerden olabilir. Sermaye hesaplamasına dahil
                      edilecek borçlanma araçları dediğimiz kalem ise tahvil şeklinde borçlanma tutarı olmaktadır ve  tutarı
                      2.6 Milyar USD’dir. Repo işlemlerinden sağlananlar fonlar kredi olarak girilmiş olabilir.  Kredi ve mevduat
                      tanımından dolayı işlemleri tam ayırmak zor olsa da ödemeler dengesi rakamları ile banka bilançolarının yükümlülük
                      oluşumu kabaca yakın görünüyor. Tanımlar için IMF linki de aşağıda.
                      <Link href="https://www.imf.org/external/np/sta/bop/bopman.pdf " target="_blank">
                             <SoftTypography variant="button" fontWeight="regular" color="error">
                                https://www.imf.org/external/np/sta/bop/bopman.pdf
                             </SoftTypography>
                    </Link>
                  </SoftTypography>
                  <SoftBox component="img" src={yp_varlıklar1} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />
                  <SoftBox component="img" src={yp_yukumluluk1} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />
                  <SoftBox component="img" src={bopbanka1} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />
                <SoftTypography variant="body2" color="text">
                      Bankaların Ocak-Nisan arasındaki yükümlülük artışının sebebini aslında CDS’deki düşüşten kaynaklı olarak
                      yurtdışı borçlanma maliyetlerinin düşüşünden kaynaklandığını söyleyebiliriz.  CDS’in düşmesiyle beraber
                      Türk lirasının değerleneceği düşüncesi bankaların yurtdışı borçlanma tutarını artırmıştır. Swap limitlerinin
                      artırılacağı beklentisi de bu artışa sebep olmuş olabilir. Zaten bu dönemde yurtdışı ile swap işlemlerin
                      arttığından bahsetmiştik. Bankalar yurtdışı borçlanma tutarlarının bir kısmını swap ile TL’ye dönerek kendilerine
                      TL fonlama yaratmaktadır.Az önce bahsettiğimiz gibi bankanın yabancı para yükümlülüğü 13 Milyar USD artmıştır.
                      Dolayısıyla bankanın yabancı para aktifi de benzer tutar da net yabancı para pozisyonu sınırları içinde artmalıdır.
                      Tabloya bakınca yabancı para krediler 12 Milyar USD artmıştır. Yurtdışından sağlanan 13 Milyar USD’lik tutar yabancı
                      para kredi olarak firmalara verildi olarak anlaşılmamalıdır. Bu aslında bir bilanço denkliğidir. Verilen yabancı
                      para krediler büyük çoğunlukla kaydi dövizdir. Yani yabancı para kredi yurtdışı borç ödemesinde veya ithalat ödemesinde
                      kullanılmazsa bankacılık sisteminden gerçek anlamda döviz çıkmamış olur. Buna karşılık yurtdışından alınan döviz ise
                      gerçek anlamda ülkeye döviz girişidir.  Şunu hatırlatmakta da fayda var. Eğer bir şirket yabancı para kredi kullanırsa
                      aynı anda yabancı para mevduatı artar. Fakat aynı dönemde yabancı para mevduat artışlarında herhangi bir atış görünmüyor.
                      Bunun sebebi yabancı para kredi çekenlerin daha sonra krediyi Türk lirası mevduata veya diğer Türk lirası varlıklara
                      dönmüş olmasıdır. Bilanço denkliği diğer işlemler ile gerçekleşmiştir.Ocak-Nisan ayında yaklaşık 15 Milyar USD yani
                      500 Milyar TL net hata noksandan çıkış vardır. YP Kredi + TL Kredi toplamı yaklaşık 1.5 trilyon TL’dir.
                      TL mevduat artışı 268 Milyar TL dir. 1.2 trilyonluk hareketi buradan açıklayabiliriz. Sistem dışına çıkan da 500
                      Milyar TL ve para piyasası fonlarına 200 Milyar TL gibi bir para girişi olmuştur.
                </SoftTypography>
                  <SoftBox component="img" src={tp_varlıklar1} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />
                  <SoftBox component="img" src={tp_yukumluluk1} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />
                  <SoftBox component="img" src={mmf1} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />
                  <SoftBox component="img" src={api1} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />
                 <SoftTypography variant="h4" fontWeight="medium">
                    Nisan-Mayıs 2024 Finansal Hareketler
                </SoftTypography>
                  <SoftTypography variant="body2" color="text">
                     Nisan-Mayıs aylarında ise toplam rezervler 19.3 Milyar USD artmıştır. Toplam rezervlerin artması yurtdışından
                     para girişi veya sistem dışındaki dövizin veya altınının sisteme girip merkez bankası tarafından alınması ile
                     olur. Mayıs ayı ödemeler dengesi verilerine baktığımızda merkez bankası döviz rezerv artışı 17.5 milyar USD.Bu tutarın
                     4.5 Milyar USD net hata noksan geri kalanı diğer kalemlerden gelmiştir. Cari açığın düşük olması da rezerv birikimini
                     güçlendirmiştir. TCMB döviz rezerv artışı 17.8 Milyar USD altın rezerv artışı 1.5 Milyar USD. Net rezervler ise 31.3 Milyar
                     USD swap hariç net rezerv ise 51 Milyar USD artmıştır. TCMB’nin bankalarla yaptığı swap net rezervler içinde
                     gösterildiği için swap hariç net rezervin 19.5 Milyar USD’lik kısmı swapların kapatılmasıyla oluşmuştur.
                     Toplam rezerv 19.3 Milyar USD artmışken net rezervin 31.3 Milyar artması demek 12 Milyar USD’lik bir tutarın
                     brüt rezervlerden net rezervlere kaydırılması demektir. Şimdi bu hareketlerin detaylarını yine banka bilançosu
                     ile ilişkilendirelim.
                  </SoftTypography>
                  <SoftBox component="img" src={rezerv2} alt="Aralık Nisan Rezervler"  width="70%" my={2}  ml={16}  />
                  <SoftBox component="img" src={bop2}alt="Aralık Nisan BOP"  width="70%" my={2}  ml={16}  />
                  <SoftTypography variant="body2" color="text">
                      Öncelikle swap işlemlerine bakalım. Merkez bankası ile yapılan swap işlemlerinin azalması demek bankaların
                      bilanço dışı işlemlerinin 19 Milyar USD azalması demektir. Haftalık yabancı para pozisyon verilerine baktığımızda
                      ise bilanço dışı yabancı para pozisyonu 8.2 Milyar USD azalmıştır. Daha önce bahsettiğimiz gibi bilanço dışı
                      işlemlerin muhasebe işlemlerinden dolayı net değişime bakıyoruz. TCMB ile yapılan swaplar 19.5 Milyar USD azalmış
                      fakat yabancı para bilanço dışı işlemler 8.22 Milyar USD azaldığı için yurtdışı bankalarla  yapılan swap işlemleri
                      11 Milyar USD artmıştır gibi düşünebiliriz. Yani piyasada konuşulan anlamda 11 Milyar USD carry pozisyonları
                      artmıştır diyebiliriz. Fakat Swap pozisyonlarına bakınca TCMB hariç swap işlemlerinin de 19.4 Milyar USD arttığını
                      görüyoruz. Yabancı para net pozisyon raporlamasına göre 8.2 Milyar USD’lik henüz açıklayamadığım bir tutar var.
                      Diğer bilanço kalemlerinde farkı bulunca  tekrar değerlendireceğim. Ocak-Nisan  ayı arasında ise yaklaşık 14 Milyar
                      USD tahmin etmiştik. Kabaca Ocak_Mayıs ayında 25 Milyar USD carry pozisyonu artmıştır.
                  </SoftTypography>
                    <SoftBox component="img" src={swap2} alt="Aralık Nisan Swaplar"  width="70%" my={2}  ml={16}  />
                  <SoftTypography variant="body2" color="text">
                     Mayıs ayında 11 Milyar USD carry girişi +19.3 Milyar USD (TCMB toplam rezerv) yabancı girişi + sistem dışı girişi
                     ile toplamda 30 Milyar USD para girişi olmuştur. Bu da toplam TL miktarını yaklaşık 1 trilyon artırmıştır. Bu işlemleri
                     daha iyi tahmin edebilmek için ödemeler dengesi verilerinin (özellikle net hata noksan kalemi) açıklanması gerekmektedir.
                  </SoftTypography>
                  <SoftBox component="img" src={yp_varlıklar2} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />
                  <SoftBox component="img" src={yp_yukumluluk2} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />
                   <SoftTypography variant="body2" color="text">
                      Swap harçi net rezervin 51 Milyar USD artmış bunun yaklaşık 39 Milyar USD döviz girişi ve swapların kapatılıp
                      dövizin  TCMB ye satılması ile oluşmuştur. Şimdi 12 Milyar USD’lik tutarı analiz edelim. Bunu analiz ederken
                      ise bankaların yabancı para bilanço işlemlerini inceleyelim. Yabancı para bilanço dışı işlemlerde 8 Milyar USD
                      azalış vardır. Bankalar net yabancı para pozisyonunu koruyacağı için yabancı para bilanço işlemlerini de 8 Milyar
                      USD küçültmüşler Nisan-Mayıs arasında. Aslında dolarizasyona bakarken de sadece dth’lara veya yabancı para kredilere
                      bakmak doğru olmayabilir. Yabancı para bilançonun büyüklüğüne bakmak daha anlamlı olabilir.
                  </SoftTypography>
                     <SoftBox component="img" src={yp2} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />
                     <SoftBox component="img" src={yp_varlıklar3} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />
                     <SoftBox component="img" src={yp_yukumluluk3} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />
                  <SoftTypography variant="body2" color="text">
                      Aylık bilançoya bakılınca yabancı para varlıklar 2 Milyar USD yabancı para yükümlülükler 12 Milyar USD
                      azalmıştır. Haftalık yabancı para pozisyonundaki rakamlarla örtüşüyor. Yabancı para yükümlülüklerdeki
                      azalışın 9.2 Milyar USD’si mevduat azalışından kaynaklanmaktadır.  Yani yerliler döviz mevduatlarını bozmuştur
                      diyebiliriz. (Dövizlerin sistemden çıkmadığını varsayıyoruz. DTH çekip yastık altı da DTH azalışıdır ama bu
                      hareket o değil.) Peki yerlilerin bozduğu dövizi merkez bankası almış olabilir mi ? Hayır! Çünkü bankacılık
                      sitemindeki DTH’ların çoğu kaydi dövizdir ve sistemdeki net döviz etkilenmez. Fakat DTH karşılığında bankalar
                      merkez bankasında % 25 oranında zorunluk karşılık tutmak zorunda olduğun için  azalan 9 Milyar USD için yaklaşık
                      2 Milyar USD’lik merkez bankası brüt rezervinde azalış olacaktır.  Aylık verilere göre TCMB Alacak hesabında da
                      3 Milyar USD azalış vardır. Açıklamaya çalıştığımız 12 Milyar USD’lik tutarın 5.5 Milyar USD’lik kısmını buradan
                      kaynaklanmaktadır. Haftalık bilançoda ise TCMB alacaklar hesabı 8.5 Milyar USD, Bankalardan alacaklar tutar ise
                      2.2 Milyar USD toplam 10.7 Milyar USD, aylık da ise bu tutar 9.4 Milyar USD’dir. Burada yine raporlama farklılıkları
                      olabilir ama bu kalemde brüt rezerve etki etmiştir ve tutarı yaklaşık 10 Milyar USD diyebiliriz.  Bunu anlamanın en iyi
                      yolu TCMB analitik bilanço verilerine bakmaktır. Analitik bilanço da bankalar döviz mevduatı yaklaşık 8 Milyar USD
                      düşmüştür.  Aslında TCMB’nin net rezervinin artıran etkenlerden biri de hesaplar arasındaki değişikliktir.
                  </SoftTypography>
                     <SoftBox component="img" src={bankalardoviz} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />
                  <SoftTypography variant="body2" color="text">
                       Daha önce dediğimiz gibi bilançonun varlık ve yükümlülük tarafı denk olmak zorundadır. Mesela bankalar hem
                       swap kalemlerindeki hem de tcmb hesaplarındaki tuttukları dövizleri merkez bankasına satmışlardır. Bilançonun
                       denk olması için bunların yerine başka kalemleri artırması gerekmektedir. Dolayısıyla bilanço içi varlıklar
                       kaleminde azalttığı TCMB hesaplarının büyük kısmını kredi vererek denkleştirmişlerdir. Bilanço içindeki farkı ve
                       kaybettiği TCMB swaplarını ise yurtdışı swaplarla telafı etmişlerdir. Toplamda yabancı para bilanço küçülmesi
                       olmasına rağmen kredi vererek ve swap ile bunu telafi etmişlerdir.
                  </SoftTypography>
                  <SoftTypography variant="body2" color="text">
                       Bu açıklamalar farklı bilançoları inceleyerek bilanço denkliğinden yola çıkarak finansal hareketleri incelemeye
                       çalışan mekanik bir  analizdir.  Rakamlar tam olarak uyumlu olmayabilir çünkü  her bir bilançonun veya raporun
                       kendine ait hesaplama standartları, tanım farklılıkları ve değerleme ve zaman  farklılıkları vardır. Dolayısıyla
                       bütün rakamları tutturmak çok zordur. Mesela BDDK sitesinde yer alan yurtdışı bankalar ile ödemeler dengesi
                       istatistiklerinde yer alan yurtdışı bankalar kapsamı tanım farkından dolayı farklı olabilir.
                  </SoftTypography>
                  <SoftTypography variant="h4" fontWeight="medium">
                       Analiz
                   </SoftTypography>
                   <SoftTypography variant="body2" color="text">
                        Bu verileri mekanik olarak yorumlamak yerine ekonomik aktörlerin davranışlarını analiz etmeye çalışalım. Öncelikle
                        TL faizler yüksek oluşu ve TL nin değerli kalması bu davranışların ana sebebi olmaktadır. TL faizler yüksek olduğu
                        için firmalar yabancı para kredi kullanmayı seçmişlerdir.  Nisan-Mayıs ayında yabancı para krediler 9.5 Milyar USD
                        artmıştır. YP kredi faizlerinin görece düşük olması ve kurun stabil kalacağı inancı yabancı para kredilerin artışına
                        neden olmuştur.  Bununla beraber TCMB tarafından yabancı para kredilere önlem alınacağı haberi de etkili olmuştur. Bu
                        kredilerin TL karşılığı ise 261 Milyar TL’dir. Bir banka YP kredi verdiğinde bilançosunda YP Mevduat oluşur. Bunu şu
                        şekilde düşünebiliriz. Mesela bir bankadan kredi aldığınızda, hesabınıza çektiğiniz kredi tutarı yatmaktadır.
                        Mevduat hesabınız da aynı bankada da bulunmaktadır. Dolayısıyla banka kredi verdiğinde hem aktifi hem pasifi
                        artar. Burada da durum aynıdır. YP kredi çekildiği için YP mevduatın artması gerekir. Yani 9.5 Milyar USD kredi karşılığında
                        9.5  Milyar USD mevduat oluşması gerekir. Fakat YP mevduatlarda da 9.5 Milyar USD düşüş olmuştur. Bu rakamların toplamı 600
                        Milyar  TL gibi  tutardır.  TL Kredi artışı ise 100 Milyar TL’dir. Aynı mantıkla baktığımızda toplam TL mevduat artışı YP
                        Kredi ve YP Mevduattan gelen tutarlarla 700 Milyar TL gibi tutar olması gerekir. Fakat TL mevduat artışı  600  Milyar TL’dir.
                        Aynı dönemde para piyasası fonlarında artış 100 Milyar TL’dir.  Aradaki TL farkı para piyasası fonlarına gitmiş bankalar ise
                        repo yoluyla bu parayı geri almıştır.
                        </SoftTypography>
                        <SoftTypography variant="body2" color="text">
                         Repo işlemlerinden sağlanan fonlar 450 Milyar TL artmıştır. Bunun 100 Milyarı TCMB ile yapılan işlemler,
                         100 Milyarı da para piyasası fonlarındandır. Geri kalan 250 Milyar TL ise yurtdışı ile işlemler olabilir
                         fakat bu tutarları menkul kıymet istatistiklerinde göremiyoruz. Daha önce de swap işlemlerinde açıklayamadığımız
                         8 Milyar USD tutar vardı ve bu rakam da Repo işlemlerine yakın bir tutar. Gecen hafta yurtdışı işlemler ile ilgili
                         Bloomberg de bir makale yayınlanmıştı. Bu konu hakkında daha fazla bilgi sahibi değiliz.
                         <Link href="https://www.fastbull.com/news-detail/turkey-bond-inflow-figures-swell-with-local-banks-4296511_0 " target="_blank">
                             <SoftTypography variant="button" fontWeight="regular" color="error">
                                https://www.fastbull.com/news-detail/turkey-bond-inflow-figures-swell-with-local-banks-4296511_0
                             </SoftTypography>
                         </Link>
                         </SoftTypography>
                         <SoftTypography variant="body2" color="text">
                            YP kredilerin 9.5 Milyar USD artışı  bankanın bilanço içi varlığını artırttığı için bilanço dışı varlık olan swap
                            9.5 Milyar USD azaltmış ve merkez bankasına satılmıştır. Bu da merkez bankasının swap hariç net rezervlerini 9.5 Milyar
                            USD artırmıştır. Bu işlem sonucunda piyasadaki TL likiditesi değişmemiştir. Çünkü bankalar swap ile aldığı TL fonlamayı
                            kapatmış döviz karşılığı kalıcı TL likiditesi oluşturmuş bunu da bilanço içi varlıklarda göstermiştir.  Bankalar bu işlemi
                            yapmak istemelerinin nedeni daha düşük faizle değerlenirdikleri swapdaki dövizi merkez bankasına satıp daha yüksek faizli
                            yabancı para kredi vermek istemesidir. Hem yabancı para kredi talebi hem de dövize erişimin artık kolay olması bankaları
                            bu işlem için motive etmiştir.
                         </SoftTypography>
                  <SoftBox component="img" src={tp_varlıklar2} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />
                  <SoftBox component="img" src={tp_yukumluluk2} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />
                  <SoftTypography variant="body2" color="text">
                    DTH tutarı da 9.5 Milyar USD azalmıştır. Bu da TL mevduat tutarını artırmıştır. Daha önce bahsedildiği gibi bu işlem de bilanço
                    içi net pozisyonu azaltmıştır. (Yaklaşık 10 Milyar USD diyebiliriz) Bu da bankalar yabancı para bilanço dışı net pozisyonunu swap
                    azaltarak dengelemişlerdir. Bu işlemler sonucunda bankanın elinde uzun vadeli TL biriktiği (swaplar kapatılıp TL alınca bankalar kalıcı
                    TL elde eder) için bu dönemde faizler de gerilemev görülmüştür.  Yine bahsedildiği gibi DTHlar azaldığı için zorunluk karşılıklar azalmış
                    ve TCMB’ye satılmış bu da merkez bankasının net rezervlerinin artışına sebep olmuştur. (2 Milyar USD)  . TL Mevduat faizlerinde gerileme
                    sonucunda TCMB zorunlu karşılıkları artırmış ve yaklaşık 620 Milyar TL piyasadan likidite çekilmilştir. TL bilançodaki TCMB alacaklar ve
                    zorunlu karşılıklar tutarında yaklaşık 550 Milyar TL artış vardır.Buna karşılık bankalar bu tutarı TCMB ile yaptıkları APİ işlemlerini
                    artırarak ve merkez bankasındaki yabancı para serbest mevduatı azaltarak karşılaşmışlardır. Bankaların yabancı para serbest mevduatı
                    8 Milyar USD’yi TCMB’ ye satıp TL elde edip bunu da TL zorunlu karşılıklarda kullanmışlar diyebiliriz. Bu işlemde merkez bankasının
                    net rezervini artırmıştır. TL bilançodaki diğer işlemler ise menkul kıymet alımıdır. Hazine borçlanmasını artırdığı için bu rakamda artmaktadır
                  </SoftTypography>
                  <SoftTypography variant="body2" color="text">
                    Yabancı para bilançoda bankalara borçlar ve bankalardan alacaklar tutarı birbirine yakın olarak detaylı incelemedik fakat bu işlemler
                    genelde ithalat, ihracat miktarlarına, yurtdşı bankalar yatırılan teminatlar gibi düşünülebilir. Haftalık bilanço da yabancı para binço
                    içi yükümlülük net yabancı paraya göre düşük görülüyor fakat bu elimizdeki veri seti ile ilgilidir.
                    Bu sırada yabancı bankaların carry işlemleri de devam etmiş ve 11 Milyar USD’lik bir swap miktarı artmıştır.  Bu gelen dövizi de yabancı
                    para bilanço içi oluşan net döviz pozisyon farkını kapatmak için kullanmışlardır.
                  </SoftTypography>
                     <SoftBox component="img" src={bankalarmevduat} alt="Aralık Nisan YP"  width="70%" my={2}  ml={16}  />

              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditProduct;
