/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
//import typography from "assets/theme/base/typography";

function configs(labels_x, datasets) {
  return {
    options: {
      xAxis: {
        categories: labels_x,
        gridLineDashStyle: "dash",
        gridLineWidth: 1,
        type: 'datetime',
        labels: {
        rotation: -45,
        formatter: function() {
                //let tempdate = new Date(labels_x[this.pos])
                //return this.chart.time.dateFormat('%Y-%m-%d', tempdate)
                return labels_x[this.pos]
            }

  },
      },
      yAxis: {
        gridLineDashStyle: "dash",
        gridLineWidth: 1,
        lineWidth: 1,
        tickWidth: 1,
        opposite:false
      },
      legend: {
            enabled: true
        },
       /*       tooltip: {
        pointFormatter: function() {
          let tempdate = new Date(labels_x[this.x])
          return   '<span style="color:'+this.series.color+'"> <b>'+ this.series.name +'</b></span>: <b>'+this.y+ '</b><br/>';
        },
        shared: true,
        valueDecimals: 1,
    },*/
    tooltip: {
            outside: true,
            split: false,
            shared: true,
            useHTML: true,
            headerFormat: '<span style="font-size:14px"><strong>{point.x}</strong></span><table>',
            pointFormat: '<p style="font-size:12px;margin:0px;padding-top:1px;padding-bottom:1px;color:{series.color};">{series.name} <strong>{point.y}</strong></p>',
            //pointFormatter: function() {
            //return  '<p style="font-size:12px;margin:0px;padding-top:1px;padding-bottom:1px;color:' + this.series.color + '>' +this.series.name + '<strong>' + this.y+'</strong></p>'
            //},
            //valueDecimals: 2,
            //backgroundColor: 'black',
            borderWidth: 0,
            style: {
                width: '300px',
                padding: '0px'
            },
            shadow: false
        },
      series:  datasets,
   rangeSelector: {
    enabled:false
    },
    scrollbar: {
          enabled: false,
        },
exporting: {
      enabled: true
    },
        navigator: {
          enabled: true,
           maskFill: 'rgba(16, 16, 16, 0.5)',
          series: {
    type: 'areaspline',
    fillOpacity: 0.05,
    dataGrouping: {
        smoothed: true
    },
    lineWidth: 1,
    marker: {
        enabled: false
    }
},
          height: 35,
          xAxis: {
                labels: {
                    enabled: false
                }
            },
                      yAxis: {
                labels: {
                    enabled: true
                }
            },
        },
      accessibility: {
    enabled: false
  },
      chart: {
        type: "areaspline",
        height: 540,
      },
      colors: ["#280659", "#900c3e", "#C70039", "#D54800", "#FF5733"],
      fontSize:0.2,
      plotOptions: {
        areaspline: {
          fillOpacity: 0.1,
        },
      },
      title: {
        text: "",
      },
      credits: {
        enabled: true,
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 320,
            },
            chartOptions: {
              legend: {
                enable: false,
              },
              yAxis: {
                gridLineDashStyle: "longdash",
                lineWidth: 1,
                tickWidth: 1,
                labels: {
                  align: "left",
                  x: 0,
                  y: -5,
                },
                title: {
                  text: null,
                },
              },
              subtitle: {
                text: null,
              },
            },
          },
        ],
      },
    },
  };
}


export default configs;
