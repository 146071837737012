import { ChartOptions, ChartData } from "chart.js";

// const params=["07112019","30032021","23032023","22032024","05022025","30052040","14012041"]


export const getChartData = (
  data1,data2,data3,data4,data5,data6,data7,labels
): ChartData => ({
  labels: labels,
  datasets: [
     {
      label: "07112019",
      borderColor:  "#280659",
      backgroundColor: "rgba(40,6,89,0.1)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: data1,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: true,
    },
      {
      label: "30032021",
      borderColor:  "#900c3e",
      backgroundColor: "rgba(144,12,62,0.1)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: data2,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: true,
    },
          {
      label: "23032023",
      borderColor:  "#D4AF00",
      backgroundColor: "rgba(212,175,0,0.2)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: data3,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: true,
    },
          {
      label: "22032024",
      borderColor:  "#D54800",
      backgroundColor: "rgb(213, 72, 0,0.1)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: data4,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: true,
    },
          {
      label: "05022025",
      borderColor: "#1D7874",
      backgroundColor: "rgb(29, 120, 116,0.3)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: data5,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
    },
    {
      label: "14022034",
      borderColor:  "#280659",
      backgroundColor: "rgba(40,6,89,0.1)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: data4,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
    },
          {
      label: "30052040",
      borderColor:  "#280659",
      backgroundColor: "rgba(40,6,89,0.3)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: data6,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
    },
              {
      label: "14012041",
      borderColor:  "#900c3e",
      backgroundColor: "rgba(144,12,62,0.3)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: data7,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
    },
  ]
});

export const chartOptions: ChartOptions =  {
    interaction:
    {mode:'index'},
  elements: {
    line: {
      tension: 0.1
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
  legend: {display: true,
  position:'bottom'},
    filler: {
      propagate: true
    }
  },
  scales: {
    xAxes: [
      {
        display: true,
        ticks: {
          autoSkip: false,
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        }
      }
    ]
  }
  };

