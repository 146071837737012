import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import LoanGraphs from "layouts/financialsector/banking/loans/components/loanGraphs";

// Data
import loanGrowth from "layouts/financialsector/banking/loans/data/loanGrowth";


function Graphs() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [openMenu, setOpenMenu] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [itemValue, setItemValue] = useState(0);
  const [graph, setGraph] = useState("0-0");
  var graphval;

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  //const handleSetTabValue = (event, newTabValue) => setTabValue(newTabValue);
  const handleSetItemValue = (event, newItemValue) => setItemValue(newItemValue);
  const handleSetTabValue = (event, newTabValue) => {
  setTabValue(newTabValue)
  graphval=newTabValue+"-"+"0"
  setGraph(graphval)
  };


  const handleSetGraph = (event, newGraph) => {
  handleCloseMenu()
  graphval=tabValue+"-"+event.target.value
  setGraph(graphval)
  };

  const handleOpenMenu = ({ currentTarget }) => setOpenMenu(currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      onClick={handleSetItemValue}
    >
      <MenuItem onClick={handleSetGraph} value="3">Kamu</MenuItem>
      <MenuItem onClick={handleSetGraph}  value="4">Yerli Özel</MenuItem>
      <MenuItem onClick={handleSetGraph} value="5">Yabancı</MenuItem>
      <SoftBox component="div" bgColor="secondary" opacity={0.3} width="100%" height="1px" my={1} />
      <MenuItem onClick={handleSetGraph} value="6" >
        <SoftTypography variant="inherit" color="error">
          Sektör
        </SoftTypography>
      </MenuItem>
    </Menu>
  );

  const params=["Sektör-TP Krediler(TL)", "Sektör-YP Krediler(TL)", "Sektör-Toplam Krediler(TL)"]
  const params2=["Sektör-TP Ticari Krediler(TL)", "Sektör-YP Ticari Krediler(TL)", "Sektör-Toplam Ticari Krediler(TL)"]
  const params3=["Sektör-Toplam Tüketici Kredileri ve Bireysel Kredi Kartları(TL)"]
  const params4=["Kamu-TP Krediler(TL)", "Kamu-YP Krediler(TL)", "Kamu-Toplam Krediler(TL)"]
  const params5=["Kamu-TP Ticari Krediler(TL)", "Kamu-YP Ticari Krediler(TL)", "Kamu-Toplam Ticari Krediler(TL)"]
  const params6=["Kamu-Toplam Tüketici Kredileri ve Bireysel Kredi Kartları(TL)"]
  const params7=["Yerli Özel-TP Krediler(TL)", "Yerli Özel-YP Krediler(TL)", "Yerli Özel-Toplam Krediler(TL)"]
  const params8=["Yerli Özel-TP Ticari Krediler(TL)", "Yerli Özel-YP Ticari Krediler(TL)", "Yerli Özel-Toplam Ticari Krediler(TL)"]
  const params9=["Yerli Özel-Toplam Tüketici Kredileri ve Bireysel Kredi Kartları(TL)"]
  const params10=["Yabancı-TP Krediler(TL)", "Yabancı-YP Krediler(TL)", "Yabancı-Toplam Krediler(TL)"]
  const params11=["Yabancı-TP Ticari Krediler(TL)", "Yabancı-YP Ticari Krediler(TL)", "Yabancı-Toplam Ticari Krediler(TL)"]
  const params12=["Yabancı-Toplam Tüketici Kredileri ve Bireysel Kredi Kartları(TL)"]

  return (
    <Card>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SoftTypography variant="h6">Kredi Büyümesi (13HO)</SoftTypography>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="60%">
          <SoftBox width="90%">
              <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab label="Toplam" />
                <Tab label="Ticari" />
                <Tab  label="Tüketici" />
              </Tabs>
            </AppBar>
          </SoftBox>
          {renderMenu()}
          <SoftTypography
            color="text"
            onClick={handleOpenMenu}
            sx={{ lineHeight: 0, cursor: "pointer" }}
          >
            <Icon fontSize="default">more_vert</Icon>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox p={1} mt={0} width="100%" height="25.5rem">
        <LoanGraphs data={loanGrowth(params)} date=""  value={graph} index={"0-0"} />
        <LoanGraphs data={loanGrowth(params2)} date=""  value={graph} index={"1-0"} />
        <LoanGraphs data={loanGrowth(params3)} date=""  value={graph} index={"2-0"} />
        <LoanGraphs data={loanGrowth(params4)} date=""  value={graph} index={"0-3"} />
        <LoanGraphs data={loanGrowth(params5)} date=""  value={graph} index={"1-3"} />
        <LoanGraphs data={loanGrowth(params6)} date=""  value={graph} index={"2-3"} />
        <LoanGraphs data={loanGrowth(params7)} date=""  value={graph} index={"0-4"} />
        <LoanGraphs data={loanGrowth(params8)} date=""  value={graph} index={"1-4"} />
        <LoanGraphs data={loanGrowth(params9)} date=""  value={graph} index={"2-4"} />
        <LoanGraphs data={loanGrowth(params10)} date=""  value={graph} index={"0-5"} />
        <LoanGraphs data={loanGrowth(params11)} date=""  value={graph} index={"1-5"} />
        <LoanGraphs data={loanGrowth(params12)} date=""  value={graph} index={"2-5"} />
        <LoanGraphs data={loanGrowth(params)} date=""  value={graph} index={"0-6"} />
        <LoanGraphs data={loanGrowth(params2)} date=""  value={graph} index={"1-6"} />
        <LoanGraphs data={loanGrowth(params3)} date=""  value={graph} index={"2-6"} />
      </SoftBox>
    </Card>
  );
}

export default Graphs;
