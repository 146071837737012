import { ChartOptions, ChartData } from "chart.js";
import { InputVariables, OutputVariables } from "layouts/realsector/firms/components/Summary/components/interfaces";



let delayed;
export const getChartData1 = (
  plannerData,plannerData2,labels
): ChartData => ({
  labels: labels,
  datasets: [
     {
      label: "Kaldıraç Oranı",
      borderColor:  "#900c3e",
      backgroundColor: "rgba(144,12,62,0.5)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: plannerData,
      pointRadius: 2.2,
    },
    {
      label: "Finansal Borç Oranı",
      borderColor:  "#D4AF37",
      backgroundColor: "rgba(212,175,55,0.9)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: plannerData2,
      pointRadius: 2.2,
    },
  ]
});

export const chartOptions1: ChartOptions =  {
    interaction:
    {mode:'index'},
  elements: {
    line: {
      tension: 0.1
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
  //stacked100: { enable: true, replaceTooltipLabel: false },
  legend: {
  display: true,
  position:'bottom',
  align: 'center',
  labels: {
        usePointStyle: true,
        font: { size: 9.5  }
      }
  },
    filler: {
      propagate: true
    }
  },
      scales: {
        y: {
          grid: {
            drawBorder: true,
            display: true,
            drawOnChartArea: true,
            drawTicks: true,
            borderDash: [5, 5],
          },
          ticks: {
            display: true,
            padding: 2,
            font: {
              size: 10,
              style: "normal",
              lineHeight: 1.6,
            },
          },
        },
        x: {
          grid: {
            drawBorder: true,
            display: true,
            drawOnChartArea: true,
            drawTicks: true,
            borderDash: [5, 5],
          },
          ticks: {
            display: true,
            padding: 0,
            font: {
              size: 10,
              style: "normal",
              lineHeight: 1.6,
            },
          },
        },

      },

  };
