/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
import get_government_data from "layouts/api";


function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}

function convertdata(data) {
  let result = [];
  for (let [key, value] of Object.entries(data)) {
	         if (key != "date") {
			   result.push({ title: key, values: value});
			   }
	    }
  return result;
}


function budgetData(params) {
    const defaultData= {revenue_tipcg: [{'title': 'a) Gelir Vergisi', 'values': [ 100,10,5,145,200,40,210]}],
                        revenue_tonp: [{'title': 'b) Motorlu Taşıtlar Vergisi', 'values': [ 100,10,5,145,200,40,210]}],
                        revenue_tongs: [{'title': 'a) Dahilde Alınan Katma Değer Vergisi', 'values': [ 100,10,5,145,200,40,210]}],
                        revenue_tonitt: [{'title': 'a) Gümrük Vergileri', 'values': [ 100,10,5,145,200,40,210]}],
                        revenue_std: [{'title': '5. Damga Vergisi', 'values': [ 100,10,5,145,200,40,210]}],
                        revenue_fee: [{'title': '6. Harçlar', 'values': [ 100,10,5,145,200,40,210]}],
                        revenue_tonnc: [{'title': '7. Başka Yerde Sınıflandırılmayan Diğer Vergiler', 'values': [ 100,10,5,145,200,40,210]}],
                        revenue_pi: [{'title': 'II-Teşebbüs ve Mülkiyet Gelirleri', 'values': [ 100,10,5,145,200,40,210]}],
                        revenue_gasr: [{'title': 'III-Alınan Bağışlar ve Yardımlar ile Özel Gelirler', 'values': [ 100,10,5,145,200,40,210]}],
                        revenue_isf: [{'title': 'IV-Faizler, Paylar ve Cezalar', 'values': [ 100,10,5,145,200,40,210]}],
                        revenue_cr: [{'title': 'V-Sermaye Gelirleri', 'values': [ 100,10,5,145,200,40,210]}],
                        revenue_rc: [{'title': 'VI-Alacaklardan Tahsilat', 'values': [ 100,10,5,145,200,40,210]}],
                        revenue_rfsbi: [{'title': 'Özel Bütçeli İdarelerin Gelirleri', 'values': [ 100,10,5,145,200,40,210]}],
                        revenue_rfrsi: [{'title': 'Düzenleyici ve Denetleyici Kurumların Gelirleri', 'values': [ 100,10,5,145,200,40,210]}],
                        expense_interestpayment: [{'title': 'Faiz Giderleri', 'values': [ 100,10,5,145,200,40,210]}],
                        exc_expense_interestpayment: [{'title': 'Faiz Dışı Giderler', 'values': [ 100,10,5,145,200,40,210]}],
                        finance_budget_escrow: [{'title': 'Bütçe Emanatleri', 'values': [ 100,10,5,145,200,40,210]}],
                        finance_retain: [{'title': 'Avanslar', 'values': [ 100,10,5,145,200,40,210]}],
                        finance_debt: [{'title': 'Borçlanma(Net)', 'values': [ 100,10,5,145,200,40,210]}],
                        finance_loan: [{'title': 'Borç Verme(Net)', 'values': [ 100,10,5,145,200,40,210]}],
                        finance_privatization: [{'title': 'Özelleştirme Geliri', 'values': [ 100,10,5,145,200,40,210]}],
                        finance_tmsf: [{'title': 'TMSF Gelir Fazlaları', 'values': [ 100,10,5,145,200,40,210]}],
                        finance_cash: [{'title': 'Kasa/Banka ve Diğer İşlemler', 'values': [ 100,10,5,145,200,40,210]}],
                        labels: [ "","","","","","",""]
    }
    //const [chartData, setChartData] = useState(null);
    const [data, setData] = useState(defaultData);
    //const dataFetchedRef = useRef(false);
    const fetchData = async () => {
        const config={"func":"budget","params":params};
        const response = await get_government_data(config);
        const tmpdata = await response.data;

        const revenue_tipcg = convertdata(tmpdata["1. Gelir ve  Kazanç Üzerinden Alınan Vergiler"]);
        const revenue_tonp = convertdata(tmpdata["2. Mülkiyet Üzerinden Alınan Vergiler"]);
        const revenue_tongs = convertdata(tmpdata["3. Dahilde Alınan Mal ve Hizmet Vergileri"]);
        const revenue_tonitt = convertdata(tmpdata["4. Uluslararası Ticaret ve Muamelelerden Alınan Vergiler"]);
        const revenue_std = convertdata(tmpdata["5. Damga Vergisi"]);
        const revenue_fee = convertdata(tmpdata["6. Harçlar"]);
        const revenue_tonnc = convertdata(tmpdata["7. Başka Yerde Sınıflandırılmayan Diğer Vergiler"]);
        const revenue_pi = convertdata(tmpdata["II-Teşebbüs ve Mülkiyet Gelirleri"]);
        const revenue_gasr = convertdata(tmpdata["III-Alınan Bağışlar ve Yardımlar ile Özel Gelirler"]);
        const revenue_isf = convertdata(tmpdata["IV-Faizler, Paylar ve Cezalar"]);
        const revenue_cr = convertdata(tmpdata["V-Sermaye Gelirleri"]);
        const revenue_rc = convertdata(tmpdata["VI-Alacaklardan Tahsilat"]);
        const revenue_rfsbi = convertdata(tmpdata["Özel Bütçeli İdarelerin Gelirleri"]);
        const revenue_rfrsi = convertdata(tmpdata["Düzenleyici ve Denetleyici Kurumların Gelirleri"]);
        const expense_interestpayment = convertdata(tmpdata["Faiz Giderleri"]);
        const exc_expense_interestpayment = convertdata(tmpdata["Faiz Dışı Giderler"]);
        const finance_budget_escrow=convertdata(tmpdata["Bütçe Emanetleri"]);
        const finance_retain=convertdata(tmpdata["Avanslar"]);
        const finance_debt=convertdata(tmpdata["Borçlanma(Net)"]);
        const finance_loan=convertdata(tmpdata["Borç Verme(Net)"]);
        const finance_privatization=convertdata(tmpdata["Özelleştirme Geliri"]);
        const finance_tmsf=convertdata(tmpdata["TMSF Gelir Fazlaları"]);
        const finance_cash=convertdata(tmpdata["Kasa/Banka ve Diğer İşlemler"]);
        const labels = tmpdata["index"];
        setData({revenue_tipcg:revenue_tipcg,revenue_tonp:revenue_tonp,revenue_tongs:revenue_tongs,revenue_tonitt:revenue_tonitt,
        revenue_std:revenue_std,revenue_fee:revenue_fee,revenue_tonnc:revenue_tonnc,revenue_pi:revenue_pi,revenue_gasr:revenue_gasr,
        revenue_isf:revenue_isf,revenue_cr:revenue_cr,revenue_rc:revenue_rc,revenue_rfsbi:revenue_rfsbi,revenue_rfrsi:revenue_rfrsi,
        expense_interestpayment,exc_expense_interestpayment:exc_expense_interestpayment,finance_budget_escrow:finance_budget_escrow,
        finance_retain:finance_retain,finance_debt:finance_debt,finance_loan:finance_loan,finance_privatization:finance_privatization,
        finance_tmsf:finance_tmsf,finance_cash:finance_cash,
        labels:labels});
    }

    useEffect(() => {
            fetchData();
    },[params]);



//const [chartData, setChartData] = useState(defaultData);
//useEffect(() => {
//    data.then((response) => {
//      //const chartDatasets = response.datasets.map((dataset) => ({
//      //  ...dataset,
//      //}));
//      setChartData(response.datasets);
//    });
//});
  return data
}

export default budgetData;

