/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import ReportApi from "api/analytics";

const get_credit_growth_data = (params) => {
  const data = ReportApi.creditgrowth(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export default get_credit_growth_data;

const get_credit_data = (params) => {
  const data = ReportApi.creditdata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_credit_data};

const get_npl_data = (params) => {
  const data = ReportApi.npldata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_npl_data};

const get_credit_change_data = (params) => {
  const data = ReportApi.creditchangedata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_credit_change_data};

const get_interest_rates_data = (params) => {
  const data = ReportApi.interesetratesdata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_interest_rates_data};

const get_deposits_data = (params) => {
  const data = ReportApi.depositsdata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_deposits_data};

const get_deposit_change_data = (params) => {
  const data = ReportApi.depositchangedata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_deposit_change_data};

const get_incomestatements_data = (params) => {
  const data = ReportApi.incomestatementsdata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};

const get_cashflow_data = (params) => {
  const data = ReportApi.cashflowdata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_cashflow_data};

const get_bs_data = (params) => {
  const data = ReportApi.balancesheetdata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_bs_data};

const get_rasyo_data = (params) => {
  const data = ReportApi.rasyodata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_rasyo_data};

const get_funding_data = (params) => {
  const data = ReportApi.fundingdata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_funding_data};

const get_government_data = (params) => {
  const data = ReportApi.governmentbudget(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_government_data};

const get_tax_data = (params) => {
  const data = ReportApi.taxdata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_tax_data};


const get_cgb_rev_data = (params) => {
  const data = ReportApi.cgbdatarev(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_cgb_rev_data};

const get_cgb_exp_data = (params) => {
  const data = ReportApi.cgbdataexp(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_cgb_exp_data};

const get_primary_exp_data = (params) => {
  const data = ReportApi.primaryexp(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_primary_exp_data};

const get_domestic_debt_data = (params) => {
  const data = ReportApi.domesticdebt(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_domestic_debt_data};


const get_foreign_debt_ir_data = (params) => {
  const data = ReportApi.foreigndebtir(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_foreign_debt_ir_data};

const get_foreign_debt_spread_data = (params) => {
  const data = ReportApi.foreigndebtspread(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_foreign_debt_spread_data};

const get_currency_debt_data = (params) => {
  const data = ReportApi.currencydebt(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_currency_debt_data};

const get_ir_type_debt_data = (params) => {
  const data = ReportApi.irtypedata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_ir_type_debt_data};

const get_reserve_data = (params) => {
  const data = ReportApi.reservedata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_reserve_data};

const get_credit_card_data = (params) => {
  const data = ReportApi.creditcard(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_credit_card_data};

const get_credit_card_data_per_txn = (params) => {
  const data = ReportApi.creditcardpertxn(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_credit_card_data_per_txn};


const get_credit_card_data_index = (params) => {
  const data = ReportApi.creditcardindex(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_credit_card_data_index};



const get_trades_data = (params) => {
  const data = ReportApi.tradesdata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_trades_data};


const get_trades_group_data = (params) => {
  const data = ReportApi.tradesgroupdata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_trades_group_data};

const get_trades_group_series_data = (params) => {
  const data = ReportApi.tradesgroupseriesdata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_trades_group_series_data};




const get_last_date_data = (params) => {
  const data = ReportApi.lastdate(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_last_date_data};

const get_inflation_data = (params) => {
  const data = ReportApi.inflationdata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_inflation_data};

const get_gdp_data = (params) => {
  const data = ReportApi.gdpdata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_gdp_data};

const get_payroll_data = (params) => {
  const data = ReportApi.payrolldata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_payroll_data};

const get_payroll_data_history = (params) => {
  const data = ReportApi.payrolldatahistory(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_payroll_data_history};

const get_bop_data = (params) => {
  const data = ReportApi.bopdata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_bop_data};

const get_swap_data = (params) => {
  const data = ReportApi.swapdata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_swap_data};

const get_funding_table_data = (params) => {
  const data = ReportApi.fundingtable(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_funding_table_data};

const get_analytic_bs_data = (params) => {
  const data = ReportApi.analyticbs(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_analytic_bs_data};

const get_currentaccount_data = (params) => {
  const data = ReportApi.currentaccount(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_currentaccount_data};

const get_expectations_data = (params) => {
  const data = ReportApi.expectations(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_expectations_data};

const get_topic_data = (params) => {
  const data = ReportApi.topicdata(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_topic_data};

const get_bankbs_data = (params) => {
  const data = ReportApi.bankbs(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_bankbs_data};

const get_bop_finance_data = (params) => {
  const data = ReportApi.bopfinance(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_bop_finance_data};

const get_trades_index_data = (params) => {
  const data = ReportApi.tradeindex(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_trades_index_data};

const get_trades_index_detail_data = (params) => {
  const data = ReportApi.tradeindexdetail(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_trades_index_detail_data};

const get_data = (params) => {
  const data = ReportApi.tradeindexdetail(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_data};


const get_menu_list = (params) => {
  const data = ReportApi.menulist(params).then((response) => {
    const series = response;
    return series;
  });
  return data;
};
export {get_menu_list};
