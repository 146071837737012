/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
//import typography from "assets/theme/base/typography";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

function configs(charts, datasets) {
  am4core.useTheme(am4themes_animated);
  //charts.paddingRight = 0;
  var data = [];
  for (let [key, value] of Object.entries(datasets)) {
    data.push(value);
  }

  charts.data = data;
// Add and configure Series
var pieSeries = charts.series.push(new am4charts.PieSeries());
pieSeries.dataFields.value = "values";
pieSeries.dataFields.category = "index";
pieSeries.slices.template.stroke = am4core.color("white");
pieSeries.slices.template.strokeOpacity = 90;
pieSeries.slices.template.strokeWidth = 2;


pieSeries.slices.template.fillOpacity = 0.9;

var hs = pieSeries.slices.template.states.getKey("hover");
hs.properties.scale = 1;
hs.properties.fillOpacity = 0.9;


// This creates initial animation
pieSeries.hiddenState.properties.opacity = 5;
pieSeries.hiddenState.properties.endAngle = -90;
pieSeries.hiddenState.properties.startAngle = -90;

charts.innerRadius = am4core.percent(40);


charts.hiddenState.properties.radius = am4core.percent(0);

pieSeries.ticks.template.disabled = false;
pieSeries.alignLabels = false;
pieSeries.labels.template.radius = am4core.percent(10);
//pieSeries.labels.template.relativeRotation=360;
pieSeries.labels.template.paddingTop = 0;
pieSeries.labels.template.paddingBottom = 0;
pieSeries.labels.template.paddingLeft = 75;
pieSeries.labels.template.paddingRight = 25;

pieSeries.labels.template.fontSize = "0.8rem";
pieSeries.labels.template.fontFamily = "Helvetica, Arial, sans-serif";
//pieSeries.fillOpacity = 0.8;

pieSeries.colors.list  = [
  am4core.color("#280659"),
  am4core.color("#900c3e"),
  am4core.color("#C70039"),
  am4core.color("#D54800"),
  am4core.color("#FF5733"),
  am4core.color("#1D7874"),
  ];
}
export default configs;
