/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// App.js
import React from 'react';
//import Chatbot from './components/Chatbot';
import App from './src/App';
import  './output.css';
import  './src/styles.css';

//import ReactGA from 'react-ga4';

//const VITE_GOOGLE_ANALYTICS_KEY="G-C9QJ2ZPB7B"
//ReactGA.initialize(`${import.meta.env.VITE_GOOGLE_ANALYTICS_KEY}`);


import { useState } from "react";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";


// banking dashboard components

function EconGPT() {

  return (
    <DashboardLayout>
    <div id="overlay">
        <App />
    </div>
    <Footer />
    </DashboardLayout>
  );
}

export default EconGPT;
