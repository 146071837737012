import React from 'react'
import {Outlet, Navigate} from 'react-router-dom'

export const Anonymous = () => {
    const item = JSON.parse(localStorage.getItem('user'))
    const token = item?.accessToken
    //console.log(token)

    return (
      token ? <Navigate to="/" /> : <Outlet />
    )
}
