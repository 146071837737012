/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
import get_rasyo_data from "layouts/api";


function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}


function convertdata(data,format) {
  let result = [];
  if (format == "summary") {
    for (let [key, value] of Object.entries(data)) {
	    if (key != "date") {
	           //value.forEach((e,i)=>value[i]=100*e)
			   result.push({ title: key, values: value.slice(-7)});
			   }
	    }
	} else if (format == "percentage") {
      for (let [key, value] of Object.entries(data)) {
	    if (key != "date") {
	           value.forEach((e,i)=>value[i]=100*e)
			   result.push({ title: key, values: value});
			   }
	    }
    } else if (format == "highcharts") {
      for (let [key, value] of Object.entries(data)) {
	    if (key != "date") {
	           value.forEach((e,i)=>value[i]=100*e)
			   result.push({ name: key, data: value});
			   }
	    }
    } else if (format == "chartjs") {
      for (let [key, value] of Object.entries(data)) {
	    if (key != "date") {
	           value.forEach((e,i)=>value[i]=100*e)
			   result.push(value);
			   }
	    }
    } else {
	    for (let [key, value] of Object.entries(data)) {
	         if (key != "date") {
			   result.push({ title: key, values: value});
			   }
	    }
	}
  return result;
}

function getField(array, field) {
       return array.find((e) => e.title == field);
    }
function getSingleColumn(data, field) {
      for (let [key, value] of Object.entries(data)) {
      if (key == field) {
          return value
	    }}
    }

function rasyolarData(params) {
    const defaultData= {workingcapitalratio: [{'title': 'Stokta Kalma Süresi', 'values': [ 100,10,5,145,200,40,210]}],
                        cashconversioncycle: [{'title': 'Nakit Akım Döngüsü', 'values': [ 100,10,5,145,200,40,210]}],
                        interestcoverage: [{'title': 'İşletme Sermayesi', 'values': [ 100,10,5,145,200,40,210]}],
                        profitabilityratio: [{'title': 'Net Kar Marjjı', 'values': [ 100,10,5,145,200,40,210]}],
                        operatingreturns: [{'title': 'ROA', 'values': [ 100,10,5,145,200,40,210]}],
                        liquidityratios: [{'title': 'Cari Oran', 'values': [ 100,10,5,145,200,40,210]}],
                        sales: [{'title': 'Satışlar', 'values': [ 100,10,5,145,200,40,210]}],
                        favok: [{'title': 'Satışlar2', 'values': [ 100,10,5,145,200,40,210]}],
                        grosspl: [{'title': 'Satışlar3', 'values': [ 100,10,5,145,200,40,210]}],
                        netpl: [{'title': 'Satışlar4', 'values': [ 100,10,5,145,200,40,210]}],
                        summarygraphs: [{'title': 'Satışlar5', 'values': [ 100,10,5,145,200,40,210]}],
                        leverageratios: [{'name': 'Leverage', 'data': [ 100,10,5,145,200,40,210]}],
                        investmentindex: [ 100,10,5,145,200,40,210],
                        labels: [ "","","","","","",""],
    }
    //const [chartData, setChartData] = useState(null);
    const [data, setData] = useState(defaultData);

    //const dataFetchedRef = useRef(false);
    const fetchData = async () => {
        const config={"func":"rasyo","params":params};
        const response = await get_rasyo_data(config);
        const tmpdata = await response.data;
        const liquidity_ratios = convertdata( tmpdata["Liquidity Ratios"],"percentage");
        const working_capital_ratios = convertdata( tmpdata["Working Capital Ratios"],"summary");
        const profitability_ratio = convertdata( tmpdata["Profitability Ratios"],"summary");
        const working_capital = convertdata( tmpdata["Working Capital"],"summary");
        const operating_returns = convertdata( tmpdata["Operating Returns"],"summary");
        const interest_coverage = convertdata( tmpdata["Interest Coverage"],"summary");
        const summarygraphs =   convertdata( tmpdata["Summary Graphs"],"summary");
        const sales = getField(summarygraphs,"Serbest Nakit/Satışlar")
        const favok = getField(profitability_ratio,"FAVÖK Marjı")
        const grosspl = getField(profitability_ratio,"Brüt Kar Marjı")
        const netpl = getField(profitability_ratio,"Net Kar Marjı")
        const leverage_ratios=convertdata( tmpdata["Leverage Ratios"],"highcharts");
        //const investmentindex= getSingleColumn(tmpdata["Investment Index"],"Yatırım Endeksi")
        const investmentindex= getSingleColumn(tmpdata["Interest Coverage"],"FAVÖK/Finansman Giderleri")
        const leverage_ratios1=getSingleColumn( tmpdata["Leverage Ratios"],"Kaldıraç Oranı");
        const leverage_ratios2=getSingleColumn( tmpdata["Leverage Ratios"],"Finansal Borç Oranı");
        //const working_capital_ratios = convertdata( tmpdata["Working Capital Ratios"],"");
        //const summary_page_ratios = convertdata( tmpdata["Summary Page Ratios"],"summary");
        const index = tmpdata["index"];
        //console.log(tmpdata)

        //const date = transposedata.date;
	    setData({liquidityratios:liquidity_ratios,workingcapitalratio:working_capital_ratios,cashconversioncycle:working_capital,
	    interestcoverage:interest_coverage,profitabilityratio:profitability_ratio,operatingreturns:operating_returns,
	    sales:sales,favok:favok,grosspl:grosspl,netpl:netpl,summarygraphs:summarygraphs,leverageratios:leverage_ratios,
	    leverageratios1:leverage_ratios1,leverageratios2:leverage_ratios2,investmentindex:investmentindex,labels:index});
    }

    useEffect(() => {
            fetchData();
    },[params]);



//const [chartData, setChartData] = useState(defaultData);
//useEffect(() => {
//    data.then((response) => {
//      //const chartDatasets = response.datasets.map((dataset) => ({
//      //  ...dataset,
//      //}));
//      setChartData(response.datasets);
//    });
//});
  return data
}

export default rasyolarData;

