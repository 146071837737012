/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useRef, useEffect, useState, useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// Overview page components
import DefaultCell from "layouts/financialsector/banking/loans/components/DefaultCell";
import TotalCell from "layouts/financialsector/banking/loans/components/TotalCell";

//Data
import creditChangeData from "layouts/financialsector/banking/loans/data/infoTemplateTableData";


function dataTableData(params) {

const [tableData, setTableData] = useState( [
                                {"index": "Sektör-TP Ticari Krediler(TL)","last": 0,"oneweek": 0,"onemonth": 0,"threemonth": 0},
                                {"index": "Sektör-TP Ticari Krediler(TL)","last": 0,"oneweek": 0,"onemonth": 0,"threemonth": 0},
                                {"index": "Sektör-TP Ticari Krediler(TL)","last": 0,"oneweek": 0,"onemonth": 0,"threemonth": 0},
                                {"index": "Sektör-TP Ticari Krediler(TL)","last": 0,"oneweek": 0,"onemonth": 0,"threemonth": 0},

]);

const table=creditChangeData(params)

useEffect(() => {
 table.then((response) => {
      const tableDatasets = response;
      setTableData(tableDatasets)
   });
 }, []);

const rowstr=[]
const tmp_data = {};
tableData.forEach(function(data){
    const tmp_data = {};
    if(data.index.includes("Ticari")) {
        tmp_data["title"] =<DefaultCell>Ticari</DefaultCell>
        tmp_data["last"] = <DefaultCell>{String((data.last/1000).toFixed(1)+"M")}</DefaultCell>
        tmp_data["oneweek"] = <DefaultCell>{String((data.oneweek/1000).toFixed(1)+"M")}</DefaultCell>
        tmp_data["onemonth"] = <DefaultCell>{String((data.onemonth/1000).toFixed(1)+"M")}</DefaultCell>
        tmp_data["threemonth"] = <DefaultCell>{String((data.threemonth/1000).toFixed(1)+"M")}</DefaultCell>
        rowstr.push(tmp_data)
        }
    else if(data.index.includes("İhtiyaç"))  {
        tmp_data["title"] =<DefaultCell>İhtiyaç</DefaultCell>
        tmp_data["last"] = <DefaultCell>{String((data.last/1000).toFixed(1)+"M")}</DefaultCell>
        tmp_data["oneweek"] = <DefaultCell>{String((data.oneweek/1000).toFixed(1)+"M")}</DefaultCell>
        tmp_data["onemonth"] = <DefaultCell>{String((data.onemonth/1000).toFixed(1)+"M")}</DefaultCell>
        tmp_data["threemonth"] = <DefaultCell>{String((data.threemonth/1000).toFixed(1)+"M")}</DefaultCell>
        rowstr.push(tmp_data)
        }
      else if(data.index.includes("Kart"))  {
        tmp_data["title"] =<DefaultCell>Kredi Kartları</DefaultCell>
        tmp_data["last"] = <DefaultCell>{String((data.last/1000).toFixed(1)+"M")}</DefaultCell>
        tmp_data["oneweek"] = <DefaultCell>{String((data.oneweek/1000).toFixed(1)+"M")}</DefaultCell>
        tmp_data["onemonth"] = <DefaultCell>{String((data.onemonth/1000).toFixed(1)+"M")}</DefaultCell>
        tmp_data["threemonth"] = <DefaultCell>{String((data.threemonth/1000).toFixed(1)+"M")}</DefaultCell>
        rowstr.push(tmp_data)
        }
      else if(data.index.includes("Konut"))  {
        tmp_data["title"] =<DefaultCell>Konut</DefaultCell>
        tmp_data["last"] = <DefaultCell>{String((data.last/1000).toFixed(1)+"M")}</DefaultCell>
        tmp_data["oneweek"] = <DefaultCell>{String((data.oneweek/1000).toFixed(1)+"M")}</DefaultCell>
        tmp_data["onemonth"] = <DefaultCell>{String((data.onemonth/1000).toFixed(1)+"M")}</DefaultCell>
        tmp_data["threemonth"] = <DefaultCell>{String((data.threemonth/1000).toFixed(1)+"M")}</DefaultCell>
        rowstr.push(tmp_data)
        }
        else if(data.index.includes("Taşıt"))  {
        tmp_data["title"] =<DefaultCell>Taşıt</DefaultCell>
        tmp_data["last"] = <DefaultCell>{String((data.last/1000).toFixed(1)+"M")}</DefaultCell>
        tmp_data["oneweek"] = <DefaultCell>{String((data.oneweek/1000).toFixed(1)+"M")}</DefaultCell>
        tmp_data["onemonth"] = <DefaultCell>{String((data.onemonth/1000).toFixed(1)+"M")}</DefaultCell>
        tmp_data["threemonth"] = <DefaultCell>{String((data.threemonth/1000).toFixed(1)+"M")}</DefaultCell>
        rowstr.push(tmp_data)
        }
        else {
        tmp_data["title"] =<TotalCell>Toplam</TotalCell>
        tmp_data["last"] = <TotalCell>{String((data.last/1000).toFixed(1)+"M")}</TotalCell>
        tmp_data["oneweek"] = <TotalCell>{String((data.oneweek/1000).toFixed(1)+"M")}</TotalCell>
        tmp_data["onemonth"] = <TotalCell>{String((data.onemonth/1000).toFixed(1)+"M")}</TotalCell>
        tmp_data["threemonth"] = <TotalCell>{String((data.threemonth/1000).toFixed(1)+"M")}</TotalCell>
        rowstr.push(tmp_data)
        }
});


const dataTableDatatmp={
    columns: [
        { Header: "Krediler", accessor: "title", align: "left"},
        { Header: "Son", accessor: "last", align: "left" },
        { Header: "1 Hafta", accessor: "oneweek", align: "left" },
        { Header: "1 Ay", accessor: "onemonth", align: "left" },
        { Header: "3 Ay", accessor: "threemonth", align: "left" },
  ],
  rows:rowstr,
  };
//console.log(dataTableDatatmp)
return dataTableDatatmp
}

// Setting default values for the props of DataTable
dataTableData.defaultProps = {
  noEndBorder: false,
};

// Typechecking props for the DataTable
dataTableData.propTypes = {
  table: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default dataTableData;
