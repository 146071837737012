/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
import get_bop_finance_data from "layouts/api";

function transpose(data) {
  let result = {};
  //console.log("---------------")
  //console.log(data)
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}

function financetableData(params) {
    const config={"func":"bop_finance","params":params}
    const result = get_bop_finance_data(config).then((response) => {
    //const data1 = transpose(response.data);
    const data = response.data;
    //creturn {
    //c  labels: index,
     //c datasets: resultdata,
   //c };
   return data
  });
  //console.log(result)
  return result;
}

export default financetableData;
