import { ChartOptions, ChartData } from "chart.js";

// const params=["07112019","30032021","23032023","22032024","05022025","30052040","14012041"]

export const getChartDataDetail = (
  data1,data2,data3,data4,data5,data6,data7,data8,data9,data10,data11,data12,data13,
  data14,data15,data16,data17,data18,data19,data20,data21,data22,data23,labels
): ChartData => ({
  labels: labels,
  datasets: [
     /*{
      label: "1 Yatırım (sermaye) malları",
      borderColor:  "#280659",
      backgroundColor: "rgb(29, 120, 116,0.03)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: data1,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: true,
      stack: "1",
    },*/
   {
      label: "1.1 Yatırım (sermaye) malları (taşımacılık araçları hariç)",
      borderColor:  "#6C1D8B",
      backgroundColor: "rgba(108, 29, 139,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data2,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
     {
      label: "1.2 Sanayi ile ilgili taşımacılık araç ve gereçleri",
      borderColor:  "#8C3B9D",
      backgroundColor: "rgba(140, 59, 157,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data3,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
     /*{
      label: "2 Hammadde (ara mallar)",
      borderColor:  "#900c3e",
      backgroundColor: "rgb(29, 120, 116,0.03)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: data4,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: true,
      stack: "3",
    },*/
    {
      label: "2.1_Sanayi için işlem görmemiş hammaddeler",
      borderColor:  "#A41A4F",
      backgroundColor: "rgba(164, 26, 79,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data5,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
    {
      label: "2.2 Sanayi için işlem görmüş hammaddeler",
      borderColor:  "#b52a5d",
      backgroundColor: "rgba(181, 42, 93,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data6,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
    {
      label: "2.3 İşlem görmemiş yakıt ve yağlar",
      borderColor:  "#c53d6b",
      backgroundColor: "rgba(197, 61, 107,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data7,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
    {
      label: "2.4 Yatırım mallarının aksam ve parçaları",
      borderColor:  "#d6527c",
      backgroundColor: "rgba(214, 82, 124,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data8,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
    {
      label: "2.5 Taşımacılık araçlarının aksam ve parçaları",
      borderColor:  "#e7678d",
      backgroundColor: "rgba(231, 103, 141,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data9,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
    {
      label: "2.6_Esası yiyecek ve içecek olan işlenmemiş hammadeler",
      borderColor:  "#f17e9e",
      backgroundColor: "rgba(241, 126, 158,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data10,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
    {
      label: "2.7_Esası yiyecek ve içecek olan işlenmiş hammaddeler",
      borderColor:  "#f8a1b2",
      backgroundColor: "rgba(248, 161, 178,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data11,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
    {
      label: "2.8_İşlem görmüş diğer yakıt ve yağlar",
      borderColor:  "#fbb7c4",
      backgroundColor: "rgba(251, 183, 196,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data12,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
     /*    {
      label: "3 Tüketim malları",
      borderColor:  "#1D7874",
      backgroundColor: "rgb(29, 120, 116,0.03)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: data13,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: true,
      stack: "4",
    },*/
    {
      label: "3.1 Binek otomobilleri",
      borderColor:  "#24817D",
      backgroundColor: "rgba(36, 129, 125,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data14,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
    {
      label: "3.2 Dayanıklı tüketim malları",
      borderColor:  "#2B8A86",
      backgroundColor: "rgba(43, 138, 134,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data15,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
    {
      label: "3.3 Yarı dayanıklı tüketim malları",
      borderColor:  "#33938F",
      backgroundColor: "rgba(51, 147, 143,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data16,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
    {
      label: "3.4 Dayanıksız tüketim malları",
      borderColor:  "#3A9C98",
      backgroundColor: "rgba(58, 156, 152,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data17,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
    {
      label: "3.5 Esası yiyecek ve içecek olan işlenmemiş tüketim malları",
      borderColor:  "#41A5A1",
      backgroundColor: "rgba(65, 165, 161,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data18,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
    {
      label: "3.6 Esası yiyecek ve içecek olan işlenmiş tüketim malları",
      borderColor:  "#4AAEAA",
      backgroundColor: "rgba(74, 174, 170,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data19,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
    {
      label: "3.7 Motor benzini ve diğer hafif yağlar",
      borderColor:  "#51B7B3",
      backgroundColor: "rgba(81, 183, 179,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data20,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
    {
      label: "3.8 Sanayii ile ilgili olmayan taşıma araç ve gereçleri",
      borderColor:  "#58C0BC",
      backgroundColor: "rgba(88, 192, 188,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data21,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
     /*{
      label: "4 Diğerleri",
      borderColor:  "#D4AF00",
      backgroundColor: "rgb(29, 120, 116,0.03)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: data22,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: true,
      stack: "4",
    },*/
    {
      label: "4.1 Başka yerde belirtilmeyen diğer mallar",
      borderColor:  "#D6B700",
      backgroundColor: "rgba(214, 183, 0,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data23,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "2",
    },
  ]
});

/*
1_Yatırım (sermaye) malları
1.1_Yatırım (sermaye) malları (taşımacılık araçları hariç)
1.2_Sanayi ile ilgili taşımacılık araç ve gereçleri
2_Hammadde (ara mallar)
2.1_Sanayi için işlem görmemiş hammaddeler
2.2_Sanayi için işlem görmüş hammaddeler
2.3_İşlem görmemiş yakıt ve yağlar
2.4_Yatırım mallarının aksam ve parçaları
2.5_Taşımacılık araçlarının aksam ve parçaları
2.6_Esası yiyecek ve içecek olan işlenmemiş hammadeler
2.7_Esası yiyecek ve içecek olan işlenmiş hammaddeler
2.8_İşlem görmüş diğer yakıt ve yağlar
3_Tüketim malları
3.1_Binek otomobilleri
3.2_Dayanıklı tüketim malları
3.3_Yarı dayanıklı tüketim malları
3.4_Dayanıksız tüketim malları
3.5_Esası yiyecek ve içecek olan işlenmemiş tüketim malları
3.6_Esası yiyecek ve içecek olan işlenmiş tüketim malları
3.7_Motor benzini ve diğer hafif yağlar
3.8_Sanayii ile ilgili olmayan taşıma araç ve gereçleri
4_Diğerleri
4.1_Başka yerde belirtilmeyen diğer mallar
*/
export const chartOptionsDetail: ChartOptions =  {
    interaction:
    {mode:'index'},
  elements: {
    line: {
      tension: 0.1
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
  //stacked100: { enable: true, replaceTooltipLabel: false },
  legend: {display: true,
  position:'bottom',
          labels: {
          boxWidth: 10,
          usePointStyle: true,
        }},
    filler: {
      propagate: true
    }
  },
  scales: {
    xAxes: [
      {
        display: true,
        ticks: {
          autoSkip: false,
        }
      },
    ],
    yAxes: {
      suggestedMin: 0,
      suggestedMax: 100,
       ticks: {
          // forces step size to be 50 units
          stepSize: 5
        }
    }
  },

  };

  export const chartOptions3: ChartOptions =  {
    interaction:
    {mode:'index'},
  elements: {
    line: {
      tension: 0.1
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
  //stacked100: { enable: true, replaceTooltipLabel: false },
  legend: {display: true,
  position:'bottom',
          labels: {
          boxWidth: 10,
          usePointStyle: true,
        }},
    filler: {
      propagate: true
    }
  },
  scales: {
    xAxes: [
      {
        stacked:true,
        display: true,
        ticks: {
          autoSkip: false,
        }
      },
    ],
    yAxes: [
      {
      stacked:true,
      display: true,
        ticks: {
          beginAtZero: true,
        }
      }
    ]
  },

  };

