import * as React from "react";
import "layouts/realsector/dcf/components/assets/styleelement.css";
import "layouts/realsector/dcf/components/assets/styles.css";
import { CellChange, NumberCell, ReactGrid } from "@silevis/reactgrid";
//import "@silevis/reactgrid/styles.css";
import { getColumns } from "layouts/realsector/dcf/components/getColumns";
import { getRows}  from "layouts/realsector/dcf/components/getRows";

import { inflows as emptyInflows,outflows as emptyOutflows,opex as emptyOpex,
otherInflows as emptyOtherInflows, otherOutflows as emptyOtherOutflows,
financialOutflow as emptyFinancialExpense} from "layouts/realsector/dcf/data/rawData";

import {
  CashInflow,
  CashOutflow,
  OPEX,
  CashOtherInflow,
  CashOtherOutflow,
  FinancialExpense,
  InputVariables,
  OutputVariables
} from "layouts/realsector/dcf/components/interfaces";

import { Bar } from "react-chartjs-2";
import { getChartData, chartOptions } from  "layouts/realsector/dcf/data/chart";
import { calculateOutputVariables } from "layouts/realsector/dcf/components/plannerOutputVariables";


const applyChange = (change: CellChange<NumberCell>) => < K >(
  groups: Array<K>
) =>
  groups.map((group) =>
    group.title === change.rowId
      ? {
          ...group,
          values: group.values.map((value, idx) =>
            change.columnId === idx + 1 ? change.newCell.value : value
          )
        }
      : group
  );

export const DCF: React.FC = () => {
  const [cashInflow, setCashInflow] = React.useState(() => [...emptyInflows]);
  const [cashOutflow, setCashOutflow] = React.useState(() => [...emptyOutflows]);
  const [opex, setOpex] = React.useState(() => [...emptyOpex]);
  const [cashOtherInflow, setCashOtherInflow] = React.useState(() => [...emptyOtherInflows]);
  const [cashOtherOutflow, setCashOtherOutflow] = React.useState(() => [...emptyOtherOutflows]);
  const [financialExpense, setFinancialExpense] = React.useState(() => [...emptyFinancialExpense]);


  const columns = getColumns();
  const inputVariables: InputVariables = {
    cashInflow,
    cashOutflow,
    opex,
    cashOtherInflow,
    cashOtherOutflow,
    financialExpense
  };

  const outputVariables = calculateOutputVariables(inputVariables);
  const plannerData: InputVariables & OutputVariables = {
    ...inputVariables,
    ...outputVariables
  };

  const rows = getRows(plannerData);
  //console.log("inputVariables")
  //console.log(inputVariables)
  //console.log("plannerdata")
  //console.log(plannerData)
  //console.log("Row")
  //console.log(rows)

  const handleChanges = (changes: CellChange[]) => {
    changes.forEach((change: CellChange<NumberCell>) => {
      setCashInflow((cashInflow) => applyChange(change)(cashInflow));
      setCashOutflow((cashOutflow) => applyChange(change)(cashOutflow));
      setOpex((opex) => applyChange(change)(opex));
      setCashOtherInflow((cashOtherInflow) => applyChange(change)(cashOtherInflow));
      setCashOtherOutflow((cashOtherOutflow) => applyChange(change)(cashOtherOutflow));
      setFinancialExpense((financialExpense) => applyChange(change)(financialExpense));

    });
  };

  return (
    <>
      <div className="dcf-app" style={{ width: 1200 }} >
        <Bar
          data={getChartData(plannerData)}
          options={chartOptions}
          height={50}
        />
        <ReactGrid
          rows={rows}
          columns={columns}
          onCellsChanged={handleChanges}
          stickyTopRows={1}
          stickyLeftColumns={1}
          stickyRightColumns={1}
          // props below are availble for PRO version
          //enableFillHandle
          //enableRangeSelection
        />
      </div>
    </>
  );
};

export default DCF;
