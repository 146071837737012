import { ChartOptions, ChartData } from "chart.js";
import { InputVariables, OutputVariables } from "layouts/realsector/firms/components/Summary/components/interfaces";


let delayed;

export const getChartData = (
  plannerData,labels
): ChartData => ({
  labels: labels,
  datasets: [
     {
      label: "",
      borderColor:  "#280659",
      backgroundColor: "rgba(40,6,89,0.5)",
      fill: false,
      stack: "1",
      barPercentage: 0.8,
      data: plannerData,
      borderWidth: 2
    },
  ]
});

export const chartOptions: ChartOptions = {
  elements: {
    line: {
      tension: 0.1
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
  legend: {display: false},
    filler: {
      propagate: true
    }
  },
    animation: {
      onComplete: () => {
        delayed = true;
      },
      delay: (context) => {
        let delay = 0;
        if (context.type === 'data' && context.mode === 'default' && !delayed) {
          delay = context.dataIndex * 100 + context.datasetIndex * 10;
        }
        return delay;
      },
    },
  scales: {
    xAxes: [
      {
        display: true,
        ticks: {
          autoSkip: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ]
  }
  };
