// @mui material components
import Grid from "@mui/material/Grid";
// Data
import expectationsData from "layouts/financialsector/tcmb/expectations/data/expectationsData";

//Graphs
import DefaultAmPyramidCharts from "examples/Charts/AmChartsPyramid/DefaultAmPyramidCharts";

function PyramidChartsTemplate() {
const titlename="Enflasyon Beklentileri"
//const titlename=""
const data=expectationsData("params")
  return (
                  <Grid container spacing={0} container direction="column" justifyContent="flex-start"alignItems="stretch" sx={{ mb: 1 }}>
                        <DefaultAmPyramidCharts
                             title={titlename}
                             description=""
                             chart={data}
                             height={"36rem"}
                     />
                  </Grid>
  );
}
export default PyramidChartsTemplate;
