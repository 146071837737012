/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";
import React, { useRef } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";
//import zoomPlugin from 'chartjs-plugin-zoom';

//import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// VerticalBarChart configurations
import configs from "examples/Charts/LineCharts/MixCharts/configs";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
//Chart.register(zoomPlugin);

//Chart.register(ChartjsPluginStacked100);

function VerticalBarChart({ title, description, height, chart,width }) {
    const barRef = useRef();
   const chartOptions = {
    plugins: {
      legend: {
        position: "bottom",
        display: true,
        /*onClick: (e, item) => {
          const chartItem = barRef.current.config.data;
          const dataItem = chartItem.datasets[item.datasetIndex];
          const saleItem = chartItem.datasets.find((element) =>
            element.label.includes(`${item.text}_Birim`)
          );

          if (dataItem.hidden === true || dataItem.hidden === null) {
            dataItem.hidden = false;
            saleItem.hidden = false;
          } else {
            dataItem.hidden = true;
            saleItem.hidden = true;
          }
          barRef.current.update();
        },*/
        labels: {
          boxWidth: 10,
          usePointStyle: true,
          generateLabels: (chart) => {
            return Chart.defaults.global.legend.labels.generateLabels
              .apply(this, [chart])
              .filter(function (item, i) {
                //return !item.text.includes("Miktar");
                return item.text
              });
          }
        }
      }
    }
  };

//console.log("barchart3")
  const chartDatasets = chart.datasets
    ? chart.datasets.map((dataset) => ({
        ...dataset,
      fill: true,
      lineTension: 0.6,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
        //maxBarThickness: 35,
      }))
    : [];
  const { data,options } = configs(chart.labels || [], chartDatasets,chartOptions);
  console.log(data)
  const renderChart = (

    <SoftBox p={0}>
      {title || description ? (
        <SoftBox px={description ? 1 : 0} pt={description ? 1 : 0}>
          {title && (
            <SoftBox mb={1}>
              <SoftTypography variant="h6">{title}</SoftTypography>
            </SoftBox>
          )}
          <SoftBox mb={2}>
            <SoftTypography component="div" variant="button" fontWeight="regular" color="text">
              {description}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ) : null}
      {useMemo(
        () => (
          <SoftBox sx={{ height,width:width }}>
            <Bar data={data} options={options} ref={barRef}/>
          </SoftBox>
        ),
        [chart, height]
      )}
    </SoftBox>
  );

  return title || description ?  <Card>{renderChart} </Card> : renderChart;
}

// Setting default values for the props of VerticalBarChart
VerticalBarChart.defaultProps = {
  title: "",
  description: "",
  height: "26.125rem",
  width: "550"
};

// Typechecking props for the VerticalBarChart
VerticalBarChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default VerticalBarChart;
