/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";


// prop-types is a library for type checking of props
import PropTypes from "prop-types";

import StaticCard from "examples/Cards/StaticCard";
import controllerCardIcons from "layouts/financialsector/banking/loans/data/controllerCardIcons";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";



function RateTemplate({ title,last,previous, action  }) {

const {
    iconLight,
    iconDark,
  } = controllerCardIcons;

const [iconState, setIconState] = useState(false);
  return (
            <StaticCard
              title={title}
              description={"Önceki Hafta: "+" %"+previous}
              value={"%"+last}
              action={action}
              height="100%"
            />
  );
}

// Typechecking props for the Template
RateTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

export default RateTemplate;
