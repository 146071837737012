import Card from "@mui/material/Card";

// prop-types is a library for type checking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// @mui material components
import Grid from "@mui/material/Grid";
//Graphs

import funddatadistr from "layouts/financialsector/fundsinfo/data/funddatadistr";
import funddatachange from "layouts/financialsector/fundsinfo/data/funddatachange";

//import marketdata from "layouts/data/data/marketDataforHighCharts";

import {getStockChartData,stockConfigs} from "layouts/financialsector/fundsinfo/components/configs/fondagilim";
import {chartOptions} from "layouts/financialsector/fundsinfo/components/configs/hisseislem";

import PieChart from "examples/Charts/PieChart";
import ReportsBarChart from "examples/Charts/BarCharts/DefaultBarChartsforFund";


function convertDate(date) {
  var yyyy = date.getFullYear().toString();
  var mm = (date.getMonth()+1).toString();
  var dd  = date.getDate().toString();

  var mmChars = mm.split('');
  var ddChars = dd.split('');

  return yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]);
}


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


function Graph(item) {

//var labeltext = item.label.join(" , ");
//if (item.label.constructor ===  Array) {
//  var labeltext = item.label.join(" , ");
//} else {
//  var labeltext = item.label;
//}

  //const params={}
  //params["startdate"]=convertDate(item.startdate)
 // params["enddate"]=convertDate(item.enddate)
  //params["işlem"]="Hisse İşlemleri"

  //var startdt=convertDate(item.startdate)
  //var enddt=convertDate(item.enddate)

  //console.log(item.startdate,item.enddate)
  const params="Fon Degisim"
  const params2="Fon Dagilim"
  const hisseChartData=funddatadistr(params2)

  //console.log(funddata2(params2,item.startdate,item.enddate))
  const hisseislemChartData=funddatachange(params,item.startdate,item.enddate)

  //console.log(params,item.startdate,item.enddate)
  //console.log(hisseislemChartData)

  var chardata = getStockChartData(hisseChartData.datasets, hisseChartData.labels);
  var configsdata = stockConfigs(hisseChartData.datasets, hisseChartData.labels);


  return (
      <>
      <Item>
        <Grid container spacing={2} sx={{ height: 600 }}>
              <Grid item xs={12} xl={6}>
               <Card sx={{ mt: 0 }} >
                <SoftBox
                     alignItems="center"
                     position="relative"
                     top={0}
                     left={0}
                    width="calc(100% - 0rem)"
                 >
                <ReportsBarChart
                title="Nakit Girişleri"
                description= ""
                chart={hisseislemChartData}
                chartoptions={chartOptions}
                height= {"32rem"}
                width="calc(100% - 0rem)"
              />
               </SoftBox>
              </Card>
            </Grid>

            <Grid item xs={12} xl={6}>
               <Card sx={{ mt: 0 }} >
                <SoftBox
                     alignItems="center"
                     position="relative"
                     top={0}
                     left={0}
                    width="calc(100% - 0rem)"
                 >
                     <PieChart title="Fon Dağılımı" chart={chardata} height="32rem" chartoptions={configsdata}/>
                </SoftBox>
              </Card>
            </Grid>
     </Grid>
   </Item>
    </>
  );
}
export default Graph;
