
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";


// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";



// Soft UI Dashboard PRO React icons
import { MdOutlineCalendarMonth } from "react-icons/md";
import { BsCalendar2MonthFill } from "react-icons/bs";
import { BsCalendar2DayFill } from "react-icons/bs";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

import Footer from "examples/Footer";

//Graphs

//Data

// Soft UI Dashboard PRO React example components
import PyramidTemplate from "layouts/financialsector/tcmb/expectations/components/PyramidTemplate";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const { values } = breakpoints;

function Expectations() {

  return (


    <DashboardLayout>
      <DashboardNavbar />
        <SoftBox pt={3}>
            <SoftBox mb={0} p={1}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    Beklentiler
                </SoftTypography>
            </SoftBox>
                <SoftBox mb={3}>
                    <PyramidTemplate/>
                </SoftBox>
        </SoftBox>

     <Footer />
    </DashboardLayout>

  );
}
export default Expectations;
