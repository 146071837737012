/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import get_credit_data_by_sector from "layouts/api";

function amChartsDoughnutData(){
 const data=[
    {
        "level_0": 2,
        "index": "Afrika",
        "values": 4559
    },
    {
        "level_0": 4,
        "index": "Asya",
        "values": 47123
    },
    {
        "level_0": 1,
        "index": "Avrupa",
        "values": 1418888
    },
    {
        "level_0": 0,
        "index":  "Avustralya",
        "values": 6134
    },
    {
        "level_0": 3,
        "index": "Diğer",
        "values": 164
    },
    {
        "level_0": 5,
        "index": "Güney Amerika",
        "values": 2424
    },
    {
        "level_0": 6,
        "index": "Kuzey ve Orta Amerika",
        "values": 31343
    },
    {
        "level_0": 7,
        "index": "BDT",
        "values": 226400
    },
    {
        "level_0": 8,
        "index": "Uzak Doğu",
        "values": 5159
    },
    {
        "level_0": 9,
        "index": "Türkiye",
        "values": 173588
    }
]


  const config={"func":"sector_data","params":""}
  const result = get_credit_data_by_sector(config).then((response) => {
  //console.log(response.data)
    return {
      datasets: data,

    };
  });

  return result;
}

export default amChartsDoughnutData();
