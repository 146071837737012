import { Row } from "@silevis/reactgrid";

import {
  WorkingCapitalRatio,
  CashConversionCycle,
  InterestCoverage,
  ProfitabilityRatio,
  OperatingReturns,
  Sales,
  FAVOK,
  GrossPL,
  NetPL,
  SummaryGraph,
  Labels,
  InputVariables,
  MonthlyValues,
  OutputVariables
} from "layouts/realsector/firms/components/Summary/components/interfaces";
import { months } from "layouts/realsector/firms/components/Summary/components/helpers";
import {
  emptyTextCell,
  nonEditable,
  textCell,
  monthHeaderCell,
  bottomLine,
  numberCell,
  showZero,
  noSideBorders
} from "layouts/realsector/firms/components/Summary/components/cells";

export const HEADER_ROW_ID = "header";
export const MONTHSTOTAL_ROW_ID = "monthsTotal";
export const MONTHSTOTAL_ROW_ID2= "monthsTotal2";
export const MONTHSTOTAL_ROW_ID3= "monthsTotal3";


const ROW_HEIGHT = 21;
const HEADING_ROW_HEIGHT = 30;

function sumGroupValues(values: MonthlyValues): number {
  return values.reduce(
    (prev, curr) => (isNaN(prev) ? 0 : prev) + (isNaN(curr) ? 0 : curr)
  );
}

function getHeaderRow(
  groups: (Labels)[],
): Row [] {
  const labels_data=groups.slice(-7)
  const tmp_labels = labels_data.map((_, idx) => nonEditable(monthHeaderCell(labels_data[idx], "justify-content-center")));
  const labels = [nonEditable(emptyTextCell)].concat(tmp_labels)
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells:labels,
  };
}


function getBlankRow(): Row {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: [
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      //nonEditable(monthHeaderCell("Totals", "justify-content-end")),
    ],
  };
}

function getGroupRows(
  title: "İşletme Sermayesi Rasyoları" ,
  summaryTitle: string,
  groups: (WorkingCapitalRatio | ProfitibilityRatio | OperatingReturns | InterestCoverage)[],
  monthlyGroupTotals: MonthlyValues,
): Row[] {
  return [
    {
      rowId: `${title}Header`,
      height: HEADING_ROW_HEIGHT,
      cells: [
        bottomLine(
          nonEditable(
            textCell(
              title,
              `align-items-end text-md font-bold text-${
                  title === "İşletme Sermayesi"  ? "darkblue" : title === "Duran Varlıklar" ? "darkblue" : "red"
              }`
            )
          )
        ),
        ...months().map((_) =>
          noSideBorders(bottomLine(nonEditable(emptyTextCell)))
        ),
      ]
    },
    ...groups.map(({ title, values }) => ({
      rowId: title,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell(title, "padding-left-lg")),
        ...values.map((_, idx) => nonEditable(showZero(numberCell(values[idx])))),
      ]
    })),
    {
      rowId: `${title}Summary`,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell(summaryTitle, `padding-left-lg font-bold text-${
                  title === "İşletme Sermayesi" ? "darkblue" : title === "Duran Varlıklar" ? "darkblue" : "red"
                }`)),
        ...months().map((_, idx) =>
          nonEditable(
              textCell(
                "",
                `font-bold text-${
                  title === "İşletme Sermayesi"  ? "darkblue" : title === "Duran Varlıklar" ? "darkblue" : "red"
                }`
              )
          )
        ),
      ]
    }
  ];
}


function getSingleRow(
  title: "Nakit Akım Döngüsü"  | "Diğer Giderler " | "Finansman Giderleri" ,
  groups: (CashConversionCycle)[],
  //yearlyGroupTotal: number
): Row[] {
  return [
    ...groups.map(({ title, values }) => ({
      rowId: title,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell(title, "padding-left-lg font-bold text-darkblue")),
        ...values.map((_, idx) => nonEditable(numberCell(values[idx]))),
      ],
    })),
  ];
}


export function getRows({
  workingcapitalratio,
  cashconversioncycle,
  interestcoverage,
  profitabilityratio,
  operatingreturns,
  labels
}: InputVariables & OutputVariables): Row[] {
  return [
    getHeaderRow(labels),
    ...getGroupRows(
      "İşletme Sermayesi Rasyoları",
      "",
      workingcapitalratio,
    ),
  ...getSingleRow(
       "",
       cashconversioncycle,
     ),
  // ...getSingleRow(
  //     "",
  //     workingcapital,
  //   ),
    getBlankRow(),
    ...getGroupRows(
      "Karlılık Rasyoları",
      "",
      profitabilityratio,
    ),
    ...getGroupRows(
      "İşletme Karlılığı",
      "",
      operatingreturns,
    ),
   ...getGroupRows(
      "Faiz Karşılama Oranı",
      "",
      interestcoverage,
    ),
  ];
}
