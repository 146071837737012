// @mui material components
import Grid from "@mui/material/Grid";
// Data
import { getChartData, chartOptions } from  "layouts/realsector/firms/components/data/investmentindex";
import { getChartData1, chartOptions1 } from  "layouts/realsector/firms/components/data/leverageratios";

//Graphs

import ReportsBarChart from "examples/Charts/BarCharts/DefaultBarCharts";
import LineCharts from "examples/Charts/LineCharts/DefaultLineCharts";

import rasyolarData from "layouts/realsector/firms/data/rasyolar";

import SynchronizedHighLineChart from "examples/Charts/HighChartsLine/SynchronizedHighLineChart";
//import rasyolarData from "layouts/realsector/firms/data/rasyolar";
import DefaultHighLineChart2 from "examples/Charts/HighChartsLine/DefaultHighLineChart2";
import DefaultHighColumnChart from "examples/Charts/HigChartsColumn/DefaultHighColumnChart";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



function Graphs1Template(stock) {
const data=rasyolarData(stock.stock)
const chardata = getChartData(data.investmentindex,data.labels);
const chardata2 = getChartData1(data.leverageratios1,data.leverageratios2,data.labels);

//const titlename= stock.stock +" Nakit Akımları (1000 TL)"
//console.log("yatırım endeksi")
  return (
         <Box sx={{ flexGrow: 1 }}>
         <Grid container spacing={3}>
                         <Grid item xs={12} xl={6}>
                          <Grid container spacing={0} container direction="column" justifyContent="flex-start"alignItems="stretch" sx={{ mb: 1 }}>
                          <Item><SynchronizedHighLineChart title={"Likidite Rasyoları"} height="21.75rem"  chart={data.liquidityratios} labels={data.labels}/> </Item>
                         </Grid>
                         </Grid>
                       <Grid item xs={12} xl={6}>

                        <Item>
                          <Grid container spacing={1} container direction="column" justifyContent="flex-start"alignItems="stretch" sx={{ mb: 2 }}>
                                <LineCharts
                                    title="Kaldıraç Rasyoları"
                                    description= ""
                                    chart={chardata2}
                                    chartoptions={chartOptions1}
                                    height={300}
                                />
                           </Grid>
                         </Item>

                         <Item>
                          <Grid container spacing={1} container direction="column" justifyContent="flex-start"alignItems="stretch" sx={{ mb: 0.5 }}>
                                <ReportsBarChart
                                    title="FAVÖK/Finansman Gideri"
                                    description= ""
                                    chart={chardata}
                                    chartoptions={chartOptions}
                                    height={300}
                                />
                           </Grid>
                         </Item>
                       </Grid>

        </Grid>
            </Box>
  );
}
export default Graphs1Template;
