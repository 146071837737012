/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React,{useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
import get_analytic_bs_data from "layouts/api";


function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}


function convertdata(data) {
  let result = [];
  for (let [key, value] of Object.entries(data)) {
	    if (key != "date") {
			   result.push({ title: key, values: value });
			   }
	}
  return result;
}


function balanceSheetData(params) {
    const defaultData= {dısyukumluluk: [{'title': 'Dış Yükümlülükler', 'values': [ 0,0,0,0,0,0,0]}],
                        icyukumluluk: [{'title': 'Maddi Duran Varlıklar', 'values': [ 0,0,0,0,0,0,0]}],
                        rezervpara: [{'title': 'Kısa Vadeli Borçlar', 'values': [ 0,0,0,0,0,0,0]}],
                        digermbpara: [{'title': 'Diğer Merkez Bankası Parası', 'values': [ 0,0,0,0,0,0,0]}],
                        dısvarlıklar: [{'title': 'Özkaynaklar', 'values': [ 0,0,0,0,0,0,0]}],
                        nakitislemler: [{'title': 'Özkaynaklar1', 'values': [ 0,0,0,0,0,0,0]}],
                        degerlemehesabı: [{'title': 'Özkaynaklar2', 'values': [ 0,0,0,0,0,0,0]}],
                        labels: [ "","","","","","",""],
    }
    //const [chartData, setChartData] = useState(null);
    const [data, setData] = useState(defaultData);

    //const dataFetchedRef = useRef(false);
    const fetchData = async () => {
        const valueindex=1
        const config={"func":"analytic_bs","params":params};
        const response = await get_analytic_bs_data(config);

        const tmpdata = await response.data;
        //const date = transposedata.date;
        const dısvarlıklar = convertdata( tmpdata["Dış Varlıklar"]);
        const nakitislemler = convertdata( tmpdata["Nakit İşlemler"]);
        const degerlemehesabı = convertdata( tmpdata["Değerleme Hesabı"]);

        const dısyukumluluk = convertdata( tmpdata["Dış Yükümlülükler"]);
        const icyukumluluk = convertdata( tmpdata["İç Yükümlülükler"]);
        const rezervpara = convertdata( tmpdata["Rezerv Para"]);
        const digermbpara = convertdata( tmpdata["Diğer Merkez Bankası Parası"]);
        const index = tmpdata["index"];

	    setData({dısvarlıklar:dısvarlıklar,degerlemehesabı:degerlemehesabı,nakitislemler:nakitislemler,dısyukumluluk:dısyukumluluk,icyukumluluk:icyukumluluk,rezervpara:rezervpara,digermbpara:digermbpara,labels:index});
    }

    React.useEffect(() => {
               fetchData();
    },[params]);



//const [chartData, setChartData] = useState(defaultData);

//useEffect(() => {
//    data.then((response) => {
//      //const chartDatasets = response.datasets.map((dataset) => ({
//      //  ...dataset,
//      //}));
//      setChartData(response.datasets);
//    });
//});
  return data
}

export default balanceSheetData;

