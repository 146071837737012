
/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";


// Soft UI Dashboard PRO React example components
import CurrencyDebtTemplate from "layouts/government/budget/components/CurrencyDebtTemplate";
import IRTypeDebtTemplate from "layouts/government/budget/components/IRTypeDebtTemplate";


function Debt1Template() {

  return (
  <Box sx={{ flexGrow: 1 }}>
    <Grid container mt={5} spacing={2} sx={{ height: 550 }}>
             <CurrencyDebtTemplate/>
             <IRTypeDebtTemplate/>
      </Grid>
   </Box>

  );
}

export default Debt1Template;
