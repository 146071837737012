
export const graphOptions = [
  { value: "line", label: 'Line' },
  { value: "bar", label: 'Bar' },
  { value: "combo", label: 'Combo' },
];



export const creditcardOptions:  CreditCardOptions[] = [
{'value': 'credit_card', 'label': 'Kredi Kartları'},
]

export interface CreditCardOptions {
   value: string;
   label: string;
}

export interface BankingOptions {
   value: string;
   label: string;
}


export const bankingOptions:  BankingOptions[] = [
{'value': 'loans', 'label': 'Krediler'},
{'value': 'deposit', 'label': 'Mevduatlar'},
{'value': 'cash', 'label': 'Nakit Değerler'},
{'value': 'tcmbacalak', 'label': 'TCMB Alacaklar'},
{'value': 'parapiyasasıalacak', 'label': 'Para Piyasalarından Alacaklar'},
{'value': 'bankalardanalacak', 'label': 'Bankalardan Alacaklar'},
{'value': 'gudkarzarar', 'label': 'Gerçeğe Uygun Değer Farkı K/Z Yan. Menk. Değ. (Net)'},
{'value': 'guddiger', 'label': 'GUD Farkı Diğer Kapsamlı Gelire Yan. Menk. Değ.'},
{'value': 'itfaedilmis', 'label': 'İtfa Edilmiş Maliyeti Üzerinden Değerlenen Menkul Değerler'},
{'value': 'repokonusumd', 'label': 'Repo İşlemine Konu Menkul Değerler'},
{'value': 'teminatkonusumd', 'label': 'Teminata Verilen Menkul Değerler'},
{'value': 'eurobond', 'label': 'Eurobondlar'},
{'value': 'zorunlukarsılık', 'label': 'Zorunlu Karşılıklar'},
{'value': 'tcmbborclar', 'label': 'TCMB Borçlar'},
{'value': 'parapiyasasıborclar', 'label': 'Para Piyasalarına Borçlar'},
{'value': 'bankalaraborclar', 'label': 'Bankalara Borçlar'},
{'value': 'repofonlar', 'label': 'Repo İşlemlerinden Sağlanan Fonlar'},
{'value': 'ihracedilen', 'label': 'İhraç Edilen Menkul Kıymetler'},
{'value': 'bankalarakredi', 'label': 'Bankalara Kullandırılan Krediler'},
{'value': 'bankalarmevduatı', 'label': 'Bankalar Mevduatı'},
{'value': 'karzarar', 'label': 'Kar Zarar'},


]

export const hmbOptions:  HMBOptions[] = [
{'value': 'centralbudgetrevenue', 'label': 'Merkezi Yönetim Gelirleri'},
{'value': 'centralbudgetexpense', 'label': 'Merkezi Yönetim Giderleri'},
{'value': 'centralbudgetfinance', 'label': 'Merkezi Yönetim Bütçe Finansmanı'},

]

export interface HMBOptions {
   value: string;
   label: string;
}


export interface MenuOption {
   label: string;
   options:  [] ;
}

export const menuOption:  MenuOption[] = [
   {  label: "",
    options: [],
    },
   {  label: 'Bankacılık',
    options: bankingOptions,
    },
   {  label: 'Kredi Kartları',
    options: creditcardOptions,
    },
   {  label: 'Merkezi Yönetim Bütçesi',
    options: hmbOptions,
    },
    ]

//export const menu_list={"all":menuOption,"creditcard":creditcardOptions}