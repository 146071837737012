/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import get_cgb_exp_data from "layouts/api";

function cgbDataExp(){
  const config={"func":"cgbdata_exp","params":""}
  const result = get_cgb_exp_data(config).then((response) => {
    return {
      datasets: response.data,
    };
  });
  return result;
}

export default cgbDataExp();
