/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React,{useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
import get_bs_data from "layouts/api";


function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}


function convertdata(data) {
  let result = [];
  for (let [key, value] of Object.entries(data)) {
	    if (key != "date") {
			   result.push({ title: key, values: value });
			   }
	}
  return result;
}


function balanceSheetData(params) {
    const defaultData= {liquidasset: [{'title': 'Nakit ve Nakit Benzeri', 'values': [ 0,0,0,0,0,0,0]}],
                        fixedasset: [{'title': 'Maddi Duran Varlıklar', 'values': [ 0,0,0,0,0,0,0]}],
                        shorttermloan: [{'title': 'Kısa Vadeli Borçlar', 'values': [ 0,0,0,0,0,0,0]}],
                        longtermloan: [{'title': 'Uzun Vadeli Borçlar', 'values': [ 0,0,0,0,0,0,0]}],
                        equity: [{'title': 'Özkaynaklar', 'values': [ 0,0,0,0,0,0,0]}],
                        labels: [ "","","","","","",""],
    }
    //const [chartData, setChartData] = useState(null);
    const [data, setData] = useState(defaultData);

    //const dataFetchedRef = useRef(false);
    const fetchData = async () => {
        const valueindex=1
        const config={"func":"balancesheet","params":params};
        const response = await get_bs_data(config);

        const tmpdata = await response.data;
        const liquidasset = convertdata( tmpdata["Dönen Varlıklar"]);
        const fixedasset = convertdata( tmpdata["Duran Varlıklar"]);
        const shorttermloan = convertdata( tmpdata["Kısa Vadeli Borçlar"]);
        const longtermloan = convertdata( tmpdata["Uzun Vadeli Borçlar"]);
        const equity = convertdata( tmpdata["Özkaynaklar"]);
        const index = tmpdata["index"];

	    setData({liquidasset:liquidasset,fixedasset:fixedasset,shorttermloan:shorttermloan,longtermloan:longtermloan,equity:equity,labels:index});
    }

    React.useEffect(() => {
               fetchData();
    },[params]);
  return data
}

export default balanceSheetData;

