import { Row } from "@silevis/reactgrid";

import {
  İhracat,
  İthalat,
  NetTransit,
  ParasalOlmayanAltın,
  İmalat,
  BakımOnarım,
  Taşımacılık,
  DigerHizmet,
  UcretOdeme,
  YatırımGelirleri,
  İkinciGelirDengesi,
  DogrudanYatırımNetVarlık,
  DogrudanYatırımNetYukumluluk,
  PortfoyYatırımNetVarlık,
  PortfoyYatırımNetYukumluluk,
  EfektifMevduatNetVarlık,
  EfektifMevduatNetYukumluluk,
  KrediNetVarlık,
  KrediNetYukumluluk,
  DigerYatırımlarDiger,
  RezervVarlıklar,
  NetHataNoksan,
  Labels,
  InputVariables,
  MonthlyValues,
  OutputVariables
} from "layouts/nationalaccount/currentaccount/components/BalanceSheet/components/interfaces";
import { months } from "layouts/nationalaccount/currentaccount/components/BalanceSheet/components/helpers";
import {
  emptyTextCell,
  nonEditable,
  textCell,
  monthHeaderCell,
  bottomLine,
  numberCell,
  showZero,
  noSideBorders
} from "layouts/nationalaccount/currentaccount/components/BalanceSheet/components/cells";

export const HEADER_ROW_ID = "header";
export const MONTHSTOTAL_ROW_ID = "monthsTotal";
export const MONTHSTOTAL_ROW_ID2= "monthsTotal2";
export const MONTHSTOTAL_ROW_ID3= "monthsTotal3";


const ROW_HEIGHT = 21;
const HEADING_ROW_HEIGHT = 30;

function sumGroupValues(values: MonthlyValues): number {
  return values.reduce(
    (prev, curr) => (isNaN(prev) ? 0 : prev) + (isNaN(curr) ? 0 : curr)
  );
}

function getHeaderRow(
  groups: (Labels)[],
): Row [] {
  const labels_data=groups.slice(-12)
  const tmp_labels = labels_data.map((_, idx) => nonEditable(monthHeaderCell(labels_data[idx], "justify-content-center")));
  const labels = [nonEditable(emptyTextCell)].concat(tmp_labels)
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells:labels,
  };
}


function getBlankRow(title:"Header"): Row {
  return {
    rowId: `${title}`,
    height: ROW_HEIGHT,
    cells: [
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      //nonEditable(monthHeaderCell("Totals", "justify-content-end")),
    ],
  };
}

function getSingleRow(
  title: "" ,
  groups: [],
  //yearlyGroupTotal: number
): Row[] {
  return [
    ...groups.map(({ title, values }) => ({
      rowId: `${title}Header`,
      height: HEADING_ROW_HEIGHT,
      cells: [
        bottomLine(nonEditable(textCell(title, "align-items-end text-md font-bold text-green"))),
        ...values.map((_, idx) => nonEditable(numberCell(values[idx],
                `font-bold disabled text-darkblue`))),
      ],
    })),
  ];
}



function getGroupRows(
  title: "",
  summaryTitle: string,
  groups: [],
  monthlyGroupTotals: MonthlyValues,
): Row[] {
  return [
    {
      rowId: `${title}Header`,
      height: HEADING_ROW_HEIGHT,
      cells: [
          nonEditable(
            textCell(
              title,
              `align-items-end text-md font-bold disabled text-darkblue`
            )
          ),
        ...months().map((_, idx) =>
          nonEditable(
            showZero(
              numberCell(
                monthlyGroupTotals[idx],
                `font-bold text-darkblue`
              )
            )
          )
        ),
      ]
    },
    ...groups.map(({ title, values }) => ({
      rowId: title,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell(title, "padding-left-lg")),
        ...values.map((_, idx) => nonEditable(showZero(numberCell(values[idx])))),
      ]
    })),
  ];
}


function getMultiGroupRows(
  title: "",
  summaryTitle: string,
  groups: [],
): Row[] {
  return [
    ...groups.map(({ title, values }) => ({
      rowId: title,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell(title,
              `text-md font-bold text-${
                    title === "Ticari Krediler" ? "green" : title === "Diğer Varlıklar ve Yükümlülükler" ? "green": title === "Net Varlık Edinimi   " ? "green" : title === "Özel Çekme Hakları, SDR" ? "green": "darkblue"
                       }`
         )),
        ...values.map((_, idx) => nonEditable(showZero(numberCell(values[idx],`font-bold text-${
                    title === "Ticari Krediler" ? "green" : title === "Diğer Varlıklar ve Yükümlülükler" ? "green": title === "Net Varlık Edinimi   " ? "green" : title === "Özel Çekme Hakları, SDR" ? "green": "darkblue"
                       }`)))),
      ]
    })),
  ];
}


function getTotalsRow(
  title: string,
  monthlyTotals: MonthlyValues,
): Row {
  const monthsTotalCell = (value: number) =>
      nonEditable(showZero(numberCell(value, "text-md-darkblue font-bold")))
  return {
    rowId: `${title}Header`,
    height: HEADING_ROW_HEIGHT,
    cells: [
     nonEditable(textCell(title, "text-lg-darkblue disabled font-bold")),
      ...months().map((_, idx) =>
        nonEditable(monthsTotalCell(monthlyTotals[idx]))
      ),
    ],
  };
}

function getTotalsRow2(
  title: string,
  monthlyTotals: MonthlyValues,
): Row {
  const monthsTotalCell = (value: number) =>
      nonEditable(showZero(numberCell(value, "text-md font-bold text-green")))
  return {
    rowId: `${title}Header`,
    height: HEADING_ROW_HEIGHT,
    cells: [
     nonEditable(textCell(title, "text-md font-bold disabled text-green")),
      ...months().map((_, idx) =>
        nonEditable(monthsTotalCell(monthlyTotals[idx]))
      ),
    ],
  };
}


function getTotalsHeadRow(
  title: string,
  monthlyTotals: MonthlyValues,
): Row {
  const monthsTotalCell = (value: number) =>
      bottomLine(nonEditable(showZero(numberCell(value, "text-lg-red disabled font-bold"))));
  return {
    rowId: `${title}Header`,
    height: HEADING_ROW_HEIGHT,
    cells: [
      bottomLine(nonEditable(textCell(title, "text-lg-red disabled font-bold"))),
      ...months().map((_, idx) =>
        bottomLine(nonEditable(monthsTotalCell(monthlyTotals[idx])))
      ),
    ],
  };
}

function getSingleTotalRow(
  title: "" ,
  groups: [],
  //yearlyGroupTotal: number
): Row[] {
  return [
    ...groups.map(({ title, values }) => ({
      rowId: `${title}Header`,
      height: HEADING_ROW_HEIGHT,
      cells: [
        nonEditable(textCell(title, "text-md-darkblue disabled font-bold")),
        ...values.map((_, idx) => nonEditable(numberCell(values[idx],
                `font-bold text-lg-darkblue`))),
      ],
    })),
  ];
}


export function getRowsSummary({
  ihracat,
  monthlyİhracatTotals,
  ithalat,
  monthlyİthalatTotals,
  monthlyMalTicaretiTotals,
  nettransit,
  //monthlyNetTransitTotals,
  parasalolmayanaltın,
  //monthlyParasalOlmayanAltınTotals,
  monthlyDısTicaretTotals,
  imalat,
  bakımonarım,
  taşımacılık,
  monthlyTaşımacılıkTotals,
  digerhizmet,
  monthlyHizmetlerDengesiTotals,
  ucretodeme,
  yatırımgelirleri,
  monthlyYatırımGelirleriTotals,
  monthlyBirinciGelirDengesiTotals,
  ikincigelir,
  monthlyİkinciGelirDengesiTotals,
  monthlyCariDengeTotals,
  monthlyDogrudanYatırımTotals,
  monthlyPortfoyYatırımTotals,
  monthlyEfektifMevduatTotals,
  monthlyKrediTotals,
  monthlyDigerYatırımTotals,
  monthlyFinancialAccountTotals,
  digeryatırımlardiger,
  rezervvarlıklar,
  monthlyRezervVarlıklarTotals,
  nethatanoksan,
  monthlyNetHataNoksanTotals,
  labels,
}: InputVariables & OutputVariables): Row[] {
  return [
   getHeaderRow(labels),
   /*getTotalsHeadRow(
      "MERKEZİ YÖNETİM GELİRLERİ",
      monthlyCentralGovRevenueTotals,
    ),*/
   getTotalsHeadRow(
      "CARİ DENGE (A+B+C+D)",
      monthlyCariDengeTotals,
    ),
   getTotalsHeadRow(
      "A) Dış Ticaret Dengesi",
      monthlyDısTicaretTotals,
    ),
   getTotalsRow(
      "Mal Dengesi",
      monthlyMalTicaretiTotals,
    ),
   ...getSingleTotalRow(
     "Net Transit Ticaret Geliri",
       nettransit,
     ),
    ...getSingleTotalRow(
     "Parasal Olmayan Altın",
       parasalolmayanaltın,
     ),
    getTotalsHeadRow(
      "B) Hizmetler Dengesi",
      monthlyHizmetlerDengesiTotals,
    ),
    ...getSingleTotalRow(
       "İmalat Hizmetleri",
       imalat,
     ),
    ...getSingleTotalRow(
       "Bakım ve Onarım Hizmetleri",
       bakımonarım,
     ),
     getTotalsRow(
       "Taşımacılık",
       monthlyTaşımacılıkTotals,
     ),
    ...getMultiGroupRows(
      "Diger Hizmetler",
      "Diğer Hizmetler",
       digerhizmet,
    ),
   getTotalsHeadRow(
      "C) Birinci Gelir Dengesi",
      monthlyBirinciGelirDengesiTotals,
    ),
    ...getSingleTotalRow(
       "Ücret Hizmetleri",
       ucretodeme,
     ),
    getTotalsRow(
       "Yatırım Gelirleri",
       monthlyYatırımGelirleriTotals,
     ),
   getTotalsHeadRow(
      "D) İkinci Gelir Dengesi",
      monthlyİkinciGelirDengesiTotals,
    ),
    ...getMultiGroupRows(
      "İkinci Gelir",
      "İkinci Gelir",
       ikincigelir,
    ),
   getTotalsHeadRow(
      "FİNANS HESABI",
       monthlyFinancialAccountTotals,
    ),
   getTotalsRow(
      "Doğrudan Yatırımlar",
      monthlyDogrudanYatırımTotals,
    ),
    getTotalsRow(
      "Portfoy Yatırımları",
      monthlyPortfoyYatırımTotals,
    ),
    getTotalsRow(
      "Diğer Yatırımlar",
      monthlyDigerYatırımTotals,
    ),
        getTotalsRow2(
     "Efektif ve Mevduatlar",
      monthlyEfektifMevduatTotals,
    ),
    getTotalsRow2(
     "Krediler",
      monthlyKrediTotals,
    ),
    ...getMultiGroupRows(
      "Diğer Varlıklar ve Yükümlülükler",
      "Diğer Varlıklar ve Yükümlülükler",
       digeryatırımlardiger,
    ),
    getTotalsRow(
      "Rezerv Varlıklar",
      monthlyRezervVarlıklarTotals,
    ),
     getTotalsHeadRow(
      "NET HATA ve NOKSAN",
      monthlyNetHataNoksanTotals,
    ),
  ];
}
