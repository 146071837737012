import { Column } from "@silevis/reactgrid";

const COL_WIDTH = 124;

export function getColumns(): Column[] {
  return [
    {
      columnId: "titlesColumn",
      width: 320
    },
    { columnId: 1, width: COL_WIDTH },
    { columnId: 2, width: COL_WIDTH },
    { columnId: 3, width: COL_WIDTH },
    { columnId: 4, width: COL_WIDTH },
    { columnId: 5, width: COL_WIDTH },
    { columnId: 6, width: COL_WIDTH },
    { columnId: 7, width: COL_WIDTH },
    //{
    //  columnId: "Totals",
    //  width: 120
    //}
  ];
}
