/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
import get_menu_list from "layouts/api";


function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}


function menuData(params) {
    const defaultData={}
    const [data, setData] = useState(defaultData);

        //const dataFetchedRef = useRef(false);
    const fetchData = async () => {
        const config={"func":"get_menu_list","params":params}
        const response = await get_menu_list(config);
        const tmpdata = await response.data;
        //const date = transposedata.date;
	    setData(tmpdata);
    }

    useEffect(() => {
            fetchData();
    },[]);


  return data

}

export default menuData;
