// @mui material components
import Grid from "@mui/material/Grid";
import DefaultAmLineChart from "examples/Charts/AmChartsLine/DefaultAmLineChart";
import DefaultAmBarChart from "examples/Charts/AmChartsBar/DefaultAmBarChart";
import DefaultAmBarLineChart from "examples/Charts/AmChartsBarandLine/DefaultAmBarLineChart";
import DefaultHighLineChart from "examples/Charts/HighChartsLine/DefaultHighLineChart";
import DefaultHighStockColumnChart from "examples/Charts/HighStockColumns/DefaultHighStockColumnChart";
import DefaultHighComboChart from "examples/Charts/HighChartsCombo/DefaultHighComboChart";

//Graphs

import marketdata from "layouts/data/data/marketDataforAmCharts";
//import marketdata from "layouts/data/data/marketDataforHighCharts";

function Graph(item) {

//var labeltext = item.label.join(" , ");
if (item.label.constructor ===  Array) {
  var labeltext = item.label.join(" , ");
} else {
  var labeltext = item.label;
}

  return (
    <>
      <div  style={{ width: 1200 }} >
                   <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
            {item.graph=="line" ? (
                <DefaultAmLineChart
                title={labeltext}
                description={""}
                height="34.25rem"
                chart={marketdata(item.value)}
                check={item.check}
              />
            ) : item.graph=="bar" ? (
                <DefaultAmBarChart
                title={labeltext}
                description={""}
                height="34.25rem"
                chart={marketdata(item.value)}
                check={item.check}
              />
            ) : (
                <DefaultAmBarLineChart
                title={labeltext}
                description={""}
                height="34.25rem"
                chart={marketdata(item.value)}
                check={item.check}
              />
            )
            }
            </Grid>
          </Grid>
        </div>


    </>
  );
}
export default Graph;
