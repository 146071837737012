import {
  DısVarlıklar,
  Nakitİslemler,
  DegerlemeHesabı,
  DısYukumluluk,
  IcYukumluluk,
  RezervPara,
  DigerMBPara,
  Labels,
  MonthlyValues
} from "layouts/financialsector/tcmb/analytic/components/BalanceSheet/components/interfaces";
import { months } from "layouts/financialsector/tcmb/analytic/components/BalanceSheet/components/helpers";


const sumGroupInAMonth = (group: (DısVarlıklar | Nakitİslemler | DegerlemeHesabı | DısYukumluluk | IcYukumluluk | RezervPara | DigerMBPara )[]) => (
  monthIndex: number
): number =>
  group.reduce(
    (acc, { values }) =>
      acc + (isNaN(values[monthIndex]) ? 0 : values[monthIndex]),
    0
  );

const calcMonthlyGroupTotals = (
  group: (DısVarlıklar | Nakitİslemler | DegerlemeHesabı | DısYukumluluk | IcYukumluluk | RezervPara | DigerMBPara )[]
): MonthlyValues => months().map((_, idx) => sumGroupInAMonth(group)(idx));

// DCF'e eklenecek.
export function calculateOutputVariables(
  inputVariables: InputVariables
): OutputVariables {
  const {
    dısvarlıklar,
    nakitislemler,
    degerlemehesabı,
    dısyukumluluk,
    icyukumluluk,
    rezervpara,
    digermbpara,
    labels,
  } = inputVariables;

  const monthlyDısVarlıklarTotals = calcMonthlyGroupTotals(dısvarlıklar);
  const monthlyNakitİslemlerTotals = calcMonthlyGroupTotals(nakitislemler);
  const monthlyDegerlemeHesabıTotals = calcMonthlyGroupTotals(degerlemehesabı);
  const monthlyDısYukumlulukTotals = calcMonthlyGroupTotals(dısyukumluluk);
  const monthlyIcYukumlulukTotals = calcMonthlyGroupTotals(icyukumluluk);
  const monthlyRezervParaTotals = calcMonthlyGroupTotals(rezervpara);
  const monthlyDigerMBParaTotals = calcMonthlyGroupTotals(digermbpara);
  //const yearlyInflowTotal = monthlyLiquidAssetTotals.reduce((a, b) => a + b);
  //const yearlyOutflowTotal = monthlyFixedAssetTotals.reduce((a, b) => a + b);

  //const yearlyInflowOuflowDiff = yearlyInflowTotal - yearlyOutflowTotal;
  //const yearlyInflowOuflowShortTermLoanDiffs= yearlyInflowOuflowDiff - yearlyShortTermLoanTotal;


  const monthlyLiabilitySum1 = months().map(
    (_, idx) => monthlyDısYukumlulukTotals[idx] + monthlyIcYukumlulukTotals[idx]
  );

  const monthlyLiabilitySum2 = months().map(
    (_, idx) => monthlyRezervParaTotals[idx] + monthlyDigerMBParaTotals[idx]
  );

    const monthlyLiabilitySum = months().map(
    (_, idx) => monthlyDısYukumlulukTotals[idx] + monthlyIcYukumlulukTotals[idx]+monthlyRezervParaTotals[idx] + monthlyDigerMBParaTotals[idx]
  );

    const monthlyAssetSum = months().map(
    (_, idx) => monthlyDısVarlıklarTotals[idx] + monthlyNakitİslemlerTotals[idx]+monthlyDegerlemeHesabıTotals[idx]
  );


   const monthlyAssetLiabilityDiffs = months().map(
    (_, idx) => monthlyDısVarlıklarTotals[idx] + monthlyIcYukumlulukTotals[idx]-monthlyRezervParaTotals[idx] - monthlyDigerMBParaTotals[idx]
  );


  return {
    monthlyDısVarlıklarTotals,
    monthlyNakitİslemlerTotals,
    monthlyDegerlemeHesabıTotals,
    monthlyAssetSum,
    monthlyDısYukumlulukTotals,
    monthlyIcYukumlulukTotals,
    monthlyLiabilitySum1,
    monthlyRezervParaTotals,
    monthlyDigerMBParaTotals,
    monthlyLiabilitySum2,
    monthlyLiabilitySum,
    monthlyAssetLiabilityDiffs

  };
}
