import { months } from "layouts/realsector/dcf/components/helpers";
import { CashInflow, CashOutflow,OPEX,CashOtherInflow,CashOtherOutflow,MonthlyValues} from "layouts/realsector/dcf/components/interfaces";

const emptyMonthsValues: MonthlyValues = [
  NaN,
  NaN,
  NaN,
  NaN,
  NaN,
  NaN,
  NaN
];

// Burdaki isimler DCF altına gidecek
export const inflows: CashInflow[] = [
{'title': 'Cep Telefonu Yenileme Satışları', 'values': [9499164,2374791,2374791,2374791,2374791,2374791,2374791 ]} ,
{'title': 'Ticari Ürün Satışları', 'values': [16935029,8433757,4233757,4233757,4233757,4233757,4233757 ]} ,
{'title': 'Hizmet Satışları', 'values': [0,0,0,0,0,0,0]} ,
{'title': 'Yurtdışı Satışlar', 'values': [ 5012114,1253029,1253029,1253029,1253029,1253029,1253029 ]} ,
{'title': 'Diğer Gelir', 'values': [ 23552,5888,5888,5888,5888,5888,5888 ]} ,
{'title': 'Satış İndirimleri', 'values': [ -522706,-130677,-130677,-130677,-130677,-130677,-130677]} ,
];

export const outflows: CashOutflow[] = [
{'title': 'Satınalma Maliyetleri', 'values': [ 21181850,8170162,5295463,5295463,5295463,5295463,5295463 ]} ,
{'title': 'Parça Maliyetleri', 'values': [ 1104660,426084,276165,276165,276165,276165,276165]} ,
{'title': 'Üretim İşçilikleri', 'values': [ 1384677,346169,346169,346169,346169,346169,346169 ]} ,
{'title': 'Genel Üretim Giderleri', 'values': [ 1074145,414314,268536,268536,268536,268536,268536]} ,
];

export const opex: OPEX[] = [
{'title': 'Ar-Ge İşçilik Giderler', 'values': [ 211677,52919,52919,52919,52919,52919,52919 ]} ,
{'title': 'Ar-Ge Giderleri', 'values': [ 1072638,268160,268160,268160,268160,268160,268160 ]} ,
{'title': 'Satış Giderleri', 'values': [ 1702979,425745,425745,425745,425745,425745,425745 ]} ,
{'title': 'Yönetim Giderleri', 'values': [ 1632651,408163,408163,408163,408163,408163,408163 ]} ,
];

export const otherInflows: CashOtherInflow[] = [
{'title': 'Diğer Gelirler', 'values': [  58308,14577,14577,14577,14577,14577,14577  ]} ,
];

export const otherOutflows: CashOtherOutflow[] = [
{'title': 'Diğer Giderler', 'values': [  361650,90413,90413,90413,90413,90413,90413  ]} ,
];

export const financialOutflow: FinancialOutflow[] = [
{'title': 'Finansman Giderleri', 'values': [  958588,239647,77698,67763,64846,44656,36371   ]} ,
];
