import {
  LiquidAsset,
  FixedAsset,
  ShortTermLoan,
  LongTermLoan,
  Equity,
  Labels,
  MonthlyValues
} from "layouts/realsector/firms/components/BalanceSheet/components/interfaces";
import { months } from "layouts/realsector/firms/components/BalanceSheet/components/helpers";


const sumGroupInAMonth = (group: (LiquidAsset | FixedAsset | ShortTermLoan | LongTermLoan | Equity )[]) => (
  monthIndex: number
): number =>
  group.reduce(
    (acc, { values }) =>
      acc + (isNaN(values[monthIndex]) ? 0 : values[monthIndex]),
    0
  );

const calcMonthlyGroupTotals = (
  group: (LiquidAsset | FixedAsset | ShortTermLoan | LongTermLoan | Equity )[]
): MonthlyValues => months().map((_, idx) => sumGroupInAMonth(group)(idx));

// DCF'e eklenecek.
export function calculateOutputVariables(
  inputVariables: InputVariables
): OutputVariables {
  const {
    liquidasset,
    fixedasset,
    shorttermloan,
    longtermloan,
    equity,
    labels,
  } = inputVariables;

  const monthlyLiquidAssetTotals = calcMonthlyGroupTotals(liquidasset);
  const monthlyFixedAssetTotals = calcMonthlyGroupTotals(fixedasset);
  const monthlyShortTermLoanTotals = calcMonthlyGroupTotals(shorttermloan);
  const monthlyLongTermLoanTotals = calcMonthlyGroupTotals(longtermloan);
  const monthlyEquitySum = calcMonthlyGroupTotals(equity);

  //const yearlyInflowTotal = monthlyLiquidAssetTotals.reduce((a, b) => a + b);
  //const yearlyOutflowTotal = monthlyFixedAssetTotals.reduce((a, b) => a + b);

  //const yearlyInflowOuflowDiff = yearlyInflowTotal - yearlyOutflowTotal;
  //const yearlyInflowOuflowShortTermLoanDiffs= yearlyInflowOuflowDiff - yearlyShortTermLoanTotal;


  const monthlyAssetsSum = months().map(
    (_, idx) => monthlyLiquidAssetTotals[idx] + monthlyFixedAssetTotals[idx]
  );

  const monthlyLiabilitySum = months().map(
    (_, idx) => monthlyShortTermLoanTotals[idx] + monthlyLongTermLoanTotals[idx]
  );

  const monthlyLiabilityEquitySum = months().map(
    (_, idx) => monthlyShortTermLoanTotals[idx] + monthlyLongTermLoanTotals[idx]+ monthlyEquitySum[idx]
  );

   const monthlyAssetLiabilityDiffs = months().map(
    (_, idx) => monthlyLiquidAssetTotals[idx] + monthlyFixedAssetTotals[idx]-monthlyShortTermLoanTotals[idx] - monthlyLongTermLoanTotals[idx]
  );


  return {
    monthlyLiquidAssetTotals,
    monthlyFixedAssetTotals,
    monthlyAssetsSum,
    monthlyShortTermLoanTotals,
    monthlyLongTermLoanTotals,
    monthlyLiabilitySum,
    monthlyEquitySum,
    monthlyLiabilityEquitySum,
    monthlyAssetLiabilityDiffs

  };
}
