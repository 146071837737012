
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";


// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import FundingTable from "layouts/financialsector/tcmb/funding/components/FundingTable";


// Soft UI Dashboard PRO React icons
import { MdOutlineCalendarMonth } from "react-icons/md";
import { BsCalendar2MonthFill } from "react-icons/bs";
import { BsCalendar2DayFill } from "react-icons/bs";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

import Footer from "examples/Footer";

//Graphs

//Data

// Soft UI Dashboard PRO React example components
import FundingGraph from "layouts/financialsector/tcmb/funding/components/FundingGraph";

// Data
import fundingdata from "layouts/financialsector/tcmb/funding/data/fundingdata";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));


function Funding() {
//const titlename=stock.stock + " Nakit Akımları (1000 TL)"

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [balance, setBalance] = useState(true);


  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

const handleSetTabValue = (event, newValue) => setTabValue(newValue);

const handleSetBalance = () => {
  setBalance(!balance)
  };

const params="fonlama"
const params2="maliyet"

//const data=reservedata(params)
//const data2=reservedata(params2)
//const data3=reservedata(params3)
  return (


    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
           <SoftBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
            </SoftBox>

            <Grid item xs={12} lg={12}>
            <Item>
            <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ backgroundColor: "transparent" }}
              >
                <Tab label="Fonlama" icon={<BsCalendar2DayFill />} />
                <Tab label="Maliyet" icon={<BsCalendar2MonthFill />} />
              </Tabs>
            </AppBar>
          </Grid>

      <SoftBox p={1} mt={0} width="100%" height="34.5rem">

        <FundingGraph data={ fundingdata(params)}  value={tabValue} index={0}    balance={balance}/>
        <FundingGraph data={ fundingdata(params2)}  value={tabValue} index={1}   balance={balance}/>

      </SoftBox>
             </Item>
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox my={6} width="100%">
            <Divider sx={{height: 8}}/>
        </SoftBox>
       <FundingTable/>
      </SoftBox>


     <Footer />
    </DashboardLayout>

  );
}
export default Funding;
