/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import PdfApi from "api/pdf";
var fileDownload = require('js-file-download');

const get_pdf_file = (params) => {
  const data = PdfApi.pdf_file(params).then((response) => {
    //fileDownload(response.data,  params.params+'.pdf');
        //console.log(response)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        //console.log(url)
        link.setAttribute('download', params.params+'.pdf')
        document.body.appendChild(link)
        link.click()
    /*console.log(response)
    let url = window.URL.createObjectURL(new Blob([response.data]));
    let link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", params.params+'.pdf');
    document.body.appendChild(link);
    link.click();*/
  });
};
export default get_pdf_file;
