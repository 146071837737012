
export const chartOptions: ChartOptions =  {
    interaction:
    {mode:'index'},
  elements: {
    line: {
      tension: 0.1
    }
  },
  indexAxis: 'y',
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
  legend: {display: false},
  filler: { propagate: true},
  tooltip: {
                usePointStyle: true,
                callbacks: {
                    labelPointStyle: function(context) {
                        return {
                            pointStyle: 'circle',
                            rotation: 0
                        };
                    }
                },
                filter: (tooltipItem) => tooltipItem.raw > 0,
            }
  },
  };