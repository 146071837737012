import { ChartOptions, ChartData } from "chart.js";
import { InputVariables, OutputVariables } from "layouts/financialsector/banking/balancesheet/components/BalanceSheet/components/interfaces";




export const getChartData = (
  plannerData: InputVariables & OutputVariables,labels
): ChartData => ({
  labels: labels.slice(-12),
  datasets: [
     {
      label: "Aktifler",
      borderColor:  "#280659",
      backgroundColor: "rgba(40,6,89,0.5)",
      fill: false,
      stack: "0",
      barPercentage: 0.8,
      data: plannerData.monthlyAktifTotals.slice(-12).map((value) => value),
      borderWidth: 2
    },
     {
      label: "Yabancı Kaynaklar",
      borderColor: "#1D7874",
      backgroundColor: "rgb(29, 120, 116,0.3)",
      fill: false,
      stack: "1",
      barPercentage: 0.8,
      data: plannerData.monthlyYabancıKaynakTotals.slice(-12).map((value) => value),
      borderWidth: 2
    },
    {
      label: "Özkaynaklar",
      borderColor:  "#900c3e",
      backgroundColor:"rgba(144,12,62,0.6)",
      fill: false,
      stack: "1",
      barPercentage: 0.8,
      data: plannerData.monthlyÖzkaynakTotals.slice(-12).map((value) => value),
      borderWidth: 2
    },

    /*{
      label: "Özel Bütçeli İdarelerin Gelirleri",
      borderColor: "#1D7874",
      backgroundColor: "rgb(29, 120, 116,0.5)",
      fill: false,
      stack: "2",
      barPercentage: 0.8,
      data: plannerData.monthlyRevenueRFSBITotals.slice(-12).map((value) => value),
      borderWidth: 2
    },
        {
      label: "Düzenleyici ve Denetleyici Kurumların Gelirleri",
      borderColor:  "#D4AF00",
      backgroundColor: "rgba(212,175,0,0.6)",
      fill: false,
      stack: "2",
      barPercentage: 0.8,
      data: plannerData.monthlyRevenueRFRSITotals.slice(-12).map((value) => value),
      borderWidth: 2
    }*/
  ]
});

let delayed;
export const chartOptions: ChartOptions =
{
      legend: {
    display: true
  },
  elements: {
    line: {
      tension: 0.1
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    filler: {
      propagate: true
    }
  },
  layout: {
    padding: {
      left: 112
    }
  },
    animation: {
      onComplete: () => {
        delayed = true;
      },
      delay: (context) => {
        let delay = 0;
        if (context.type === 'data' && context.mode === 'default' && !delayed) {
          delay = context.dataIndex * 300 + context.datasetIndex * 100;
        }
        return delay;
      },
    },
  scales: {
    xAxes: [
      {
        display: true,
        ticks: {
          autoSkip: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        },
      }
    ]
  }
  }
  ;

