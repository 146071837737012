import { Bar } from "react-chartjs-2";

import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";


// Soft UI Dashboard PRO React example components
import GraphView from "layouts/nationalaccount/tradebalance/components/TradeIndexGraphs";



function TradeIndexGraph(chartdata) {
console.log(chartdata)
  if (chartdata.exporimp == true ) {
    var monthlydata_last=chartdata.chartdata.ithalat
    var yearlydata_last=chartdata.chartdata1.ithalat
    var exporimptext="İthalat"
    } else {
    var monthlydata_last=chartdata.chartdata.ihracat
    var yearlydata_last=chartdata.chartdata1.ihracat
    var exporimptext="İhracat"
      }
  return (
      <Card>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SoftTypography variant="h6">Dış Ticaret Endeksleri</SoftTypography>
      </SoftBox>
      <SoftBox p={2} mt={0} width="100%" height="38.25rem">
	        <GraphView data={monthlydata_last}  value={chartdata.tabValue} index={0}  />
	        <GraphView data={yearlydata_last}  value={chartdata.tabValue} index={1}/>
      </SoftBox>
    </Card>
  );
};

export default TradeIndexGraph;
