import { ChartOptions, ChartData } from "chart.js";

// const params=["07112019","30032021","23032023","22032024","05022025","30052040","14012041"]

export const getChartData = (
  datasets,labels
): ChartData => ({
  labels: labels,
  datasets:datasets
});


export const chartOptions: ChartOptions =  {
    interaction:
    {mode:'index'},
  elements: {
    line: {
      tension: 0.01
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
  //stacked100: { enable: true, replaceTooltipLabel: false },
  legend: {display: true,
  position:'bottom'},
    filler: {
      propagate: true
    }
  },
  scales: {
    xAxes: [
      {
        stacked:true,
        display: true,
        ticks: {
          autoSkip: false,
        }
      },
    ],
    yAxes: [
      {
      stacked:true,
      display: true,
        ticks: {
          beginAtZero: true,
        }
      }
    ]
  },

  };