/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useEffect, useState, useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
//import { Line } from "react-chartjs-2";
// highcharts components
import Highcharts2 from "highcharts";
import HighchartsReact2 from "highcharts-react-official";

//import Highcharts from "highcharts/highstock";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// HighChartsLine configurations
import configs from "examples/Charts/HighChartsLine/SynchronizedHighLineChart/configs";

function SynchronizedHighLineChart({title, description,  height, chart,labels }) {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const oldReset = Highcharts2.Pointer.prototype.reset;
  const oldHighlight = Highcharts2.Point.prototype.highlight;

  Highcharts2.Pointer.prototype.reset = () => {};


Highcharts2.Point.prototype.highlight = function (event) {
    event = this.series.chart.pointer.normalize(event);
    this.onMouseOver(); // Show the hover marker
    this.series.chart.tooltip.refresh(this); // Show the tooltip
    this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
};



  //const dataset1=[chart.liquidityratios];
  useEffect(() => {
    const fetchData = async () => {
      try {
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      Highcharts2.Pointer.prototype.reset = oldReset;
      Highcharts2.Point.prototype.highlight = oldHighlight;
    };
  }, []);

  const renderChart = (dataset, index) => {
    const data = dataset.values.map((val, i) => [tmpdata.labels[i], val]);
    const colors= ["#280659", "#900c3e","#1D7874", "#C70039", "#D54800", "#FF5733"];
    //const colour = Highcharts.getOptions().colors[index];
    const colour = colors[index]
    const {options} = configs(dataset,data,colour,labels);

    return (
            <HighchartsReact2 highcharts={Highcharts2} options={options} key={dataset.title}/>
    );
  };

  const handleMouseMove = (e) => {
    let point = null;
    let event = null;

    e.persist();
    Highcharts2.charts.forEach((chart) => {
      if (!chart) return;
      event = chart.pointer.normalize(e);
      point = chart.series[0].searchPoint(event, true);

      if (point) {
        point.highlight(e);
      }
    });
  };


  let tmpdata={}
  tmpdata["labels"]=labels
  tmpdata["datasets"]=chart

  return (
  <Card>
   <SoftBox p={2}>
        <SoftBox px={description ? 1 : 0} pt={description ? 1 : 0}>
          {title && (
            <SoftBox mb={1}>
              <SoftTypography variant="h6">{title}</SoftTypography>
            </SoftBox>
          )}
          <SoftBox mb={2}>
            <SoftTypography variant="button" fontWeight="regular" textcolor="text">
              {description}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
    <div onMouseMove={handleMouseMove} onTouchMove={handleMouseMove} >
      {loading ? <p>Loading...</p> : tmpdata.datasets.map(renderChart)}
    </div>
   </SoftBox>
   </Card>
  );
}


// Setting default values for the props of GradientLineChart
SynchronizedHighLineChart.defaultProps = {
  title: "",
  description: "",
  height: "19.125rem",
};

// Typechecking props for the GradientLineChart
SynchronizedHighLineChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  //chart: PropTypes.objectOf(PropTypes.array).isRequired,
};


export default SynchronizedHighLineChart;
