export interface ColourOption {
   value: string;
   label: string;
   color: string;
   isFixed?: boolean;
   isDisabled?: boolean;
}

export const colourOptions:  ColourOption[] = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
];

export interface FlavourOption {
   value: string;
   label: string;
   rating: string;
}

export const flavourOptions:  FlavourOption[] = [
  { value: 'vanilla', label: 'Vanilla', rating: 'safe' },
  { value: 'chocolate', label: 'Chocolate', rating: 'good' },
  { value: 'strawberry', label: 'Strawberry', rating: 'wild' },
  { value: 'salted-caramel', label: 'Salted Caramel', rating: 'crazy' },
];

export interface StateOption {
   value: string;
   label: string;
}

export const stateOptions:  StateOption[] = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FM', label: 'Federated States Of Micronesia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PW', label: 'Palau' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const optionLength = [
  { value: 1, label: 'general' },
  {
    value: 2,
    label:
      'Evil is the moment when I lack the strength to be true to the Good that compels me.',
  },
  {
    value: 3,
    label:
      "It is now an easy matter to spell out the ethic of a truth: 'Do all that you can to persevere in that which exceeds your perseverance. Persevere in the interruption. Seize in your being that which has seized and broken you.",
  },
];

export const dogOptions = [
  { id: 1, label: 'Chihuahua' },
  { id: 2, label: 'Bulldog' },
  { id: 3, label: 'Dachshund' },
  { id: 4, label: 'Akita' },
];

// let bigOptions = [];
// for (let i = 0; i < 10000; i++) {
// 	bigOptions = bigOptions.concat(colourOptions);
// }

export interface GroupedOption {
   label: string;
   options:  ColourOption[] |  FlavourOption[];
}

export const groupedOptions:  GroupedOption[] = [
  {
    label: 'Colours',
    options: colourOptions,
  },
  {
    label: 'Flavours',
    options: flavourOptions,
  },
];

export const stocksOptions:  StocksOption[] = [
 {"value": "AVOD", "label": "AVOD"},
 {"value": "ACSEL", "label": "ACSEL"},
 {"value": "ADEL", "label": "ADEL"},
 {"value": "ADESE", "label": "ADESE"},
 {"value": "AFYON", "label": "AFYON"},
 {"value": "AGHOL", "label": "AGHOL"},
 {"value": "AHGAZ", "label": "AHGAZ"},
 {"value": "AKCNS", "label": "AKCNS"},
 {"value": "AKYHO", "label": "AKYHO"},
 {"value": "AKENR", "label": "AKENR"},
 {"value": "AKFGY", "label": "AKFGY"},
 {"value": "AKFEN", "label": "AKFEN"},
 {"value": "AKFYE", "label": "AKFYE"},
 {"value": "ATEKS", "label": "ATEKS"},
 {"value": "AKSGY", "label": "AKSGY"},
 {"value": "AKMGY", "label": "AKMGY"},
 {"value": "AKSA", "label": "AKSA"},
 {"value": "AKSEN", "label": "AKSEN"},
 {"value": "AKSUE", "label": "AKSUE"},
 {"value": "ALCAR", "label": "ALCAR"},
 {"value": "ALGYO", "label": "ALGYO"},
 {"value": "ALCTL", "label": "ALCTL"},
 {"value": "ALFAS", "label": "ALFAS"},
 {"value": "ALKIM", "label": "ALKIM"},
 {"value": "ALKA", "label": "ALKA"},
 {"value": "AYCES", "label": "AYCES"},
 {"value": "ALMAD", "label": "ALMAD"},
 {"value": "AEFES", "label": "AEFES(*)"},
 {"value": "ASUZU", "label": "ASUZU"},
 {"value": "ANGEN", "label": "ANGEN"},
 {"value": "ANELE", "label": "ANELE"},
 {"value": "ARCLK", "label": "ARCLK(*)"},
 {"value": "ARDYZ", "label": "ARDYZ"},
 {"value": "ARENA", "label": "ARENA"},
 {"value": "ARMDA", "label": "ARMDA"},
 {"value": "ARSAN", "label": "ARSAN"},
 {"value": "ARZUM", "label": "ARZUM"},
 {"value": "ASELS", "label": "ASELS"},
 {"value": "ASTOR", "label": "ASTOR"},
 {"value": "ATAGY", "label": "ATAGY"},
 {"value": "AGYO", "label": "AGYO"},
 {"value": "ATSYH", "label": "ATSYH"},
 {"value": "ATLAS", "label": "ATLAS"},
 {"value": "ATATP", "label": "ATATP"},
 {"value": "AVGYO", "label": "AVGYO"},
 {"value": "AVTUR", "label": "AVTUR"},
 {"value": "AVHOL", "label": "AVHOL"},
 {"value": "AYDEM", "label": "AYDEM"},
 {"value": "AYEN", "label": "AYEN"},
 {"value": "AYES", "label": "AYES"},
 {"value": "AYGAZ", "label": "AYGAZ"},
 {"value": "AZTEK", "label": "AZTEK"},
 {"value": "BAGFS", "label": "BAGFS"},
 {"value": "BAKAB", "label": "BAKAB"},
 {"value": "BALAT", "label": "BALAT"},
 {"value": "BNTAS", "label": "BNTAS"},
 {"value": "BANVT", "label": "BANVT"},
 {"value": "BARMA", "label": "BARMA"},
 {"value": "BASGZ", "label": "BASGZ"},
 {"value": "BASCM", "label": "BASCM"},
 {"value": "BTCIM", "label": "BTCIM"},
 {"value": "BSOKE", "label": "BSOKE"},
 {"value": "BAYRK", "label": "BAYRK"},
 {"value": "BERA", "label": "BERA"},
 {"value": "BRKSN", "label": "BRKSN"},
 {"value": "BJKAS", "label": "BJKAS"},
 {"value": "BEYAZ", "label": "BEYAZ"},
 {"value": "BIENY", "label": "BIENY"},
 {"value": "BLCYT", "label": "BLCYT"},
 {"value": "BLKOM", "label": "BLKOM"},
 {"value": "BIMAS", "label": "BIMAS"},
 {"value": "BIOEN", "label": "BIOEN"},
 {"value": "BRKO", "label": "BRKO"},
 {"value": "BRLSM", "label": "BRLSM"},
 {"value": "BRMEN", "label": "BRMEN"},
 {"value": "BIZIM", "label": "BIZIM"},
 {"value": "BMSTL", "label": "BMSTL"},
 {"value": "BMSCH", "label": "BMSCH"},
 {"value": "BOBET", "label": "BOBET"},
 {"value": "BRSAN", "label": "BRSAN"},
 {"value": "BRYAT", "label": "BRYAT"},
 {"value": "BFREN", "label": "BFREN"},
 {"value": "BOSSA", "label": "BOSSA"},
 {"value": "BRISA", "label": "BRISA"},
 {"value": "BURCE", "label": "BURCE"},
 {"value": "BURVA", "label": "BURVA"},
 {"value": "BUCIM", "label": "BUCIM"},
 {"value": "BVSAN", "label": "BVSAN"},
 {"value": "CRFSA", "label": "CRFSA"},
 {"value": "CASA", "label": "CASA"},
 {"value": "CEOEM", "label": "CEOEM"},
 {"value": "CREAN", "label": "CREAN"},
 {"value": "CCOLA", "label": "CCOLA"},
 {"value": "CONSE", "label": "CONSE"},
 {"value": "COSMO", "label": "COSMO"},
 {"value": "CLDNM", "label": "CLDNM"},
 {"value": "CLKEN", "label": "CLKEN"},
 {"value": "CANTE", "label": "CANTE"},
 {"value": "CLEBI", "label": "CLEBI"},
 {"value": "CELHA", "label": "CELHA"},
 {"value": "CEMAS", "label": "CEMAS"},
 {"value": "CEMTS", "label": "CEMTS"},
 {"value": "CMBTN", "label": "CMBTN"},
 {"value": "CMENT", "label": "CMENT"},
 {"value": "CIMSA", "label": "CIMSA"},
 {"value": "CUSAN", "label": "CUSAN"},
 {"value": "DAGI", "label": "DAGI"},
 {"value": "DAGHL", "label": "DAGHL"},
 {"value": "DAPGM", "label": "DAPGM"},
 {"value": "DARDL", "label": "DARDL"},
 {"value": "DGATE", "label": "DGATE"},
 {"value": "DMSAS", "label": "DMSAS"},
 {"value": "DENGE", "label": "DENGE"},
 {"value": "DZGYO", "label": "DZGYO"},
 {"value": "DERIM", "label": "DERIM"},
 {"value": "DERHL", "label": "DERHL"},
 {"value": "DESA", "label": "DESA"},
 {"value": "DESPC", "label": "DESPC"},
 {"value": "DTYGD", "label": "DTYGD"},
 {"value": "DEVA", "label": "DEVA"},
 {"value": "DNISI", "label": "DNISI"},
 {"value": "DIRIT", "label": "DIRIT"},
 {"value": "DITAS", "label": "DITAS"},
 {"value": "DOCO", "label": "DOCO"},
 {"value": "DOBUR", "label": "DOBUR"},
 {"value": "DDTCR", "label": "DDTCR"},
 {"value": "DOHOL", "label": "DOHOL"},
 {"value": "DTRND", "label": "DTRND"},
 {"value": "DGNMO", "label": "DGNMO"},
 {"value": "ARASE", "label": "ARASE"},
 {"value": "DOGUB", "label": "DOGUB"},
 {"value": "DGGYO", "label": "DGGYO"},
 {"value": "DOAS", "label": "DOAS"},
 {"value": "DOKTA", "label": "DOKTA"},
 {"value": "DURDO", "label": "DURDO"},
 {"value": "DYOBY", "label": "DYOBY"},
 {"value": "EDATA", "label": "EDATA"},
 {"value": "ECZYT", "label": "ECZYT"},
 {"value": "EDIP", "label": "EDIP"},
 {"value": "EGEEN", "label": "EGEEN"},
 {"value": "EGGUB", "label": "EGGUB"},
 {"value": "EGPRO", "label": "EGPRO"},
 {"value": "EGSER", "label": "EGSER"},
 {"value": "EPLAS", "label": "EPLAS"},
 {"value": "ECILC", "label": "ECILC"},
 {"value": "EKIZ", "label": "EKIZ"},
 {"value": "EKSUN", "label": "EKSUN"},
 {"value": "ELITE", "label": "ELITE"},
 {"value": "EMKEL", "label": "EMKEL"},
 {"value": "EMNIS", "label": "EMNIS"},
 {"value": "EKGYO", "label": "EKGYO"},
 {"value": "EMVAR", "label": "EMVAR"},
 {"value": "ENJSA", "label": "ENJSA"},
 {"value": "ENKAI", "label": "ENKAI"},
 {"value": "ENSRI", "label": "ENSRI"},
 {"value": "ERBOS", "label": "ERBOS"},
 {"value": "ERCB", "label": "ERCB"},
 {"value": "EREGL", "label": "EREGL"},
 {"value": "ERGLI", "label": "ERGLI"},
 {"value": "KIMMR", "label": "KIMMR"},
 {"value": "ERSU", "label": "ERSU"},
 {"value": "ESCAR", "label": "ESCAR"},
 {"value": "ESCOM", "label": "ESCOM"},
 {"value": "ESEN", "label": "ESEN"},
 {"value": "ETILR", "label": "ETILR"},
 {"value": "EUKYO", "label": "EUKYO"},
 {"value": "EUYO", "label": "EUYO"},
 {"value": "ETYAT", "label": "ETYAT"},
 {"value": "EUHOL", "label": "EUHOL"},
 {"value": "TEZOL", "label": "TEZOL"},
 {"value": "EUREN", "label": "EUREN"},
 {"value": "EYGYO", "label": "EYGYO"},
 {"value": "FADE", "label": "FADE"},
 {"value": "FSDAT", "label": "FSDAT"},
 {"value": "FMIZP", "label": "FMIZP"},
 {"value": "FENER", "label": "FENER"},
 {"value": "FLAP", "label": "FLAP"},
 {"value": "FONET", "label": "FONET"},
 {"value": "FROTO", "label": "FROTO"},
 {"value": "FORMT", "label": "FORMT"},
 {"value": "FRIGO", "label": "FRIGO"},
 {"value": "GWIND", "label": "GWIND"},
 {"value": "GSRAY", "label": "GSRAY"},
 {"value": "GAPIN", "label": "GAPIN"},
 {"value": "GARFL", "label": "GARFL"},
 {"value": "GRNYO", "label": "GRNYO"},
 {"value": "GEDIK", "label": "GEDIK"},
 {"value": "GEDZA", "label": "GEDZA"},
 {"value": "GENIL", "label": "GENIL"},
 {"value": "GENTS", "label": "GENTS"},
 {"value": "GEREL", "label": "GEREL"},
 {"value": "GZNMI", "label": "GZNMI"},
 {"value": "GMTAS", "label": "GMTAS"},
 {"value": "GESAN", "label": "GESAN"},
 {"value": "GLYHO", "label": "GLYHO"},
 {"value": "GGBVK", "label": "GGBVK"},
 {"value": "GOODY", "label": "GOODY"},
 {"value": "GOKNR", "label": "GOKNR"},
 {"value": "GOLTS", "label": "GOLTS"},
 {"value": "GOZDE", "label": "GOZDE"},
 {"value": "GSDDE", "label": "GSDDE"},
 {"value": "GSDHO", "label": "GSDHO"},
 {"value": "GUBRF", "label": "GUBRF"},
 {"value": "GLRYH", "label": "GLRYH"},
 {"value": "GRSEL", "label": "GRSEL"},
 {"value": "SAHOL", "label": "SAHOL"},
 {"value": "HLGYO", "label": "HLGYO"},
 {"value": "HATEK", "label": "HATEK"},
 {"value": "HDFFL", "label": "HDFFL"},
 {"value": "HDFGS", "label": "HDFGS"},
 {"value": "HEDEF", "label": "HEDEF"},
 {"value": "HEKTS", "label": "HEKTS"},
 {"value": "HKTM", "label": "HKTM"},
 {"value": "HTTBT", "label": "HTTBT"},
 {"value": "HUBVC", "label": "HUBVC"},
 {"value": "HUNER", "label": "HUNER"},
 {"value": "HURGZ", "label": "HURGZ"},
 {"value": "INVEO", "label": "INVEO"},
 {"value": "INVES", "label": "INVES"},
 {"value": "ISKPL", "label": "ISKPL"},
 {"value": "IEYHO", "label": "IEYHO"},
 {"value": "IDEAS", "label": "IDEAS"},
 {"value": "IDGYO", "label": "IDGYO"},
 {"value": "IHEVA", "label": "IHEVA"},
 {"value": "IHLGM", "label": "IHLGM"},
 {"value": "IHGZT", "label": "IHGZT"},
 {"value": "IHAAS", "label": "IHAAS"},
 {"value": "IHLAS", "label": "IHLAS"},
 {"value": "IHYAY", "label": "IHYAY"},
 {"value": "IMASM", "label": "IMASM"},
 {"value": "INALR", "label": "INALR"},
 {"value": "INDES", "label": "INDES"},
 {"value": "INTEM", "label": "INTEM"},
 {"value": "IPEKE", "label": "IPEKE"},
 {"value": "ISDMR", "label": "ISDMR"},
 {"value": "ISGYO", "label": "ISGYO"},
 {"value": "ISGSY", "label": "ISGSY"},
 {"value": "ISYAT", "label": "ISYAT"},
 {"value": "ISBIR", "label": "ISBIR"},
 {"value": "ISSEN", "label": "ISSEN"},
 {"value": "ITTFH", "label": "ITTFH"},
 {"value": "IZINV", "label": "IZINV"},
 {"value": "IZMDC", "label": "IZMDC"},
 {"value": "IZFAS", "label": "IZFAS"},
 {"value": "JANTS", "label": "JANTS"},
 {"value": "KFEIN", "label": "KFEIN"},
 {"value": "KLKIM", "label": "KLKIM"},
 {"value": "KLVKS", "label": "KLVKS"},
 {"value": "KAPLM", "label": "KAPLM"},
 {"value": "KAREL", "label": "KAREL"},
 {"value": "KARSN", "label": "KARSN"},
 {"value": "KRTEK", "label": "KRTEK"},
 {"value": "KARYE", "label": "KARYE"},
 {"value": "KARTN", "label": "KARTN"},
 {"value": "KATVK", "label": "KATVK"},
 {"value": "KATMR", "label": "KATMR"},
 {"value": "KENT", "label": "KENT"},
 {"value": "KERVT", "label": "KERVT"},
 {"value": "KRVGD", "label": "KRVGD"},
 {"value": "KERVN", "label": "KERVN"},
 {"value": "KZBGY", "label": "KZBGY"},
 {"value": "KLGYO", "label": "KLGYO"},
 {"value": "KLRHO", "label": "KLRHO"},
 {"value": "KMPUR", "label": "KMPUR"},
 {"value": "KLMSN", "label": "KLMSN"},
 {"value": "KCAER", "label": "KCAER"},
 {"value": "KCHOL", "label": "KCHOL"},
 {"value": "KLSYN", "label": "KLSYN"},
 {"value": "KNFRT", "label": "KNFRT"},
 {"value": "KONTR", "label": "KONTR"},
 {"value": "KONYA", "label": "KONYA"},
 {"value": "KONKA", "label": "KONKA"},
 {"value": "KGYO", "label": "KGYO"},
 {"value": "KORDS", "label": "KORDS"},
 {"value": "KRPLS", "label": "KRPLS"},
 {"value": "KORTS", "label": "KORTS"},
 {"value": "KOZAL", "label": "KOZAL"},
 {"value": "KOZAA", "label": "KOZAA"},
 {"value": "KRGYO", "label": "KRGYO"},
 {"value": "KRSTL", "label": "KRSTL"},
 {"value": "KRONT", "label": "KRONT"},
 {"value": "KSTUR", "label": "KSTUR"},
 {"value": "KUVVA", "label": "KUVVA"},
 {"value": "KUYAS", "label": "KUYAS"},
 {"value": "KUTPO", "label": "KUTPO"},
 {"value": "KTSKR", "label": "KTSKR"},
 {"value": "LIDER", "label": "LIDER"},
 {"value": "LINK", "label": "LINK"},
 {"value": "LOGO", "label": "LOGO"},
 {"value": "LKMNH", "label": "LKMNH"},
 {"value": "LUKSK", "label": "LUKSK"},
 {"value": "MACKO", "label": "MACKO"},
 {"value": "MAKIM", "label": "MAKIM"},
 {"value": "MAKTK", "label": "MAKTK"},
 {"value": "MANAS", "label": "MANAS"},
 {"value": "MAGEN", "label": "MAGEN"},
 {"value": "MARKA", "label": "MARKA"},
 {"value": "MAALT", "label": "MAALT"},
 {"value": "MRSHL", "label": "MRSHL"},
 {"value": "MRGYO", "label": "MRGYO"},
 {"value": "MARTI", "label": "MARTI"},
 {"value": "MTRKS", "label": "MTRKS"},
 {"value": "MAVI", "label": "MAVI"},
 {"value": "MZHLD", "label": "MZHLD"},
 {"value": "MEDTR", "label": "MEDTR"},
 {"value": "MEGAP", "label": "MEGAP"},
 {"value": "MNDRS", "label": "MNDRS"},
 {"value": "MEPET", "label": "MEPET"},
 {"value": "MERCN", "label": "MERCN"},
 {"value": "MERIT", "label": "MERIT"},
 {"value": "MERKO", "label": "MERKO"},
 {"value": "METUR", "label": "METUR"},
 {"value": "METRO", "label": "METRO"},
 {"value": "MTRYO", "label": "MTRYO"},
 {"value": "MIATK", "label": "MIATK"},
 {"value": "MGROS", "label": "MGROS"},
 {"value": "MIPAZ", "label": "MIPAZ"},
 {"value": "MSGYO", "label": "MSGYO"},
 {"value": "MPARK", "label": "MPARK"},
 {"value": "MMCAS", "label": "MMCAS"},
 {"value": "MOBTL", "label": "MOBTL"},
 {"value": "MNDTR", "label": "MNDTR"},
 {"value": "EGEPO", "label": "EGEPO"},
 {"value": "NATEN", "label": "NATEN"},
 {"value": "NTGAZ", "label": "NTGAZ"},
 {"value": "NTHOL", "label": "NTHOL"},
 {"value": "NETAS", "label": "NETAS"},
 {"value": "NIBAS", "label": "NIBAS"},
 {"value": "NUHCM", "label": "NUHCM"},
 {"value": "NUGYO", "label": "NUGYO"},
 {"value": "NRHOL", "label": "NRHOL"},
 {"value": "NRLIN", "label": "NRLIN"},
 {"value": "NURVK", "label": "NURVK"},
 {"value": "OBASE", "label": "OBASE"},
 {"value": "ODAS", "label": "ODAS"},
 {"value": "ONCSM", "label": "ONCSM"},
 {"value": "OPET", "label": "OPET"},
 {"value": "ORCAY", "label": "ORCAY"},
 {"value": "ORGE", "label": "ORGE"},
 {"value": "ORMA", "label": "ORMA"},
 {"value": "OSTIM", "label": "OSTIM"},
 {"value": "OTKAR", "label": "OTKAR"},
 {"value": "OTOKC", "label": "OTOKC"},
 {"value": "OTTO", "label": "OTTO"},
 {"value": "OYAKC", "label": "OYAKC"},
 {"value": "OYAYO", "label": "OYAYO"},
 {"value": "OYLUM", "label": "OYLUM"},
 {"value": "OZKGY", "label": "OZKGY"},
 {"value": "OZGYO", "label": "OZGYO"},
 {"value": "OZRDN", "label": "OZRDN"},
 {"value": "OZSUB", "label": "OZSUB"},
 {"value": "PALEN", "label": "PALEN"},
 {"value": "PAMEL", "label": "PAMEL"},
 {"value": "PNLSN", "label": "PNLSN"},
 {"value": "PAGYO", "label": "PAGYO"},
 {"value": "PAPIL", "label": "PAPIL"},
 {"value": "PRDGS", "label": "PRDGS"},
 {"value": "PRKME", "label": "PRKME"},
 {"value": "PARSN", "label": "PARSN"},
 {"value": "PSGYO", "label": "PSGYO"},
 {"value": "PCILT", "label": "PCILT"},
 {"value": "PGSUS", "label": "PGSUS"},
 {"value": "PEKGY", "label": "PEKGY"},
 {"value": "PENGD", "label": "PENGD"},
 {"value": "PENTA", "label": "PENTA"},
 {"value": "PEGYO", "label": "PEGYO"},
 {"value": "PSDTC", "label": "PSDTC"},
 {"value": "PETKM", "label": "PETKM"},
 {"value": "PKENT", "label": "PKENT"},
 {"value": "PETUN", "label": "PETUN"},
 {"value": "PINSU", "label": "PINSU"},
 {"value": "PNSUT", "label": "PNSUT"},
 {"value": "PKART", "label": "PKART"},
 {"value": "PLTUR", "label": "PLTUR"},
 {"value": "POLHO", "label": "POLHO"},
 {"value": "POLTK", "label": "POLTK"},
 {"value": "PRZMA", "label": "PRZMA"},
 {"value": "QYHOL", "label": "QYHOL"},
 {"value": "QUAGR", "label": "QUAGR"},
 {"value": "RNPOL", "label": "RNPOL"},
 {"value": "RALYH", "label": "RALYH"},
 {"value": "RYGYO", "label": "RYGYO"},
 {"value": "RYSAS", "label": "RYSAS"},
 {"value": "RHEAG", "label": "RHEAG"},
 {"value": "RODRG", "label": "RODRG"},
 {"value": "ROYAL", "label": "ROYAL"},
 {"value": "RTALB", "label": "RTALB"},
 {"value": "RUBNS", "label": "RUBNS"},
 {"value": "SAFKR", "label": "SAFKR"},
 {"value": "SANEL", "label": "SANEL"},
 {"value": "SNICA", "label": "SNICA"},
 {"value": "SANFM", "label": "SANFM"},
 {"value": "SANKO", "label": "SANKO"},
 {"value": "SAMAT", "label": "SAMAT"},
 {"value": "SARKY", "label": "SARKY"},
 {"value": "SARTN", "label": "SARTN"},
 {"value": "SASA", "label": "SASA"},
 {"value": "SAYAS", "label": "SAYAS"},
 {"value": "SDTTR", "label": "SDTTR"},
 {"value": "SEKUR", "label": "SEKUR"},
 {"value": "SELEC", "label": "SELEC"},
 {"value": "SELGD", "label": "SELGD"},
 {"value": "SELVA", "label": "SELVA"},
 {"value": "SNKRN", "label": "SNKRN"},
 {"value": "SRVGY", "label": "SRVGY"},
 {"value": "SEYKM", "label": "SEYKM"},
 {"value": "SILVR", "label": "SILVR"},
 {"value": "SNGYO", "label": "SNGYO"},
 {"value": "SMRTG", "label": "SMRTG"},
 {"value": "SMART", "label": "SMART"},
 {"value": "SODSN", "label": "SODSN"},
 {"value": "SOKE", "label": "SOKE"},
 {"value": "SKTAS", "label": "SKTAS"},
 {"value": "SONME", "label": "SONME"},
 {"value": "SNPAM", "label": "SNPAM"},
 {"value": "SUMAS", "label": "SUMAS"},
 {"value": "SUNTK", "label": "SUNTK"},
 {"value": "SUWEN", "label": "SUWEN"},
 {"value": "SZUKI", "label": "SZUKI"},
 {"value": "SEGYO", "label": "SEGYO"},
 {"value": "SOKM", "label": "SOKM"},
 {"value": "DRPHN", "label": "DRPHN"},
 {"value": "TOKI", "label": "TOKI"},
 {"value": "TNZTP", "label": "TNZTP"},
 {"value": "TATGD", "label": "TATGD"},
 {"value": "TAVHL", "label": "TAVHL"},
 {"value": "TEKTU", "label": "TEKTU"},
 {"value": "TKFEN", "label": "TKFEN"},
 {"value": "TKNSA", "label": "TKNSA"},
 {"value": "TMPOL", "label": "TMPOL"},
 {"value": "TETMT", "label": "TETMT"},
 {"value": "TGSAS", "label": "TGSAS"},
 {"value": "TOASO", "label": "TOASO"},
 {"value": "TRGYO", "label": "TRGYO"},
 {"value": "TLMAN", "label": "TLMAN"},
 {"value": "TSPOR", "label": "TSPOR"},
 {"value": "TDGYO", "label": "TDGYO"},
 {"value": "TSGYO", "label": "TSGYO"},
 {"value": "TUCLK", "label": "TUCLK"},
 {"value": "TUKAS", "label": "TUKAS"},
 {"value": "TRCAS", "label": "TRCAS"},
 {"value": "TUREX", "label": "TUREX"},
 {"value": "TRILC", "label": "TRILC"},
 {"value": "TCELL", "label": "TCELL"},
 {"value": "TMSN", "label": "TMSN"},
 {"value": "TUPRS", "label": "TUPRS"},
 {"value": "THYAO", "label": "THYAO"},
 {"value": "PRKAB", "label": "PRKAB"},
 {"value": "TTKOM", "label": "TTKOM"},
 {"value": "TTRAK", "label": "TTRAK"},
 {"value": "TBORG", "label": "TBORG"},
 {"value": "TURGG", "label": "TURGG"},
 {"value": "SISE", "label": "SISE"},
 {"value": "UFUK", "label": "UFUK"},
 {"value": "ULAS", "label": "ULAS"},
 {"value": "ULUSE", "label": "ULUSE"},
 {"value": "ULUUN", "label": "ULUUN"},
 {"value": "UMPAS", "label": "UMPAS"},
 {"value": "USAK", "label": "USAK"},
 {"value": "UZERB", "label": "UZERB"},
 {"value": "ULKER", "label": "ULKER"},
 {"value": "UNLU", "label": "UNLU"},
 {"value": "VKGYO", "label": "VKGYO"},
 {"value": "VKFYO", "label": "VKFYO"},
 {"value": "VAKKO", "label": "VAKKO"},
 {"value": "VANGD", "label": "VANGD"},
 {"value": "VBTYZ", "label": "VBTYZ"},
 {"value": "VDFLO", "label": "VDFLO"},
 {"value": "VERUS", "label": "VERUS"},
 {"value": "VERTU", "label": "VERTU"},
 {"value": "VESBE", "label": "VESBE"},
 {"value": "VESTL", "label": "VESTL"},
 {"value": "VKING", "label": "VKING"},
 {"value": "YAPRK", "label": "YAPRK"},
 {"value": "YATAS", "label": "YATAS"},
 {"value": "YYLGD", "label": "YYLGD"},
 {"value": "YAYLA", "label": "YAYLA"},
 {"value": "YGGYO", "label": "YGGYO"},
 {"value": "YEOTK", "label": "YEOTK"},
 {"value": "YGYO", "label": "YGYO"},
 {"value": "YYAPI", "label": "YYAPI"},
 {"value": "YESIL", "label": "YESIL"},
 {"value": "YBTAS", "label": "YBTAS"},
 {"value": "YONGA", "label": "YONGA"},
 {"value": "YKSLN", "label": "YKSLN"},
 {"value": "YUNSA", "label": "YUNSA"},
 {"value": "ZEDUR", "label": "ZEDUR"},
 {"value": "ZRGYO", "label": "ZRGYO"},
 {"value": "ZOREN", "label": "ZOREN"}
];