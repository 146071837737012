/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import get_credit_card_data from "layouts/api";


function getField(array, field) {
var result;
var values = Object.keys(array).map(function(key){
        if (key == field) {
          result=array[key];
	    }
});
return result
}

function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}


function create_dataset(data){
var colors= ["#280659", "#810160", "#C13358", "#EC714D", "#FEB44D","#F9F871",
             "#003A89", "#0064AA", "#008DBB", "#00B5C3","#5BDAC6",
             "#1D7874", "#007C92", "#2B7BAA", "#6976B3", "#9D6CA8","#C1648C",
             "#334B49", "#95B1AE", "#945B72", "#CB8EA6","#334B49","#95B1AE", "#596B98", "#8E9ECE",
             "#EC714D", "#FEB44D","#FFA17A", "#003A89", "#0064AA", "#008DBB", "#00B5C3","#5BDAC6"]

 var bgcolors= ["rgba(40,6,89,0.5)","rgba(129,1,96,0.5)","rgba(193,51,88,0.5)","rgba(236,113,77,0.5)",
               "rgba(254,180,77,0.5)","rgba(249,248,113,0.5)","rgba(0,58,137,0.5)","rgba(0,100,170,0.5)",
               "rgba(0,141,187,0.5)","rgba(0,181,195,0.5)","rgba(91,218,198,0.5)","rgba(29,120,116,0.5)",
               "rgba(0,124,146,0.5)","rgba(43,123,170,0.5)","rgba(105,118,179,0.5)","rgba(157,108,168,0.5)",
               "rgba(193,100,140,0.5)","rgba(51,75,73,0.5)","rgba(149,177,174,0.5)","rgba(148,91,114,0.5)",
               "rgba(203,142,166,0.5)","rgba(51,75,73,0.5)","rgba(149,177,174,0.5)","rgba(89,107,152,0.5)",
               "rgba(142,158,206,0.5)","rgba(236,113,77,0.5)","rgba(254,180,77,0.5)","rgba(255,161,122,0.5)",
               "rgba(0,58,137,0.5)","rgba(0,100,170,0.5)","rgba(0,141,187,0.5)","rgba(0,181,195,0.5)",
               "rgba(91,218,198,0.5)"
]

let datasets=[];
let cnt=0;
for (let [key, value] of Object.entries(data)) {
      let color=colors[cnt];
      let bgcolor=bgcolors[cnt];
      if (key != "date") {
       if (key == "Toplam") {
               datasets.push({
               label: key,
               data: value,
               backgroundColor: bgcolor,
               borderColor: color,
               type: "bar",
               fill: true,
               lineTension: 0.6,
               pointRadius: 0,
               borderWidth:0.8,
               borderRadius:0,
               hidden: false,
               stack: "1",
               });
       } else {
               datasets.push({
               label: key,
               data: value,
               backgroundColor: bgcolor,
               borderColor: color,
               type: "bar",
               fill: true,
               lineTension: 0.6,
               pointRadius: 0,
               borderWidth:0.8,
               borderRadius:0,
               hidden: true,
               stack: "2",
               });
       }
      }
      cnt=cnt+1
    }

 return datasets
}


function creditcardData(params) {
   const defaultData= { "Altın": [{'title': 'Altın', 'values': [ 100,10,5,145,200,40,210]}],
                        "Brüt Rezerve": [{'title': 'Brüt Rezerv', 'values': [ 100,10,5,145,200,40,210]}],
                         "Net Rezerv": [{'title': 'Net Rezerv', 'values': [ 100,10,5,145,200,40,210]}],
                        labels: [ "","","","","","",""],
    }
    const [data, setData] = useState(defaultData);
    //const dataFetchedRef = useRef(false);
    const fetchData = async () => {
        const config={"func":"credit_card_data","params":params}
        const response = await get_credit_card_data(config);
        var tutar=response.data.tutar
        const tmpdata = await transpose(tutar);
        const date = tmpdata.date;
        const datasets= await create_dataset(tmpdata);

        var txn=response.data.txn
        const tmpdata1 = await transpose(txn);
        const datasets1= await create_dataset(tmpdata1);
        //const date = transposedata.date;
	    setData({datasets:datasets,datasets1:datasets1,labels:date});
    }

    useEffect(() => {
            fetchData();
    },[]);
  return data
}

export default creditcardData;
