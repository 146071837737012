import { Row } from "@silevis/reactgrid";

import {
  Revenue_TIPCG,
  Revenue_TONP,
  Revenue_TONGS,
  Revenue_TONITT,
  Revenue_STD,
  Revenue_Fee,
  Revenue_TONNC,
  Revenue_PI,
  Revenue_GASR,
  Revenue_ISF,
  Revenue_CR,
  Revenue_RC,
  Revenue_RfSBI,
  Revenue_RfRSI,
  Expense_InterestPayment,
  Exc_Expense_InterestPayment,
  Finance_Budget_Escrow,
  Finance_Retain,
  Finance_Debt,
  Finance_Loan,
  Finance_Privatization,
  Finance_Tmsf,
  Finance_Cash,
  Labels,
  InputVariables,
  MonthlyValues,
  OutputVariables
} from "layouts/government/budget/components/BalanceSheet/components/interfaces";
import { months } from "layouts/government/budget/components/BalanceSheet/components/helpers";

import {
  emptyTextCell,
  nonEditable,
  textCell,
  monthHeaderCell,
  bottomLine,
  numberCell,
  showZero,
  noSideBorders
} from "layouts/government/budget/components/BalanceSheet/components/cells";

export const HEADER_ROW_ID = "header";
export const MONTHSTOTAL_ROW_ID = "monthsTotal";
export const MONTHSTOTAL_ROW_ID2= "monthsTotal2";
export const MONTHSTOTAL_ROW_ID3= "monthsTotal3";


const ROW_HEIGHT = 21;
const HEADING_ROW_HEIGHT = 30;

function sumGroupValues(values: MonthlyValues): number {
  return values.reduce(
    (prev, curr) => (isNaN(prev) ? 0 : prev) + (isNaN(curr) ? 0 : curr)
  );
}

function getHeaderRow(
  groups: (Labels)[],
): Row [] {
  const labels_data=groups.slice(-12)
  const tmp_labels = labels_data.map((_, idx) => nonEditable(monthHeaderCell(labels_data[idx], "justify-content-center")));
  const labels = [nonEditable(emptyTextCell)].concat(tmp_labels)
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells:labels,
  };
}


function getBlankRow(title:"Header"): Row {
  return {
    rowId: `${title}`,
    height: ROW_HEIGHT,
    cells: [
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      //nonEditable(monthHeaderCell("Totals", "justify-content-end")),
    ],
  };
}
function getSingleRow(
  title: "" ,
  groups: [],
  //yearlyGroupTotal: number
): Row[] {
  return [
    ...groups.map(({ title, values }) => ({
      rowId: `${title}Header`,
      height: HEADING_ROW_HEIGHT,
      cells: [
        nonEditable(textCell(title, "align-items-end text-md font-bold text-green")),
        ...values.map((_, idx) => nonEditable(numberCell(values[idx],
                `font-bold disabled text-darkblue`))),
      ],
    })),
  ];
}


function getGroupRows(
  title: "1. Gelir ve Kazanç Üz. Alınan Verg." | "2. Mülkiyet Üzerinden Alınan Verg." | "3. Dahilde Al. Mal ve Hiz. Verg." | "4. Ulus. Tic. ve Muam. Alınan Verg.",
  summaryTitle: string,
  groups: (Revenue_TIPCG | Revenue_TONP | Revenue_TONGS| Revenue_TONITT)[],
  monthlyGroupTotals: MonthlyValues,
): Row[] {
  return [
    {
      rowId: `${title}Header`,
      height: HEADING_ROW_HEIGHT,
      cells: [
          nonEditable(
            textCell(
              title,
              `align-items-end text-md disabled font-bold text-${
                  title === "1. Gelir ve Kazanç Üz. Alınan Verg."  ? "green" : title === "2. Mülkiyet Üzerinden Alınan Verg." ? "green": title === "3. Dahilde Al. Mal ve Hiz. Verg." ? "green" : title === "4. Ulus. Tic. ve Muam. Alınan Verg." ? "green": "darkblue"
              }`
            )
          ),
        ...months().map((_, idx) =>
          nonEditable(
            showZero(
              numberCell(
                monthlyGroupTotals[idx],
                `font-bold text-${
                  title === "1. Gelir ve Kazanç Üz. Alınan Verg."  ? "green" : title === "2. Mülkiyet Üzerinden Alınan Verg." ? "green" : title === "3. Dahilde Al. Mal ve Hiz. Verg." ? "green" : title === "4. Ulus. Tic. ve Muam. Alınan Verg." ? "green": "darkblue"
                }`
              )
            )
          )
        ),
      ]
    },
    ...groups.map(({ title, values }) => ({
      rowId: title,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell(title, "padding-left-lg")),
        ...values.map((_, idx) => nonEditable(showZero(numberCell(values[idx])))),
      ]
    })),
  ];
}
function getTotalsRow(
  title: string,
  monthlyTotals: MonthlyValues,
): Row {
  const monthsTotalCell = (value: number) =>
      nonEditable(showZero(numberCell(value, "text-md-darkblue font-bold")))
  return {
    rowId: `${title}Header`,
    height: HEADING_ROW_HEIGHT,
    cells: [
     nonEditable(textCell(title, "text-lg-darkblue disabled font-bold")),
      ...months().map((_, idx) =>
        nonEditable(monthsTotalCell(monthlyTotals[idx]))
      ),
    ],
  };
}

function getTotalsHeadRow(
  title: string,
  monthlyTotals: MonthlyValues,
): Row {
  const monthsTotalCell = (value: number) =>
      bottomLine(nonEditable(showZero(numberCell(value, "text-lg-red disabled font-bold"))));
  return {
    rowId: `${title}Header`,
    height: HEADING_ROW_HEIGHT,
    cells: [
      bottomLine(nonEditable(textCell(title, "text-lg-red disabled font-bold"))),
      ...months().map((_, idx) =>
        bottomLine(nonEditable(monthsTotalCell(monthlyTotals[idx])))
      ),
    ],
  };
}

function getSingleTotalRow(
  title: "" ,
  groups: [],
  //yearlyGroupTotal: number
): Row[] {
  return [
    ...groups.map(({ title, values }) => ({
      rowId: `${title}Header`,
      height: HEADING_ROW_HEIGHT,
      cells: [
        nonEditable(textCell(title, "text-md-darkblue disabled font-bold")),
        ...values.map((_, idx) => nonEditable(numberCell(values[idx],
                `font-bold text-lg-darkblue`))),
      ],
    })),
  ];
}

export function getRowsDetail({
  revenue_tipcg,
  monthlyRevenueTIPCGTotals,
  revenue_tonp,
  monthlyRevenueTONPTotals,
  monthlyDirectTaxTotals,
  revenue_tongs,
  monthlyRevenueTONGSTotals,
  revenue_tonitt,
  monthlyRevenueTONITTTotals,
  monthlyInDirectTaxTotals,
  revenue_std,
  revenue_fee,
  revenue_tonnc,
  revenue_pi,
  revenue_gasr,
  revenue_isf,
  revenue_cr,
  revenue_rc,
  monthlyRevenueRFSBITotals,
  monthlyRevenueRFRSITotals,
  revenue_rfrsi,
  monthlyTaxRevenueTotals,
  monthlyRevenueTotals,
  monthlyCentralGovRevenueTotals,
  expense_interestpayment,
  monthlyExpenseIPTotals,
  exc_expense_interestpayment,
  monthlyExcExpenseIPTotals,
  monthlyCentralGovExpensesTotals,
  monthlyCentralGovBudgetBalanceTotals,
  monthlyCentralGovBudgetExcIPTotals,
  monthlyFinanceBudgetEscTotals,
  finance_budget_escrow,
  finance_retain,
  finance_debt,
  finance_loan,
  monthlyFinanceDebtTotals,
  finance_privatization,
  finance_tmsf,
  finance_cash,
  monthlyCentralGovCashBalanceTotals,
  monthlyBudgetFinanceTotals,
  labels,
}: InputVariables & OutputVariables): Row[] {
  return [
   getHeaderRow(labels),
   getTotalsHeadRow(
      "MERKEZİ YÖNETİM GELİRLERİ",
      monthlyCentralGovRevenueTotals,
    ),
   getTotalsHeadRow(
      "Genel Bütçe Gelirleri (I+..+VI )",
      monthlyRevenueTotals,
    ),
   getTotalsRow(
      "I. Vergi Gelirleri (A + B)",
      monthlyTaxRevenueTotals,
    ),
     getTotalsRow(
      "A) Dolaysız Vergiler (1+2)",
      monthlyDirectTaxTotals,
    ),
    ...getGroupRows(
      "1. Gelir ve Kazanç Üz. Alınan Verg.",
      "Toplam",
      revenue_tipcg,
      monthlyRevenueTIPCGTotals
    ),
    ...getGroupRows(
      "2. Mülkiyet Üzerinden Alınan Verg.",
      "Toplam",
      revenue_tonp,
      monthlyRevenueTONPTotals
    ),
   getTotalsRow(
      "B) Dolaylı Vergiler (3+4+5+6+7)",
      monthlyInDirectTaxTotals,
    ),
        ...getGroupRows(
      "3. Dahilde Al. Mal ve Hiz. Verg.",
      "Toplam",
      revenue_tongs,
      monthlyRevenueTONGSTotals
    ),
    ...getGroupRows(
      "4. Ulus. Tic. ve Muam. Alınan Verg.",
      "Toplam",
      revenue_tonitt,
      monthlyRevenueTONITTTotals
    ),
   ...getSingleRow(
       "5. Damga Vergisi",
       revenue_std,
     ),
   ...getSingleRow(
       "6. Harçlar",
       revenue_fee,
     ),
     ...getSingleRow(
       "7. Başka Yerde Sınıflandırılmayan Diğer Vergiler",
       revenue_tonnc,
     ),
     ...getSingleTotalRow(
       "II-Teşebbüs ve Mülkiyet Gelirleri",
       revenue_pi,
     ),
    ...getSingleTotalRow(
       "III-Alınan Bağışlar ve Yardımlar ile Özel Gelirler",
       revenue_gasr,
     ),
    ...getSingleTotalRow(
       "IV-Faizler, Paylar ve Cezalar",
       revenue_isf,
     ),
    ...getSingleTotalRow(
       "V-Sermaye Gelirleri",
       revenue_cr,
     ),
    ...getSingleTotalRow(
     "VI-Alacaklardan Tahsilat",
       revenue_rc,
     ),
    getTotalsHeadRow(
      "Özel Bütçeli İdarelerin Gelirleri",
      monthlyRevenueRFSBITotals,
    ),
    getTotalsHeadRow(
      "Düz. ve Denet. Kurum. Gelirleri",
      monthlyRevenueRFRSITotals,
    ),
   getBlankRow("Header1"),
   getTotalsHeadRow(
      "MERKEZİ YÖNETİM GİDERLERİ",
      monthlyCentralGovExpensesTotals,
    ),
    ...getGroupRows(
      "Faiz Dışı Giderler",
      "Toplam",
      exc_expense_interestpayment,
      monthlyExcExpenseIPTotals
    ),
    ...getGroupRows(
      "Faiz Giderleri",
      "Toplam",
      expense_interestpayment,
      monthlyExpenseIPTotals
    ),
   getBlankRow("Header2"),
   getTotalsHeadRow(
      "FAİZ DIŞI DENGE",
      monthlyCentralGovBudgetExcIPTotals,
    ),
   getTotalsHeadRow(
      "BÜTÇE DENGESİ",
      monthlyCentralGovBudgetBalanceTotals,
    ),
   getBlankRow("Header3"),
    ...getSingleTotalRow(
       "Bütçe Emanetleri",
       finance_budget_escrow,
     ),
    ...getSingleTotalRow(
       "Avanslar",
       finance_retain,
     ),
    getTotalsHeadRow(
      "NAKİT DENGESİ",
      monthlyCentralGovCashBalanceTotals,
    ),
    getBlankRow("Header4"),

   getTotalsHeadRow(
      "BÜTÇE FİNANSMANI",
      monthlyBudgetFinanceTotals,
    ),
     ...getGroupRows(
      "Borçlanma(Net)",
      "Toplam",
      finance_debt,
      monthlyFinanceDebtTotals
    ),
    ...getSingleTotalRow(
       "Borç Verme(Net)",
       finance_loan,
     ),
    ...getSingleTotalRow(
       "Özelleştirme Geliri",
       finance_privatization,
     ),
     ...getSingleTotalRow(
       "TMSF Gelir Fazlaları",
       finance_tmsf,
     ),
     ...getSingleTotalRow(
       "Kasa/Banka ve Diğer İşlemler",
       finance_cash,
     ),
  ];
}
