/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
let delayed;
function configs(labels, datasets,chartoptions) {
  return {
    data: {
      labels,
      datasets: [...datasets],
    },
    options: chartoptions.chartoptions
}
}
export default configs;
