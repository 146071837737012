import { useState } from "react";
import {useEffect } from "react";

import Select from "react-select";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";
import SoftEditor from "components/SoftEditor";
import SoftDropzone from "components/SoftDropzone";
import SoftButton from "components/SoftButton";

import breakpoints from "assets/theme/base/breakpoints";

// Templates
import BSTemplate from "layouts/nationalaccount/currentaccount/components/BSTemplate";

import BopFinanceTable from "layouts/nationalaccount/currentaccount/components/BopFinance/components/BopFinanceTable";

//Data
//import rasyolarData from "layouts/realsector/firms/data/rasyolar";

const { values } = breakpoints;



function MainTemplate() {
//const data=rasyolarData(stock.stock)
 return (
  <div>
            <SoftBox mb={0} p={1} pt={0} >
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    Ödemeler Dengesi
                <SoftTypography variant="button" textTransform="capitalize"  fontWeight="bold">
                    (Milyar USD)
                 </SoftTypography>
                 </SoftTypography>
            </SoftBox>

            <SoftBox mb={0}>
                 <BSTemplate/>
            </SoftBox>

            <SoftBox mt={10} width="100%">
              <Divider sx={{height: 8}}/>
             </SoftBox>

            <SoftBox mb={0} width="80%" height="80%">
               <BopFinanceTable/>
             </SoftBox>

 </div>
);
}
export default MainTemplate;
