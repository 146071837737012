import { useState, useEffect } from "react";

import Card from "@mui/material/Card";

// prop-types is a library for type checking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// @mui material components
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard PRO React icons
import { MdOutlineCalendarMonth } from "react-icons/md";
import { BsCalendar2MonthFill } from "react-icons/bs";
import { BsCalendar2DayFill } from "react-icons/bs";

//Graphs

import creditcarddistrdata from "layouts/financialsector/banking/creditcard/data/creditcarddistrdata";
import creditcardchangedata from "layouts/financialsector/banking/creditcard/data/creditcardchangedata";


//import marketdata from "layouts/data/data/marketDataforHighCharts";

import CreditCardGraphChange from "layouts/financialsector/banking/creditcard/components/CreditCardGraphChange";
import CreditCardGraphChange1 from "layouts/financialsector/banking/creditcard/components/CreditCardGraphChange1";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



function Graph(item) {

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);


  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);


const params1="weekly"
const params2="monthly"
const params3="yearly"


  //const hisseislemChartData=creditcardchangedata("Hisse İşlemleri",item.startdate,item.enddate)

  return (
      <>
            <Item>
            <Grid container spacing={2} sx={{ height: 70 }}>
            <Grid item xs={4}>
              <AppBar position="static" >
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ backgroundColor: "transparent" }}
              >
                <Tab label="Haftalık" icon={<BsCalendar2DayFill />} />
                <Tab label="Aylık" icon={<BsCalendar2MonthFill />} />
                <Tab label="Yıllık" icon={<MdOutlineCalendarMonth />} />
              </Tabs>
            </AppBar>
        </Grid>
          </Grid>
        <Grid container spacing={2} sx={{ height: 600 }}>
              <Grid item xs={12} xl={6}>
               <Card sx={{ mt: 0 }} >
                <SoftBox
                     alignItems="center"
                     position="relative"
                     top={0}
                     left={0}
                    width="calc(100% - 0rem)"
                 >
                    <CreditCardGraphChange data={creditcardchangedata(params1)}  value={tabValue} index={0}  />
                    <CreditCardGraphChange data={creditcardchangedata(params2)}  value={tabValue} index={1}  />
                    <CreditCardGraphChange data={creditcardchangedata(params3)}  value={tabValue} index={2}  />
               </SoftBox>
              </Card>
            </Grid>

            <Grid item xs={12} xl={6}>
               <Card sx={{ mt: 0 }} >
                <SoftBox
                     alignItems="center"
                     position="relative"
                     top={0}
                     left={0}
                    width="calc(100% - 0rem)"
                 >
                    <CreditCardGraphChange1 data={creditcarddistrdata(params1)}  value={tabValue} index={0}  />
                    <CreditCardGraphChange1 data={creditcarddistrdata(params2)}  value={tabValue} index={1}  />
                    <CreditCardGraphChange1 data={creditcarddistrdata(params3)}  value={tabValue} index={2}  />

                </SoftBox>
              </Card>
            </Grid>
     </Grid>
      </Item>
    </>
  );
}
export default Graph;
