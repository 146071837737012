/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";

function HeadCell({ children }) {
  return (
    <SoftTypography variant="button" fontSize="13px" color="color4" pl={1}>
      {children}
    </SoftTypography>
  );
}

// Typechecking props for the DefaultCell
HeadCell.propTypes = {
  children: PropTypes.string.isRequired,
};

export default HeadCell;
