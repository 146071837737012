
/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";


// Soft UI Dashboard PRO React example components
import TopicGraph from "layouts/financialsector/tcmb/topics/components/TopicGraph";
import PieChart from "examples/Charts/PieChart";


// Data
import topicdata from "layouts/financialsector/tcmb/topics/data/topicdata";

import { getChartData2, configs } from  "layouts/financialsector/tcmb/topics/components/configs";
import { getChartData, chartOptions } from  "layouts/financialsector/tcmb/topics/components/configs/topics";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

 var bgcolors= ["rgba(40,6,89,1)","rgba(236,113,77,1)", "rgba(249,248,113,1)","rgba(0,58,137,1)",
               "rgba(255,99,1,1)","rgba(0,141,187,1)","rgba(193,51,88,1)","rgba(0,181,195,1)",
                "rgba(29,120,116,1)","rgba(129,1,96,1)","rgba(0,124,146,1)","rgba(193,100,140,1)",
                "rgba(91,218,198,1)","rgba(157,108,168,1)","rgba(43,123,170,1)","rgba(105,118,179,1)",
               "rgba(51,75,73,1)","rgba(149,177,174,1)","rgba(148,91,114,1)","rgba(203,142,166,1)",
               "rgba(51,75,73,1)","rgba(149,177,174,1)","rgba(89,107,152,1)", "rgba(142,158,206,0.5)",
               "rgba(236,113,77,0.5)","rgba(254,180,77,0.5)","rgba(255,161,122,0.5)","rgba(0,58,137,0.5)",
               "rgba(0,100,170,0.5)","rgba(0,141,187,0.5)","rgba(0,100,170,1)"
]


const pieChartData = {
  labels: ['Core Inflation','Current Account','Energy','Labor','Pandemy','Loan','Food Inflation','Monetary Policy',
  'Economic Activity', 'Global Economy','Portfolio Flows','Liraization','Fiscal Policy','Expectation'],
  datasets: [{
    label: "Topic Dağılımı",
    backgroundColors: bgcolors,
    data: [0.2098339783370109,0.0781671008707251, 0.05404367707593809,0.010265316202354876,0.010330243859187915,
    0.08022027608790863,0.03671489564380167, 0.26923803087777154,0.09559723629911371, 0.04621063742543616,0.04456726707331761,
    0.007238791571201987,0.01520404456715245,0.04236850410907941]}],
};


function CurrencyDebtTemplate() {

const data=topicdata("params")
const chardata = getChartData(data.datasets,data.labels);
const chardata2 = getChartData2(pieChartData.datasets,pieChartData.labels);
const configsdata = configs(pieChartData.datasets, pieChartData.labels);

  return (
      <DashboardLayout>
      <DashboardNavbar />
   <Grid item xs={12} lg={6} >
<Item>
      <SoftBox p={1} mt={0} width="100%" height="36.5rem">
        <TopicGraph data={chardata}  chartoptions={chartOptions} />
      </SoftBox>
     </Item>
   </Grid>
               <SoftBox mt={10} width="100%">
              <Divider sx={{height: 8}}/>
             </SoftBox>

     <Grid item xs={12} lg={6} >
              <Item>
      <SoftBox p={1} mt={0} width="100%" height="36.5rem">
              <PieChart title="Son Toplantı Konu Dağılımı"  chart={chardata2}  height="30.5rem" chartoptions={configsdata}/>
                  </SoftBox>
     </Item>
       </Grid>
    </DashboardLayout>

  );
}

export default CurrencyDebtTemplate;
