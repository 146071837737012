/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
//import typography from "assets/theme/base/typography";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

 function toggleAxes(ev){
   var axis=ev.target.yAxis;
   var disabled=true;
   axis.series.each(function(series){
   if (!series.isHiding && !series.isHidden){
   disabled=false;
   }
   });
   axis.disabled=disabled
   }


function configs(chart, datasets,check) {
console.log(datasets)
  am4core.useTheme(am4themes_animated);
  chart.colors.list = [
    am4core.color("#280659"),
    am4core.color("#900c3e"),
    am4core.color("#C70039"),
    am4core.color("#D54800"),
    am4core.color("#FF5733"),
  ];
  chart.paddingRight = 20;
  chart.colors.step = 1;
  chart.fontSize = 12;

var keys = Object.keys(datasets[0]);
if (Object.keys(datasets[0]).length ==2) {
/* Create axes */
var categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.dataFields.category = keys[0];

/* Create value axis */
var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.tooltip.disabled = true;
  valueAxis.renderer.minWidth = 35;

/* Create series */
var columnSeries = chart.series.push(new am4charts.ColumnSeries());
columnSeries.name = keys[1];
columnSeries.dataFields.valueY = keys[1];
columnSeries.dataFields.dateX = keys[0];

columnSeries.columns.template.tooltipText ="Tarih: {dateX}\n {name}: [bold]{valueY}[/]";
columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
columnSeries.columns.template.propertyFields.stroke = "stroke";
columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
columnSeries.tooltip.label.textAlign = "middle";
columnSeries.tooltip.fontSize=12
columnSeries.fillOpacity = 0.2;


var columnTemplate = columnSeries.columns.template;
columnTemplate.strokeWidth = 2;
columnTemplate.strokeOpacity = 1;

var lineSeries = chart.series.push(new am4charts.LineSeries());
lineSeries.name = keys[1];
lineSeries.dataFields.valueY = keys[1];
lineSeries.dataFields.dateX = keys[0];


lineSeries.strokeWidth = 3;
lineSeries.propertyFields.strokeDasharray = "lineDash";
lineSeries.tooltip.label.textAlign = "middle";
lineSeries.tooltip.fontSize=12
lineSeries.tooltipText== "Tarih: {dateX}\n {name}: [bold]{valueY}[/]";

var bullet = lineSeries.bullets.push(new am4charts.Bullet());
bullet.tooltipText = "Tarih: {dateX}\n {name}: [bold]{valueY}[/]";
var circle = bullet.createChild(am4core.Circle);
circle.radius = 0;
circle.fill = am4core.color("#fff");
circle.strokeWidth = 0;

}
else {
if (check==true) {

/* Create axes */
var categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.dataFields.category = keys[0];

/* Create value axis */
var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.tooltip.disabled = true;
  valueAxis.renderer.minWidth = 35;
  valueAxis.renderer.labels.template.fill=am4core.color("#280659");

/* Create value axis */
var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis2.tooltip.disabled = true;
  valueAxis2.renderer.minWidth = 35;
  valueAxis2.renderer.opposite=true
  valueAxis2.renderer.labels.template.fill=am4core.color("#900c3e");


/* Create series */
var columnSeries = chart.series.push(new am4charts.ColumnSeries());
columnSeries.name = keys[1];
columnSeries.dataFields.valueY = keys[1];
columnSeries.dataFields.dateX = keys[0];

columnSeries.columns.template.tooltipText ="Tarih: {dateX}\n {name}: [bold]{valueY}[/]";
columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
columnSeries.columns.template.propertyFields.stroke = "stroke";
columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
columnSeries.tooltip.label.textAlign = "middle";
columnSeries.tooltip.fontSize=12
columnSeries.fillOpacity = 0.2;
columnSeries.yAxis=valueAxis
columnSeries.events.on("hidden",toggleAxes);
columnSeries.events.on("shown",toggleAxes);

var columnTemplate = columnSeries.columns.template;
columnTemplate.strokeWidth = 2;
columnTemplate.strokeOpacity = 1;

var lineSeries = chart.series.push(new am4charts.LineSeries());
lineSeries.name = keys[2];
lineSeries.dataFields.valueY = keys[2];
lineSeries.dataFields.dateX = keys[0];
lineSeries.yAxis=valueAxis2
lineSeries.events.on("hidden",toggleAxes);
lineSeries.events.on("shown",toggleAxes);

lineSeries.strokeWidth = 3;
lineSeries.propertyFields.strokeDasharray = "lineDash";
lineSeries.tooltip.label.textAlign = "middle";
lineSeries.tooltip.fontSize=12
lineSeries.tooltipText== "Tarih: {dateX}\n {name}: [bold]{valueY}[/]";

var bullet = lineSeries.bullets.push(new am4charts.Bullet());
bullet.tooltipText = "Tarih: {dateX}\n {name}: [bold]{valueY}[/]";
var circle = bullet.createChild(am4core.Circle);
circle.radius = 0;
circle.fill = am4core.color("#fff");
circle.strokeWidth = 0;
} else {
/* Create axes */
var categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.dataFields.category = keys[0];

/* Create value axis */
var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.tooltip.disabled = true;
  valueAxis.renderer.minWidth = 35;


/* Create series */
var columnSeries = chart.series.push(new am4charts.ColumnSeries());
columnSeries.name = keys[1];
columnSeries.dataFields.valueY = keys[1];
columnSeries.dataFields.dateX = keys[0];

columnSeries.columns.template.tooltipText ="Tarih: {dateX}\n {name}: [bold]{valueY}[/]";
columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
columnSeries.columns.template.propertyFields.stroke = "stroke";
columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
columnSeries.tooltip.label.textAlign = "middle";
columnSeries.tooltip.fontSize=12
columnSeries.fillOpacity = 0.2;

var columnTemplate = columnSeries.columns.template;
columnTemplate.strokeWidth = 2;
columnTemplate.strokeOpacity = 1;

var lineSeries = chart.series.push(new am4charts.LineSeries());
lineSeries.name = keys[2];
lineSeries.dataFields.valueY = keys[2];
lineSeries.dataFields.dateX = keys[0];

lineSeries.strokeWidth = 3;
lineSeries.propertyFields.strokeDasharray = "lineDash";
lineSeries.tooltip.label.textAlign = "middle";
lineSeries.tooltip.fontSize=12
lineSeries.tooltipText== "Tarih: {dateX}\n {name}: [bold]{valueY}[/]";

var bullet = lineSeries.bullets.push(new am4charts.Bullet());
bullet.tooltipText = "Tarih: {dateX}\n {name}: [bold]{valueY}[/]";
var circle = bullet.createChild(am4core.Circle);
circle.radius = 0;
circle.fill = am4core.color("#fff");
circle.strokeWidth = 0;

}

}
chart.data = datasets;

  chart.cursor = new am4charts.XYCursor();
  categoryAxis.cursorTooltipEnabled = false;

  chart.scrollbarX = new am4charts.XYChartScrollbar();
  chart.scrollbarX.parent = chart.bottomAxesContainer;
  chart.scrollbarX.showSystemTooltip = false;
  chart.scrollbarX.minHeight = 12;



  chart.legend = new am4charts.Legend();
  chart.legend.useDefaultMarker = true;
  let marker = chart.legend.markers.template.children.getIndex(0);
  marker.cornerRadius(12, 12, 12, 12);
  marker.strokeWidth = 2;
  marker.strokeOpacity = 1;
  marker.stroke = am4core.color("#ddd");
  chart.legend.fontSize = 12;

    //charts.legend.markers.template.states.create("dimmed").properties.opacity = 0.3;
    //charts.legend.labels.template.states.create("dimmed").properties.opacity = 0.3;

  // Add scrollbar
   chart.scrollbarX = new am4charts.XYChartScrollbar();
   chart.scrollbarX.parent = chart.bottomAxesContainer;
   categoryAxis.start = 0.7;
   categoryAxis.end = 1;
   categoryAxis.keepSelection = true;

   chart.exporting.menu = new am4core.ExportMenu();
   chart.exporting.filePrefix = "data";
   chart.exporting.menu.align="left"
   chart.exporting.menu.vertical="top"
   //chart.exporting.WebFontFilter="/pacifico|roboto/";
   //chart.exporting.menu.fontSize="12"

}

export default configs;
