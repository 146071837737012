/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


 var bgcolors= ["rgba(40,6,89,1)","rgba(236,113,77,1)", "rgba(249,248,113,1)","rgba(0,58,137,1)",
               "rgba(255,99,1,1)","rgba(0,141,187,1)","rgba(193,51,88,1)","rgba(0,181,195,1)",
                "rgba(29,120,116,1)","rgba(129,1,96,1)","rgba(0,124,146,1)","rgba(193,100,140,1)",
                "rgba(91,218,198,1)","rgba(157,108,168,1)","rgba(43,123,170,1)","rgba(105,118,179,1)",
               "rgba(51,75,73,1)","rgba(149,177,174,1)","rgba(148,91,114,1)","rgba(203,142,166,1)",
               "rgba(51,75,73,1)","rgba(149,177,174,1)","rgba(89,107,152,1)", "rgba(142,158,206,0.5)",
               "rgba(236,113,77,0.5)","rgba(254,180,77,0.5)","rgba(255,161,122,0.5)","rgba(0,58,137,0.5)",
               "rgba(0,100,170,0.5)","rgba(0,141,187,0.5)","rgba(0,100,170,1)"
]

const horizontalBarChartData = {
  labels: ["İngiltere","Rusya","Türkiye","Polonya","Almanya","Hollanda","İrlanda","Belçika","ABD","Diğer"],
  datasets: [
    {
      label: "Ülkelere Göre",
      backgroundColors: bgcolors,
      data: [846146,194987,173588,153850,122740,48086,28574,28300,25977,293774,],
    },
  ],
};

export default horizontalBarChartData;
