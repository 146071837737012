/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
import ChatGPTApi from "api/chats";

const connect_chatgbt_api = (query) => {
  const data = ChatGPTApi.connectchatgpt(query).then((response) => {
    return response;
  });
  return data;
};
export default connect_chatgbt_api;