/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import LoanNominal from "layouts/financialsector/banking/loans/components/loanNominal";
import dataTableData from "layouts/financialsector/banking/loans/components/infoTemplateTable";

// Data
import loanNotionalData from "layouts/financialsector/banking/loans/data/loanNotionalData";

import DataTable from "layouts/financialsector/banking/loans/components/DataTable";


function Graphs() {

  const [openMenu, setOpenMenu] = useState(null);
  const [openMenu2, setOpenMenu2] = useState(null);
  const [itemValue, setItemValue] = useState(0);
  const [itemValue2, setItemValue2] = useState(5);
  const [graph, setGraph] = useState(0);
  const [graph2, setGraph2] = useState(5);
  const [table, setTable] = useState(0);
  const [table2, setTable2] = useState(5);


  const params_cc=["Sektör-TP Ticari Krediler(TL)","Sektör-TP Tüketici İhtiyaç Kredileri(TL)",
                 "Sektör-TP Bireysel Kredi Kartları(TL)","Sektör-TP Konut Kredileri(TL)",
                 "Sektör-TP Tüketici Taşıt Kredileri(TL)","Sektör-TP Krediler(TL)" ]

  const params1_cc=["Kamu-TP Ticari Krediler(TL)","Kamu-TP Tüketici İhtiyaç Kredileri(TL)",
                 "Kamu-TP Bireysel Kredi Kartları(TL)","Kamu-TP Konut Kredileri(TL)",
                 "Kamu-TP Tüketici Taşıt Kredileri(TL)","Kamu-TP Krediler(TL)" ]

  const params2_cc=["Yerli Özel-TP Ticari Krediler(TL)","Yerli Özel-TP Tüketici İhtiyaç Kredileri(TL)",
                 "Yerli Özel-TP Bireysel Kredi Kartları(TL)","Yerli Özel-TP Konut Kredileri(TL)",
                 "Yerli Özel-TP Tüketici Taşıt Kredileri(TL)","Yerli Özel-TP Krediler(TL)" ]

  const params3_cc=["Yabancı-TP Ticari Krediler(TL)","Yabancı-TP Tüketici İhtiyaç Kredileri(TL)",
                 "Yabancı-TP Bireysel Kredi Kartları(TL)","Yabancı-TP Konut Kredileri(TL)",
                 "Yabancı-TP Tüketici Taşıt Kredileri(TL)","Yabancı-TP Krediler(TL)" ]


  const params5_cc_yp=["Sektör-YP Ticari Krediler(USD)","Sektör-YP Tüketici İhtiyaç Kredileri(USD)",
                 "Sektör-YP Bireysel Kredi Kartları(USD)","Sektör-YP Konut Kredileri(USD)",
                 "Sektör-YP Tüketici Taşıt Kredileri(USD)","Sektör-YP Krediler(USD)" ]

  const params6_cc_yp=["Kamu-YP Ticari Krediler(USD)","Kamu-YP Tüketici İhtiyaç Kredileri(USD)",
                 "Kamu-YP Bireysel Kredi Kartları(USD)","Kamu-YP Konut Kredileri(USD)",
                 "Kamu-YP Tüketici Taşıt Kredileri(USD)","Kamu-YP Krediler(USD)" ]

  const params7_cc_yp=["Yerli Özel-YP Ticari Krediler(USD)","Yerli Özel-YP Tüketici İhtiyaç Kredileri(USD)",
                 "Yerli Özel-YP Bireysel Kredi Kartları(USD)","Yerli Özel-YP Konut Kredileri(USD)",
                 "Yerli Özel-YP Tüketici Taşıt Kredileri(USD)","Yerli Özel-YP Krediler(USD)" ]

  const params8_cc_yp=["Yabancı-YP Ticari Krediler(USD)","Yabancı-YP Tüketici İhtiyaç Kredileri(USD)",
                 "Yabancı-YP Bireysel Kredi Kartları(USD)","Yabancı-YP Konut Kredileri(USD)",
                 "Yabancı-YP Tüketici Taşıt Kredileri(USD)","Yabancı-YP Krediler(USD)" ]

  var graphval;
  var graphval2;

  //const handleSetTabValue = (event, newTabValue) => setTabValue(newTabValue);
  const handleSetItemValue = (event, newItemValue) => setItemValue(newItemValue);
  const handleSetItemValue2 = (event, newItemValue2) => setItemValue2(newItemValue2);


  const handleSetGraph = (event, newGraph) => {
  //console.log(event.target.value)
  handleCloseMenu()
  graphval=event.target.value
  setGraph(graphval)
  setTable(graphval)
  };

  const handleSetGraph2 = (event2, newGraph2) => {
  //console.log(event2.target.value)
  handleCloseMenu2()
  graphval2=event2.target.value
  setGraph2(graphval2)
  setTable2(graphval2)
  };


  const handleOpenMenu = ({ currentTarget }) => setOpenMenu(currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const handleOpenMenu2 = ({ currentTarget }) => setOpenMenu2(currentTarget);
  const handleCloseMenu2 = () => setOpenMenu2(null);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      onClick={handleSetItemValue}
    >
      <MenuItem onClick={handleSetGraph} value="1">Kamu</MenuItem>
      <MenuItem onClick={handleSetGraph}  value="2">Yerli Özel</MenuItem>
      <MenuItem onClick={handleSetGraph} value="3">Yabancı</MenuItem>
      <SoftBox component="div" bgColor="secondary" opacity={0.3} width="100%" height="1px" my={1} />
      <MenuItem onClick={handleSetGraph} value="0" >
        <SoftTypography variant="inherit" color="error">
          Sektör
        </SoftTypography>
      </MenuItem>
    </Menu>
  );

    const renderMenu2 = () => (
    <Menu
      anchorEl={openMenu2}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu2)}
      onClose={handleCloseMenu2}
      onClick={handleSetItemValue2}
    >
      <MenuItem onClick={handleSetGraph2} value="6">Kamu</MenuItem>
      <MenuItem onClick={handleSetGraph2}  value="7">Yerli Özel</MenuItem>
      <MenuItem onClick={handleSetGraph2} value="8">Yabancı</MenuItem>
      <SoftBox component="div" bgColor="secondary" opacity={0.3} width="100%" height="1px" my={1} />
      <MenuItem onClick={handleSetGraph2} value="5" >
        <SoftTypography variant="inherit" color="error">
          Sektör
        </SoftTypography>
      </MenuItem>
    </Menu>
  );

  const params=["Sektör-TP Krediler(TL)"]
  const params2=["Kamu-TP Krediler(TL)"]
  const params3=["Yerli Özel-TP Krediler(TL)"]
  const params4=["Yabancı-TP Krediler(TL)"]

  const params5=["Sektör-YP Krediler(USD)"]
  const params6=["Kamu-YP Krediler(USD)"]
  const params7=["Yerli Özel-YP Krediler(USD)"]
  const params8=["Yabancı-YP Krediler(USD)"]


  return (
  <Grid container spacing={2} sx={{ height: 600 }}>
   <Grid item xs={12} lg={6} >
    <Card>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SoftTypography variant="h6">TL Krediler (M:Milyar TL)</SoftTypography>
          {renderMenu()}
          <SoftTypography
            color="text"
            onClick={handleOpenMenu}
            sx={{ lineHeight: 0, cursor: "pointer" }}
          >
            <Icon fontSize="default">more_vert</Icon>
          </SoftTypography>
      </SoftBox>
      <SoftBox p={0} mt={0} width="100%" height="25.5rem">
        <LoanNominal data={loanNotionalData(params)} date=""  value={graph} index={0} />
        <LoanNominal data={loanNotionalData(params2)} date=""  value={graph} index={1} />
        <LoanNominal data={loanNotionalData(params3)} date=""  value={graph} index={2} />
        <LoanNominal data={loanNotionalData(params4)} date=""  value={graph} index={3} />
      </SoftBox>
        <DataTable table={dataTableData(params_cc)} value={table} index={0} />
        <DataTable table={dataTableData(params1_cc)} value={table} index={1} />
        <DataTable table={dataTableData(params2_cc)} value={table} index={2} />
        <DataTable table={dataTableData(params3_cc)} value={table} index={3} />
     </Card>
   </Grid>
   <Grid item xs={12} lg={6} >
    <Card>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SoftTypography variant="h6">YP Krediler (M:Milyar USD)</SoftTypography>
          {renderMenu2()}
          <SoftTypography
            color="text"
            onClick={handleOpenMenu2}
            sx={{ lineHeight: 0, cursor: "pointer" }}
          >
            <Icon fontSize="default">more_vert</Icon>
          </SoftTypography>
      </SoftBox>
      <SoftBox p={0} mt={0} width="100%" height="25.5rem">
        <LoanNominal data={loanNotionalData(params5)} date=""  value={graph2} index={5} />
        <LoanNominal data={loanNotionalData(params6)} date=""  value={graph2} index={6} />
        <LoanNominal data={loanNotionalData(params7)} date=""  value={graph2} index={7} />
        <LoanNominal data={loanNotionalData(params8)} date=""  value={graph2} index={8} />
      </SoftBox>
        <DataTable table={dataTableData(params5_cc_yp)} value={table2} index={5} />
        <DataTable table={dataTableData(params6_cc_yp)} value={table2} index={6} />
        <DataTable table={dataTableData(params7_cc_yp)} value={table2} index={7} />
        <DataTable table={dataTableData(params8_cc_yp)} value={table2} index={8} />
    </Card>
   </Grid>
  </Grid>
  );
}

export default Graphs;
