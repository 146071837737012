/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// prop-types is a library for type checking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

//Charts
import ExtraMiniGradientLineChart from "examples/Charts/LineCharts/ExtraMiniGradientLineChart";
import DefaultHighGaugeChart from "examples/Charts/HighChartsGauge/DefaultHighGaugeChart";
import GradientLineChartApi from "examples/Charts/LineCharts/GradientLineChartApi";


// Data
import nplData from "layouts/financialsector/banking/loans/data/nplData";

function NPLTemplate() {

const  npltoplam  = nplData("NPL Rasyosu");
const  nplticari  = nplData("Ticari Kredi NPL");
const  nplihtiyac  = nplData("İhtiyaç Kredisi NPL");
const  nplbkk  = nplData("Kredi Kartı NPL");
const  nplkonut  = nplData("Konut Kredisi NPL");
const  npltasit  = nplData("Taşıt Kredisi NPL");
const  nplcoverage  = nplData("NPL Coverage Ratio");


  return (
          <Grid container spacing={0} >
            <Grid item xs={12} xl={7.6} mr={0}>
               <SoftBox mb={3}>
                  <Grid container spacing={0} sx={{ width: 800, border: 0 }}>
                     <Grid item xs={12} sm={6} lg={5.2}>
                        <ExtraMiniGradientLineChart
                             title="Takipteki Alacaklar/Krediler (Toplam) (%)"
                             description= ""
                             chart={npltoplam}
                         />
                     </Grid>
                     <Grid item xs={12} sm={6} lg={5.2}>
                        <ExtraMiniGradientLineChart
                             title="Takipteki Alacaklar/Krediler (Ticari) (%)"
                             description=""
                             chart={nplticari}
                     />
	                 </Grid>
                  </Grid>
                  <Grid container spacing={0} sx={{ width: 800, border: 0 }}>
                     <Grid item xs={12} sm={6} lg={5.2}>
                        <ExtraMiniGradientLineChart
                             title="Takipteki Alacaklar/Krediler (İhtiyaç) (%)"
                             description= ""
                              chart={nplihtiyac}
                     />
                     </Grid>
                     <Grid item xs={12} sm={6} lg={5.2}>
                        <ExtraMiniGradientLineChart
                             title="Takipteki Alacaklar/Krediler (Konut) (%)"
                             description=""
                             chart={nplkonut}
                     />
	                 </Grid>
                  </Grid>
                  <Grid container spacing={0} sx={{ width: 800, border: 0 }}>
                     <Grid item xs={12} sm={6} lg={5.2}>
                        <ExtraMiniGradientLineChart
                             title="Takipteki Alacaklar/Krediler (Taşıt) (%)"
                             description= ""
                             chart={npltasit}
                     />
                     </Grid>
                     <Grid item xs={12} sm={6} lg={5.2}>
                        <ExtraMiniGradientLineChart
                             title="Takipteki Alacaklar/Krediler (BKK) (%)"
                             description=""
                             chart={nplbkk}
                     />
	                 </Grid>
                  </Grid>
               </SoftBox>
            </Grid>
            <Grid item xs={12} xl={4.4}>
                <SoftBox mb={3}>
                  <Grid container spacing={0} container direction="column" justifyContent="flex-start"alignItems="stretch" sx={{ mb: 1 }}>
                        <DefaultHighGaugeChart
                             title="Takipteki Alacaklar Gösterge (%)"
                             description=""
                             chart={npltoplam}
                     />
                  </Grid>
                  <Grid container spacing={0} container direction="column" justifyContent="flex-start" alignItems="stretch" sx={{ mt: 5 }}>
                        <GradientLineChartApi
                             title="Takipteki Alacaklar Karşılığı / Takipteki Alacaklar (%)"
                             description=""
                             chart={nplcoverage}
                     />
                   </Grid>
                </SoftBox>
            </Grid>
          </Grid>
  );
}

// Typechecking props for the Template
NPLTemplate.propTypes = {
};

export default NPLTemplate;
