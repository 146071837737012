import axios from "./index";

class ReportApi {
  static creditgrowth = (data) => {
    return axios.post(`/analytics`, data);
  };

  static creditbysector = (data) => {
    return axios.post(`/analytics`, data);
  };

  static creditdata = (data) => {
    return axios.post(`/analytics`, data);
  };

  static npldata = (data) => {
    return axios.post(`/analytics`, data);
  };
  static creditchangedata = (data) => {
    return axios.post(`/analytics`, data);
  };
  static interestratesdata = (data) => {
    return axios.post(`/analytics`, data);
  };
  static depositsdata = (data) => {
    return axios.post(`/analytics`, data);
  };
  static depositchangedata = (data) => {
    return axios.post(`/analytics`, data);
  };

 static incomestatementsdata = (data) => {
    return axios.post(`/analytics`, data);
  };

 static cashflowdata = (data) => {
    return axios.post(`/analytics`, data);
  };

 static balancesheetdata = (data) => {
    return axios.post(`/analytics`, data);
  };

 static rasyodata = (data) => {
    return axios.post(`/analytics`, data);
  };


 static fundingdata = (data) => {
    return axios.post(`/analytics`, data);
  };

 static governmentbudget = (data) => {
    return axios.post(`/analytics`, data);
  };

 static taxdata = (data) => {
    return axios.post(`/analytics`, data);
  };

 static cgbdatarev = (data) => {
    return axios.post(`/analytics`, data);
  };


 static cgbdataexp = (data) => {
    return axios.post(`/analytics`, data);
  };

 static primaryexp = (data) => {
    return axios.post(`/analytics`, data);
  };

 static domesticdebt = (data) => {
    return axios.post(`/analytics`, data);
  };

 static foreigndebtir = (data) => {
    return axios.post(`/analytics`, data);
  };

 static foreigndebtspread = (data) => {
    return axios.post(`/analytics`, data);
  };

 static currencydebt = (data) => {
    return axios.post(`/analytics`, data);
  };

 static irtypedata = (data) => {
    return axios.post(`/analytics`, data);
  };

 static reservedata = (data) => {
    return axios.post(`/analytics`, data);
  };


static creditcard = (data) => {
    return axios.post(`/analytics`, data);
  };

static creditcardpertxn = (data) => {
    return axios.post(`/analytics`, data);
  };

static creditcardindex = (data) => {
    return axios.post(`/analytics`, data);
  };

static tradesdata = (data) => {
    return axios.post(`/analytics`, data);
  };

static tradesgroupdata = (data) => {
    return axios.post(`/analytics`, data);
  };


static tradesgroupseriesdata = (data) => {
    return axios.post(`/analytics`, data);
  };


static lastdate = (data) => {
    return axios.post(`/analytics`, data);
  };

static inflationdata = (data) => {
    return axios.post(`/analytics`, data);
  };

static gdpdata = (data) => {
    return axios.post(`/analytics`, data);
  };

static payrolldata = (data) => {
    return axios.post(`/analytics`, data);
  };

static payrolldatahistory = (data) => {
    return axios.post(`/analytics`, data);
  };

static bopdata = (data) => {
    return axios.post(`/analytics`, data);
  };

static swapdata = (data) => {
    return axios.post(`/analytics`, data);
  };

static fundingtable = (data) => {
    return axios.post(`/analytics`, data);
  };

static analyticbs = (data) => {
    return axios.post(`/analytics`, data);
  };

static currentaccount = (data) => {
    return axios.post(`/analytics`, data);
  };

 static expectations = (data) => {
    return axios.post(`/analytics`, data);
  };

 static topicdata = (data) => {
    return axios.post(`/analytics`, data);
  };

 static bankbs = (data) => {
    return axios.post(`/analytics`, data);
  };

  static bopfinance = (data) => {
    return axios.post(`/analytics`, data);
  };

   static tradeindex = (data) => {
    return axios.post(`/analytics`, data);
  };

   static tradeindexdetail = (data) => {
    return axios.post(`/analytics`, data);
  };

   static menulist = (data) => {
    return axios.post(`/analytics`, data);
  };

}
export default ReportApi;

