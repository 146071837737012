import {
  İhracat,
  İthalat,
  NetTransit,
  ParasalOlmayanAltın,
  İmalat,
  BakımOnarım,
  Taşımacılık,
  DigerHizmet,
  UcretOdeme,
  YatırımGelirleri,
  İkinciGelirDengesi,
  DogrudanYatırımNetVarlık,
  DogrudanYatırımNetYukumluluk,
  PortfoyYatırımNetVarlık,
  PortfoyYatırımNetYukumluluk,
  EfektifMevduatNetVarlık,
  EfektifMevduatNetYukumluluk,
  KrediNetVarlık,
  KrediNetYukumluluk,
  DigerYatırımlarDiger,
  RezervVarlıklar,
  NetHataNoksan,
  MonthlyValues
} from "layouts/nationalaccount/currentaccount/components/BalanceSheet/components/interfaces";
import { months } from "layouts/nationalaccount/currentaccount/components/BalanceSheet/components/helpers";


const sumGroupInAMonth = (group: (İhracat | İthalat | NetTransit | ParasalOlmayanAltın | İmalat | BakımOnarım |Taşımacılık | DogrudanYatırımNetVarlık | EfektifMevduatNetVarlık)[]) => (
  monthIndex: number
): number =>
  group.reduce(
    (acc, { values }) =>
      acc + (isNaN(values[monthIndex]) ? 0 : values[monthIndex]),
    0
  );

const calcMonthlyGroupTotals = (
  group: (İhracat | İthalat | NetTransit | ParasalOlmayanAltın| İmalat | BakımOnarım | Taşımacılık | DogrudanYatırımNetVarlık | EfektifMevduatNetVarlık)[]
): MonthlyValues => months().map((_, idx) => sumGroupInAMonth(group)(idx));

// DCF'e eklenecek.
export function calculateOutputVariables(
  inputVariables: InputVariables
): OutputVariables {
  const {
    ihracat,
    ithalat,
    nettransit,
    parasalolmayanaltın,
    imalat,
    bakımonarım,
    taşımacılık,
    digerhizmet,
    ucretodeme,
    yatırımgelirleri,
    ikincigelir,
    dogrudanyatırımnetvarlık,
    dogrudanyatırımnetyukumluluk,
    portfoyyatırımnetvarlık,
    portfoyyatırımnetyukumluluk,
    efektifmevduatnetvarlık,
    efektifmevduatnetyukumluluk,
    kredinetvarlık,
    kredinetyukumluluk,
    digeryatırımlardiger,
    rezervvarlıklar,
    nethatanoksan,
  } = inputVariables;

  //console.log(efektifmevduatnetvarlık)
  const monthlyİhracatTotals = calcMonthlyGroupTotals(ihracat);
  const monthlyİthalatTotals = calcMonthlyGroupTotals(ithalat);
  const monthlyNetTransitTotals = calcMonthlyGroupTotals(nettransit);
  const monthlyParasalOlmayanAltınTotals = calcMonthlyGroupTotals(parasalolmayanaltın);
  const monthlyİmalatTotals = calcMonthlyGroupTotals(imalat);
  const monthlyBakımOnarımTotals = calcMonthlyGroupTotals(bakımonarım);
  const monthlyTaşımacılıkTotals = calcMonthlyGroupTotals(taşımacılık);
  const monthlyDigerHizmetTotals = calcMonthlyGroupTotals(digerhizmet);
  const monthlyUcretOdemeTotals = calcMonthlyGroupTotals(ucretodeme);
  const monthlyYatırımGelirleriTotals = calcMonthlyGroupTotals(yatırımgelirleri);
  const monthlyİkinciGelirDengesiTotals = calcMonthlyGroupTotals(ikincigelir);
  const monthlyDogrudanYatırımNetVarlıkTotals = calcMonthlyGroupTotals(dogrudanyatırımnetvarlık);
  const monthlyDogrudanYatırımNetYukumlulukTotals = calcMonthlyGroupTotals(dogrudanyatırımnetyukumluluk);
  const monthlyPortfoyYatırımNetVarlıkTotals = calcMonthlyGroupTotals(portfoyyatırımnetvarlık);
  const monthlyPortfoyYatırımNetYukumlulukTotals = calcMonthlyGroupTotals(portfoyyatırımnetyukumluluk);
  const monthlyEfektifMevduatNetVarlıkTotals = calcMonthlyGroupTotals(efektifmevduatnetvarlık);
  const monthlyEfektifMevduatNetYukumlulukTotals = calcMonthlyGroupTotals(efektifmevduatnetyukumluluk);
  const monthlyKrediNetVarlıkTotals = calcMonthlyGroupTotals(kredinetvarlık);
  const monthlyKrediNetYukumlulukTotals = calcMonthlyGroupTotals(kredinetyukumluluk);
  const monthlyDigerYatırımlarDigerTotals = calcMonthlyGroupTotals(digeryatırımlardiger);
  const monthlyRezervVarlıklarTotals = calcMonthlyGroupTotals(rezervvarlıklar);
  const monthlyNetHataNoksanTotals = calcMonthlyGroupTotals(nethatanoksan);



  const monthlyMalTicaretiTotals = months().map(
    (_, idx) => monthlyİhracatTotals[idx] - monthlyİthalatTotals[idx]
  );

  const monthlyDısTicaretTotals = months().map(
    (_, idx) => monthlyİhracatTotals[idx] - monthlyİthalatTotals[idx]+monthlyNetTransitTotals[idx]+monthlyParasalOlmayanAltınTotals[idx]
  );

   const monthlyHizmetlerDengesiTotals = months().map(
    (_, idx) => monthlyİmalatTotals[idx]+monthlyBakımOnarımTotals[idx] + monthlyTaşımacılıkTotals[idx]+monthlyDigerHizmetTotals[idx]
  );

  const monthlyBirinciGelirDengesiTotals = months().map(
    (_, idx) => monthlyUcretOdemeTotals[idx] + monthlyYatırımGelirleriTotals[idx]
  );

  const monthlyCariDengeTotals = months().map(
    (_, idx) => monthlyDısTicaretTotals[idx] + monthlyHizmetlerDengesiTotals[idx]+ monthlyBirinciGelirDengesiTotals[idx]+ monthlyİkinciGelirDengesiTotals[idx]
  );

  const monthlyDogrudanYatırımTotals = months().map(
    (_, idx) => monthlyDogrudanYatırımNetVarlıkTotals[idx] - monthlyDogrudanYatırımNetYukumlulukTotals[idx]
  );

  const monthlyPortfoyYatırımTotals = months().map(
    (_, idx) => monthlyPortfoyYatırımNetVarlıkTotals[idx] - monthlyPortfoyYatırımNetYukumlulukTotals[idx]
  );

    const monthlyEfektifMevduatTotals = months().map(
    (_, idx) => monthlyEfektifMevduatNetVarlıkTotals[idx] - monthlyEfektifMevduatNetYukumlulukTotals[idx]
  );

    const monthlyKrediTotals = months().map(
    (_, idx) => monthlyKrediNetVarlıkTotals[idx] - monthlyKrediNetYukumlulukTotals[idx]
  );

    const monthlyDigerYatırımTotals = months().map(
    (_, idx) => monthlyEfektifMevduatTotals[idx]+monthlyKrediTotals[idx]+monthlyDigerYatırımlarDigerTotals[idx]
  );

    const monthlyFinancialAccountTotals = months().map(
    (_, idx) => monthlyDogrudanYatırımTotals[idx] + monthlyPortfoyYatırımTotals[idx] + monthlyDigerYatırımTotals[idx]+monthlyRezervVarlıklarTotals[idx]
  );

  return {
    monthlyİhracatTotals,
    monthlyİthalatTotals,
    monthlyMalTicaretiTotals,
    monthlyNetTransitTotals,
    monthlyParasalOlmayanAltınTotals,
    monthlyDısTicaretTotals,
    monthlyİmalatTotals,
    monthlyBakımOnarımTotals,
    monthlyTaşımacılıkTotals,
    monthlyHizmetlerDengesiTotals,
    monthlyYatırımGelirleriTotals,
    monthlyBirinciGelirDengesiTotals,
    monthlyİkinciGelirDengesiTotals,
    monthlyCariDengeTotals,
    monthlyDogrudanYatırımNetVarlıkTotals,
    monthlyDogrudanYatırımNetYukumlulukTotals,
    monthlyDogrudanYatırımTotals,
    monthlyPortfoyYatırımNetVarlıkTotals,
    monthlyPortfoyYatırımNetYukumlulukTotals,
    monthlyPortfoyYatırımTotals,
    monthlyEfektifMevduatNetVarlıkTotals,
    monthlyEfektifMevduatNetYukumlulukTotals,
    monthlyEfektifMevduatTotals,
    monthlyFinancialAccountTotals,
    monthlyDigerYatırımTotals,
    monthlyKrediNetVarlıkTotals,
    monthlyKrediNetYukumlulukTotals,
    monthlyKrediTotals,
    monthlyRezervVarlıklarTotals,
    monthlyNetHataNoksanTotals
  };
}
