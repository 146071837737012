/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
//import typography from "assets/theme/base/typography";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

function configs(chart, datasets,group) {
  am4core.useTheme(am4themes_animated);
  chart.data=datasets
  // Create axes
  var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = "category";
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.minGridDistance = 30;
  categoryAxis.renderer.ticks.template.disabled = false;
  categoryAxis.renderer.ticks.template.strokeOpacity = 0.5;
  categoryAxis.renderer.labels.template.rotation = -25;
  categoryAxis.renderer.labels.template.horizontalCenter = "right";
  categoryAxis.dataItems.template.text = "{category}";
  categoryAxis.renderer.labels.template.fontSize = "12px";

  var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.calculateTotals = true;
  valueAxis.renderer.labels.template.fontSize = "13px";

  // Create series
  function createSeries(open, close, names, showSum) {
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = close;
    series.dataFields.openValueY = open;
    series.name = name;
    series.dataFields.categoryX = "category";
    series.clustered = false;
    series.strokeWidth = 0;
    series.columns.template.width = am4core.percent(90);

    var columnTemplate = series.columns.template;
    columnTemplate.strokeOpacity = 0;
    columnTemplate.propertyFields.fill = "color";

    // tooltip
    series.columns.template.tooltipText = "{categoryX}: tmp";
    series.columns.template.adapter.add("tooltipText", function(tooltipText, target) {
      var val = target.dataItem.valueY - target.dataItem.openValueY;
      return tooltipText.replace('tmp', val.toLocaleString('en-US') );
    });
    //series.columns.template.tooltipY = 0;

    var stepSeries=chart.series.push(new am4charts.StepLineSeries());
    stepSeries.dataFields.categoryX = "category";
    stepSeries.dataFields.valueY = close;
    stepSeries.noRisers=true;
    stepSeries.stroke=new am4core.InterfaceColorSet().getFor("alternativeBackground");
    stepSeries.strokeWidth=2.5;
    stepSeries.strokeDasharray="3.3";
    stepSeries.interpolationDuration=2000;
    stepSeries.sequencedInterpolation=true;

    stepSeries.startLocation=0.1;
    stepSeries.endLocation=1.1;


    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.hideOversized = false;
    labelBullet.label.fill = am4core.color("#fff");
    labelBullet.fontSize="12px"
    labelBullet.label.text = "{valueY.formatNumber('#,###')}";
    labelBullet.label.adapter.add("text", function(text, target) {
      var val = target.dataItem.valueY - target.dataItem.openValueY;
      return   val.toLocaleString('en-US')  ;
    });
    labelBullet.locationY = 0.5;

    if (showSum) {
      var sumBullet = series.bullets.push(new am4charts.LabelBullet());
      sumBullet.label.text = "{valueY.close}";
      sumBullet.verticalCenter = "bottom";
      sumBullet.dy = -8;
      sumBullet.label.adapter.add("text", function(text, target) {
        var val = Math.abs(target.dataItem.dataContext.close2 - target.dataItem.dataContext.open1);
        return  val ;
      });
    }
  }

  var rangeTemplate = categoryAxis.axisRanges.template;
  rangeTemplate.tick.disabled = false;
  rangeTemplate.tick.location = 0;
  rangeTemplate.tick.strokeOpacity = 0.6;
  rangeTemplate.tick.length = 120;
  rangeTemplate.grid.strokeOpacity = 0.5;
  rangeTemplate.tick.strokeWidth=2.5;
  rangeTemplate.grid.strokeWidth = 2.5;

  for (let i = 0; i < group.length; i++) {
  //console.log("-----------")
  //console.log(group[i].first)
  //console.log(group[i].last)
  //console.log(group[i].group)

  // create range (the additional label at the bottom)
   var range = categoryAxis.axisRanges.create();
   range.category = group[i].first;
   range.endCategory = group[i].last;
   range.label.text = group[i].group;
   range.label.dy = 120;
   range.label.dx = 80;
   range.label.truncate = true;
   range.label.fontWeight = "bold";
   range.label.fontSize = "12px";
   range.label.rotation=-1;
   //console.log(range)
   range.label.adapter.add("maxWidth", function(maxWidth, target){
     var range = target.dataItem;
     var startPosition = categoryAxis.categoryToPosition(range.category, 0);
     var endPosition = categoryAxis.categoryToPosition(range.endCategory, 1);
     var startX = categoryAxis.positionToCoordinate(startPosition);
     var endX = categoryAxis.positionToCoordinate(endPosition);
     return endX - startX;
   })
  }

  createSeries("open1", "close1", "High", false);
  chart.logo.disabled = true;
}

export default configs;
