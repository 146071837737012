import * as React from "react";
import "layouts/realsector/firms/components/Summary/components/assets/styleelement.css";
import "layouts/realsector/firms/components/Summary/components/assets/styles.css";
import { CellChange, NumberCell, ReactGrid } from "@silevis/reactgrid";
//import "@silevis/reactgrid/styles.css";
import { getColumns } from "layouts/realsector/firms/components/Summary/components/getColumns";
import { getRows}  from "layouts/realsector/firms/components/Summary/components/getRows";

//import GradientLineChart from "examples/Charts/LineCharts/GradientLineChartGPT";
import ReportsBarChart from "examples/Charts/BarCharts/DefaultBarCharts";


import {
  WorkingCapitalRatio,
  CashConversionCycle,
  InterestCoverage,
  ProfitabilityRatio,
  OperatingReturns,
  Sales,
  FAVOK,
  GrossPL,
  NetPL,
  SummaryGraph,
  Labels,
  InputVariables,
  OutputVariables
} from "layouts/realsector/firms/components/Summary/components/interfaces";

import { getChartData, chartOptions } from  "layouts/realsector/firms/components/Summary/data/chartdata";
import { calculateOutputVariables } from "layouts/realsector/firms/components/Summary/components/plannerOutputVariables";


//const applyChange = (change: CellChange<NumberCell>) => < K >(
//  groups: Array<K>
//) =>
//  groups.map((group) =>
//    group.title === change.rowId
//      ? {
//          ...group,
//          values: group.values.map((value, idx) =>
//            change.columnId === idx + 1 ? change.newCell.value : value
//          )
//        }
//      : group
//  );

//export const BalanceSheet: React.FC = (stock) => {
function Summary(data){

  const summarydata=data.data
  const [workingcapitalratio ]= [summarydata.workingcapitalratio]
  const [cashconversioncycle ]= [summarydata.cashconversioncycle]
  const [interestcoverage ]= [summarydata.interestcoverage]
  const [profitabilityratio ]= [summarydata.profitabilityratio]
  const [operatingreturns ]= [summarydata.operatingreturns]
  const [sales ]= [summarydata.sales]
  const [favok ]= [summarydata.favok]
  const [grosspl ]= [summarydata.grosspl]
  const [netpl ]= [summarydata.netpl]
  const [summarygraphs ]= [summarydata.summarygraphs]
  const [labels ]= [summarydata.labels]

  const columns = getColumns();
  const inputVariables: InputVariables = {
    workingcapitalratio,
    cashconversioncycle,
    interestcoverage,
    profitabilityratio,
    operatingreturns,
    sales,
    favok,
    grosspl,
    netpl,
    summarygraphs,
    labels
  };

  const outputVariables = calculateOutputVariables(inputVariables);
  const plannerData: InputVariables & OutputVariables = {
    ...inputVariables,
    ...outputVariables
  };

  const rows = getRows(plannerData);
  //console.log("inputVariables")
  //console.log(inputVariables)
   // console.log("plannerdata")
   // console.log(plannerData)
    //console.log(labels)
  //console.log(data.data.data.labels)
  //console.log(plannerData.monthlyFavokTotals.map((value) => value))
  //console.log("Row")
  //console.log(rows)
  /*const handleChanges = (changes: CellChange[]) => {
    changes.forEach((change: CellChange<NumberCell>) => {
      setCashInflow((cashInflow) => applyChange(change)(cashInflow));
      setCashOutflow((cashOutflow) => applyChange(change)(cashOutflow));
      setOpex((opex) => applyChange(change)(opex));
      setCashOtherInflow((cashOtherInflow) => applyChange(change)(cashOtherInflow));
      setCashOtherOutflow((cashOtherOutflow) => applyChange(change)(cashOtherOutflow));
      setFinancialExpense((financialExpense) => applyChange(change)(financialExpense));

    });
   };*/
const chardata = getChartData(plannerData,summarydata.labels);
  return (
 //  valueindex === 1 && (
    <>
      <div className="dcf-app" style={{ width: 1200 }} >
        <ReportsBarChart
                title=""
                description= ""
                chart={chardata}
                chartoptions={chartOptions}
                height= {"12.125rem"}
                width= {1200}
              />
        <ReactGrid
          rows={rows}
          columns={columns}
          //onCellsChanged={handleChanges}
          stickyTopRows={1}
          stickyLeftColumns={1}
          stickyRightColumns={1}
          // props below are availble for PRO version
          //enableFillHandle
          //enableRangeSelection
        />
      </div>
    </>
  //  )
  );
};

export default Summary;
