
import { useRef, useEffect, useState, useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// Overview page components
import DefaultCell from "layouts/financialsector/banking/loans/components/DefaultCell";
import TotalCell from "layouts/financialsector/banking/loans/components/TotalCell";

//Data
import depoChangeData from "layouts/financialsector/banking/deposits/data/infoTemplateTableData";


function dataTableData(params) {

const [tableData, setTableData] = useState( [
                                {"index": "Sektör-TP Mevduat(TL)","last": 0,"oneweek": 0,"onemonth": 0,"threemonth": 0},
                                {"index": "Sektör-TP Mevduat(TL)","last": 0,"oneweek": 0,"onemonth": 0,"threemonth": 0},
                                {"index": "Sektör-TP Mevduat(TL)","last": 0,"oneweek": 0,"onemonth": 0,"threemonth": 0},
                                {"index": "Sektör-TP Mevduat(TL)","last": 0,"oneweek": 0,"onemonth": 0,"threemonth": 0},

]);

const table=depoChangeData(params)

useEffect(() => {
 table.then((response) => {
      const tableDatasets = response;
      setTableData(tableDatasets)
   });
 }, []);

const rowstr=[]
const tmp_data = {};
tableData.forEach(function(data){
    const tmp_data = {};
    if(data.index.includes("Mevduat")) {
        tmp_data["title"] =<DefaultCell>Mevduat</DefaultCell>
        tmp_data["last"] = <DefaultCell>{String((data.last/1000).toFixed(2)+"M")}</DefaultCell>
        tmp_data["oneweek"] = <DefaultCell>{String((data.oneweek/1000).toFixed(2)+"M")}</DefaultCell>
        tmp_data["onemonth"] = <DefaultCell>{String((data.onemonth/1000).toFixed(2)+"M")}</DefaultCell>
        tmp_data["threemonth"] = <DefaultCell>{String((data.threemonth/1000).toFixed(2)+"M")}</DefaultCell>
        rowstr.push(tmp_data)
        }
    else if(data.index.includes("Hariç"))  {
        tmp_data["title"] =<DefaultCell>KKM Hariç</DefaultCell>
        tmp_data["last"] = <DefaultCell>{String((data.last/1000).toFixed(2)+"M")}</DefaultCell>
        tmp_data["oneweek"] = <DefaultCell>{String((data.oneweek/1000).toFixed(2)+"M")}</DefaultCell>
        tmp_data["onemonth"] = <DefaultCell>{String((data.onemonth/1000).toFixed(2)+"M")}</DefaultCell>
        tmp_data["threemonth"] = <DefaultCell>{String((data.threemonth/1000).toFixed(2)+"M")}</DefaultCell>
        rowstr.push(tmp_data)
        }
     else if(data.index.includes("Dahil"))  {
        tmp_data["title"] =<DefaultCell>KKM Dahil</DefaultCell>
        tmp_data["last"] = <DefaultCell>{String((data.last/1000).toFixed(2)+"M")}</DefaultCell>
        tmp_data["oneweek"] = <DefaultCell>{String((data.oneweek/1000).toFixed(2)+"M")}</DefaultCell>
        tmp_data["onemonth"] = <DefaultCell>{String((data.onemonth/1000).toFixed(2)+"M")}</DefaultCell>
        tmp_data["threemonth"] = <DefaultCell>{String((data.threemonth/1000).toFixed(2)+"M")}</DefaultCell>
        rowstr.push(tmp_data)
        }
      else {
        tmp_data["title"] =<DefaultCell>KKM</DefaultCell>
        tmp_data["last"] = <DefaultCell>{String((data.last/1000).toFixed(2)+"M")}</DefaultCell>
        tmp_data["oneweek"] = <DefaultCell>{String((data.oneweek/1000).toFixed(2)+"M")}</DefaultCell>
        tmp_data["onemonth"] = <DefaultCell>{String((data.onemonth/1000).toFixed(2)+"M")}</DefaultCell>
        tmp_data["threemonth"] = <DefaultCell>{String((data.threemonth/1000).toFixed(2)+"M")}</DefaultCell>
        rowstr.push(tmp_data)
        }
});


const dataTableDatatmp={
    columns: [
        { Header: "Krediler", accessor: "title", align: "left"},
        { Header: "Son", accessor: "last", align: "left" },
        { Header: "1 Hafta", accessor: "oneweek", align: "left" },
        { Header: "1 Ay", accessor: "onemonth", align: "left" },
        { Header: "3 Ay", accessor: "threemonth", align: "left" },
  ],
  rows:rowstr,
  };
//console.log(dataTableDatatmp)
return dataTableDatatmp
}

// Setting default values for the props of DataTable
dataTableData.defaultProps = {
  noEndBorder: false,
};

// Typechecking props for the DataTable
dataTableData.propTypes = {
  table: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default dataTableData;
