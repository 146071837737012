import { Row } from "@silevis/reactgrid";

import {
  LiquidAsset,
  FixedAsset,
  ShortTermLoan,
  LongTermLoan,
  Equity,
  Labels,
  InputVariables,
  MonthlyValues,
  OutputVariables
} from "layouts/realsector/firms/components/BalanceSheet/components/interfaces";
import { months } from "layouts/realsector/firms/components/BalanceSheet/components/helpers";
import {
  emptyTextCell,
  nonEditable,
  textCell,
  monthHeaderCell,
  bottomLine,
  numberCell,
  showZero,
  noSideBorders
} from "layouts/realsector/firms/components/BalanceSheet/components/cells";

export const HEADER_ROW_ID = "header";
export const MONTHSTOTAL_ROW_ID = "monthsTotal";
export const MONTHSTOTAL_ROW_ID2= "monthsTotal2";
export const MONTHSTOTAL_ROW_ID3= "monthsTotal3";


const ROW_HEIGHT = 21;
const HEADING_ROW_HEIGHT = 30;

function sumGroupValues(values: MonthlyValues): number {
  return values.reduce(
    (prev, curr) => (isNaN(prev) ? 0 : prev) + (isNaN(curr) ? 0 : curr)
  );
}



function getHeaderRow(
  groups: (Labels)[],
): Row [] {
  const labels_data=groups
  const tmp_labels = labels_data.map((_, idx) => nonEditable(monthHeaderCell(labels_data[idx], "justify-content-center")));
  const labels = [nonEditable(emptyTextCell)].concat(tmp_labels)
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells:labels,
  };
}




function getMonthsAssetSumRow(
  title: string,
  monthlyAssetsSum: MonthlyValues,
): Row {
  const monthsTotalCell = (value: number) =>
    bottomLine(
      nonEditable(showZero(numberCell(value, "text-md-red disabled font-bold")))
    );
  return {
    rowId: MONTHSTOTAL_ROW_ID,
    height: HEADING_ROW_HEIGHT,
    cells: [
      bottomLine(nonEditable(textCell(title, "text-lg-red font-bold"))),
      ...months().map((_, idx) =>
        nonEditable(monthsTotalCell(monthlyAssetsSum[idx]))
      ),
    ],
  };
}


function getMonthsLiabilitySumRow(
  title: string,
  monthlyLiabilitySum: MonthlyValues,
): Row {
  const monthsTotalCell = (value: number) =>
    bottomLine(
      nonEditable(showZero(numberCell(value, "text-md-red disabled font-bold")))
    );
  return {
    rowId: MONTHSTOTAL_ROW_ID2,
    height: HEADING_ROW_HEIGHT,
    cells: [
      bottomLine(nonEditable(textCell(title, "text-lg-red font-bold"))),
      ...months().map((_, idx) =>
        nonEditable(monthsTotalCell(monthlyLiabilitySum[idx]))
      ),
    ],
  };
}


function getMonthsLiabilityEquitySumRow(
  title: string,
  monthlyLiabilityEquitySum: MonthlyValues,
): Row {
  const monthsTotalCell = (value: number) =>
    bottomLine(
      nonEditable(showZero(numberCell(value, "text-md-red disabled font-bold")))
    );
  return {
    rowId: MONTHSTOTAL_ROW_ID3,
    height: HEADING_ROW_HEIGHT,
    cells: [
      bottomLine(nonEditable(textCell(title, "text-lg-red font-bold"))),
      ...months().map((_, idx) =>
        nonEditable(monthsTotalCell(monthlyLiabilityEquitySum[idx]))
      ),
    ],
  };
}


function getMonthsInflowOuflowShortTermLoanDiffRow(
  title: string,
  monthlyInflowOuflowShortTermLoanDiffs: MonthlyValues,
  //yearlyGroupsDiff: number
): Row {
  const monthsTotalCell = (value: number) =>
    bottomLine(
      nonEditable(showZero(numberCell(value, "text-md-darkblue disabled font-bold")))
    );
  return {
    rowId: MONTHSTOTAL_ROW_ID3,
    height: HEADING_ROW_HEIGHT,
    cells: [
      bottomLine(nonEditable(textCell(title, "text-md-darkblue font-bold"))),
      ...months().map((_, idx) =>
        nonEditable(monthsTotalCell(monthlyInflowOuflowShortTermLoanDiffs[idx]))
      ),
    ],
  };
}


function getGroupRows(
  title: "Dönen Varlıklar" | "Duran Varlıklar" | "Kısa Vadeli Borçlar" ,
  summaryTitle: string,
  groups: (LiquidAsset | FixedAsset | ShortTermLoan | LongTermLoan | Equity)[],
  monthlyGroupTotals: MonthlyValues,
): Row[] {
  return [
    {
      rowId: `${title}Header`,
      height: HEADING_ROW_HEIGHT,
      cells: [
        bottomLine(
          nonEditable(
            textCell(
              title,
              `align-items-end text-md font-bold text-${
                  title === "Dönen Varlıklar"  ? "green" : title === "Duran Varlıklar" ? "green" : "darkblue"
              }`
            )
          )
        ),
        ...months().map((_) =>
          noSideBorders(bottomLine(nonEditable(emptyTextCell)))
        ),
      ]
    },
    ...groups.map(({ title, values }) => ({
      rowId: title,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell(title, "padding-left-lg")),
        ...values.map((_, idx) => nonEditable(showZero(numberCell(values[idx])))),
      ]
    })),
    {
      rowId: `${title}Summary`,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell(summaryTitle, `padding-left-lg font-bold text-${
                  title === "Dönen Varlıklar" ? "green" : title === "Duran Varlıklar" ? "green" : "darkblue"
                }`)),
        ...months().map((_, idx) =>
          nonEditable(
            showZero(
              numberCell(
                monthlyGroupTotals[idx],
                `font-bold disabled text-${
                  title === "Dönen Varlıklar"  ? "green" : title === "Duran Varlıklar" ? "green" : "darkblue"
                }`
              )
            )
          )
        ),
      ]
    }
  ];
}


export function getRows({
  liquidasset,
  monthlyLiquidAssetTotals,
  fixedasset,
  monthlyFixedAssetTotals,
  monthlyInflowOuflowDiffs,
  monthlyAssetsSum,
  shorttermloan,
  monthlyShortTermLoanTotals,
  longtermloan,
  monthlyLongTermLoanTotals,
  monthlyLiabilitySum,
  equity,
  monthlyEquitySum,
  monthlyLiabilityEquitySum,
  labels
}: InputVariables & OutputVariables): Row[] {
  return [
   getHeaderRow(labels),
    ...getGroupRows(
      "Dönen Varlıklar",
      "Toplam Dönen Varlıklar",
      liquidasset,
      monthlyLiquidAssetTotals,
    ),
    ...getGroupRows(
      "Duran Varlıklar",
      "Toplam Duran Varlıklar",
      fixedasset,
      monthlyFixedAssetTotals,
    ),
    getMonthsAssetSumRow(
      "Toplam Varlıklar",
      monthlyAssetsSum,
    ),
    ...getGroupRows(
      "Kısa Vadeli Borçlar",
      "Toplam Kısa Vadeli Borçlar",
      shorttermloan,
      monthlyShortTermLoanTotals,
    ),
    ...getGroupRows(
      "Uzun Vadeli Borçlar",
      "Toplam Uzun Vadeli Borçlar",
      longtermloan,
      monthlyLongTermLoanTotals,
    ),
   getMonthsLiabilitySumRow(
      "Toplam Yabancı Kaynaklar",
      monthlyLiabilitySum,
    ),
    ...getGroupRows(
      "Özkaynaklar",
      "Toplam Özkaynaklar",
      equity,
      monthlyEquitySum,
    ),
    getMonthsLiabilityEquitySumRow(
      "Toplam Kaynaklar",
      monthlyLiabilityEquitySum,
    ),
  ];
}
