import {
  CashInflow,
  CashOutflow,
  OPEX,
  CashOtherInflow,
  CashOtherOutflow,
  FinancialExpense,
  OutputVariables,
  InputVariables,
  MonthlyValues
} from "layouts/realsector/dcf/components/interfaces";
import { months } from "layouts/realsector/dcf/components/helpers";


const sumGroupInAMonth = (group: (CashInflow | CashOutflow | OPEX | CashOtherInflow | CashOtherOutflow | FinancialExpense)[]) => (
  monthIndex: number
): number =>
  group.reduce(
    (acc, { values }) =>
      acc + (isNaN(values[monthIndex]) ? 0 : values[monthIndex]),
    0
  );

const calcMonthlyGroupTotals = (
  group: (CashInflow | CashOutflow | OPEX | CashOtherInflow | CashOtherOutflow | FinancialExpense)[]
): MonthlyValues => months().map((_, idx) => sumGroupInAMonth(group)(idx));

// DCF'e eklenecek.
export function calculateOutputVariables(
  inputVariables: InputVariables
): OutputVariables {
  const {
    cashInflow,
    cashOutflow,
    opex,
    cashOtherInflow,
    cashOtherOutflow,
    financialExpense,
  } = inputVariables;

  const monthlyInflowTotals = calcMonthlyGroupTotals(cashInflow);
  const monthlyOutflowTotals = calcMonthlyGroupTotals(cashOutflow);
  const monthlyOpexTotals = calcMonthlyGroupTotals(opex);
  const monthlyOtherInflowTotals = calcMonthlyGroupTotals(cashOtherInflow);
  const monthlyOtherOutflowTotals = calcMonthlyGroupTotals(cashOtherOutflow);
  const monthlyFinancialExpenseTotals = calcMonthlyGroupTotals(financialExpense);

  const yearlyInflowTotal = monthlyInflowTotals.reduce((a, b) => a + b);
  const yearlyOutflowTotal = monthlyOutflowTotals.reduce((a, b) => a + b);
  const yearlyOpexTotal = monthlyOpexTotals.reduce((a, b) => a + b);
  const yearlyOtherInflowTotal = monthlyOtherInflowTotals.reduce((a, b) => a + b);
  const yearlyOtherOutflowTotal = monthlyOtherOutflowTotals.reduce((a, b) => a + b);
  const yearlyFinancialExpenseTotal = monthlyFinancialExpenseTotals.reduce((a, b) => a + b);

  const yearlyInflowOuflowDiff = yearlyInflowTotal - yearlyOutflowTotal;
  const yearlyInflowOuflowOpexDiffs= yearlyInflowOuflowDiff - yearlyOpexTotal;

  const monthlyInflowOuflowDiffs = months().map(
    (_, idx) => monthlyInflowTotals[idx] - monthlyOutflowTotals[idx]
  );

    const monthlyInflowOuflowOpexDiffs = months().map(
    (_, idx) => monthlyInflowTotals[idx] - monthlyOutflowTotals[idx] - monthlyOpexTotals[idx]
  );


  return {
    monthlyInflowTotals,
    yearlyInflowTotal,
    monthlyOutflowTotals,
    yearlyOutflowTotal,
    monthlyInflowOuflowDiffs,
    yearlyInflowOuflowDiff,
    monthlyOpexTotals,
    yearlyOpexTotal,
    monthlyInflowOuflowOpexDiffs,
    yearlyInflowOuflowOpexDiffs,
    monthlyOtherInflowTotals,
    yearlyOtherInflowTotal,
    monthlyOtherOutflowTotals,
    yearlyOtherOutflowTotal,
    monthlyFinancialExpenseTotals,
    yearlyFinancialExpenseTotal
  };
}
