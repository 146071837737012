import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// prop-types is a library for type checking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import DayCard from "examples/Cards/DayCard";

import Graphs from "layouts/financialsector/banking/loans/components/loanInfo";

//Charts
import DefaultAmDoughnutChart from "examples/Charts/AmChartsDoughnut/AmDoughnutChartTourism";
import DefaultHighColumnChart from "examples/Charts/HigChartsColumn/HighColumnChartTourism";

// Data
import amChartsDoughnutData from "layouts/realsector/sectors/tourism/data/amChartsDoughnutData";
import marketDataforHighCharts from "layouts/realsector/sectors/tourism/data/marketDataforHighCharts";

import {getChartData,configs} from "layouts/realsector/sectors/tourism/configs";

// Images
import bank from "assets/images/small-logos/creditcard.png";

import PieChart from "examples/Charts/PieChart";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";


// Data
import channelChartData from "layouts/realsector/sectors/tourism/data/channelsChartData";
import horizontalBarChartData from "layouts/realsector/sectors/tourism/data/horizontalBarChartData";

function LoanTemplate() {
  var chardata = getChartData(channelChartData.datasets, channelChartData.labels);
  var configsdata = configs(channelChartData.datasets, channelChartData.labels);

  return (
    <Grid container spacing={3}>
            <Grid item xs={12} xl={7}>
              <VerticalBarChart title="Ülkelere Göre" chart={horizontalBarChartData}  height="22rem"/>

            </Grid>
            <Grid item xs={12} xl={5}>
               <Card sx={{ mt: 0 }} >
                <SoftBox
                     alignItems="center"
                     position="relative"
                     top={0}
                     left={0}
                    width="calc(100% - 0rem)"
                 >
                     <PieChart title="Ülke Gruplarına Göre" chart={chardata} height="22rem" chartoptions={configsdata}/>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
  );
}

// Typechecking props for the Template
LoanTemplate.propTypes = {
};

export default LoanTemplate;
