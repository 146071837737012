
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";


// Soft UI Dashboard PRO React example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Soft UI Dashboard PRO React icons
import { MdOutlineCalendarMonth } from "react-icons/md";
import { BsCalendar2MonthFill } from "react-icons/bs";
import { BsCalendar2DayFill } from "react-icons/bs";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

//Graphs

//Data

// Soft UI Dashboard PRO React example components
import RezervGraph from "layouts/financialsector/tcmb/reserves/components/RezervGraph";

// Data
import reservedata from "layouts/financialsector/tcmb/reserves/components/data/reservedata";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



function RezervTemplate() {
//const titlename=stock.stock + " Nakit Akımları (1000 TL)"

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);


  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

const handleSetTabValue = (event, newValue) => setTabValue(newValue);

const params="weekly"
const params2="monthly"

//const data=reservedata(params)
//const data2=reservedata(params2)
//const data3=reservedata(params3)

  return (

    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
            <Item>
            <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ backgroundColor: "transparent" }}
              >
                <Tab label="Haftalık" icon={<BsCalendar2MonthFill />} />
                <Tab label="Aylık" icon={<MdOutlineCalendarMonth />} />
              </Tabs>
            </AppBar>
          </Grid>

      <SoftBox p={1} mt={0} width="100%" height="32.5rem">
        <RezervGraph data={reservedata(params)}  value={tabValue} index={0}  />
        <RezervGraph data={reservedata(params2)}  value={tabValue} index={1}  />

      </SoftBox>
             </Item>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
     <Footer />
    </DashboardLayout>

  );
}
export default RezervTemplate;
