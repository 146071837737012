import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Paper from '@mui/material/Paper';
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  //[`&.${tableCellClasses.head}`]: {
    //backgroundColor: theme.palette.common.white,
    //color: theme.palette.common.white
 // },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    height: "5px",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    height: 1
  }
}));


function BasicTable({data}) {
  const resultdata=[];
  for (let [key, value] of Object.entries(data)) {
      resultdata.push(value);
  }
  let merged = resultdata[0].map((item, idx) => {
      return { "label": item, "value": resultdata[1][idx] }
  })

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 50 }} size="small" aria-label="a dense table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Year</StyledTableCell>
            <StyledTableCell align="right">Data</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {merged.map((row) => (
            <StyledTableRow
              key={row.label}
              >
              <StyledTableCell component="th" scope="row">
                {row.label}
              </StyledTableCell>
              <StyledTableCell align="right">{row.value}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default BasicTable;
