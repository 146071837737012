/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Fade from "@mui/material/Fade";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

import BarCharts from "examples/Charts/BarCharts/DefaultBarCharts";
import { getChartData, chartOptions } from  "layouts/dashboards/default/components/data/gdpoptions";

function GDP({ data,value,index }) {
  const { size } = typography;
  var chardata = getChartData(data.datasets, data.labels);

    return (
     value === index && (
      <Fade in timeout={850}>
        <SoftBox width="100%" height="100%" position="relative" borderRadius="lg">
         <BarCharts
                                    title=""
                                    description= ""
                                    chart={chardata}
                                    height={300}
                                    chartoptions={chartOptions}
                                    width={550}
                                />
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="absolute"
            top={0}
            left={0}
            width="calc(100% - 1rem)"
          >
            <SoftBox p={1}>
            </SoftBox>
            <SoftBadge
              color="secondary"
              variant="contained"
              size="lg"
            />
          </SoftBox>
        </SoftBox>
      </Fade>)
  );

}

// Typechecking props for the CreditGraphs
GDP.propTypes = {
  data: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default GDP;
