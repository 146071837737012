/**
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts
import Default from "layouts/dashboards/default";
import Loans from "layouts/financialsector/banking/loans";
import Deposits from "layouts/financialsector/banking/deposits";
import OffBalanceSheet from "layouts/financialsector/banking/offbs";
import CreditCard from "layouts/financialsector/banking/creditcard";
import Funding from "layouts/financialsector/tcmb/funding";
import Analytic from "layouts/financialsector/tcmb/analytic";
import Reserves from "layouts/financialsector/tcmb/reserves";
import Expectations from "layouts/financialsector/tcmb/expectations";
import Topics from "layouts/financialsector/tcmb/topics";
//import FundAnalysis from "layouts/financialsector/fundanalysis";
import FundsInfo from "layouts/financialsector/fundsinfo";

import BalanceSheet from "layouts/financialsector/banking/balancesheet";
import EconGPT from "layouts/econgpt";
import Firms from "layouts/realsector/firms";
import DCF from "layouts/realsector/dcf";
import Tourism from "layouts/realsector/sectors/tourism";
import Budget from "layouts/government/budget";
import Summary from "layouts/blog/financialflows";

import TradeBalance from "layouts/nationalaccount/tradebalance";
import CurrentAccountBalance from "layouts/nationalaccount/currentaccount";

import Data from "layouts/data";

import SignInBasic from "layouts/authentication/sign-in/basic";
//import SignInCover from "layouts/authentication/sign-in/cover";
//import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpBasic from "layouts/authentication/sign-up/basic";
//import SignUpCover from "layouts/authentication/sign-up/cover";
//import SignUpIllustration from "layouts/authentication/sign-up/illustration";
import SignOut from "layouts/authentication/sign-out";
//import ResetBasic from "layouts/authentication/reset-password/basic";
//import ResetCover from "layouts/authentication/reset-password/cover";
//import ResetIllustration from "layouts/authentication/reset-password/illustration";
//import LockBasic from "layouts/authentication/lock/basic";
//import LockCover from "layouts/authentication/lock/cover";
//import LockIllustration from "layouts/authentication/lock/illustration";
//import VerificationBasic from "layouts/authentication/2-step-verification/basic";
//import VerificationCover from "layouts/authentication/2-step-verification/cover";
//import VerificationIllustration from "layouts/authentication/2-step-verification/illustration";
//import Error404 from "layouts/authentication/error/404";
//import Error500 from "layouts/authentication/error/500";

// Soft UI Dashboard PRO React icons
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCardT from "examples/Icons/CreditCard";
import { FcMoneyTransfer } from "react-icons/fc";
import { GiFactory } from "react-icons/gi";
import { ImOffice } from "react-icons/im";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { RiGovernmentFill } from "react-icons/ri";
import { GiArtificialIntelligence } from "react-icons/gi";
import { MdOutlineDataThresholding } from "react-icons/md";
import { SiUnitednations } from "react-icons/si";

const routes = [
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <MdOutlineDashboardCustomize size="20px" />,
     collapse: [
      {
        name: "Makro",
        key: "default",
        route: "/dashboards/default",
        component: <Default />,
        protected: true,
      },
            {
        name: "Finansal Hareketler",
        key: "financialflows",
        route: "/blog/financialflows",
        component: <Summary />,
        protected: true,
      },
    ],
  },
  { type: "title", title: "Veriler", key: "title-pages" },
  {
    type: "collapse",
    name: "Finans Sektörü",
    key: "financialsector",
    icon: <ImOffice size="22px" />,
    collapse: [
      {
        name: "Bankacılık",
        key: "banking",
        collapse: [
          {
            name: "Krediler",
            key: "loans",
            route: "/financialsector/banking/loans",
            component: <Loans />,
            protected: true,
          },
          {
            name: "Mevduatlar",
            key: "deposits",
            route: "/financialsector/banking/deposits",
            component: <Deposits />,
            protected: true,
          },
          {
            name: "Bilanço",
            key: "balancesheet",
            route: "/financialsector/banking/balancesheet",
            component: <BalanceSheet />,
            protected: true,
          },
          {
            name: "Bilanço Dışı ",
            key: "offbalancesheet",
            route: "/financialsector/banking/offbs",
            component: <OffBalanceSheet />,
            protected: true,
          },
          {
            name: "Kredi Kartları",
            key: "creditcard",
            route: "/financialsector/banking/creditcard",
            component: <CreditCard />,
            protected: true,
          },
        ],
      },
      {
        name: "TCMB",
        key: "tcmb",
        collapse: [
         {
            name: "Fonlama",
            key: "funding",
            route: "/financialsector/tcmb/funding",
            component: <Funding />,
            protected: true,
          },
         {
            name: "Analitik Bilanço",
            key: "analytic",
            route: "/financialsector/tcmb/analytic",
            component: <Analytic />,
            protected: true,
          },
         {
            name: "Rezervler",
            key: "reserves",
            route: "/financialsector/tcmb/reserves",
            component: <Reserves />,
            protected: true,
          },
         {
            name: "Beklentiler",
            key: "expectations",
            route: "/financialsector/tcmb/expectations",
            component: <Expectations />,
            protected: true,
          },
         {
            name: "Konu Analizi",
            key: "topics",
            route: "/financialsector/tcmb/topics",
            component: <Topics />,
            protected: true,
          },
        ],
      },
      {
        name: "Fonlar",
        key: "fonlar",
        collapse: [
      {
        name: "Fon Bilgileri",
        key: "fundsinfo",
        route: "/financialsector/fundsinfo",
        component: <FundsInfo />,
        protected: true,
      },
      /*{
        name: "FundAnalysis",
        key: "fundanalysis",
        route: "/financialsector/fundanalysis",
        component: <FundAnalysis />,
        protected: true,
      },*/
       ],
     },
    ],
  },
  {
    type: "collapse",
    name: "Reel Sektör",
    key: "realsector",
    icon: <GiFactory size="22px" />,
    collapse: [
      {
        name: "Şirketler",
        key: "firms",
        route: "/realsector/firms",
        component: <Firms />,
        protected: true,
      },
       {
       type: "collapse",
       name: "Sektörler",
       key: "sectors",
       icon: <GiFactory size="22px" />,
       collapse: [
      {
        name: "Turizm",
        key: "tourism",
        route: "/realsector/tourism",
        component: <Tourism />,
        protected: true,
      }],}

     /* {
        name: "DCF",
        key: "dcf",
        route: "/realsector/dcf",
        component: <DCF />,
        protected: true,
      },*/
    ],
  },
  {
    type: "collapse",
    name: "Kamu Hesapları",
    key: "government",
    icon: <RiGovernmentFill size="22px" />,
    collapse: [
          {
        name: "Merkezi Yönetim Bütçesi",
        key: "budget",
        route: "/government/budget",
        component: <Budget />,
        protected: true,
      },
    ],
  },
    {
    type: "collapse",
    name: "Ulusal Hesaplar",
    key: "nationalaccount",
    icon: <SiUnitednations size="22px" />,
    collapse: [
      {
        name: "Ödemeler Dengesi",
        key: "currentaccount",
        route: "/nationalaccount/currentaccount",
        component: <CurrentAccountBalance />,
        protected: true,
      },
          {
        name: "Dış Ticaret",
        key: "tradebalance",
        route: "/nationalaccount/tradebalance",
        component: <TradeBalance />,
        protected: true,
      },
       /*{
        name: "Ödemeler Dengesi",
        key: "currentaccount",
        route: "",
        component: <Budget />,
        protected: true,
      },*/
    ],
  },
    {
    type: "collapse",
    name: "Veriler",
    key: "data",
    route: "/data",
    component: <Data />,
    protected: true,
    icon: <MdOutlineDataThresholding size="26px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "EconTalks",
    key: "econgpt",
    route: "/econgpt",
    component: <EconGPT />,
    protected: true,
    icon: <GiArtificialIntelligence size="26px" />,
    noCollapse: true,
  },
 /* {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Document size="12px" />,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
      {
        name: "Sign Up",
        key: "sign-up",
        route: "/authentication/sign-up/basic",
        component: <SignUpBasic />,
      },
      {
        name: "Sign Out",
        key: "sign-out",
        route: "/authentication/sign-out",
        component: <SignOut />,
      },
    ],
  },*/
  { type: "divider", key: "divider-1" },
];

export default routes;
