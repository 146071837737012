/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// am4charts components
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// GradientLineChart configurations
import configs from "examples/Charts/AmChartsLine/DefaultAmLineChart/configs";

// Soft UI Dashboard React base styles
//import colors from "assets/theme/base/colors";

function DefaultAmLineChart({ title, description, height, chart,check }) {
  //const chartRef = useRef(null);
  const [chartData, setChartData] = useState({});
  //const { options } = chartData;
  useEffect(() => {
    let chartRef = am4core.create("chartdiv", am4charts.XYChart);
    //chartRef.current = charttemp;
    chart.then((response) => {
      const chartDatasets = response.datasets.map((dataset) => ({
        ...dataset,
      }));
      setChartData(configs(chartRef, chartDatasets,check));
    });
  }, [chart]);
  const renderChart = (
    <SoftBox p={2}>
      {title || description ? (
        <SoftBox px={description ? 1 : 0} pt={description ? 1 : 0}>
          {title && (
            <SoftBox mb={1}>
              <SoftTypography variant="h6">{title}</SoftTypography>
            </SoftBox>
          )}
          <SoftBox mb={2}>
            <SoftTypography variant="button" fontWeight="regular" textcolor="text">
              {description}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ) : null}
      {useMemo(
        () => (
          <div id="chartdiv" style={{ height }}></div>
        ),
        [chartData, height]
      )}
    </SoftBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of GradientLineChart
DefaultAmLineChart.defaultProps = {
  title: "",
  description: "",
  height: "19.125rem",
};

// Typechecking props for the GradientLineChart
DefaultAmLineChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default DefaultAmLineChart;
