
export const chartOptions: ChartOptions =  {
    interaction:
    {mode:'index'},
  elements: {
    line: {
      tension: 0.1
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
  legend: {display: false},
  filler: { propagate: true},
  tooltip: {
                usePointStyle: true,
                callbacks: {
                    labelPointStyle: function(context) {
                        return {
                            pointStyle: 'circle',
                            rotation: 0
                        };
                    }
                },
                filter: (tooltipItem) => tooltipItem.raw > 0,
            }
  },
  scales: {
        xAxes: {
            axis:"x",
            type: "category",
            offset: true,
            grid: {
                offset: true,
                display: true,
                lineWidth: 1,
                drawBorder: true,
                drawOnChartArea: true,
                drawTicks: true,
                tickLength: 8,
                borderDash: [],
                borderDashOffset: 0,
                borderWidth: 1,
                color: "rgba(0,0,0,0.1)",
                borderColor: "rgba(0,0,0,0.1)"
            },
            ticks: {
                minRotation: 0,
                maxRotation: 50,
                mirror: false,
                textStrokeWidth: 0,
                textStrokeColor:"" ,
                padding: 3,
                display: true,
                autoSkip: true,
                autoSkipPadding: 3,
                labelOffset: 0,
                minor: {},
                major: {},
                align: "center",
                crossAlign: "near",
                showLabelBackdrop: false,
                backdropColor: "rgba(255, 255, 255, 0.75)",
                backdropPadding: 2,
                color: "#666"
            },
            display: true,
            reverse: false,
            beginAtZero: false,
            bounds: "ticks",
            grace: 0,
            title: {
                display: false,
                text:"" ,
                padding: {
                    top: 4,
                    bottom: 4
                },
                color: "#666"
            },
            id: "xAxes",
            position: "bottom"
        },
        yAxes: {
            axis: "y",
            type: "linear",
            beginAtZero: true,
            ticks: {
                minRotation: 0,
                maxRotation: 50,
                mirror: false,
                textStrokeWidth: 0,
                textStrokeColor: "",
                padding: 3,
                display: true,
                autoSkip: true,
                autoSkipPadding: 3,
                labelOffset: 0,
                minor: {},
                major: {},
                align: "center",
                crossAlign: "near",
                showLabelBackdrop: false,
                backdropColor: "rgba(255, 255, 255, 0.75)",
                backdropPadding: 2,
                color: "#666"
            },
            display: true,
            offset: false,
            reverse: false,
            bounds: "ticks",
            grace: 0,
            grid: {
                display: true,
                lineWidth: 1,
                drawBorder: true,
                drawOnChartArea: true,
                drawTicks: true,
                tickLength: 8,
                offset: false,
                borderDash: [],
                borderDashOffset: 0,
                borderWidth: 1,
                color: "rgba(0,0,0,0.1)",
                borderColor: "rgba(0,0,0,0.1)"
            },
            title: {
                display: false,
                text: "",
                padding: {
                    top: 4,
                    bottom: 4
                },
                color: "#666"
            },
            id: "yAxes",
            position: "left"
        }
    },
  };