import { ChartOptions, ChartData } from "chart.js";

export const getChartData = (
  data1,data2,labels
): ChartData => ({
  labels: labels,
  datasets: [
     {
      label: "Aylık Ortalama Maliyet",
      borderColor:  "#280659",
      backgroundColor: "rgba(40,6,89,0.3)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: data1,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
    },
      {
      label: "Birikimli Maliyet",
      borderColor:  "#900c3e",
      backgroundColor: "rgba(144,12,62,0.5)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: data2,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
    },
  ]
});

export const chartOptions: ChartOptions =  {
    interaction:
    {mode:'index'},
  elements: {
    line: {
      tension: 0.1
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
  legend: {display: true,
  position:'bottom'},
    filler: {
      propagate: true
    }
  },
  scales: {
    xAxes: [
      {
        display: true,
        ticks: {
          autoSkip: false,
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        }
      }
    ]
  }
  };