/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import get_trades_group_data from "layouts/api";

function create_dataset(data,label){
var colors= ["color1","color2","color3","color4"]
const labels=Object.keys(data)
const tmpdata = Object.keys(data).map(key => (data[key][0]/1000).toFixed(0));

const datasets={
  labels: labels,
  datasets: {
    label: label ,
    backgroundColors: colors,
    data: tmpdata,
  },
};

 return datasets
}



function TradesGroup(params) {

   const defaultData= {monthlyexport:{labels: ["Yatırım malları", "Ara Mallar", "Tüketim malları", "Diğerleri"],
                       datasets: { label: "monthlyexport",
                       backgroundColors: ["color1","color2", "color3","color4", "info", "success", "warning"],
                       data: [2612.2, 9928.9, 7337.3, 112.9]}},
                       yearlyexport:{labels: ["Yatırım malları", "Ara Mallar", "Tüketim malları", "Diğerleri"],
                       datasets: { label: "yearlyexport",
                       backgroundColors: ["color1","color2", "color3","color4", "info", "success", "warning"],
                       data: [2612.2, 9928.9, 7337.3, 112.9]}},
                       monthlyimport:{labels: ["Yatırım malları", "Ara Mallar", "Tüketim malları", "Diğerleri"],
                       datasets: { label: "monthlyimport",
                       backgroundColors: ["color1","color2", "color3","color4", "info", "success", "warning"],
                       data: [2612.2, 9928.9, 7337.3, 112.9]}},
                       yearlyimport:{labels: ["Yatırım malları", "Ara Mallar", "Tüketim malları", "Diğerleri"],
                       datasets: { label: "yearlyimport",
                       backgroundColors: ["color1","color2", "color3","color4", "info", "success", "warning"],
                       data: [2612.2, 9928.9, 7337.3, 112.9]}}

                       };
    const [data, setData] = useState(defaultData);

    //const dataFetchedRef = useRef(false);
    const fetchData = async () => {
        const config={"func":"trades_group_data","params":params}
        const response = await get_trades_group_data(config);
        const tmpdata = await response.data;
        const monthlyexport=create_dataset(tmpdata.monthlyexport,"monthlyexport")
        const yearlyexport=create_dataset(tmpdata.yearlyexport,"yearlyexport")
        const monthlyimport=create_dataset(tmpdata.monthlyimport,"monthlyimport")
        const yearlyimport=create_dataset(tmpdata.yearlyimport,"yearlyimport")
        //const date = transposedata.date;
	    setData({monthlyexport:monthlyexport,yearlyexport:yearlyexport,monthlyimport:monthlyimport,yearlyimport:yearlyimport});
    }

    useEffect(() => {
            fetchData();
    },[]);
  return data
}

export default TradesGroup;
