/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
//import typography from "assets/theme/base/typography";

function configs(datasets,nodes) {
  const data=datasets
  const tmpnodes=nodes
  return {
    options: {
    chart: {
        type: 'sankey',
        height: '45%'
    },
    title: {
        text: ''
    },
     accessibility:{
        point: {
            valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.'
        }
    },
    tooltip: {
        headerFormat: null,
        pointFormat:
      '<span style="font-weight:bold;font-size:18px">'+ '{point.fromNode.name} \u2192 {point.toNode.name}: {point.weight}'+'</span>',
        nodeFormat: '{point.name}: {point.sum:.2f}'
    },
      credits: {
        enabled: false,
      },
    series: [{
        name: '',
        data:  data,
        nodes: tmpnodes,
        dataLabels: {
        allowOverlap: false,
        padding: 0,
        formatter: function() {
        //console.log(this.point)
          return  this.point.weight.toLocaleString('en-DE')
        },
        nodeFormatter: function() {
          return this.key
        },
        style: {
          fontSize: "16px",
          fontWeight: "500",
          color:"black",
          //fill: "rgb(0, 0, 0)",
          //stroke: "rgb(0, 0, 0)",
          //stroke_width: 0,
          //stroke_linejoin: "round",
          textOutline: 'transparent'
        }
      }
    }]
    },
  };
}

export default configs;
