/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
//import Footer from "examples/Footer";


// banking dashboard components
import DCF from "layouts/realsector/dcf/components/DCF";


function FirmDCF() {

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <SoftBox pt={0}>
          <SoftBox mb={0} mt={0}>
   <div className="dcf-app">
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start"
      }}
    >
        <SoftBox p={1} mt={0} width="100%" height="25.5rem">
        <DCF/>
      </SoftBox>
    </div>
    </div>
     </SoftBox>
        </SoftBox>

    </DashboardLayout>
  );
}

export default FirmDCF;
