/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useRef, useEffect, useState, useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Overview page components
import DefaultCell from "layouts/financialsector/tcmb/funding/components/DefaultCell";
import TotalCell from "layouts/financialsector/tcmb/funding/components/TotalCell";

//Data

import dataTableData from "layouts/financialsector/tcmb/funding/components/FundingTable/table";
import DataTable from "layouts/financialsector/tcmb/funding/components/DataTable";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.color2,
}));


function Tables() {
return (
 <SoftBox>
            <Card>
            <Item>
               <SoftTypography variant="h6" color="color2"  fontWeight="bold">Günlük İşlemler</SoftTypography>
                   <DataTable table={dataTableData("daily")} />
            </Item>
          </Card>
          <SoftBox my={6} width="100%">
            <Divider sx={{height: 4}}/>
        </SoftBox>
         <Card>
            <Item>
               <SoftTypography variant="h6" color="color2"  fontWeight="bold">Fonlama Stok</SoftTypography>
          <DataTable table={dataTableData("stok")} />
            </Item>
          </Card>
          </SoftBox>
          )
}

// Setting default values for the props of DataTable
Tables.defaultProps = {
  noEndBorder: false,
};

// Typechecking props for the DataTable
// Tables.propTypes = {
//   table: PropTypes.objectOf(PropTypes.array).isRequired,
// };

export default Tables;
