/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
import get_payroll_data from "layouts/api";
import get_payroll_data_history from "layouts/api";


function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}


function getField(array, field) {
       return array.find((e) => e.title == field);
    }
function getSingleColumn(data, field) {
      for (let [key, value] of Object.entries(data)) {
      if (key == field) {
          return value
	    }}
    }

function payrollData(params) {
    const defaultData= {   chart: {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: { label: "Sales", data: [450, 200, 100, 220, 500, 100, 400, 230, 500] },
  },
  items: [{icon: {color: 'primary', component: 'library_books'},
  label: 'İşsiz',
  progress: {content: '8.21M', percentage: 94.0}},
 {icon: {color: 'info', component: 'touch_app'},
  label: 'İşgücü Katılım',
  progress: {content: '%53.9', percentage: 54.0}},
 {icon: {color: 'warning', component: 'payment'},
  label: 'İstihdam',
  progress: {content: '%49.0', percentage: 49.0}},
 {icon: {color: 'error', component: 'extension'},
  label: 'İşsizlik',
  progress: {content: '%9.1', percentage: 44.0}}]
    }
    //const [chartData, setChartData] = useState(null);
    const [data, setData] = useState(defaultData);
    //const dataFetchedRef = useRef(false);
    const fetchData = async () => {
        const config={"func":"payroll","params":"params"};
        const response = await get_payroll_data(config);
        const tmpdata = await response.data;

        const config1={"func":"payrollhistory","params":"params"};
        const response1 = await get_payroll_data_history(config1);
        const tmpdata1 = await response1.data;
        const items = tmpdata;
        //const working_capital_ratios = convertdata( tmpdata["Working Capital Ratios"],"");
        //const summary_page_ratios = convertdata( tmpdata["Summary Page Ratios"],"summary");

        //const date = transposedata.date;
	    setData({chart:tmpdata1,items:items});
    }

    useEffect(() => {
            fetchData();
    },[params]);



//const [chartData, setChartData] = useState(defaultData);
//useEffect(() => {
//    data.then((response) => {
//      //const chartDatasets = response.datasets.map((dataset) => ({
//      //  ...dataset,
//      //}));
//      setChartData(response.datasets);
//    });
//});
  return data
}

export default payrollData;

