/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
import get_cashflow_data from "layouts/api";

function cashflowData(params) {

const defaultData=  {datasets:[ {
  category: "Net revenue",
  value: 8786,
  open: 0,
  stepValue: 8786,
  displayValue: 8786
} ],
                     group:[   {"group": "İşletme Faal. Kay. Nakit Akışı",
                    "first":"Dönem Karı (Zararı)",
                    "last":"Diğer İşletme Faaliyeti Nakit Akışı"}]};


    const [data, setData] = useState(defaultData);

const fetchData = async () => {
    const config={"func":"cashflow","params":params}
    const result = await get_cashflow_data(config)
    const tmpdata = await result.data;

    var group=[   {"group": "İşletme Faal. Kay. Nakit Akışı",
                    "first":"Dönem Karı (Zararı)",
                    "last":"Diğer İşletme Faaliyeti Nakit Akışı"},
                    {"group": "Yatırım Faal. Kay. Nakit Akışı",
                    "first":"Sabit Sermaye Yatırımı",
                    "last":"Diğer Yatırım Faaliyeti Nakit Akışı"},
                    {"group": "Finansman Faal. Kay. Nakit Akışı",
                     "first":"Finansal Borçlardaki Değişim",
                     "last":"Diğer Finansal Faaliyetlerden Nakit Akışı"},
                    {"group":"",
                    "first":"Yabancı Para Çevrim Farklarının Etkisi",
                    "last":"Yabancı Para Çevrim Farklarının Etkisi"
                    },
                    {"group":"",
                    "first":"Dönem Sonu Nakit",
                    "last":"Dönem Sonu Nakit"}]

                    setData({datasets:tmpdata,group:group})

                    }

     useEffect(() => {
            fetchData();
    },[params]);
    //return {
    //  datasets: data,
    //  group:group
    //};

  return data;
}

export default cashflowData;