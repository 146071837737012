import {
  Revenue_TIPCG,
  Revenue_TONP,
  Revenue_TONGS,
  Revenue_TONITT,
  Revenue_STD,
  Revenue_Fee,
  Revenue_TONNC,
  Revenue_PI,
  Revenue_GASR,
  Revenue_ISF,
  Revenue_CR,
  Revenue_RC,
  Revenue_RfSBI,
  Revenue_RfRSI,
  Expense_InterestPayment,
  Exc_Expense_InterestPayment,
  Finance_Budget_Escrow,
  Finance_Retain,
  Finance_Debt,
  Finance_Loan,
  Finance_Privatization,
  Finance_Tmsf,
  Finance_Cash,
  MonthlyValues
} from "layouts/government/budget/components/BalanceSheet/components/interfaces";
import { months } from "layouts/government/budget/components/BalanceSheet/components/helpers";


const sumGroupInAMonth = (group: (Revenue_TIPCG | Revenue_TONP | Revenue_TONGS | Revenue_TONITT | Revenue_STD |Revenue_Fee|Revenue_TONNC)[]) => (
  monthIndex: number
): number =>
  group.reduce(
    (acc, { values }) =>
      acc + (isNaN(values[monthIndex]) ? 0 : values[monthIndex]),
    0
  );

const calcMonthlyGroupTotals = (
  group: (Revenue_TIPCG | Revenue_TONP | Revenue_TONGS | Revenue_TONITT| Revenue_STD |Revenue_Fee|Revenue_TONNC)[]
): MonthlyValues => months().map((_, idx) => sumGroupInAMonth(group)(idx));

// DCF'e eklenecek.
export function calculateOutputVariables(
  inputVariables: InputVariables
): OutputVariables {
  const {
    revenue_tipcg,
    revenue_tonp,
    revenue_tongs,
    revenue_tonitt,
    revenue_std,
    revenue_fee,
    revenue_tonnc,
    revenue_pi,
    revenue_gasr,
    revenue_isf,
    revenue_cr,
    revenue_rc,
    revenue_rfsbi,
    revenue_rfrsi,
    expense_interestpayment,
    exc_expense_interestpayment,
    finance_budget_escrow,
    finance_retain,
    finance_debt,
    finance_loan,
    finance_privatization,
    finance_tmsf,
    finance_cash,
  } = inputVariables;

  const monthlyRevenueTIPCGTotals = calcMonthlyGroupTotals(revenue_tipcg);
  const monthlyRevenueTONPTotals = calcMonthlyGroupTotals(revenue_tonp);
  const monthlyRevenueTONGSTotals = calcMonthlyGroupTotals(revenue_tongs);
  const monthlyRevenueTONITTTotals = calcMonthlyGroupTotals(revenue_tonitt);
  const monthlyRevenueSTDTotals = calcMonthlyGroupTotals(revenue_std);
  const monthlyRevenueFeeTotals = calcMonthlyGroupTotals(revenue_fee);
  const monthlyRevenueTONNCTotals = calcMonthlyGroupTotals(revenue_tonnc);
  const monthlyRevenuePITotals = calcMonthlyGroupTotals(revenue_pi);
  const monthlyRevenueGASRTotals = calcMonthlyGroupTotals(revenue_gasr);
  const monthlyRevenueISFTotals = calcMonthlyGroupTotals(revenue_isf);
  const monthlyRevenueCRTotals = calcMonthlyGroupTotals(revenue_cr);
  const monthlyRevenueRCTotals = calcMonthlyGroupTotals(revenue_rc);
  const monthlyRevenueRFSBITotals = calcMonthlyGroupTotals(revenue_rfsbi);
  const monthlyRevenueRFRSITotals = calcMonthlyGroupTotals(revenue_rfrsi);
  const monthlyExpenseIPTotals = calcMonthlyGroupTotals(expense_interestpayment);
  const monthlyExcExpenseIPTotals = calcMonthlyGroupTotals(exc_expense_interestpayment);
  const monthlyFinanceBudgetEscTotals = calcMonthlyGroupTotals(finance_budget_escrow);
  const monthlyFinanceRetainTotals = calcMonthlyGroupTotals(finance_retain);
  const monthlyFinanceDebtTotals = calcMonthlyGroupTotals(finance_debt);
  const monthlyFinanceLoanTotals = calcMonthlyGroupTotals(finance_loan);
  const monthlyFinancePrivTotals = calcMonthlyGroupTotals(finance_privatization);
  const monthlyFinanceTMSFTotals = calcMonthlyGroupTotals(finance_tmsf);
  const monthlyFinanceCashTotals = calcMonthlyGroupTotals(finance_cash);



  const monthlyDirectTaxTotals = months().map(
    (_, idx) => monthlyRevenueTIPCGTotals[idx] + monthlyRevenueTONPTotals[idx]
  );

  const monthlyInDirectTaxTotals = months().map(
    (_, idx) => monthlyRevenueTONGSTotals[idx] + monthlyRevenueTONITTTotals[idx]+ monthlyRevenueSTDTotals[idx]+ monthlyRevenueFeeTotals[idx]+ monthlyRevenueTONNCTotals[idx]
  );

  const monthlyTaxRevenueTotals = months().map(
    (_, idx) => monthlyDirectTaxTotals[idx] + monthlyInDirectTaxTotals[idx]
  );

  const monthlyOtherRevenueTotals = months().map(
    (_, idx) => monthlyRevenuePITotals[idx] + monthlyRevenueGASRTotals[idx] + monthlyRevenueISFTotals[idx] + monthlyRevenueCRTotals[idx] + monthlyRevenueRCTotals[idx]+ monthlyRevenueRFSBITotals[idx] + monthlyRevenueRFRSITotals[idx]
  );


  const monthlyRevenueTotals = months().map(
    (_, idx) => monthlyTaxRevenueTotals[idx] + monthlyRevenuePITotals[idx] + monthlyRevenueGASRTotals[idx] + monthlyRevenueISFTotals[idx] + monthlyRevenueCRTotals[idx] + monthlyRevenueRCTotals[idx]
  );

  const monthlyCentralGovRevenueTotals = months().map(
    (_, idx) => monthlyRevenueTotals[idx] + monthlyRevenueRFSBITotals[idx] + monthlyRevenueRFRSITotals[idx]
  );

  const monthlyCentralGovExpensesTotals = months().map(
    (_, idx) => monthlyExpenseIPTotals[idx] + monthlyExcExpenseIPTotals[idx]
  );

  const monthlyCentralGovBudgetBalanceTotals = months().map(
    (_, idx) => monthlyCentralGovRevenueTotals[idx] - monthlyCentralGovExpensesTotals[idx]
  );

    const monthlyCentralGovBudgetExcIPTotals = months().map(
    (_, idx) => monthlyCentralGovBudgetBalanceTotals[idx] + monthlyExpenseIPTotals[idx]
  );

  const monthlyCentralGovCashBalanceTotals = months().map(
    (_, idx) => monthlyCentralGovBudgetBalanceTotals[idx] + monthlyFinanceBudgetEscTotals[idx] + monthlyFinanceRetainTotals[idx]
  );

    const monthlyBudgetFinanceTotals = months().map(
    (_, idx) => monthlyFinanceDebtTotals[idx] - monthlyFinanceLoanTotals[idx] + monthlyFinancePrivTotals[idx] + monthlyFinanceTMSFTotals[idx] + monthlyFinanceCashTotals[idx]
  );

  return {
    monthlyRevenueTIPCGTotals,
    monthlyRevenueTONPTotals,
    monthlyDirectTaxTotals,
    monthlyRevenueTONGSTotals,
    monthlyRevenueTONITTTotals,
    monthlyRevenueSTDTotals,
    monthlyRevenueFeeTotals,
    monthlyRevenueTONNCTotals,
    monthlyInDirectTaxTotals,
    monthlyTaxRevenueTotals,
    monthlyRevenueTotals,
    monthlyOtherRevenueTotals,
    monthlyRevenueRFSBITotals,
    monthlyRevenueRFRSITotals,
    monthlyCentralGovRevenueTotals,
    monthlyExpenseIPTotals,
    monthlyExcExpenseIPTotals,
    monthlyCentralGovExpensesTotals,
    monthlyCentralGovBudgetBalanceTotals,
    monthlyCentralGovBudgetExcIPTotals,
    monthlyFinanceBudgetEscTotals,
    monthlyFinanceDebtTotals,
    monthlyFinanceLoanTotals,
    monthlyCentralGovCashBalanceTotals,
    monthlyBudgetFinanceTotals,
  };
}
