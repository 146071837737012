/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
import get_fund_content_info_data from "layouts/api";

var colors= ["#280659","#C13358","#F9F871","#003A89",
            "#FF6301","#008DBB","#810160","#00B5C3",
            "#1D7874","#EC714D", "#007C92", "#5BDAC6",
            "#C1648C","#9D6CA8", "#2B7BAA", "#6976B3",
             "#334B49", "#95B1AE", "#945B72", "#CB8EA6",
             "#334B49","#95B1AE", "#596B98", "#8E9ECE",
             "#EC714D", "#FEB44D","#FFA17A", "#003A89",
              "#0064AA", "#008DBB", ,"#0064AA"]

 var bgcolors= ["rgba(40,6,89,1)","rgba(236,113,77,1)", "rgba(249,248,113,1)","rgba(0,58,137,1)",
               "rgba(255,99,1,1)","rgba(0,141,187,1)","rgba(193,51,88,1)","rgba(0,181,195,1)",
                "rgba(29,120,116,1)","rgba(129,1,96,1)","rgba(0,124,146,1)","rgba(193,100,140,1)",
                "rgba(91,218,198,1)","rgba(157,108,168,1)","rgba(43,123,170,1)","rgba(105,118,179,1)",
               "rgba(51,75,73,1)","rgba(149,177,174,1)","rgba(148,91,114,1)","rgba(203,142,166,1)",
               "rgba(51,75,73,1)","rgba(149,177,174,1)","rgba(89,107,152,1)", "rgba(142,158,206,0.5)",
               "rgba(236,113,77,0.5)","rgba(254,180,77,0.5)","rgba(255,161,122,0.5)","rgba(0,58,137,0.5)",
               "rgba(0,100,170,0.5)","rgba(0,141,187,0.5)","rgba(0,100,170,1)"
]

function create_dataset(data){


var greencolors= ["#0E3C15","#1B4D2A","#285B3F","#357148","#438558","#519F6E","#5FBD83","#6CE68A",
                  "#7AFC90","#8AFF9A","#97F7A3","#A4F3AB","#B0E8B1","#BEE0B7","#CCDAC1","#D9D4CE",
                  "#E5CFC8","#F1C8C2","#F7C0B6",]

var greenbgcolors= ["rgb(10, 33, 13,0.8)","rgb(17, 45, 21,0.8)","rgb(23, 58, 29,0.8)","rgb(30, 71, 37,0.8)",
"rgb(38, 85, 45,0.8)","rgb(47, 99, 54,0.8)","rgb(56, 113, 62,0.8)","rgb(65, 127, 71,0.8)",
"rgb(74, 141, 79,0.8)","rgb(83, 155, 87,0.8)","rgb(92, 169, 94,0.8)","rgb(101, 182, 101,0.8)",
"rgb(110, 195, 107,0.8)","rgb(119, 206, 113,0.8)","rgb(128, 217, 118,0.8)","rgb(137, 226, 123,0.8)",
"rgb(146, 233, 127,0.8)","rgb(155, 238, 130,0.8)","rgb(164, 241, 132,0.8)","rgb(173, 242, 133,0.8)",
"rgb(182, 239, 133,0.8)","rgb(189, 232, 131,0.8)","rgb(196, 223, 127,0.8)","rgb(203, 210, 122,0.8)",
"rgb(210, 195, 114,0.8)"]

var redcolors= ["#a70000","#b00000","#b90000","#c20000","#cb0000","#d40000","#dd0000","#e60000",
                "#ef0000","#f80000","#ff1414","#ff4040","#ff6666","#ff8080","#ff9999","#ffb3b3",
                "#ffcccc","#ffded9","#ffebeb","#f5f5f5","#e0e0e0","#cccccc","#b3b3b3","#999999",
                "#666666"]
var redbgcolors= ["rgb(167, 0, 0,0.8)","rgb (176, 0, 0,0.8)","rgb(185, 0, 0,0.8)","rgb(194, 0, 0,0.8)",
"rgb(203, 0, 0,0.8)","rgb(212, 0, 0,0.8)","rgb(221, 0, 0,0.8)","rgb(230, 0, 0,0.8)",
"rgb(239, 0, 0,0.8)","rgb(248, 0, 0,0.8)","rgb(255, 20, 20,0.8)","rgb(255, 64, 64,0.8)",
"rgb(255, 102, 102,0.8)","rgb(255, 128, 128,0.8)","rgb(255, 153, 153,0.8)","rgb(255, 179, 179,0.8)",
"rgb(255, 204, 204,0.8)","rgb(255, 222, 217,0.8)","rgb(255, 235, 235,0.8)","rgb(245, 245, 245,0.8)",
"rgb(224, 224, 224,0.8)","rgb(204, 204, 204,0.8)","rgb(179, 179, 179,0.8)","rgb(153, 153, 153,0.8)",
"rgb(102, 102, 102,0.8)"]

let datasets=[];
let cnt=1;
for (let [key, value] of Object.entries(data)) {
      let greencolor=greencolors[cnt];
      let greenbgcolor=greenbgcolors[cnt];
      let redcolor=redcolors[cnt];
      let redbgcolor=redbgcolors[cnt];
      if (key != "İşlem Tarihi") {
            if (key.indexOf(' ') >= 0) {
               //console.log(key,value)
               datasets.push({
               label: key,
               data: value,
               backgroundColor: greenbgcolor,
               borderColor: greencolor,
               type: "bar",
               fill: true,
               lineTension: 0.4,
               pointRadius: 0,
               borderWidth:0.8,
               borderRadius:0,
               barPercentage: 1.2,
               //hidden: true,
               stack: "2",
               });
      } else {
               datasets.push({
               label: key,
               data: value,
               backgroundColor:redbgcolor,
               borderColor: redcolor,
               type: "bar",
               fill: true,
               lineTension: 0.4,
               pointRadius: 0,
               borderWidth:0.8,
               borderRadius:0,
               barPercentage: 1.2,
               //hidden: true,
               stack: "1",
               });
      }
      }
      cnt=cnt+1
    }


 return datasets
}

function filter_dataset(data,field){

let datasets=[];
for (let [key, value] of Object.entries(data)) {
      datasets.push(value[field]);
    }

 return datasets
}

var redcolors= ["#a70000","#b00000","#b90000","#c20000","#cb0000","#d40000","#dd0000","#e60000",
                "#ef0000","#f80000","#ff1414","#ff4040","#ff6666","#ff8080","#ff9999","#ffb3b3",
                "#ffcccc","#ffded9","#ffebeb","#f5f5f5","#e0e0e0","#cccccc","#b3b3b3","#999999",
                "#666666"]
function fundData(params,startdate,enddate) {
   const channelsChartData = {
  labels: [ "Avustralya","Afrika", "Asya", "Avrupa","Diğer","Güney Amerika","Kuzey ve Orta Amerika","BDT","Uzak Doğu", "Türkiye"],
  datasets: [{
    label: [ "Avustralya","Afrika", "Asya", "Avrupa","Diğer","Güney Amerika","Kuzey ve Orta Amerika","BDT","Uzak Doğu", "Türkiye"],
    backgroundColors: redcolors,
    data: [6134,4559, 47123, 1418888,164,2424,31343,226400,5159,173588],
  }],
};


    const [data, setData] = useState(channelsChartData);
    //const dataFetchedRef = useRef(false);
    const fetchData = async () => {
        const config={"func":"get_fund_content_info_data","params":params}
        const response = await get_fund_content_info_data(config);
        var data=response.data
        const tmpdata = await data["fundcontentdistribution"];
        const borsakodu= await filter_dataset(tmpdata,"category")
        const toplamdeger= await filter_dataset(tmpdata,"portfoliovalue")
        setData({datasets:[{label:borsakodu,backgroundColors:bgcolors,data:toplamdeger }],labels:borsakodu});
            }

    useEffect(() => {
            fetchData();
    },[]);
  return data
}

export default fundData;

