

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

import Graphs from "layouts/dashboards/default/components/InflationGraph";
// Data
import inflationdata from "layouts/dashboards/default/data/inflationdata";
// Soft UI Dashboard PRO React icons
import { MdOutlineCalendarMonth } from "react-icons/md";
import { BsCalendar2MonthFill } from "react-icons/bs";
import { BsCalendar2DayFill } from "react-icons/bs";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


function InflationGraphs() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(1);


  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

const handleSetTabValue = (event, newValue) => setTabValue(newValue);


const params="monthly"
const params2="yearly"

  return (
        <Item>
         <Grid container spacing={3}>
         <Grid item xs={12} lg={3} sx={{ fontWeight: 'bold',}}>
          Enflasyon
           </Grid>
            <Grid item xs={12} md={6} lg={5} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ backgroundColor: "transparent" }}
              >
                <Tab label="Aylık" icon={<BsCalendar2MonthFill />} />
                <Tab label="Yıllık" icon={<MdOutlineCalendarMonth />} />
              </Tabs>
            </AppBar>
          </Grid>
          </Grid>
        <SoftBox p={0} width="100%" height="18rem">
        <Graphs data={ inflationdata(params)}  value={tabValue} index={0}  />
        <Graphs data={ inflationdata(params2)}  value={tabValue} index={1} />
      </SoftBox>
      </Item>
)
}

export default InflationGraphs;
