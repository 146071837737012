import { ChartOptions, ChartData } from "chart.js";

// const params=["07112019","30032021","23032023","22032024","05022025","30052040","14012041"]

export const getChartData = (
  datasets,labels
): ChartData => ({
  labels: labels,
  datasets:datasets
});


export const chartOptions: ChartOptions =  {
    interaction:
    {mode:'index'},
  elements: {
    line: {
      tension: 0.1
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
  //stacked100: { enable: true, replaceTooltipLabel: false },
  legend: {
  display: true,
  position:'bottom',
  labels: {
        padding: 5,
      }
  },
    filler: {
      propagate: true
    }
  },
  scales: {
    xAxes: [
      {
        display: true,
        ticks: {
          autoSkip: false,
        }
      },
    ],
    yAxes: {
       ticks: {
          // forces step size to be 50 units
          beginAtZero: true,
        }
    }
  },

  };