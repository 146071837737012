/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import get_expectations_data from "layouts/api";


const parseDMY = s => {
  let [d, m, y] = s.split("-");
  const tmpdate=new Date(d, m-1, y)
  var curyear=tmpdate.getFullYear()
  var curmonth=tmpdate.getMonth()
  var  currentdate = d.toString()+ "-" + parseInt(m).toString();
  return currentdate;
};

function transform_array(data) {
  let result = [];
  for (let row of data) {
    const newDate=parseDMY(row["date"])
      result.push({
        date: newDate,
        "Aylık12": row["Aylık12"],
        "Aylık24": row["Aylık24"],
        "distribution": row["distribution"]
    });
  }
  return result;
}

function transform_array2(data) {
  let result = [];
  for (let row of data) {
    const newDate=parseDMY(row["date"])
      result.push({
        date: newDate,
        "12 Ay Sonrası Yıllık TÜFE": row["12 Ay Sonrası Yıllık TÜFE"],
        "24 Ay Sonrası Yıllık TÜFE": row["24 Ay Sonrası Yıllık TÜFE"],
        "distribution": row["distribution"]
    });
  }
  return result;
}

function expectationsData(params) {
   const defaultData= {datasets:{ "data1": [{'title': 'Altın', 'values': [ 100,10,5,145,200,40,210]}],
                        "data2": [{'title': 'Brüt Rezerv', 'values': [ 100,10,5,145,200,40,210]}],
    }}
    const [data, setData] = useState(defaultData);
    //const dataFetchedRef = useRef(false);
    const fetchData = async () => {
        const config={"func":"expectations","params":params}
        const response = await get_expectations_data(config);
        var data=response.data
        const tmpdata1 = await transform_array(data.data1);
        const tmpdata={"data1":tmpdata1,"data2":data.data2}
        /*var data_tl=response.data.faiz
        console.log(tmpdata)
        const tmpdata1 = await transpose(data_tl);
        const datasets1= await create_dataset(tmpdata1);*/
        //const datasets= await create_dataset(tmpdata)
        //const date = tmpdata.date;

	    setData({datasets:tmpdata});
    }

    useEffect(() => {
            fetchData();
    },[]);
  return data
}

export default expectationsData;
