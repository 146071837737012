import { useState } from "react";
import {useEffect } from "react";

import Select from "react-select";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";
import SoftEditor from "components/SoftEditor";
import SoftDropzone from "components/SoftDropzone";
import SoftButton from "components/SoftButton";

import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Templates
import MainTemplate from "layouts/financialsector/banking/balancesheet/components/MainTemplate/";

//import { stocksOptions } from "layouts/realsector/firms/data";
//const defaultStock = { value: "ARCLK", label: "ARCLK" }
//const { values } = breakpoints;

// data

function Budget() {
  return (
    <DashboardLayout>
      <DashboardNavbar />

        <SoftBox>
         <MainTemplate/>
        </SoftBox>
     <Footer />
   </DashboardLayout>
  );
}
export default Budget;
