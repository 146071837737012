
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Fade from "@mui/material/Fade";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

import HighLineChartforLoanNominal from "examples/Charts/HighChartsLine/HighLineChartforLoanNominal";


function LoanNominal({ data, date, value, index }) {
  const { size } = typography;
  return (
    value === index && (
      <Fade in timeout={850}>
        <SoftBox width="100%" height="100%"  borderRadius="lg">
            <HighLineChartforLoanNominal
                height="21.75rem"
                chart={data}
              />
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="relative"
            top={0}
            left={0}
            bottom={0}
            width="calc(100% - 1rem)"
          >
          </SoftBox>
        </SoftBox>
      </Fade>
    )
  );
}

// Typechecking props for the CreditGraphs
LoanNominal.propTypes = {
  data: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default LoanNominal;
