/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
//import typography from "assets/theme/base/typography";

function configs(labels, datasets) {
  return {
    options: {
      xAxis: {
                  tickPositioner: function(min, max){
             var interval = this.options.tickInterval,
                 ticks = [],
                 count = 0;

            while(min < max) {
               if(min%2==0){
                   ticks.push(min);
                }
                ticks.push(min);
                min += interval;
                count ++;
            }

            ticks.push(max);

            ticks.info = {
                unitName: 'day',
                count: 2,
                higherRanks: {},
		        totalRange: interval * count
            }
            return ticks;
        },
        categories: labels,
        gridLineDashStyle: "dash",
        gridLineWidth: 1,
        labels: {style:{fontSize: 12}},
        tickInterval:49,

      },
      yAxis: {
      title: { text: null},
      labels: {
            formatter: function () {
                    return (100*this.value > 0 ? ' + ' : '') + 100*this.value + '%';
                }
        },
        gridLineDashStyle: "dash",
        gridLineWidth: 1,
        lineWidth: 1,
        tickWidth: 2,
        tickInterval: 0.1,

      },
      series: datasets,
      tooltip: {
        pointFormatter: function() {
          return   '<span style="color:'+this.series.color+'"> <b>'+ this.series.name +'</b></span>: <b>'+ (100*this.y).toFixed(2)  + '</b>%<br/>';
        },
        shared: true,
        valueDecimals: 1,
    },
      chart: {
        type: "areaspline",
        height: 360,

      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        x: 0,
        y: 15,
    },
      colors: ["#1D7874","#280659", "#900c3e", "#C70039", "#D54800", "#FF5733"],
      //colors: ["#388087", "#6FB3B8", "#BADFE7", "#C2EDCE", "#F6F6F2"],
      //colors: [ "#DF4C73","#286FB4","#E2F0F9", "#B0DDE4",  "#FFFFFF"],
      //colors: [ "#15158F","#4D53B3","#6F7BC5", "#FFFEFB",  "#C3D4EE"],
      //colors: [ "#FD4445","#0091CF","#01395E", "#FFAF00",  "#C3D4EE"],
      //colors: [ "#0E6C","#F05833","#E9DDC7", "#FFAF00",  "#C3D4EE"],
      //colors: [ "#787F88","#64696C", "#C70039","#5C6C6C","#C4BECC",   "#A4A4AC"],
      //colors: ["#6a040f","#6b705c","#083d77","#b56576","#eaac8b"],
      //colors: ["#0f1225","#1f4b8e","#adbccd","#f1ece6","#eadbd4"],
      //***colors: ["#3D5220","#B7CB99","#778FD2","#2A3759","#431D32"],
      //colors: ["#711c02","#794412","#b88743","#84805a","#485c2c","#33341c"],
      fontSize:0.4,
      plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
                formatter: function(label) {
                    if (this.point.index === this.series.points.length - 1)
                        //console.log(this.point)
                        return  (100*this.y).toFixed(2)
                        //(100*this.y).toFixed(2)
          //'<span style="color:'+this.series.color+'"> %<b>'+ (100*this.y).toFixed(2) +'</b></span> <br/>';
                }
            },
          },
          areaspline: { fillOpacity: 0.3},
      },
      title: {text: null},
      credits: { enabled: false},
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                enable: false,
              },
              yAxis: {
                gridLineDashStyle: "longdash",
                lineWidth: 1,
                tickWidth: 1,
                labels: {
                  align: "left",
                  x: 0,
                  y: -5,
                },
                title: {
                  text: null,
                },
              },
              subtitle: {
                text: null,
              },
              credits: {
                enabled: false,
              },
            },
          },
        ],
      },
    },
  };
}

export default configs;
