/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
import get_incomestatements_data from "layouts/api";

function incomeStatementsData(params) {

    const defaultData= {datasets: [
            ['Solar', 'Electricity & Heat', 0.48],
            ['Nuclear', 'Electricity & Heat', 8.42],
            ['Hydro', 'Electricity & Heat', 2.75],
            ['Wind', 'Electricity & Heat', 2.35],
            ['Geothermal', 'Electricity & Heat', 0.15],
            ['Natural Gas', 'Electricity & Heat', 9.54],
            ['Coal', 'Electricity & Heat', 12.7],
            ['Biomass', 'Electricity & Heat', 0.52],
            ['Petroleum', 'Electricity & Heat', 0.21],
        ],
         nodes:  [ {
                id: 'Electricity & Heat',
                color: '#ffa500',
                offset: -110
            }],
    }

const [data, setData] = useState(defaultData);


const fetchData = async () => {
    const config={"func":"incomestatements","params":params}
    const result =await get_incomestatements_data(config)
    const data = await result.data;

    const resultdata = {};
    for (let [key, value] of Object.entries(data[0])) {
        resultdata[key]=value;
    }

    var revenues=resultdata["Satışlar"]
    var costofrevenues=Math.abs(resultdata["Satışların Maliyeti"] || 0)
    var grossprofit=Math.abs(resultdata["Brüt Kar (Zarar)"] || 0)
    var opex=Math.abs(resultdata["Araştırma ve Geliştirme Giderleri"] || 0 )+Math.abs(resultdata["Pazarlama Giderleri"] || 0 )+Math.abs(resultdata["Genel Yönetim Giderleri"] || 0)
    var operatingprofit=Math.abs(resultdata["Esas Faaliyet Karı (Zararı)"]|| 0)
    var otherincome=Math.abs((resultdata["Esas Faaliyetlerden Diğer Gelirler"] || 0 )+(resultdata["Yatırım Faaliyetlerinden Gelirler"]|| 0)+(resultdata["Özkaynak Yöntemiyle Değerlenen Yatırımların Karlarından (Zararlarından) Paylar"]|| 0))
    var otherexpense=Math.abs((resultdata["Esas Faaliyetlerden Diğer Giderler"]|| 0)+(resultdata["Yatırım Faaliyetlerinden Giderler"]|| 0))
    if (resultdata["Özkaynak Yöntemiyle Değerlenen Yatırımların Karlarından (Zararlarından) Paylar"]>0){
        var otherincome=Math.abs((resultdata["Esas Faaliyetlerden Diğer Gelirler"] || 0 )+(resultdata["Yatırım Faaliyetlerinden Gelirler"]|| 0)+(resultdata["Özkaynak Yöntemiyle Değerlenen Yatırımların Karlarından (Zararlarından) Paylar"]|| 0))
        var otherexpense=Math.abs((resultdata["Esas Faaliyetlerden Diğer Giderler"]|| 0)+(resultdata["Yatırım Faaliyetlerinden Giderler"]|| 0))
    } else if (resultdata["Özkaynak Yöntemiyle Değerlenen Yatırımların Karlarından (Zararlarından) Paylar"]<0) {
        var otherincome=Math.abs((resultdata["Esas Faaliyetlerden Diğer Gelirler"] || 0 )+(resultdata["Yatırım Faaliyetlerinden Gelirler"]|| 0))
        var otherexpense=Math.abs((resultdata["Esas Faaliyetlerden Diğer Giderler"]|| 0)+(resultdata["Yatırım Faaliyetlerinden Giderler"]|| 0)+(resultdata["Özkaynak Yöntemiyle Değerlenen Yatırımların Karlarından (Zararlarından) Paylar"]|| 0))
    }
    var capex=Math.abs(resultdata["Finansman Giderleri"]|| 0)
    var tax=Math.abs(resultdata["Vergi Geliri (Gideri)"]|| 0)
    var netprofitloss=Math.abs(resultdata["Dönem Karı (Zararı)"]|| 0)
    var capincome=Math.abs(resultdata["Finansman Gelirleri"]|| 0)
    var forex=Math.abs(resultdata["Net Parasal Poziyon Kazanç(Kayıp)"]|| 0)
    //console.log(otherincome)
    var tmpdata= [
            {name: "Satışlar", color: "#0b8a39", from: "", to: "Satışlar", weight: revenues},
            {name: "Satışların Maliyeti", color: "#9c080f", from: "Satışlar", to: "Satışların Maliyeti", weight: costofrevenues},
            {name: "Brüt KZ", color: "#0b8a39", from: "Satışlar", to: "Brüt KZ", weight: grossprofit},
            {name: "Diğer Gelir", color: "#0b8a39", from: "", to: "Diğer Gelir", weight: 0},
            {name: "OPEX", color: "#9c080f", from: "Brüt KZ", to: "OPEX", weight: opex},
            {name: "Diğer Gelir", color: "#0b8a39", from: "Diğer Gelir", to: "Brüt KZ", weight: otherincome},
            {name: "Diğer Giderler", color: "#9c080f", from: "Brüt KZ", to: "Diğer Giderler", weight: otherexpense},
            {name: "Esas Faaliyet KZ", color: "#0b8a39", from: "Brüt KZ", to: "Esas Faaliyet KZ", weight: operatingprofit},
            {name: "Finansman Giderleri", color: "#9c080f", from: "Esas Faaliyet KZ", to: "Finansman Giderleri", weight: capex},

            {name: " ", color: "#0b8a39", from: "", to: " ", weight: 0},
            {name: "Finansman Geliri", color: "#0b8a39", from: " ", to: "Finansman Geliri", weight: 0},
            {name: "Finansman Geliri", color: "#0b8a39", from: "Finansman Geliri", to: "Esas Faaliyet KZ", weight: capincome},
        ]

    var nodes= [
          {id: "", color: '#0b8a39',offset: -40},
          {id: "Satışlar", color: '#989898'},
          {id: "Satışların Maliyeti", color: '#9c080f',offset: -40},
          {id: "Brüt KZ", color: '#989898',offset: -30},
          {id: "OPEX", color: '#9c080f',offset: -90},
          {id: "Diğer Gelir", color: '#0b8a39'},
          {id: "Esas Faaliyet KZ", color: '#989898',offset: -40},
          {id: "Diğer Giderler", color: '#9c080f',offset: -70},
          {id: "Finansman Giderleri", color: '#9c080f',offset: -90},
          {id: "Finansman Geliri", color: '#0b8a39',offset: -25},
          {id: "Vergi Gideri (Gelir)", color: '#989898',offset: -20},
          {id: "Net Parasal Poziyon Kazanç(Kayıp)", color: '#989898',offset: -10}
        ]

        if (resultdata["Vergi Geliri (Gideri)"]>0){
            tmpdata.push({name: "Vergi Gideri (Gelir)", color: "#989898", from: " ", to: "Vergi Gideri (Gelir)", weight: 0})
            tmpdata.push({name: "Vergi Gideri (Gelir)", color: "#989898", from: "Vergi Gideri (Gelir)", to: "Esas Faaliyet KZ", weight: tax})
        }  else if (resultdata["Vergi Geliri (Gideri)"]<0) {
            tmpdata.push({name: "Vergi Gideri (Gelir)", color: "#989898", from: "Esas Faaliyet KZ", to: "Vergi Gideri (Gelir)", weight: tax})
        }

        if (resultdata["Net Parasal Poziyon Kazanç(Kayıp)"]>0){
            tmpdata.push({name: "Net Parasal Poziyon Kazanç(Kayıp)", color: "#989898", from: " ", to: "Net Parasal Poziyon Kazanç(Kayıp)", weight: 0})
            tmpdata.push({name: "Net Parasal Poziyon Kazanç(Kayıp)", color: "#989898", from: "Net Parasal Poziyon Kazanç(Kayıp)", to: "Esas Faaliyet KZ", weight: forex})
        } else if (resultdata["Net Parasal Poziyon Kazanç(Kayıp)"]<0) {
            tmpdata.push({name: "Net Parasal Poziyon Kazanç(Kayıp)", color: "#989898", from: "Esas Faaliyet KZ", to: "Net Parasal Poziyon Kazanç(Kayıp)", weight: forex})
        }


         if (resultdata["Dönem Karı (Zararı)"]>0){
            tmpdata.push({name: "Dönem KZ", color: "#0b8a39", from: "Esas Faaliyet KZ", to: "Dönem KZ", weight: netprofitloss})
            nodes.push({id: "Dönem KZ", color: '#0b8a39',offset: -40})
          } else if (resultdata["Dönem Karı (Zararı)"]<0){
            tmpdata.push({name: "Dönem KZ", color: "#9c080f", from: "Esas Faaliyet KZ", to: "Dönem KZ", weight: netprofitloss})
            nodes.push({id: "Dönem KZ", color: '#9c080f',offset: -20})
          }
           setData({datasets:tmpdata,nodes:nodes})
          }
    useEffect(() => {
            fetchData();
    },[params]);

  return data;
}

export default incomeStatementsData;