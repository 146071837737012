// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";


import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));




//Charts
import DefaultAmSunburstChart from "examples/Charts/AmChartsSunburst/DefaultAmSunburstChart";
import DefaultAmComboChart from "examples/Charts/AmChartsCombo/DefaultAmComboChart";


// Data
import vergigeliriData from "layouts/government/budget/data/vergigeliridata";
import merkezigelirData from "layouts/government/budget/data/merkezigelirdata";


function RevenueTemplate() {
const titlename=" Nakit Akımları (1000 TL)"
  return (
         <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={2} sx={{ height: 550 }}>
   <Grid item xs={12} lg={6} >
    <Card>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={1}>
        <SoftTypography variant="h6">Merkezi Bütçe Gelirleri</SoftTypography>
      </SoftBox>
      <SoftBox p={0} mt={0} width="100%" height="32rem">
      <SoftBox width="100%" height="100%"  borderRadius="lg">
<Item>
              <DefaultAmComboChart
                title=""
                description=""
                height="28.25rem"
                chart={merkezigelirData}
                chartname="gelirchart"
              />

 </Item>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="relative"
            top={0}
            left={0}
            bottom={0}
            width="calc(100% - 1rem)"
          >
          </SoftBox>
        </SoftBox>

      </SoftBox>
    </Card>
   </Grid>

    <Grid item xs={12} lg={6} >
    <Card>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={2}>
        <SoftTypography variant="h6">Vergi Gelirleri</SoftTypography>
      </SoftBox>
      <SoftBox p={0} mt={0} width="100%" height="32rem">
<Item>
                    <DefaultAmSunburstChart
                    title=""
                    description=""
                    height="28.75rem"
                    chart={vergigeliriData}
                    chartname="taxchart"
                 />
</Item>

      </SoftBox>
    </Card>
   </Grid>


  </Grid>
  </Box>
  );
}
export default RevenueTemplate;
