// @mui material components
import Grid from "@mui/material/Grid";
// Data
import cashflowData from "layouts/realsector/firms/data/cashflowData";
//Graphs
import DefaultAmWaterfallCharts from "examples/Charts/AmChartsWaterfall/DefaultAmWaterfallCharts";

function CashFlowTemplate(stock) {
const titlename=stock.stock + " Nakit Akımları (1000 TL)"
  return (
                  <Grid container spacing={0} container direction="column" justifyContent="flex-start"alignItems="stretch" sx={{ mb: 1 }}>
                        <DefaultAmWaterfallCharts
                             title={titlename}
                             description=""
                             chart={cashflowData(stock.stock)}
                     />
                  </Grid>
  );
}
export default CashFlowTemplate;
