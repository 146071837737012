import connect_chatgbt_api from "layouts/api_chatgpt";

export const davinci = async (prompt, key, gptVersion) => {
  const config={"query":prompt,"key":key,"gptVersion":gptVersion}
  const response = await connect_chatgbt_api(config).then((result) => {
    const data = result;
    return data
    });


  return response.data;
};
