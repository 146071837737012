/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable no-dupe-keys */
// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import "chartjs-plugin-datalabels";

const { gradients, dark } = colors;

export const getChartData2 = (
  datasets,labels
): ChartData => ({
  labels: labels,
  datasets:datasets
});


export function configs(datasets, labels) {
  const backgroundColors = [];
  if (datasets[0].backgroundColors) {
    datasets[0].backgroundColors.forEach((color) =>
      gradients[color]
        ? backgroundColors.push(color)
        : backgroundColors.push(color)
    );
  } else {
    backgroundColors.push(color);
  }

  return {
    data: {
      labels,
      datasets: [
        {
          label: datasets.label,
          weight: 9,
          cutout: 0,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          backgroundColor: backgroundColors,
          fill: false,
          data: datasets.data,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
        },
         datalabels: {
           labels: {
      value: {
        color: 'white'
      }
    },
font: {
          weight: 'bold',
          size: 9,
        },

    anchor: "end",
    align: "start",
formatter: function(value, context) {
  return context.chart.data.labels[context.dataIndex]+ ":" +Math.round(value*100) + '%';
}
  },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
}

