/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
import get_credit_change_data from "layouts/api";

function transpose(data) {
  let result = {};
  //console.log("---------------")
  //console.log(data)
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}

function creditChangeData(params) {
    const config={"func":"credit_change_data","params":params}
    const result = get_credit_change_data(config).then((response) => {
    //const data = transpose(response.data);
    const data = response.data;
    //cconst index = data.index;
    //cconst resultdata = [];
    //cfor (let [key, value] of Object.entries(data)) {
    //c  if (key != "index") {
    //c    resultdata.push({ label: key, data: value });
    //c  }
    //c}
    //creturn {
    //c  labels: index,
     //c datasets: resultdata,
   //c };
   return data
  });
  //console.log(result)
  return result;
}

export default creditChangeData;
