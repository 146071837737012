/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useRef, useEffect, useState, useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// Overview page components
import DefaultCell from "layouts/financialsector/tcmb/funding/components/DefaultCell";
import TotalCell from "layouts/financialsector/tcmb/funding/components/TotalCell";

//Data
import creditChangeData from "layouts/financialsector/tcmb/funding/data/fundingTableData";


function dataTableData(params) {

const [tableData, setTableData] = useState( [
                                {"İşlemTarihi": "2024-05-03","Yontem": "0","İşlem": "0","Valor": "0","Vade": "0","Vkgs":"0","Tutar":"0","OrtalamaBasit":"0","OrtalamaBilesik":"0"},
                                {"İşlemTarihi": "2024-05-03","Yontem": "0","İşlem": "0","Valor": "0","Vade": "0","Vkgs":"0","Tutar":"0","OrtalamaBasit":"0","OrtalamaBilesik":"0"},
                                {"İşlemTarihi": "2024-05-03","Yontem": "0","İşlem": "0","Valor": "0","Vade": "0","Vkgs":"0","Tutar":"0","OrtalamaBasit":"0","OrtalamaBilesik":"0"},
                                {"İşlemTarihi": "2024-05-03","Yontem": "0","İşlem": "0","Valor": "0","Vade": "0","Vkgs":"0","Tutar":"0","OrtalamaBasit":"0","OrtalamaBilesik":"0"},

]);

const table=creditChangeData(params)

useEffect(() => {
 table.then((response) => {
      const tableDatasets = response;
      setTableData(tableDatasets)
   });
 }, []);


const rowstr=[]
const tmp_data = {};
tableData.forEach(function(data){
    const tmp_data = {};
    tmp_data["işlemtarihi"] =<DefaultCell>{data.İşlemTarihi}</DefaultCell>
    tmp_data["işlem"] = <DefaultCell>{data.İşlem}</DefaultCell>
    tmp_data["yontem"] = <DefaultCell>{data.Yontem}</DefaultCell>
    tmp_data["valor"] = <DefaultCell>{data.Valor}</DefaultCell>
    tmp_data["vade"] = <DefaultCell>{String(data.Vade)}</DefaultCell>
    tmp_data["vkgs"] = <DefaultCell>{String(data.Vkgs)}</DefaultCell>
    tmp_data["tutar"] = <DefaultCell>{String(parseFloat(data.Tutar).toLocaleString())}</DefaultCell>
    tmp_data["ortalamabasit"] = <DefaultCell>{String(data.OrtalamaBasit)}</DefaultCell>
    tmp_data["ortalamabilesik"] = <DefaultCell>{String(data.OrtalamaBilesik)}</DefaultCell>
    rowstr.push(tmp_data)


});


const dataTableDatatmp={
    columns: [
        { Header: "İşlem Tarihi", accessor: "işlemtarihi", align: "left"},
        { Header: "İşlem", accessor: "işlem", align: "left" },
        { Header: "Yöntem", accessor: "yontem", align: "left" },
        { Header: "Valor", accessor: "valor", align: "left" },
        { Header: "Vade", accessor: "vade", align: "left" },
        { Header: "VKGS", accessor: "vkgs", align: "left" },
        { Header: "Tutar", accessor: "tutar", align: "left" },
        { Header: "Ortalama Basit Faiz", accessor: "ortalamabasit", align: "left" },
        { Header: "Ortalama Bileşik Faiz", accessor: "ortalamabilesik", align: "left" },

  ],
  rows:rowstr,
  };
//console.log(dataTableDatatmp)
return dataTableDatatmp
}

// Setting default values for the props of DataTable
dataTableData.defaultProps = {
  noEndBorder: false,
};

// Typechecking props for the DataTable
dataTableData.propTypes = {
  table: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default dataTableData;
