// @mui material components
import Grid from "@mui/material/Grid";
//Graphs
import BS from "layouts/financialsector/tcmb/analytic/components/BalanceSheet/components/BS";
import balanceSheetData from "layouts/financialsector/tcmb/analytic/data/balancesheet";
//import rawData from "layouts/realsector/firms/components/BalanceSheet/data/rawDataTmp";

function BalanceSheetTemplate(stock) {

  return (
         <BS chart={balanceSheetData(stock.stock)}/>
  );
}
export default BalanceSheetTemplate;
