/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React components
import SoftButton from "components/SoftButton";


function StockFlowCard({ color, state, icon, title, description,onChange,action,value }) {
  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox
        p={3}
        height="100%"
        bgColor={state ? color : "white"}
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={0}
          lineHeight={1}
        >
          <SoftTypography variant="button" color={state ? "white" : "text"}>
            {state ? "KMH Hariç" : "KMH Dahil"}
          </SoftTypography>
          <SoftBox mr={1}>
            <Switch checked={state} onChange={onChange} />
          </SoftBox>
        </SoftBox>
        <SoftTypography
            variant="h4"
            color={state ? "white" : "text"}
            textTransform="capitalize"
            fontWeight="medium"
           onClick={action}
          >
        {value}
        </SoftTypography>
        <SoftBox mt={0} lineHeight={0}>
          <SoftTypography
            variant="body2"
            color={state ? "white" : "text"}
            textTransform="capitalize"
            fontWeight="medium"
          >
            {title}
          </SoftTypography>
          {description ? (
            <SoftTypography variant="caption" color={state ? "white" : "text"}>
              {description}
            </SoftTypography>
          ) : null}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of ControllerCard
StockFlowCard.defaultProps = {
  color: "secondary",
  state: false,
  description: "",
};

// Typechecking props for the ControllerCard
StockFlowCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  state: PropTypes.bool,
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,

};

export default StockFlowCard;
