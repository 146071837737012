import { Row } from "@silevis/reactgrid";

import {
  CashInflow,
  CashOutflow,
  OPEX,
  CashOtherInflow,
  CashOtherOutflow,
  FinancialExpense,
  InputVariables,
  MonthlyValues,
  OutputVariables
} from "layouts/realsector/dcf/components/interfaces";
import { months } from "layouts/realsector/dcf/components/helpers";
import {
  emptyTextCell,
  nonEditable,
  textCell,
  monthHeaderCell,
  bottomLine,
  numberCell,
  showZero,
  noSideBorders
} from "layouts/realsector/dcf/components/cells";

export const HEADER_ROW_ID = "header";
export const MONTHSTOTAL_ROW_ID = "monthsTotal";


const ROW_HEIGHT = 26;
const HEADING_ROW_HEIGHT = 36;

function sumGroupValues(values: MonthlyValues): number {
  return values.reduce(
    (prev, curr) => (isNaN(prev) ? 0 : prev) + (isNaN(curr) ? 0 : curr)
  );
}

function getHeaderRow(): Row {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: [
      nonEditable(emptyTextCell),
      nonEditable(monthHeaderCell("Jan", "justify-content-center")),
      nonEditable(monthHeaderCell("Feb", "justify-content-center")),
      nonEditable(monthHeaderCell("Mar", "justify-content-center")),
      nonEditable(monthHeaderCell("Apr", "justify-content-center")),
      nonEditable(monthHeaderCell("May", "justify-content-center")),
      nonEditable(monthHeaderCell("Jun", "justify-content-center")),
      nonEditable(monthHeaderCell("July", "justify-content-center")),
      nonEditable(monthHeaderCell("Totals", "justify-content-end")),
    ],
  };
}

function getMonthsInflowOuflowDiffRow(
  title: string,
  monthlyInflowOuflowDiffs: MonthlyValues,
  yearlyGroupsDiff: number
): Row {
  const monthsTotalCell = (value: number) =>
    bottomLine(
      nonEditable(showZero(numberCell(value, "text-md-darkblue disabled font-bold")))
    );
  return {
    rowId: MONTHSTOTAL_ROW_ID,
    height: HEADING_ROW_HEIGHT,
    cells: [
      bottomLine(nonEditable(textCell(title, "text-md-darkblue font-bold"))),
      ...months().map((_, idx) =>
        nonEditable(monthsTotalCell(monthlyInflowOuflowDiffs[idx]))
      ),
      bottomLine(
        nonEditable(
          showZero(numberCell(yearlyGroupsDiff, "text-md-darkblue font-bold"))
        )
      )
    ],
  };
}

function getMonthsInflowOuflowOpexDiffRow(
  title: string,
  monthlyInflowOuflowOpexDiffs: MonthlyValues,
  yearlyGroupsDiff: number
): Row {
  const monthsTotalCell = (value: number) =>
    bottomLine(
      nonEditable(showZero(numberCell(value, "text-md-darkblue disabled font-bold")))
    );
  return {
    rowId: MONTHSTOTAL_ROW_ID,
    height: HEADING_ROW_HEIGHT,
    cells: [
      bottomLine(nonEditable(textCell(title, "text-md-darkblue font-bold"))),
      ...months().map((_, idx) =>
        nonEditable(monthsTotalCell(monthlyInflowOuflowOpexDiffs[idx]))
      ),
      bottomLine(
        nonEditable(
          showZero(numberCell(yearlyGroupsDiff, "text-md-darkblue font-bold"))
        )
      )
    ],
  };
}


function getGroupRows(
  title: "Brüt Satışlar" | "Maliyet" | "Faaliyet Giderleri" ,
  summaryTitle: string,
  groups: (CashInflow | CashOutflow | OPEX)[],
  monthlyGroupTotals: MonthlyValues,
  yearlyGroupTotal: number
): Row[] {
  return [
    {
      rowId: `${title}Header`,
      height: HEADING_ROW_HEIGHT,
      cells: [
        bottomLine(
          nonEditable(
            textCell(
              title,
              `align-items-end text-lg font-bold text-${
                  title === "Brüt Satışlar" ? "green" : title === "Maliyetler" ? "red" : "darkblue"
              }`
            )
          )
        ),
        ...months().map((_) =>
          noSideBorders(bottomLine(nonEditable(emptyTextCell)))
        ),
        bottomLine(nonEditable(emptyTextCell))
      ]
    },
    ...groups.map(({ title, values }) => ({
      rowId: title,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell(title, "padding-left-lg")),
        ...values.map((_, idx) => numberCell(values[idx])),
        nonEditable(
          showZero(numberCell(sumGroupValues(values), "font-bold disabled"))
        )
      ]
    })),
    {
      rowId: `${title}Summary`,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell(summaryTitle, `padding-left-lg font-bold text-${
                  title === "Brüt Satışlar" ? "green" : title === "Maliyetler" ? "red" : "darkblue"
                }`)),
        ...months().map((_, idx) =>
          nonEditable(
            showZero(
              numberCell(
                monthlyGroupTotals[idx],
                `font-bold disabled text-${
                  title === "Brüt Satışlar" ? "green" : title === "Maliyetler" ? "red" : "darkblue"
                }`
              )
            )
          )
        ),
        nonEditable(
          showZero(
            numberCell(
              yearlyGroupTotal,
              `font-bold disabled text-${
                 title === "Brüt Satışlar" ? "green" : title === "Maliyetler" ? "red" : "darkblue"
              }`
            )
          )
        )
      ]
    }
  ];
}

function getCashOtherFlowRow(
  title: "Diğer Gelirler"  | "Diğer Giderler " | "Finansman Giderleri" ,
  groups: (CashOtherInflow |  CashOtherOutflow | FinancialExpense)[],
  yearlyGroupTotal: number
): Row[] {
  return [
    ...groups.map(({ title, values }) => ({
      rowId: title,
      height: ROW_HEIGHT,
      cells: [
       title.includes(")")
        ?nonEditable(textCell(title, " text-md-darkblue font-bold"))
        :nonEditable(textCell(title, " text-md-darkblue font-bold")),
        ...values.map((_, idx) => numberCell(values[idx],    `font-bold text-md-${"darkblue"}`)),
                nonEditable(
          showZero(
            numberCell(
              yearlyGroupTotal,
              `font-bold disabled text-${
                 title === "Brüt Satışlar" ? "green" : title === "Maliyetler" ? "red" : "darkblue"
              }`
            )
          )
        )
      ],
    })),
  ];
}

export function getRows({
  cashInflow,
  monthlyInflowTotals,
  yearlyInflowTotal,
  cashOutflow,
  monthlyOutflowTotals,
  yearlyOutflowTotal,
  monthlyInflowOuflowDiffs,
  yearlyInflowOuflowDiff,
  opex,
  monthlyOpexTotals,
  yearlyOpexTotal,
  monthlyInflowOuflowOpexDiffs,
  yearlyInflowOuflowOpexDiffs,
  cashOtherInflow,
  yearlyOtherInflowTotal,
  cashOtherOutflow,
  yearlyOtherOutflowTotal,
  financialExpense,
  yearlyFinancialExpenseTotal
}: InputVariables & OutputVariables): Row[] {
  return [
    getHeaderRow(),
    ...getGroupRows(
      "Brüt Satışlar",
      "Net Satışlar",
      cashInflow,
      monthlyInflowTotals,
      yearlyInflowTotal
    ),
    ...getGroupRows(
      "Maliyetler",
      "Toplam Maliyet",
      cashOutflow,
      monthlyOutflowTotals,
      yearlyOutflowTotal
    ),
    getMonthsInflowOuflowDiffRow(
      "Brüt Kar Zarar",
      monthlyInflowOuflowDiffs,
      yearlyInflowOuflowDiff
    ),
    ...getGroupRows(
      "Faaliyet Giderleri",
      "Toplam Faaliyet Gideri",
      opex,
      monthlyOpexTotals,
      yearlyOpexTotal
    ),
    getMonthsInflowOuflowOpexDiffRow(
      "Faaliyet Kar Zarar",
      monthlyInflowOuflowOpexDiffs,
      yearlyInflowOuflowOpexDiffs
    ),
    ...getCashOtherFlowRow(
       "Diğer Gelirler",
       cashOtherInflow,
       yearlyOtherInflowTotal
     ),
    ...getCashOtherFlowRow(
       "Diğer Giderler",
       cashOtherOutflow,
       yearlyOtherOutflowTotal
     ),
    ...getCashOtherFlowRow(
       "Finansman Giderleri",
       financialExpense,
       yearlyFinancialExpenseTotal
     ),
  ];
}
