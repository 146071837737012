import * as React from "react";
import "layouts/financialsector/tcmb/analytic/components/BalanceSheet/components/assets/styleelement.css";
import "layouts/financialsector/tcmb/analytic/components/BalanceSheet/components/assets/styles.css";
import { CellChange, NumberCell, ReactGrid } from "@silevis/reactgrid";
//import "@silevis/reactgrid/styles.css";
import { getColumns } from "layouts/financialsector/tcmb/analytic/components/BalanceSheet/components/getColumns";
import { getRows}  from "layouts/financialsector/tcmb/analytic/components/BalanceSheet/components/getRows";

//import SoftBox from "components/SoftBox";

import {
  DısVarlıklar,
  Nakitİslemler,
  DegerlemeHesabı,
  DısYukumluluk,
  IcYukumluluk,
  RezervPara,
  DigerMBPara,
  Labels,
  InputVariables,
  OutputVariables
} from "layouts/financialsector/tcmb/analytic/components/BalanceSheet/components/interfaces";

import { Bar } from "react-chartjs-2";
import { getChartData, chartOptions } from  "layouts/financialsector/tcmb/analytic/components/BalanceSheet/data/chart";
import { calculateOutputVariables } from "layouts/financialsector/tcmb/analytic/components/BalanceSheet/components/plannerOutputVariables";

//import GradientLineChart from "examples/Charts/LineCharts/GradientLineChartGPT";
import ReportsBarChart from "examples/Charts/BarCharts/DefaultBarCharts";

//const applyChange = (change: CellChange<NumberCell>) => < K >(
//  groups: Array<K>
//) =>
//  groups.map((group) =>
//    group.title === change.rowId
//      ? {
//          ...group,
//          values: group.values.map((value, idx) =>
//            change.columnId === idx + 1 ? change.newCell.value : value
//          )
//        }
//      : group
//  );

//export const BalanceSheet: React.FC = (stock) => {
function BS(chart){
  const [dısvarlıklar ]= [chart.chart.dısvarlıklar]
  const [nakitislemler ]= [chart.chart.nakitislemler]
  const [degerlemehesabı ]= [chart.chart.degerlemehesabı]

  const [dısyukumluluk ]= [chart.chart.dısyukumluluk]
  const [icyukumluluk ]= [chart.chart.icyukumluluk]
  const [rezervpara ]= [chart.chart.rezervpara]
  const [digermbpara ]= [chart.chart.digermbpara]
  const [labels ]= [chart.chart.labels]

  const columns = getColumns();
  const inputVariables: InputVariables = {
    dısvarlıklar,
    nakitislemler,
    degerlemehesabı,
    dısyukumluluk,
    icyukumluluk,
    rezervpara,
    digermbpara,
    labels,
  };

  const outputVariables = calculateOutputVariables(inputVariables);
  const plannerData: InputVariables & OutputVariables = {
    ...inputVariables,
    ...outputVariables
  };

  const rows = getRows(plannerData);
  //console.log("inputVariables")
  //console.log(inputVariables)
  //console.log("plannerdata")
  //console.log(plannerData)
  //console.log("Row")
  //console.log(rows)
  //console.log(labels)

  /*const handleChanges = (changes: CellChange[]) => {
    changes.forEach((change: CellChange<NumberCell>) => {
      setCashInflow((cashInflow) => applyChange(change)(cashInflow));
      setCashOutflow((cashOutflow) => applyChange(change)(cashOutflow));
      setOpex((opex) => applyChange(change)(opex));
      setCashOtherInflow((cashOtherInflow) => applyChange(change)(cashOtherInflow));
      setCashOtherOutflow((cashOtherOutflow) => applyChange(change)(cashOtherOutflow));
      setFinancialExpense((financialExpense) => applyChange(change)(financialExpense));

    });
   };*/
const chardata = getChartData(plannerData,labels);
  return (
 //  valueindex === 1 && (
    <>


      <div className="dcf-app" style={{ width: 1200 }} >

                <ReportsBarChart
                title=""
                description= ""
                chart={chardata}
                chartoptions={chartOptions}
                height= {"12.125rem"}
                width= {1200}
              />
        <ReactGrid
          rows={rows}
          columns={columns}
          //onCellsChanged={handleChanges}
          stickyTopRows={1}
          stickyLeftColumns={1}
          stickyRightColumns={1}
          // props below are availble for PRO version
          //enableFillHandle
          //enableRangeSelection
        />
      </div>


    </>
  //  )
  );
};

export default BS;
