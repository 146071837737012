import { ChartOptions, ChartData } from "chart.js";
import { InputVariables, OutputVariables } from "layouts/realsector/firms/components/Summary/components/interfaces";



let delayed;
export const getChartData = (
  plannerData: InputVariables & OutputVariables,labels
): ChartData => ({
  labels: labels.slice(-7),
  datasets: [
     {
      label: "Favök Marjı",
      borderColor:  "#280659",
      backgroundColor: "rgba(40,6,89,0.5)",
      fill: false,
      stack: "2",
      barPercentage: 0.8,
      data: plannerData.monthlyFavokTotals.map((value) => value),
      borderWidth: 2
    },
    {
      label: "Brüt Kar Marjı",
      borderColor:  "#900c3e",
      backgroundColor: "rgba(144,12,62,0.5)",
      fill: false,
      stack: "3",
      barPercentage: 0.8,
      data: plannerData.monthlyGrossPLTotals.map((value) => value),
      borderWidth: 2
    },
     {
      label: "Net Kar Marjı",
      borderColor:  "#D4AF37",
      backgroundColor: "rgba(212,175,55,0.5)",
      stack: "4",
      fill: false,
      barPercentage: 0.8,
      data: plannerData.monthlyNetPLTotals.map((value) => value),
      borderWidth: 2
    },
    {
      label: "Serbest Nakit /Satışlar",
      borderColor: "#1D7874",
      backgroundColor: "rgb(29, 120, 116,0.5)",
      type: "line",
      fill: false,
      lineTension: 0,
      data: plannerData.monthlySalesTotals.map((value, idx) => value)
    }
  ]
});

export const chartOptions: ChartOptions =  {
      legend: {
    display: true
  },
  elements: {
    line: {
      tension: 0.1
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    filler: {
      propagate: true
    }
  },
  layout: {
    padding: {
      left: 285
    }
  },
    animation: {
      onComplete: () => {
        delayed = true;
      },
      delay: (context) => {
        let delay = 0;
        if (context.type === 'data' && context.mode === 'default' && !delayed) {
          delay = context.dataIndex * 300 + context.datasetIndex * 100;
        }
        return delay;
      },
    },
  scales: {
    xAxes: [
      {
        display: true,
        ticks: {
          autoSkip: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        },
      }
    ]
  }
  };
