import { useState } from "react";
import {useEffect } from "react";

import Select from "react-select";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";
import SoftEditor from "components/SoftEditor";
import SoftDropzone from "components/SoftDropzone";
import SoftButton from "components/SoftButton";

import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Templates
import IncomeStatementsTemplate from "layouts/realsector/firms/components/IncomeStatementsTemplate";
import CashFlowTemplate from "layouts/realsector/firms/components/CashFlowTemplate";
import BalanceSheetTemplate from "layouts/realsector/firms/components/BalanceSheetTemplate";
import SummaryTemplate from "layouts/realsector/firms/components/SummaryTemplate";
import Graphs1Template from "layouts/realsector/firms/components/Graphs1Template";

//Data

const { values } = breakpoints;



function MainTemplate(stock) {

 return (
  <div>
            <SoftBox mb={0} p={1} pt={0} >
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    Bilanço
                </SoftTypography>
            </SoftBox>

            <SoftBox mb={0}>
                 <BalanceSheetTemplate stock={stock.stock}/>
            </SoftBox>

            <SoftBox mt={10} width="100%">
              <Divider sx={{height: 8}}/>
             </SoftBox>

            <SoftBox mb={0} p={1} pt={0} >
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    Gelir Gider Grafiği
                </SoftTypography>
            </SoftBox>

            <SoftBox mb={0}>
                <IncomeStatementsTemplate stock={stock.stock}/>
            </SoftBox>

        <SoftBox mt={10} width="100%">
            <Divider sx={{height: 8}}/>
        </SoftBox>

        <SoftBox pt={3}>
            <SoftBox mb={0} p={1}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    Nakit Akımları
                </SoftTypography>
            </SoftBox>
                <SoftBox mb={3}>
                    <CashFlowTemplate stock={stock.stock}/>
                </SoftBox>
        </SoftBox>

        <SoftBox mt={10} width="100%">
            <Divider sx={{height: 8}}/>
        </SoftBox>

        <SoftBox pt={3}>
            <SoftBox mb={0} p={1}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    Rasyolar
                </SoftTypography>
            </SoftBox>
                <SoftBox mb={3}>
                    <Graphs1Template stock={stock.stock}/>
                </SoftBox>
        </SoftBox>

        <SoftBox mt={10} width="100%">
            <Divider sx={{height: 8}}/>
        </SoftBox>


        <SoftBox pt={3}>
            <SoftBox mb={0} p={1}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    Diğer Bilgiler
                </SoftTypography>
            </SoftBox>
                <SoftBox mb={3}>
                    <SummaryTemplate  stock={stock.stock}/>
                </SoftBox>
        </SoftBox>

        <SoftBox mt={10} width="100%">
            <Divider sx={{height: 8}}/>
        </SoftBox>


 </div>
);
}
export default MainTemplate;
