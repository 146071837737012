/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
import get_currentaccount_data from "layouts/api";


function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}

function convertdata(data) {
  let result = [];
  for (let [key, value] of Object.entries(data)) {
	         if (key != "date") {
			   result.push({ title: key, values: value});
			   }
	    }
  return result;
}


function budgetData(params) {
    const defaultData= {ihracat: [{'title': 'a) Gelir Vergisi', 'values': [ 100,10,5,145,200,40,210]}],
                        ithalat: [{'title': 'b) Motorlu Taşıtlar Vergisi', 'values': [ 100,10,5,145,200,40,210]}],
                        nettransit: [{'title': 'Net Transit Ticaret', 'values': [ 100,10,5,145,200,40,210]}],
                        parasalolmayanaltın: [{'title': 'Parasal Olmayan Altın', 'values': [ 100,10,5,145,200,40,210]}],
                        imalat: [{'title': 'İmalat Hizmetleri', 'values': [ 100,10,5,145,200,40,210]}],
                        bakımonarım: [{'title': 'Bakım ve Onarım Hizmetleri', 'values': [ 100,10,5,145,200,40,210]}],
                        taşımacılık: [{'title': 'Taşımacılık', 'values': [ 100,10,5,145,200,40,210]}],
                        digerhizmet: [{'title': 'Diğer Hizmetler', 'values': [ 100,10,5,145,200,40,210]}],
                        ucretodeme: [{'title': 'Ücret Ödemeleri', 'values': [ 100,10,5,145,200,40,210]}],
                        yatırımgelirleri: [{'title': 'Yatırım Gelirleri', 'values': [ 100,10,5,145,200,40,210]}],
                        ikincigelir: [{'title': 'İkinci Gelir', 'values': [ 100,10,5,145,200,40,210]}],
                        dogrudanyatırımnetvarlık: [{'title': 'Doğrudan Yatırım Net Varlık Edinimi', 'values': [ 100,10,5,145,200,40,210]}],
                        dogrudanyatırımnetyukumluluk: [{'title': 'Doğrudan Yatırım Net Yukumluluk', 'values': [ 100,10,5,145,200,40,210]}],
                        portfoyyatırımnetvarlık: [{'title': 'Portfoy Yatırım Net Varlık Edinimi', 'values': [ 100,10,5,145,200,40,210]}],
                        portfoyyatırımnetyukumluluk: [{'title': 'Portfoy Yatırım Net Yukumluluk', 'values': [ 100,10,5,145,200,40,210]}],
                        efektifmevduatnetvarlık: [{'title': 'Mevduat Net Varlık Edinimi', 'values': [ 100,10,5,145,200,40,210]}],
                        efektifmevduatnetyukumluluk: [{'title': 'Mevduat Net Yukumluluk', 'values': [ 100,10,5,145,200,40,210]}],
                        kredinetvarlık: [{'title': 'Kredi Net Varlık Edinimi', 'values': [ 100,10,5,145,200,40,210]}],
                        kredinetyukumluluk: [{'title': 'Kredi Net Yukumluluk', 'values': [ 100,10,5,145,200,40,210]}],
                        digeryatırımlardiger: [{'title': 'Diger Yatırımlar Diger', 'values': [ 100,10,5,145,200,40,210]}],
                        rezervvarlıklar: [{'title': 'Rezerv Varlıklar', 'values': [ 100,10,5,145,200,40,210]}],
                        nethatanoksan: [{'title': 'Net Hata Noksan', 'values': [ 100,10,5,145,200,40,210]}],
                        labels: [ "","","","","","",""]
    }
    const [data, setData] = useState(defaultData);
    const fetchData = async () => {
        const config={"func":"current_account","params":params};
        const response = await get_currentaccount_data(config);
        const tmpdata = await response.data;
        const ihracat = convertdata(tmpdata["İhracat"]);
        const ithalat = convertdata(tmpdata["İthalat"]);
        const nettransit = convertdata(tmpdata["Net Transit Ticaret"]);
        const parasalolmayanaltın = convertdata(tmpdata["Parasal Olmayan Altın"]);
        const imalat = convertdata(tmpdata["İmalat Hizmetleri"]);
        const bakımonarım = convertdata(tmpdata["Bakım ve Onarım Hizmetleri"]);
        const taşımacılık = convertdata(tmpdata["Taşımacılık"]);
        const digerhizmet = convertdata(tmpdata["Diğer Hizmetler"]);
        const ucretodeme = convertdata(tmpdata["Ücret Ödemeleri"]);
        const yatırımgelirleri = convertdata(tmpdata["Yatırım Gelirleri"]);
        const ikincigelir = convertdata(tmpdata["İkinci Gelir"]);
        const dogrudanyatırımnetvarlık = convertdata(tmpdata["Doğrudan Yatırım Net Varlık Edinimi"]);
        const dogrudanyatırımnetyukumluluk = convertdata(tmpdata["Doğrudan Yatırım Net Yukumluluk"]);
        const portfoyyatırımnetvarlık = convertdata(tmpdata["Portfoy Yatırım Net Varlık Edinimi"]);
        const portfoyyatırımnetyukumluluk = convertdata(tmpdata["Portfoy Yatırım Net Yukumluluk"]);
        const efektifmevduatnetvarlık = convertdata(tmpdata["Mevduat Net Varlık Edinimi"]);
        const efektifmevduatnetyukumluluk=convertdata(tmpdata["Mevduat Net Yukumluluk"]);
        const kredinetvarlık=convertdata(tmpdata["Kredi Net Varlık Edinimi"]);
        const kredinetyukumluluk=convertdata(tmpdata["Kredi Net Yukumluluk"]);
        const digeryatırımlardiger=convertdata(tmpdata["Diger Yatırımlar Diger"]);
        const rezervvarlıklar=convertdata(tmpdata["Rezerv Varlıklar"]);
        const nethatanoksan=convertdata(tmpdata["Net Hata Noksan"]);
        const labels = tmpdata["index"];
        setData({ihracat:ihracat,ithalat:ithalat,nettransit:nettransit,parasalolmayanaltın:parasalolmayanaltın,imalat:imalat,
        bakımonarım:bakımonarım,taşımacılık:taşımacılık,digerhizmet:digerhizmet,ucretodeme:ucretodeme,
        yatırımgelirleri:yatırımgelirleri,ikincigelir:ikincigelir,dogrudanyatırımnetvarlık:dogrudanyatırımnetvarlık,
        dogrudanyatırımnetyukumluluk:dogrudanyatırımnetyukumluluk,portfoyyatırımnetvarlık:portfoyyatırımnetvarlık,
        portfoyyatırımnetyukumluluk:portfoyyatırımnetyukumluluk,efektifmevduatnetvarlık:efektifmevduatnetvarlık,
        efektifmevduatnetyukumluluk:efektifmevduatnetyukumluluk,kredinetvarlık:kredinetvarlık,
        kredinetyukumluluk:kredinetyukumluluk,digeryatırımlardiger:digeryatırımlardiger,rezervvarlıklar:rezervvarlıklar,
        nethatanoksan:nethatanoksan,labels:labels});
    }

    useEffect(() => {
            fetchData();
    },[params]);



//const [chartData, setChartData] = useState(defaultData);
//useEffect(() => {
//    data.then((response) => {
//      //const chartDatasets = response.datasets.map((dataset) => ({
//      //  ...dataset,
//      //}));
//      setChartData(response.datasets);
//    });
//});
  return data
}

export default budgetData;

