/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import get_last_date_data from "layouts/api";

function lastdate(){
  const [date, setDate] = useState({"date":"2024-03-15"});
  const config={"func":"last_date"}
  const fetchData = async () => {
    const response = await get_last_date_data(config);
    var data=response.data
    const date = data;
    setDate({date:date})
   }

    useEffect(() => {
    const data=fetchData();
    },[]);

  //console.log(date)
  return date;
}

export default lastdate;
