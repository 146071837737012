import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


// banking dashboard components
import LoanTemplate from "layouts/financialsector/banking/loans/components/loanTemplate";
import NPLTemplate from "layouts/financialsector/banking/loans/components/nplTemplate";
import IRTemplate from "layouts/financialsector/banking/loans/components/interestRateTemplate";
import InfoTemplate from "layouts/financialsector/banking/loans/components/infoTemplate";


function Loans() {
  const { values } = breakpoints;

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <SoftBox pt={3}>
            <SoftBox mb={3}>
                <LoanTemplate/>
            </SoftBox>
            <SoftBox mb={3}>
                <IRTemplate/>
            </SoftBox>
        </SoftBox>

        <SoftBox mt={10} width="100%">
            <Divider sx={{height: 8}}/>
        </SoftBox>

        <SoftBox pt={2}>
            <SoftBox mb={0} p={1} >
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    Kredi Miktarı
                </SoftTypography>
            </SoftBox>
            <SoftBox mb={3} mt={1}>
                <InfoTemplate/>
            </SoftBox>
        </SoftBox>

        <SoftBox mt={10} width="100%">
            <Divider sx={{height: 8}}/>
        </SoftBox>

        <SoftBox pt={3}>
            <SoftBox mb={0} p={1}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    aktif kalitesi
                </SoftTypography>
            </SoftBox>
                <SoftBox mb={3}>
                    <NPLTemplate/>
                </SoftBox>
        </SoftBox>

        <SoftBox my={6} width="100%">
            <Divider sx={{height: 8}}/>
        </SoftBox>
     <Footer />
    </DashboardLayout>
  );
}

export default Loans;
