/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import get_trades_group_series_data from "layouts/api";

function getField(array, field) {
var result;
var values = Object.keys(array).map(function(key){
        if (key == field) {
          result=array[key];
	    }
});
return result
}

function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}


function create_dataset(data,label){
var colors= ["color5"]
const labels=data.date
const tmpdata =getField(data,"Parasal Olmayan Altın")

const datasets={
  labels: labels,
  datasets: {
    label: label ,
    backgroundColors: colors,
    data: tmpdata,
  },
};

 return datasets
}

function Trades(params) {

   const defaultData= {
    "export_series": {"Yatırım Malları": [1146854.599,1399984.11,1388305.897,1303655.505,1418766.496,],
                             "Ara Mallar": [6407290.145,6738594.955,7400225.384,7043599.297,7352190.534,],
                             "Tüketim Malları": [4664059.801,4963747.666,5220397.257,5044269.951,5320608.043,],
                              "Diğerleri": [45119.718,52848.857,57375.071,58489.837,49977.854,]},
    "import_series": {"Yatırım Malları": [2652643.748,2866832.535,2947137.935,3326384.647,3521730.669,],
                             "Ara Mallar": [14659694.65,14929019.95,15386025.05,17525491.66,17306046.9,],
                             "Tüketim Malları": [2103754.829,2517329.07,2960254.42,2785158.832,2944402.551,],
                             "Diğerleri": [148389.269,32514.712,29394.6,51309.661,66617.335,]},
    "export_detail_series": {"Yatırım Malları": [1146854.599,1399984.11,1388305.897,1303655.505,1418766.496,],
                             "Ara Mallar": [6407290.145,6738594.955,7400225.384,7043599.297,7352190.534,],
                             "Tüketim Malları": [4664059.801,4963747.666,5220397.257,5044269.951,5320608.043,],
                              "Diğerleri": [45119.718,52848.857,57375.071,58489.837,49977.854,]},
    "import_detail_series": {"Yatırım Malları": [2652643.748,2866832.535,2947137.935,3326384.647,3521730.669,],
                             "Ara Mallar": [14659694.65,14929019.95,15386025.05,17525491.66,17306046.9,],
                             "Tüketim Malları": [2103754.829,2517329.07,2960254.42,2785158.832,2944402.551,],
                             "Diğerleri": [148389.269,32514.712,29394.6,51309.661,66617.335,]},
    "labels": [ "2013-01-31","2013-02-28","2013-03-31", "2013-04-30", "2013-05-31", ] }

    const [data, setData] = useState(defaultData);

    //const dataFetchedRef = useRef(false);
    const fetchData = async () => {
        const config={"func":"trades_group_series_data","params":params}
        const response = await get_trades_group_series_data(config);
        //const tmpdata = await transpose(response.data);
        const tmpdata= response.data
        const export_series=getField(tmpdata,"export_series")
        const import_series=getField(tmpdata,"import_series")
        const export_detail_series=getField(tmpdata,"export_detail_series")
        const import_detail_series=getField(tmpdata,"import_detail_series")
        const date=getField(tmpdata,"date")
        //const ticaretdenge=getField(tmpdata,"Dış Ticaret Dengesi")
        //const altın=create_dataset(tmpdata,"Parasal Olmayan Altın")
        //const date = tmpdata.date;
        //const sonayihracat = ihracat[ihracat.length - 1];
        //const sonayithalat = ithalat[ithalat.length - 1];
        //const sonayticaretdenge = ticaretdenge[ticaretdenge.length - 1];
        //const date = transposedata.date;
	    setData({export_series:export_series,import_series:import_series,export_detail_series:export_detail_series,
	    import_detail_series:import_detail_series,labels:date});
    }

    useEffect(() => {
            fetchData();
    },[]);
  return data
}

export default Trades;
