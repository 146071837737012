import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


// banking dashboard components
import InfoTemplate from "layouts/financialsector/banking/deposits/components/infoTemplate";
import IRTemplate from "layouts/financialsector/banking/deposits/components/interestRateTemplate";


function Deposits() {
  const { values } = breakpoints;

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <SoftBox pt={0}>
            <SoftBox mb={0} p={1} pt={0} >
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    Mevduatlar
                </SoftTypography>
            </SoftBox>
            <SoftBox mb={0} mt={0}>
                <InfoTemplate/>
            </SoftBox>
            <SoftBox mb={0}>
                <IRTemplate/>
            </SoftBox>
        </SoftBox>
     <Footer />
    </DashboardLayout>
  );
}

export default Deposits;
