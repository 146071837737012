/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
import get_data from "layouts/api";

function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}

function converdate(dates) {
const resultdate =dates.map((x) => {
      const d=new Date(x.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
      let text = d.toLocaleDateString();
      const textdate=new Date(x).getTime()/ 1000
      return  text;
});
return resultdate
}

function marketData(params) {
    const config={"func":"get_data","params":params}
    const result = get_data(config).then((response) => {
    const data = transpose(response.data);
    const date = converdate(data.date);
    const resultdata = [];
    for (let [key, value] of Object.entries(data)) {
      if (key != "date") {
        resultdata.push({ name: key, data: value });
        //i=i+1
      }
    }
    return {
      labels: date,
      datasets: resultdata,
    };
  });
  console.log(result)
  return result;
}

export default marketData;
