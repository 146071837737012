/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";


// Soft UI Dashboard PRO React example components
import GraphView from "layouts/nationalaccount/tradebalance/components/TradeGraphs";



function Trades(chartdata,tabValue) {
  return (
    <Card>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SoftTypography variant="h6">Dış Ticaret</SoftTypography>
      </SoftBox>
      <SoftBox p={2} mt={0} width="100%" height="24.25rem">
	        <GraphView data={chartdata.chartdata}  value={chartdata.tabValue} index={0} chartoptions={chartdata.chartOptions} />
	        <GraphView data={chartdata.chartdata1}  value={chartdata.tabValue} index={1} chartoptions={chartdata.chartOptions1} />
      </SoftBox>
    </Card>
  );
}

export default Trades;
