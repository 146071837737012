/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";
import "layouts/financialsector/banking/balancesheet/components/BalanceSheet/components/assets/styleelement.css";
import "layouts/financialsector/banking/balancesheet/components/BalanceSheet/components/assets/styles.css";
import { CellChange, NumberCell, ReactGrid } from "@silevis/reactgrid";
//import "@silevis/reactgrid/styles.css";
import { getColumns } from "layouts/financialsector/banking/balancesheet/components/BalanceSheet/components/getColumns";
//import { getRowsSummary}  from "layouts/financialsector/banking/balancesheet/components/BalanceSheet/components/getRows";
import { getRowsDetail}  from "layouts/financialsector/banking/balancesheet/components/BalanceSheet/components/getRows/detay";
import { getRowsSummary}  from "layouts/financialsector/banking/balancesheet/components/BalanceSheet/components/getRows";

import BarCharts from "examples/Charts/BarCharts/DefaultBarCharts";
import Header from "layouts/financialsector/banking/balancesheet/components/Header";

import {
  Aktifler,
  YabancıKaynaklar,
  Özkaynaklar,
  InputVariables,
  OutputVariables
} from "layouts/financialsector/banking/balancesheet/components/BalanceSheet/components/interfaces";

import { getChartData, chartOptions } from  "layouts/financialsector/banking/balancesheet/components/BalanceSheet/data/chartdata";
import { getChartDataSummary, chartOptionssummary } from  "layouts/financialsector/banking/balancesheet/components/BalanceSheet/data/chartdatasummary";
import { calculateOutputVariables } from "layouts/financialsector/banking/balancesheet/components/BalanceSheet/components/plannerOutputVariables";
import { calculateOutputVariables2 } from "layouts/financialsector/banking/balancesheet/components/BalanceSheet/components/plannerOutputVariables2";

function convertdata(data) {
  let result = [];
  for (let [key, value] of Object.entries(data)) {
	         if (key != "date") {
			   result.push({ title: key, values: value});
			   }
	    }
  return result;
}

function Summary(data,summary){
  if (data.summary=="Aylık") {
     var tmpdata=data.data.monthly
     var [aktifler ]= [convertdata(tmpdata["Aktifler"])]
     var [yabancıkaynaklar ]= [convertdata(tmpdata["Yabancı Kaynaklar"])]
     var [özkaynaklar ]= [convertdata(tmpdata["Özkaynaklar"])]
     var issummary=data.summary
     var [labels]= [tmpdata["index"]]
     var columns = getColumns();
     var inputVariables: InputVariables = { aktifler, yabancıkaynaklar,özkaynaklar,labels };
     var outputVariables = calculateOutputVariables(inputVariables);
     var plannerData: InputVariables & OutputVariables = {
    ...inputVariables,
    ...outputVariables
  };
    var rowsSummary = getRowsSummary(plannerData);
    var chardata = getChartData(plannerData,tmpdata["index"]);

  } else {
     var tmpdata=data.data.weekly
     var [aktifler ]= [convertdata(tmpdata["Aktifler"])]
     var [yabancıkaynaklar ]= [convertdata(tmpdata["Yabancı Kaynaklar"])]
     var issummary=data.summary
     var [labels]= [tmpdata["index"]]
     var columns = getColumns();
     var inputVariables: InputVariables = { aktifler, yabancıkaynaklar,labels };
     var outputVariables = calculateOutputVariables2(inputVariables);
     var plannerData: InputVariables & OutputVariables = {
    ...inputVariables,
    ...outputVariables
  };
    var rowsSummary = getRowsDetail(plannerData);
    var chardata = getChartDataSummary(plannerData,tmpdata["index"]);
  }
  //const summarydata=tmpdata[0]
  //convertdata(tmpdata["Aktifler"]);
console.log(plannerData)
//const chardatasummary = getChartDataSummary(plannerData,tmpdata["index"]);

  return (
 //  valueindex === 1 && (
    <>
          <div className="dcf-app" style={{ width: 1200 }} >

                       <BarCharts
                                    title=""
                                    description= ""
                                    chart={chardata}
                                    height={200}
                                    chartoptions={issummary ? chartOptionssummary : chartOptions}
                                />

        <ReactGrid
          rows={rowsSummary}
          columns={columns}
          stickyTopRows={1}
        />
      </div>
    </>
  //  )
  );
};

export default Summary;
