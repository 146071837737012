/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import get_topic_data from "layouts/api";


function getField(array, field) {
var result;
var values = Object.keys(array).map(function(key){
        if (key == field) {
          result=array[key];
	    }
});
return result
}

function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}


function create_dataset(data){
var colors= ["#280659","#C13358","#F9F871","#003A89",
            "#FF6301","#008DBB","#810160","#00B5C3",
            "#1D7874","#EC714D", "#007C92", "#5BDAC6",
            "#C1648C","#9D6CA8", "#2B7BAA", "#6976B3",
             "#334B49", "#95B1AE", "#945B72", "#CB8EA6",
             "#334B49","#95B1AE", "#596B98", "#8E9ECE",
             "#EC714D", "#FEB44D","#FFA17A", "#003A89",
              "#0064AA", "#008DBB", ,"#0064AA"]

 var bgcolors= ["rgba(40,6,89,1)","rgba(236,113,77,1)", "rgba(249,248,113,1)","rgba(0,58,137,1)",
               "rgba(255,99,1,1)","rgba(0,141,187,1)","rgba(193,51,88,1)","rgba(0,181,195,1)",
                "rgba(29,120,116,1)","rgba(129,1,96,1)","rgba(0,124,146,1)","rgba(193,100,140,1)",
                "rgba(91,218,198,1)","rgba(157,108,168,1)","rgba(43,123,170,1)","rgba(105,118,179,1)",
               "rgba(51,75,73,1)","rgba(149,177,174,1)","rgba(148,91,114,1)","rgba(203,142,166,1)",
               "rgba(51,75,73,1)","rgba(149,177,174,1)","rgba(89,107,152,1)", "rgba(142,158,206,0.5)",
               "rgba(236,113,77,0.5)","rgba(254,180,77,0.5)","rgba(255,161,122,0.5)","rgba(0,58,137,0.5)",
               "rgba(0,100,170,0.5)","rgba(0,141,187,0.5)","rgba(0,100,170,1)"
]

let datasets=[];
let cnt=-1;
for (let [key, value] of Object.entries(data)) {
      let color=colors[cnt];
      let bgcolor=bgcolors[cnt];
      if (key != "date") {
               datasets.push({
               label: key,
               data: value,
               backgroundColor: bgcolor,
               borderColor: color,
               type: "bar",
               fill: true,
               lineTension: 0.,
               pointRadius: 0,
               borderWidth:0.,
               borderRadius:0,
               barPercentage: 4,
               hidden: false,
               stack: "2",
               });
       }
      cnt=cnt+1
    }

 return datasets
}


function topicData(params) {
   const defaultData= { "Altın": [{'title': 'Altın', 'values': [ 100,10,5,145,200,40,210]}],
                        "Brüt Rezerve": [{'title': 'Brüt Rezerv', 'values': [ 100,10,5,145,200,40,210]}],
                         "Net Rezerv": [{'title': 'Net Rezerv', 'values': [ 100,10,5,145,200,40,210]}],
                        labels: [ "","","","","","",""],
    }
    const [data, setData] = useState(defaultData);
    const fetchData = async () => {
        const config={"func":"topic_data","params":params}
        const response = await get_topic_data(config);
        var tmpdata=await transpose(response.data);
        const date = await getField(tmpdata,"date");
        const datasets= await create_dataset(tmpdata);

	    setData({datasets:datasets,labels:date});
    }

    useEffect(() => {
            fetchData();
    },[]);
  return data
}

export default topicData;
