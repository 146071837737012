/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import BasicTable from "layouts/econgpt/src/components/table";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChartGPT";
import ReportsBarChart from "examples/Charts/BarCharts/BarChartGPT";

function MessageCard({ text, }) {
  //console.log(jsonData)
  var result;
  //console.log(text)
  if (text.includes("type")) {
     const jsonData =  text.replace(/'/g, '"');
     const data=JSON.parse(jsonData)
     const labels=data.index;
     const datasets=[];
     console.log("***************")
     console.log(data)
     const datas=data.data
     const columns=data.columns
     //const dataarr=[]
     var label;
     const colors=["color1", "color2","#1D7874", "#C70039", "#D54800", "#FF5733"]
     columns.forEach((label,index) => {
           const dataarr=[]
           datas.forEach((values) => {
                dataarr.push(values[index])
                //console.log(label,index,values[index])
     });
          datasets.push({"color": colors[index],"label":label,"data":dataarr});
      });

     //result=<BasicTable data={data.value}/>
     //console.log(datasets)
     //console.log(labels)
     const LineChartData = {labels,datasets}
     //console.log(LineChartData)
     const gtype=data.type
     //console.log(gtype)
     if (gtype=="bar"){
          result=<ReportsBarChart
                title=" "
                description= ""
                chart={LineChartData}
              />
     } else if (gtype=="line"){
         result= <GradientLineChart
                title=" "
                description=""
                chart={LineChartData}
                height={"16.125rem"}
              />
     } else if (gtype=="table"){
          result=<ReportsBarChart
                title=" "
                description= ""
                chart={LineChartData}

              />
     }

     //for (let [key, value] of Object.entries(tmpdata)) {
     //       var datearray=[]
     //       value.forEach((element,index) => {
     //            tabledata.push({"key":key,"value":element})
     //       });
     //}
     //console.log(tabledata)
     /*result= <GradientLineChart
                title=" "
                description=""
                chart={LineChartData}
              />*/


  }
  else
     result=text

  return (
              <SoftTypography variant="button" color="text" fontWeight="medium">
                {result}
              </SoftTypography>
  );
}

// Typechecking props for the MessageCard
MessageCard.propTypes = {
  text: PropTypes.string.isRequired,
};

export default MessageCard;
