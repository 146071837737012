import {
  WorkingCapitalRatio,
  CashConversionCycle,
  InterestCoverage,
  ProfitabilityRatio,
  OperatingReturns,
  Sales,
  FAVOK,
  GrossPL,
  NetPL,
  SummaryGraph,
  Labels,
  MonthlyValues
} from "layouts/realsector/firms/components/Summary/components/interfaces";
import { months } from "layouts/realsector/firms/components/Summary/components/helpers";


const sumGroupInAMonth = (group: (WorkingCapitalRatio | CashConversionCycle | InterestCoverage | ProfitabilityRatio | OperatingReturns | Sales | FAVOK | GrossPL | NetPL | SummaryGraph)[]) => (
  monthIndex: number
): number =>
  group.reduce(
    (acc, { values }) =>
      acc + (isNaN(values[monthIndex]) ? 0 : values[monthIndex]),
    0
  );

const calcMonthlyGroupTotals = (
  group: (WorkingCapitalRatio | CashConversionCycle | InterestCoverage | ProfitabilityRatio | OperatingReturns  | Sales | FAVOK | GrossPL | NetPL | SummaryGraph)[]
): MonthlyValues => months().map((_, idx) => sumGroupInAMonth(group)(idx));

// DCF'e eklenecek.
export function calculateOutputVariables(
  inputVariables: InputVariables
): OutputVariables {
  const {
    workingcapitalratio,
    cashconversioncycle,
    interestcoverage,
    profitabilityratio,
    operatingreturns,
    sales,
    favok,
    grosspl,
    netpl,
    summarygraphs,
    labels
  } = inputVariables;

 const monthlySalesTotals = calcMonthlyGroupTotals([sales]);
 const monthlyFavokTotals = calcMonthlyGroupTotals([favok]);
 const monthlyGrossPLTotals = calcMonthlyGroupTotals([grosspl]);
 const monthlyNetPLTotals = calcMonthlyGroupTotals([netpl]);

// const monthlySalesSum = months().map(
//   (_, idx) => monthlyFavokTotals[idx]
//  );

 // const monthlyLiabilitySum = months().map(
 //   (_, idx) => monthlyShortTermLoanTotals[idx] + monthlyLongTermLoanTotals[idx]
 // );

 // const monthlyLiabilityEquitySum = months().map(
 //   (_, idx) => monthlyShortTermLoanTotals[idx] + monthlyLongTermLoanTotals[idx]+ monthlyEquitySum[idx]
 // );

 //  const monthlyAssetLiabilityDiffs = months().map(
 //   (_, idx) => monthlyFixedAssetTotals[idx]-monthlyShortTermLoanTotals[idx] - monthlyLongTermLoanTotals[idx]
 // );


  return {
    monthlySalesTotals,
    monthlyFavokTotals,
    monthlyGrossPLTotals,
    monthlyNetPLTotals,
   // monthlySalesSum,
  };
}
