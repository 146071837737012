import { Row } from "@silevis/reactgrid";

import {
  Aktifler,
  YabancıKaynaklar,
  Labels,
  InputVariables,
  MonthlyValues,
  OutputVariables
} from "layouts/financialsector/banking/balancesheet/components/BalanceSheet/components/interfaces";
import { months } from "layouts/financialsector/banking/balancesheet/components/BalanceSheet/components/helpers";
import {
  emptyTextCell,
  nonEditable,
  textCell,
  monthHeaderCell,
  bottomLine,
  numberCell,
  showZero,
  noSideBorders
} from "layouts/financialsector/banking/balancesheet/components/BalanceSheet/components/cells";

export const HEADER_ROW_ID = "header";
export const MONTHSTOTAL_ROW_ID = "monthsTotal";
export const MONTHSTOTAL_ROW_ID2= "monthsTotal2";
export const MONTHSTOTAL_ROW_ID3= "monthsTotal3";


const ROW_HEIGHT = 21;
const HEADING_ROW_HEIGHT = 30;

function sumGroupValues(values: MonthlyValues): number {
  return values.reduce(
    (prev, curr) => (isNaN(prev) ? 0 : prev) + (isNaN(curr) ? 0 : curr)
  );
}

function getHeaderRow(
  groups: (Labels)[],
): Row [] {
  const labels_data=groups.slice(-12)
  const tmp_labels = labels_data.map((_, idx) => nonEditable(monthHeaderCell(labels_data[idx], "justify-content-center")));
  const labels = [nonEditable(emptyTextCell)].concat(tmp_labels)
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells:labels,
  };
}


function getBlankRow(title:"Header"): Row {
  return {
    rowId: `${title}`,
    height: ROW_HEIGHT,
    cells: [
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      //nonEditable(monthHeaderCell("Totals", "justify-content-end")),
    ],
  };
}
function getSingleRow(
  title: "" ,
  groups: [],
  //yearlyGroupTotal: number
): Row[] {
  return [
    ...groups.map(({ title, values }) => ({
      rowId: `${title}Header`,
      height: HEADING_ROW_HEIGHT,
      cells: [
        bottomLine(nonEditable(textCell(title, "align-items-end text-md font-bold text-green"))),
        ...values.map((_, idx) => nonEditable(numberCell(values[idx],
                `font-bold disabled text-darkblue`))),
      ],
    })),
  ];
}



function getGroupRows(
  title: "",
  summaryTitle: string,
  groups: [],
  monthlyGroupTotals: MonthlyValues,
): Row[] {
  return [
    {
      rowId: `${title}Header`,
      height: HEADING_ROW_HEIGHT,
      cells: [
          nonEditable(
            textCell(
              title,
              `align-items-end text-lg-red disabled font-bold`
            )
          ),
        ...months().map((_, idx) =>
          nonEditable(
            showZero(
              numberCell(
                monthlyGroupTotals[idx],
              `align-items-end text-lg-red disabled font-bold`
              )
            )
          )
        ),
      ]
    },
    ...groups.map(({ title, values }) => ({
      rowId: title,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell(title, "padding-left-lg")),
        ...values.map((_, idx) => nonEditable(showZero(numberCell(values[idx])))),
      ]
    })),
  ];
}


function getMultiGroupRows(
  title: "",
  summaryTitle: string,
  groups: [],
): Row[] {
  return [
    ...groups.map(({ title, values }) => ({
      rowId: title,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell(title, "text-md-darkblue disabled font-bold")),
        ...values.map((_, idx) => nonEditable(showZero(numberCell(values[idx],`font-bold text-lg-darkblue`)))),
      ]
    })),
  ];
}


function getTotalsRow(
  title: string,
  monthlyTotals: MonthlyValues,
): Row {
  const monthsTotalCell = (value: number) =>
      nonEditable(showZero(numberCell(value, "text-md-darkblue font-bold")))
  return {
    rowId: `${title}Header`,
    height: HEADING_ROW_HEIGHT,
    cells: [
     nonEditable(textCell(title, "text-lg-darkblue disabled font-bold")),
      ...months().map((_, idx) =>
        nonEditable(monthsTotalCell(monthlyTotals[idx]))
      ),
    ],
  };
}

function getTotalsHeadRow(
  title: string,
  monthlyTotals: MonthlyValues,
): Row {
  const monthsTotalCell = (value: number) =>
      bottomLine(nonEditable(showZero(numberCell(value, "text-lg-red disabled font-bold"))));
  return {
    rowId: `${title}Header`,
    height: HEADING_ROW_HEIGHT,
    cells: [
      bottomLine(nonEditable(textCell(title, "text-lg-red disabled font-bold"))),
      ...months().map((_, idx) =>
        bottomLine(nonEditable(monthsTotalCell(monthlyTotals[idx])))
      ),
    ],
  };
}

function getSingleTotalRow(
  title: "" ,
  groups: [],
  //yearlyGroupTotal: number
): Row[] {
  return [
    ...groups.map(({ title, values }) => ({
      rowId: `${title}Header`,
      height: HEADING_ROW_HEIGHT,
      cells: [
        nonEditable(textCell(title, "text-md-darkblue disabled font-bold")),
        ...values.map((_, idx) => nonEditable(numberCell(values[idx],
                `font-bold text-lg-darkblue`))),
      ],
    })),
  ];
}


export function getRowsDetail({
  aktifler,
  monthlyAktifTotals,
  yabancıkaynaklar,
  monthlyYabancıKaynakTotals,
  labels,
}: InputVariables & OutputVariables): Row[] {
    return [
   getHeaderRow(labels),
    ...getGroupRows(
      "AKTİFLER",
      "Toplam",
      aktifler,
      monthlyAktifTotals
    ),
    ...getGroupRows(
      "YABANCI KAYNAKLAR",
      "Toplam",
      yabancıkaynaklar,
      monthlyYabancıKaynakTotals
    ),
  ];
}
