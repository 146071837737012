
import {useEffect, useState } from "react";

// sweetalert2 components
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'

// Sweet Alerts page components
import RateTemplatewithAction from "layouts/financialsector/banking/deposits/components/interestRateswithAction";

import ExtraMiniHighLineChart from "examples/Charts/HighChartsLine/ExtraMiniHighLineChart";

// Data
import interestRates from "layouts/financialsector/banking/deposits/data/interestrates";

function RateWithGraphwithAction({ title, value}) {
  const [lastData, setLastData] = useState(0)
  const [prevData, setPrevData] = useState(0)
  const [lastDataKMH, setLastDataKMH] = useState(0)
  const [prevDataKMH, setprevDataKMH] = useState(0)

  const NewSwal = withReactContent(Swal)
  const irresponse=interestRates(value)
  const showAlert = () =>
    NewSwal.fire({
      title: title,
      html: (<ExtraMiniHighLineChart height="24.75rem" chart={irresponse} />),
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: '<span class="material-icons-round"></span> Close',
    });
    useEffect(() => {
    irresponse.then((response) => {
    const last_values_tmp=response.datasets
    const last_values=last_values_tmp[0].data
    const last = last_values[last_values.length - 1];
    const prev = last_values[last_values.length - 2];
    setLastData(last)
    setPrevData(prev)
    //const last_values_kmh=last_values_tmp[1].data
    //const last_kmh = last_values_kmh[last_values_kmh.length - 1];
    //const prev_kmh = last_values_kmh[last_values_kmh.length - 2];
    //setLastDataKMH(last_kmh)
    //setprevDataKMH(prev_kmh)
   });
    }, []);
  return <RateTemplatewithAction title={title} last={(100*lastData).toFixed(2)} previous={(100*prevData).toFixed(2)} last_with_KMH={(100*lastDataKMH).toFixed(2)} previous_with_KMH={(100*prevDataKMH).toFixed(2)} action={showAlert} />;
}

export default RateWithGraphwithAction;
