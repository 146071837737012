/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/*********************************************************************/
/*********************************************************************/
/******************************DİKKAT*********************************/
/*********************************************************************/
/*********************************************************************/
/* Burda sıralama önemli yani TP.KTF18 2. sıraya yazılırsa loan grafiğinde KMH hariç ve dahil değerleri karışır. */
/*
<Grid item xs={12} lg={2}>
     <RateWithGraphwithAction title={"Ticari Kredi(TL)"} value={["TP.KTF18","TP.KTF17"]}   />
</Grid>
*/
/*********************************************************************/
/*********************************************************************/
/******************************DİKKAT*********************************/
/*********************************************************************/
/*********************************************************************/

// @mui material components
import Grid from "@mui/material/Grid";

// Interest Rate template  components
import RateWithGraph from "layouts/financialsector/banking/loans/components/interestRateGraphs";
import RateWithGraphwithAction from "layouts/financialsector/banking/loans/components/interestRateGraphswithAction";


function IRTemplate() {
  return (
          <Grid container spacing={3}>
            <Grid item xs={12} lg={2}>
                <RateWithGraphwithAction title={"Ticari Kredi(TL)"} value={["TP.KTF18","TP.KTF17"]}   />
            </Grid>
            <Grid item xs={12} lg={2}>
                <RateWithGraph title={"Ticari Kredi(EUR)"} value={["TP.KTF17.EUR"]}  />
            </Grid>
            <Grid item xs={12} lg={2}>
                <RateWithGraph title={"Ticari Kredi(USD)"} value={["TP.KTF17.USD"]}  />
            </Grid>
            <Grid item xs={12} lg={2}>
                <RateWithGraphwithAction title={"İhtiyaç Kredisi"} value={["TP.KTF10","TP.KTF101"]}  />
            </Grid>
            <Grid item xs={12} lg={2}>
                <RateWithGraph title={"Konut Kredisi"} value={["TP.KTF12"]}  />
            </Grid>
            <Grid item xs={12} lg={2}>
                <RateWithGraph title={"Taşıt Kredisi"} value={["TP.KTF11"]}  />
            </Grid>
          </Grid>
  );
}

// Typechecking props for the Template
IRTemplate.propTypes = {
};

export default IRTemplate;
