/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";
import "layouts/government/budget/components/BalanceSheet/components/assets/styleelement.css";
import "layouts/government/budget/components/BalanceSheet/components/assets/styles.css";
import { CellChange, NumberCell, ReactGrid } from "@silevis/reactgrid";
//import "@silevis/reactgrid/styles.css";
import { getColumns } from "layouts/government/budget/components/BalanceSheet/components/getColumns";
import { getRowsDetail}  from "layouts/government/budget/components/BalanceSheet/components/getRows/detay";
import { getRowsSummary}  from "layouts/government/budget/components/BalanceSheet/components/getRows/";

import BarCharts from "examples/Charts/BarCharts/DefaultBarCharts";
import Header from "layouts/government/budget/components/Header";

import {
  Revenue_TIPCG,
  Revenue_TONP,
  Revenue_TONGS,
  Revenue_TONITT,
  Revenue_STD,
  Revenue_Fee,
  Revenue_TONNC,
  Revenue_PI,
  Revenue_GASR,
  Revenue_ISF,
  Revenue_CR,
  Revenue_RC,
  Revenue_RfSBI,
  Revenue_RfRSI,
  Expense_InterestPayment,
  Exc_Expense_InterestPayment,
  Finance_Budget_Escrow,
  Finance_Retain,
  Finance_Debt,
  Finance_Loan,
  Finance_Privatization,
  Finance_Tmsf,
  Finance_Cash,
  InputVariables,
  OutputVariables
} from "layouts/government/budget/components/BalanceSheet/components/interfaces";

import { getChartData, chartOptions } from  "layouts/government/budget/components/BalanceSheet/data/chartdata";
import { getChartDataSummary, chartOptionssummary } from  "layouts/government/budget/components/BalanceSheet/data/chartdatasummary";
import { calculateOutputVariables } from "layouts/government/budget/components/BalanceSheet/components/plannerOutputVariables";

function Summary(data,summary){
  const summarydata=data.data
  const [revenue_tipcg ]= [summarydata.revenue_tipcg]
  const [revenue_tonp ]= [summarydata.revenue_tonp]
  const [revenue_tongs ]= [summarydata.revenue_tongs]
  const [revenue_tonitt ]= [summarydata.revenue_tonitt]
  const [revenue_std ]= [summarydata.revenue_std]
  const [revenue_fee ]= [summarydata.revenue_fee]
  const [revenue_tonnc ]= [summarydata.revenue_tonnc]
  const [revenue_pi ]= [summarydata.revenue_pi]
  const [revenue_gasr ]= [summarydata.revenue_gasr]
  const [revenue_isf ]= [summarydata.revenue_isf]
  const [revenue_cr ]= [summarydata.revenue_cr]
  const [revenue_rc ]= [summarydata.revenue_rc]
  const [revenue_rfsbi ]= [summarydata.revenue_rfsbi]
  const [revenue_rfrsi ]= [summarydata.revenue_rfrsi]
  const [expense_interestpayment ]= [summarydata.expense_interestpayment]
  const [exc_expense_interestpayment ]= [summarydata.exc_expense_interestpayment]
  const [finance_budget_escrow ]= [summarydata.finance_budget_escrow]
  const [finance_retain ]= [summarydata.finance_retain]
  const [finance_debt ]= [summarydata.finance_debt]
  const [finance_loan ]= [summarydata.finance_loan]
  const [finance_privatization ]= [summarydata.finance_privatization]
  const [finance_tmsf ]= [summarydata.finance_tmsf]
  const [finance_cash ]= [summarydata.finance_cash]
  const issummary=data.summary
  const [labels ]= [summarydata.labels]


  const columns = getColumns();
  const inputVariables: InputVariables = {
    revenue_tipcg,
    revenue_tonp,
    revenue_tongs,
    revenue_tonitt,
    revenue_std,
    revenue_fee,
    revenue_tonnc,
    revenue_pi,
    revenue_gasr,
    revenue_isf,
    revenue_cr,
    revenue_rc,
    revenue_rfsbi,
    revenue_rfrsi,
    expense_interestpayment,
    exc_expense_interestpayment,
    finance_budget_escrow,
    finance_retain,
    finance_debt,
    finance_loan,
    finance_privatization,
    finance_tmsf,
    finance_cash,
    labels
  };

  const outputVariables = calculateOutputVariables(inputVariables);
  const plannerData: InputVariables & OutputVariables = {
    ...inputVariables,
    ...outputVariables
  };


const rowsSummary = getRowsSummary(plannerData);
const rowsDetail = getRowsDetail(plannerData);
//console.log(plannerData)
const chardata = getChartData(plannerData,summarydata.labels);
const chardatasummary = getChartDataSummary(plannerData,summarydata.labels);

  return (
 //  valueindex === 1 && (
    <>
          <div className="dcf-app" style={{ width: 1200 }} >

                       <BarCharts
                                    title=""
                                    description= ""
                                    chart={issummary ? chardatasummary : chardata}
                                    height={200}
                                    chartoptions={issummary ? chartOptionssummary : chartOptions}
                                />

        <ReactGrid
          rows={issummary ? rowsSummary : rowsDetail}
          columns={columns}
          stickyTopRows={1}
        />


      </div>
    </>
  //  )
  );
};

export default Summary;
