/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";
import "layouts/nationalaccount/currentaccount/components/BalanceSheet/components/assets/styleelement.css";
import "layouts/nationalaccount/currentaccount/components/BalanceSheet/components/assets/styles.css";
import { CellChange, NumberCell, ReactGrid } from "@silevis/reactgrid";
//import "@silevis/reactgrid/styles.css";
import { getColumns } from "layouts/nationalaccount/currentaccount/components/BalanceSheet/components/getColumns";
import { getRowsDetail}  from "layouts/nationalaccount/currentaccount/components/BalanceSheet/components/getRows/detay";
import { getRowsSummary}  from "layouts/nationalaccount/currentaccount/components/BalanceSheet/components/getRows/";

import BarCharts from "examples/Charts/BarCharts/DefaultBarCharts";
import Header from "layouts/nationalaccount/currentaccount/components/Header";

import {
  İhracat,
  İthalat,
  NetTransit,
  ParasalOlmayanAltın,
  İmalat,
  BakımOnarım,
  Taşımacılık,
  DigerHizmet,
  UcretOdeme,
  YatırımGelirleri,
  İkinciGelirDengesi,
  DogrudanYatırımNetVarlık,
  DogrudanYatırımNetYukumluluk,
  PortfoyYatırımNetVarlık,
  PortfoyYatırımNetYukumluluk,
  EfektifMevduatNetVarlık,
  EfektifMevduatNetYukumluluk,
  KrediNetVarlık,
  KrediNetYukumluluk,
  DigerYatırımlarDiger,
  RezervVarlıklar,
  NetHataNoksan,
  InputVariables,
  OutputVariables
} from "layouts/nationalaccount/currentaccount/components/BalanceSheet/components/interfaces";

import { getChartData, chartOptions } from  "layouts/nationalaccount/currentaccount/components/BalanceSheet/data/chartdata";
import { getChartDataSummary, chartOptionssummary } from  "layouts/nationalaccount/currentaccount/components/BalanceSheet/data/chartdatasummary";
import { calculateOutputVariables } from "layouts/nationalaccount/currentaccount/components/BalanceSheet/components/plannerOutputVariables";

function Summary(data,summary){
  const summarydata=data.data
  const [ihracat ]= [summarydata.ihracat]
  const [ithalat ]= [summarydata.ithalat]
  const [nettransit ]= [summarydata.nettransit]
  const [parasalolmayanaltın ]= [summarydata.parasalolmayanaltın]
  const [imalat ]= [summarydata.imalat]
  const [bakımonarım ]= [summarydata.bakımonarım]
  const [taşımacılık ]= [summarydata.taşımacılık]
  const [digerhizmet ]= [summarydata.digerhizmet]
  const [ucretodeme ]= [summarydata.ucretodeme]
  const [yatırımgelirleri ]= [summarydata.yatırımgelirleri]
  const [ikincigelir ]= [summarydata.ikincigelir]
  const [dogrudanyatırımnetvarlık ]= [summarydata.dogrudanyatırımnetvarlık]
  const [dogrudanyatırımnetyukumluluk ]= [summarydata.dogrudanyatırımnetyukumluluk]
  const [portfoyyatırımnetvarlık ]= [summarydata.portfoyyatırımnetvarlık]
  const [portfoyyatırımnetyukumluluk ]= [summarydata.portfoyyatırımnetyukumluluk]
  const [efektifmevduatnetvarlık ]= [summarydata.efektifmevduatnetvarlık]
  const [efektifmevduatnetyukumluluk ]= [summarydata.efektifmevduatnetyukumluluk]
  const [kredinetvarlık ]= [summarydata.kredinetvarlık]
  const [kredinetyukumluluk ]= [summarydata.kredinetyukumluluk]
  const [digeryatırımlardiger ]= [summarydata.digeryatırımlardiger]
  const [rezervvarlıklar ]= [summarydata.rezervvarlıklar]
  const [nethatanoksan ]= [summarydata.nethatanoksan]
  const issummary=data.summary
  const [labels ]= [summarydata.labels]


  const columns = getColumns();
  const inputVariables: InputVariables = {
    ihracat,
    ithalat,
    nettransit,
    parasalolmayanaltın,
    imalat,
    bakımonarım,
    taşımacılık,
    digerhizmet,
    ucretodeme,
    yatırımgelirleri,
    ikincigelir,
    dogrudanyatırımnetvarlık,
    dogrudanyatırımnetyukumluluk,
    portfoyyatırımnetvarlık,
    portfoyyatırımnetyukumluluk,
    efektifmevduatnetvarlık,
    efektifmevduatnetyukumluluk,
    kredinetvarlık,
    kredinetyukumluluk,
    digeryatırımlardiger,
    rezervvarlıklar,
    nethatanoksan,
    labels
  };

  const outputVariables = calculateOutputVariables(inputVariables);
  const plannerData: InputVariables & OutputVariables = {
    ...inputVariables,
    ...outputVariables
  };


const rowsSummary = getRowsSummary(plannerData);
const rowsDetail = getRowsDetail(plannerData);
//console.log(plannerData)
const chardata = getChartData(plannerData,summarydata.labels);
const chardatasummary = getChartDataSummary(plannerData,summarydata.labels);

  return (
 //  valueindex === 1 && (
    <>
          <div className="dcf-app" style={{ width: 1200 }} >

                       <BarCharts
                                    title=""
                                    description= ""
                                    chart={issummary ? chardatasummary : chardata}
                                    height={200}
                                    chartoptions={issummary ? chartOptionssummary : chartOptions}
                                />


        <ReactGrid
          rows={issummary ? rowsSummary : rowsDetail}
          columns={columns}
          stickyTopRows={1}
        />
      </div>
    </>
  //  )
  );
};

export default Summary;
