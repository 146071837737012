import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// prop-types is a library for type checking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import DayCard from "examples/Cards/DayCard";

import Graphs from "layouts/financialsector/banking/loans/components/loanInfo";

//Charts
import DefaultAmDoughnutChart from "examples/Charts/AmChartsDoughnut/DefaultAmDoughnutChart";

// Data
import amChartsDoughnutData from "layouts/financialsector/banking/loans/data/amChartsDoughnutData";
import lastupdatedate from "layouts/financialsector/banking/loans/data/lastupdatedate";

// Images
import bank from "assets/images/small-logos/creditcard.png";

function LoanTemplate() {
   const lastdate=lastupdatedate()
   const d = new Date(lastdate);
  return (
    <Grid container spacing={3}>
            <Grid item xs={12} xl={7}>
              <Graphs />
            </Grid>
            <Grid item xs={12} xl={5}>
                <Grid item xs={12}>
                  <DayCard
                    title="Son eklenen veri"
                    day={{ location: lastdate.date}}
                    icon={{ component: bank, text: "" }}
                  />
                </Grid>
               <Card sx={{ mt: 2 }} >
                <SoftBox
                     alignItems="center"
                     position="relative"
                     top={15}
                     left={0}
                    width="calc(100% - 0rem)"
                 >
                 <DefaultAmDoughnutChart
                    title=""
                    description=""
                    height="19.75rem"
                    chart={amChartsDoughnutData}
                 />
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
  );
}

// Typechecking props for the Template
LoanTemplate.propTypes = {
};

export default LoanTemplate;
