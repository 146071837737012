import { ChartOptions, ChartData } from "chart.js";
import { InputVariables, OutputVariables } from "layouts/realsector/dcf/components/interfaces";

export const getChartData = (
  plannerData: InputVariables & OutputVariables
): ChartData => ({
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
  ],
  datasets: [
    /*{
      label: "Cashbox/bank",
      borderColor: "#FF5733",
      backgroundColor: "#FF5733",
      type: "line",
      fill: false,
      lineTension: 0,
      data: plannerData.cashboxBank.map((value) => value),
      pointRadius: 4,
      borderWidth: 2
    },*/
    {
      label: "Net Satışlar",
      borderColor:  "#280659",
      backgroundColor: "rgba(40,6,89,0.5)",
      fill: false,
      stack: "1",
      barPercentage: 0.8,
      data: plannerData.monthlyInflowTotals.map((value) => value),
      borderWidth: 2
    },
    {
      label: "Maliyetler",
      borderColor:  "#900c3e",
      backgroundColor:"rgba(144,12,62,0.5)",
      stack: "2",
      fill: false,
      barPercentage: 0.8,
      data: plannerData.monthlyOutflowTotals.map((value) => value),
      borderWidth: 2
    },
       /*  {
      label: "Özkaynaklar",
      borderColor:  "#D4AF37",
      backgroundColor: "rgba(212,175,55,0.5)",
      stack: "2",
      fill: false,
      barPercentage: 0.7,
      data: plannerData.monthlyEquityTotals.map((value) => value),
      borderWidth: 1
    },*/
   /* {
      label: "Credit Line Overdraft",
      borderColor: "#D4AF37",
      backgroundColor: "#D4AF37",
      type: "line",
      fill: false,
      lineTension: 0,
      data: plannerData.cumulativeTotals.map((value, idx) => {
        const overdraft =
          -value - (isNaN(plannerData.creditLine) ? 0 : plannerData.creditLine);
        return overdraft > 0 ? overdraft : NaN;
      })
    }*/
  ]
});

export const chartOptions: ChartOptions = {
  legend: {
    display: true
  },
  elements: {
    line: {
      tension: 0.1
    }
  },
  responsive: true,
  plugins: {
    filler: {
      propagate: true
    }
  },
  layout: {
    padding: {
      left: 245
    }
  },
  scales: {
    xAxes: [
      {
        display: true,
        ticks: {
          autoSkip: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ]
  }
};
