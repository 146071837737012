// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Footer from "examples/Footer";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
//Graphs

//Data

// Soft UI Dashboard PRO React example components
import CreditCard from "layouts/financialsector/banking/creditcard/components/CreditCard";
import CreditCardPerTxn from "layouts/financialsector/banking/creditcard/components/CreditCardPerTxn";
import CreditCardIndex from "layouts/financialsector/banking/creditcard/components/CreditCardIndex";
import CreditCardChange from "layouts/financialsector/banking/creditcard/components/CreditCardChange";


function CreditCardPage() {
//const titlename=stock.stock + " Nakit Akımları (1000 TL)"
const { values } = breakpoints;

  return (


    <DashboardLayout>
      <DashboardNavbar />
       <CreditCard/>
     <SoftTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    İşlem Başına Harcama Tutarı (TL)
                </SoftTypography>
       <CreditCardPerTxn/>
       <SoftTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    Enflasyondan Arındırılmış Harcama Endeksi (2015=100)
                </SoftTypography>
       <CreditCardIndex/>

                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold">
                </SoftTypography>
                 <CreditCardChange startdate={"2024-08-20"} enddate={"2024-09-20"}/>

            <SoftBox mt={10} width="100%">
              <Divider sx={{height: 8}}/>
             </SoftBox>

     <Footer />
    </DashboardLayout>

  );
}
export default CreditCardPage;
