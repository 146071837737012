

import { useState } from "react";


// prop-types is a library for type checking of props
import PropTypes from "prop-types";

import StockFlowCard from "examples/Cards/StockFlowCardforDepo";
import controllerCardIcons from "layouts/financialsector/banking/loans/data/controllerCardIcons";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";



function RateTemplatewithAction({ title,last,previous,last_with_KMH,previous_with_KMH, action  }) {

const {
    iconLight,
    iconDark,
  } = controllerCardIcons;

const [iconState, setIconState] = useState(true);
  return (
            <StockFlowCard
              state={iconState}
              icon={iconState ? iconDark : iconDark}
              title={title}
              description={ iconState ? "Önceki Hafta: "+" %"+previous : "Önceki Hafta: "+" %"+previous_with_KMH }
              value={ iconState ? '%' +last: '%' +last_with_KMH}
              onChange={() => setIconState(!iconState)}
              action={action}
            />
  );
}

// Typechecking props for the Template
RateTemplatewithAction.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

export default RateTemplatewithAction;
