// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@tailwind base;
@tailwind components;
@tailwind utilities;

#overlay {
font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
margin:0;color:rgba(0, 0, 0, 0.87);
font-size:1rem;
font-weight:400;
line-height:1.625;
letter-spacing:0.00938em;
background-color:#f8f9fa;
}


@layer components {
.tabs-boxed .tab-active {
    background-color: rgb(30 41 59 / var(--tw-bg-opacity))!important;
    --tw-bg-opacity: .5 !important;
    --tw-text-opacity: 1 !important;
    color: rgb(226 232 240 / var(--tw-text-opacity))!important;
}
}

::-webkit-scrollbar {
  @apply w-1 bg-transparent;
}

::-webkit-scrollbar-thumb {
  @apply bg-light-grey dark:bg-dark-grey rounded-xl;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/econgpt/src/styles.css"],"names":[],"mappings":"AAAA,cAAc;AACd,oBAAoB;AACpB,mBAAmB;;AAEnB;AACA,+HAA+H;AAC/H,QAAQ,CAAC,yBAAyB;AAClC,cAAc;AACd,eAAe;AACf,iBAAiB;AACjB,wBAAwB;AACxB,wBAAwB;AACxB;;;AAGA;AACA;IACI,gEAAgE;IAChE,8BAA8B;IAC9B,+BAA+B;IAC/B,0DAA0D;AAC9D;AACA;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iDAAiD;AACnD","sourcesContent":["@tailwind base;\n@tailwind components;\n@tailwind utilities;\n\n#overlay {\nfont-family: ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\";\nmargin:0;color:rgba(0, 0, 0, 0.87);\nfont-size:1rem;\nfont-weight:400;\nline-height:1.625;\nletter-spacing:0.00938em;\nbackground-color:#f8f9fa;\n}\n\n\n@layer components {\n.tabs-boxed .tab-active {\n    background-color: rgb(30 41 59 / var(--tw-bg-opacity))!important;\n    --tw-bg-opacity: .5 !important;\n    --tw-text-opacity: 1 !important;\n    color: rgb(226 232 240 / var(--tw-text-opacity))!important;\n}\n}\n\n::-webkit-scrollbar {\n  @apply w-1 bg-transparent;\n}\n\n::-webkit-scrollbar-thumb {\n  @apply bg-light-grey dark:bg-dark-grey rounded-xl;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
