import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


// banking dashboard components
import MainTemplate from "layouts/realsector/sectors/tourism/components/mainTemplate";
import NPLTemplate from "layouts/financialsector/banking/loans/components/nplTemplate";
import IRTemplate from "layouts/financialsector/banking/loans/components/interestRateTemplate";
import InfoTemplate from "layouts/financialsector/banking/loans/components/infoTemplate";


function Loans() {
  const { values } = breakpoints;

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <SoftBox pt={3}>
            <SoftBox mb={3}>
                <MainTemplate/>
            </SoftBox>
        </SoftBox>

        <SoftBox mt={10} width="100%">
            <Divider sx={{height: 8}}/>
        </SoftBox>

     <Footer />
    </DashboardLayout>
  );
}

export default Loans;
