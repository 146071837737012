import { useState } from "react";
import {useEffect } from "react";

import Select from "react-select";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";
import SoftEditor from "components/SoftEditor";
import SoftDropzone from "components/SoftDropzone";
import SoftButton from "components/SoftButton";

import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Templates
import BalanceSheetTemplate from "layouts/financialsector/tcmb/analytic/components/BalanceSheetTemplate";

const { values } = breakpoints;



function MainTemplate() {
 return (
  <div>
            <SoftBox mb={0} p={1} pt={0} >
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold">
                    Analitik Bilanço
                </SoftTypography>
            </SoftBox>

            <SoftBox mb={0}>
                 <BalanceSheetTemplate/>
            </SoftBox>

            <SoftBox mt={10} width="100%">
              <Divider sx={{height: 8}}/>
             </SoftBox>


 </div>
);
}
export default MainTemplate;
