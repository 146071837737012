
/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DebtGraph from "layouts/government/budget/components/DebtGraph";

// Data
import foreigndebtfaiz from "layouts/government/budget/data/foreigndebtfaiz";
import foreigndebtspread from "layouts/government/budget/data/foreigndebtspread";

import { getChartData, chartOptions } from  "layouts/government/budget/components/data/foreigndebt";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


function ForeignDebtTemplate() {

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

 const handleSetTabValue = (event, newTabValue) => setTabValue(newTabValue);


 const params=["07112019","30032021","23032023","22032024","05022025","30052040","14012041"]

// const data=foreigndebtfaiz(params)
 const data=foreigndebtfaiz(params)
 const data1=foreigndebtspread(params)

const chardata = getChartData(data["07112019"],data["30032021"],data["23032023"],data["22032024"],
                              data["05022025"],data["30052040"],data["14012041"],data.labels);

const chardata1 = getChartData(data1["07112019"],data1["30032021"],data1["23032023"],data1["22032024"],
                              data1["05022025"],data1["30052040"],data1["14012041"],data1.labels);
  return (
   <Grid item xs={12} lg={6} >
<Item>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={1}>
        <SoftTypography variant="h6">Dış Borçlanma (Aylık Ort.)</SoftTypography>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="60%">
          <SoftBox width="90%">
              <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab label="Faiz" />
                <Tab label="Spread" />
              </Tabs>
            </AppBar>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox p={1} mt={0} width="100%" height="26.5rem">
        <DebtGraph data={chardata}  value={tabValue} index={0} chartoptions={chartOptions}  />
        <DebtGraph data={chardata1}  value={tabValue} index={1} chartoptions={chartOptions}  />
      </SoftBox>
     </Item>
   </Grid>
  );
}

export default ForeignDebtTemplate;
