/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useEffect, useState, useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
//import { Line } from "react-chartjs-2";

// highcharts components
//import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts/highstock';
import highchartsMore from "highcharts/highcharts-more";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// HighChartsLine configurations
import configs from "examples/Charts/HighChartsCombo/DefaultHighComboChart/configs";

function DefaultHighComboChart({ title, description, height, chart }) {
  highchartsMore(Highcharts);
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({});
  const { options } = chartData;
  useEffect(() => {
    chart.then((response) => {
      console.log(response)
      const chartDatasets = response.datasets.map((dataset) => ({
        ...dataset,
      }));
      setChartData(configs(response.labels, chartDatasets));
    });
  }, [chart]);
  const renderChart = (
    <SoftBox p={2}>
      {title || description ? (
        <SoftBox px={description ? 1 : 0} pt={description ? 1 : 0}>
          {title && (
            <SoftBox mb={1}>
              <SoftTypography variant="h6">{title}</SoftTypography>
            </SoftBox>
          )}
          <SoftBox mb={2}>
            <SoftTypography variant="button" fontWeight="regular" textcolor="text">
              {description}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ) : null}
      {useMemo(
        () => (
          <div ref={chartRef} style={{ height}}>
            <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'}  options={options} />
          </div>
        ),
        [chartData, height]
      )}
    </SoftBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of GradientLineChart
DefaultHighComboChart.defaultProps = {
  title: "",
  description: "",
  height: "19.125rem",
};

// Typechecking props for the GradientLineChart
DefaultHighComboChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default DefaultHighComboChart;
