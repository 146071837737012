/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
//import typography from "assets/theme/base/typography";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as sunburst from "@amcharts/amcharts4/plugins/sunburst";

function configs(charts, datasets) {
  am4core.useTheme(am4themes_animated);
//charts.paddingRight = -50;
//charts.paddingLeft = 0;

charts.padding(-50,0,0,40);
charts.radius = am4core.percent(98);

charts.colors.list = [
    am4core.color("#280659"),
    am4core.color("#900c3e"),
    am4core.color("#C70039"),
    am4core.color("#D54800"),
    am4core.color("#FF5733"),
  ];


charts.data = datasets;

charts.colors.step = 1;
charts.fontSize = 11;
charts.innerRadius = am4core.percent(10);

// define data fields
charts.dataFields.value = "value";
charts.dataFields.name = "name";
charts.dataFields.children = "children";


var level0SeriesTemplate = new sunburst.SunburstSeries();
level0SeriesTemplate.hiddenInLegend = false;
charts.seriesTemplates.setKey("0", level0SeriesTemplate)

// this makes labels to be hidden if they don't fit
level0SeriesTemplate.labels.template.truncate = true;
level0SeriesTemplate.labels.template.hideOversized = true;
level0SeriesTemplate.labels.fontSize = 11;

level0SeriesTemplate.labels.template.adapter.add("rotation", function(rotation, target) {
  target.maxWidth = target.dataItem.slice.radius - target.dataItem.slice.innerRadius - 10;
  target.maxHeight = Math.abs(target.dataItem.slice.arc * (target.dataItem.slice.innerRadius + target.dataItem.slice.radius) / 2 * am4core.math.RADIANS);

  return rotation;
})


var level1SeriesTemplate = level0SeriesTemplate.clone();
charts.seriesTemplates.setKey("1", level1SeriesTemplate)
level1SeriesTemplate.fillOpacity = 0.75;
level1SeriesTemplate.hiddenInLegend = true;

var level2SeriesTemplate = level0SeriesTemplate.clone();
charts.seriesTemplates.setKey("2", level2SeriesTemplate)
level2SeriesTemplate.fillOpacity = 0.5;
level2SeriesTemplate.hiddenInLegend = true;

charts.legend = new am4charts.Legend();
//charts.legend.useDefaultMarker = false;
//var marker = charts.legend.markers.template.children.getIndex(0);
//marker.cornerRadius(0, 0, 0, 0);
//marker.strokeWidth = 2;
//marker.strokeOpacity = 1;
//marker.stroke = am4core.color("#ccc");
//charts.legend.fontFamily= "sans";
charts.legend.fontSize= 12;
charts.legend.fontWeight= 600;

charts.legend.valueLabels.template.align = "right";
charts.legend.valueLabels.template.textAlign = "end";
charts.legend.parent = charts.chartContainer;
//charts.legend.background.fill = am4core.color("#000");
//charts.legend.background.fillOpacity = 0.05;
charts.legend.align = "left";
charts.legend.width = 300;
//charts.legend.itemContainers.template.paddingRight = 0
charts.legend.marginRight = 0;
charts.legend.padding(0, 0, 0, 0);
charts.legend.valueLabels.template.align = "left"
charts.legend.valueLabels.template.textAlign = "start"
charts.legend.itemContainers.template.paddingTop = 2;
charts.legend.itemContainers.template.paddingBottom = 2;
//charts.legend.margin(-10, -10, -10, -10);
charts.legend.padding(50, 0, 0, -120);
//charts.legend.align = "left";
//charts.legend.width = 200;
//charts.legend.valueLabels.template.paddingLeft = 0;


}
export default configs;
