/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import get_reserve_data from "layouts/api";

function getField(array, field) {
var result;
var values = Object.keys(array).map(function(key){
        if (key == field) {
          result=array[key];
	    }
});
return result
}

function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}


function reserveData(params) {

   const defaultData= { "Altın": [{'title': 'Altın', 'values': [ 100,10,5,145,200,40,210]}],
                        "Brüt Rezervler": [{'title': 'Brüt Rezerv', 'values': [ 100,10,5,145,200,40,210]}],
                         "Net Rezerv": [{'title': 'Net Rezerv', 'values': [ 100,10,5,145,200,40,210]}],
                        labels: [ "","","","","","",""],
    }
    const [data, setData] = useState(defaultData);

    //const dataFetchedRef = useRef(false);
    const fetchData = async () => {
        const config={"func":"reserve_data","params":params}
        const response = await get_reserve_data(config);
        const tmpdata = await transpose(response.data);
        const altın=getField(tmpdata,"Altın")
        const dovızreserv=getField(tmpdata,"Döviz Rezervi")
        const bankaswap=getField(tmpdata,"Banka Swap")
        const digerswap=getField(tmpdata,"Diğer Swap")
        const netrezerv=getField(tmpdata,"Net Rezerv")
        const swapharicnetrezerv=getField(tmpdata,"Swap Hariç Net Rezerv")
        const toplamrezerv=getField(tmpdata,"Toplam Rezervler")

        const date = tmpdata.date;
        //const date = transposedata.date;
	    setData({altın:altın,dovızreserv:dovızreserv,bankaswap:bankaswap,digerswap:digerswap,
	    netrezerv:netrezerv,swapharicnetrezerv:swapharicnetrezerv,toplamrezerv:toplamrezerv,labels:date});
    }

    useEffect(() => {
            fetchData();
    },[]);
  return data
}

export default reserveData;
