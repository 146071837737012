/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable no-dupe-keys */
// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

const { gradients, dark } = colors;


export const getChartData = (
  datasets,labels
): ChartData => ({
  labels: labels,
  datasets:datasets
});


export function configs(datasets, labels) {
  const backgroundColors = [];
  if (datasets[0].backgroundColors) {
    datasets[0].backgroundColors.forEach((color) =>
      gradients[color]
        ? backgroundColors.push(color)
        : backgroundColors.push(color)
    );
  } else {
    backgroundColors.push(color);
  }

  return {
    data: {
      labels,
      datasets: [
        {
          label: datasets.label,
          weight: 9,
          cutout: 0,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          backgroundColor: backgroundColors,
          fill: false,
          data: datasets.data,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'left',
        },
        datalabels: {
                        formatter: (value, context) => {
                            let percentage = (value / context.chart._metasets
                            [context.datasetIndex].total * 100)
                                .toFixed(2) + '%';
                            return context.dataset.label[context.dataIndex]+ ":" + percentage ;
                        },
                        display: function(context) {
                                        console.log(context.dataset.label[context.dataIndex])
                                        return context.dataset.data[context.dataIndex] >= 100000; // or >= 1 or ...
                        },
                        color: '#fff',
                        anchor: function(context) {
                                return -225;
                           },
                        align:function(context) {
                                return -245;
                           },
                        font: {
                         weight: 'bold',
                            size: 14,
                        }
                    }
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
}

export default configs;
