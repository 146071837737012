import {
  Aktifler,
  YabancıKaynaklar,
  MonthlyValues
} from "layouts/financialsector/banking/balancesheet/components/BalanceSheet/components/interfaces";

import { months } from "layouts/financialsector/banking/balancesheet/components/BalanceSheet/components/helpers";


const sumGroupInAMonth = (group: (Aktifler | YabancıKaynaklar  )[]) => (
  monthIndex: number
): number =>
  group.reduce(
    (acc, { values }) =>
      acc + (isNaN(values[monthIndex]) ? 0 : values[monthIndex]),
    0
  );

const calcMonthlyGroupTotals = (
  group: (Aktifler | YabancıKaynaklar )[]
): MonthlyValues => months().map((_, idx) => sumGroupInAMonth(group)(idx));

// DCF'e eklenecek.
export function calculateOutputVariables2(
  inputVariables: InputVariables
): OutputVariables {
  const {
    aktifler,
    yabancıkaynaklar,
  } = inputVariables;

  const monthlyAktifTotals = calcMonthlyGroupTotals(aktifler);
  const monthlyYabancıKaynakTotals = calcMonthlyGroupTotals(yabancıkaynaklar);


  return {
    monthlyAktifTotals,
    monthlyYabancıKaynakTotals,
  };
}
