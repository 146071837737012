import { ChartOptions, ChartData } from "chart.js";

// const params=["07112019","30032021","23032023","22032024","05022025","30052040","14012041"]

export const getChartData = (
  datasets,labels
): ChartData => ({
  labels: labels,
  datasets:datasets
});


export const chartOptions: ChartOptions =  {
    interaction:
    {mode:'index'},
  elements: {
    line: {
      tension: 0.1
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
  //stacked100: { enable: true, replaceTooltipLabel: false },
  legend: {
  display: true,
  position:'bottom',
  align: 'start',
  labels: {
        usePointStyle: true,
        font: { size: 9.5  }
      }
  },
    filler: {
      propagate: true
    }
  },
      scales: {
        y: {
          grid: {
            drawBorder: true,
            display: true,
            drawOnChartArea: true,
            drawTicks: true,
            borderDash: [5, 5],
          },
          ticks: {
            display: true,
            padding: 2,
            font: {
              size: 10,
              style: "normal",
              lineHeight: 1.6,
            },
          },
        },
        x: {
          grid: {
            drawBorder: true,
            display: true,
            drawOnChartArea: true,
            drawTicks: true,
            borderDash: [5, 5],
          },
          ticks: {
            display: true,
            padding: 0,
            font: {
              size: 10,
              style: "normal",
              lineHeight: 1.6,
            },
          },
        },

      },

  };