/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
//import typography from "assets/theme/base/typography";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

function configs(charts, datasets) {
  am4core.useTheme(am4themes_animated);
  //charts.paddingRight = 0;

 var data = datasets

/**
 * Chart container
 */

// Create chart instance
charts.width = am4core.percent(98);
charts.height = am4core.percent(98);
charts.layout = "horizontal";
charts.padding(0,0,0,-350);


/**
 * Column chart
 */

// Create chart instance
var columnChart = charts.createChild(am4charts.XYChart);

// Create axes
var categoryAxis = columnChart.yAxes.push(new am4charts.CategoryAxis());
categoryAxis.dataFields.category = "category";
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.renderer.inversed = true;
categoryAxis.renderer.minGridDistance = 20;

//categoryAxis.renderer.cellStartLocation = 0.1;
//categoryAxis.renderer.cellEndLocation = 0.8;

var valueAxis = columnChart.xAxes.push(new am4charts.ValueAxis());

// Create series
var columnSeries = columnChart.series.push(new am4charts.ColumnSeries());
columnSeries.dataFields.valueX = "value";
columnSeries.dataFields.categoryY = "category";
columnSeries.columns.template.strokeWidth = 0;
//columnSeries.columns.template.width = am4core.percent(20);
//columnSeries.columns.template.width = 1;


// Set up tooltip
columnSeries.columns.template.tooltipText ="{category}: {value}";
columnSeries.tooltip.getFillFromObject = true;
columnSeries.tooltip.label.propertyFields.fill = "color";
columnSeries.tooltip.background.propertyFields.stroke = "color";

//columnChart.width = am4core.percent(100);
//columnChart.height = am4core.percent(100);
//columnChart.padding(30,-50,0,-60);

/**
 * Pie chart
 */

// Create chart instance
var pieChart = charts.createChild(am4charts.PieChart);
pieChart.data = data;
pieChart.innerRadius = am4core.percent(50);

// Add and configure Series
var pieSeries = pieChart.series.push(new am4charts.PieSeries());
pieSeries.dataFields.value = "value";
pieSeries.dataFields.category = "category";
pieSeries.slices.template.propertyFields.fill = "color";
pieSeries.labels.template.disabled = true;



// Set up labels
var label1 = pieChart.seriesContainer.createChild(am4core.Label);
label1.text = "";
label1.horizontalCenter = "middle";
label1.fontSize = 35;
label1.fontWeight = 600;
label1.dy = -30;

var label2 = pieChart.seriesContainer.createChild(am4core.Label);
label2.text = "";
label2.horizontalCenter = "middle";
label2.fontSize = 12;
label2.dy = 20;

// Auto-select first slice on load
pieChart.events.on("ready", function(ev) {
  pieSeries.slices.getIndex(0).isActive = true;
});

pieChart.width = am4core.percent(70);
pieChart.height = am4core.percent(70);
pieChart.padding(30,-50,0,-60);

// Set up toggling events
pieSeries.slices.template.events.on("toggled", function(ev) {
  if (ev.target.isActive) {

    // Untoggle other slices
    pieSeries.slices.each(function(slice) {
      if (slice != ev.target) {
        slice.isActive = false;
      }
    });

    // Update column chart
    columnSeries.appeared = false;
    columnChart.data = ev.target.dataItem.dataContext.breakdown;
    columnSeries.fill = ev.target.fill;
    columnSeries.reinit();

    // Update labels
    label1.text = pieChart.numberFormatter.format(ev.target.dataItem.values.value.percent, "#.'%'");
    label1.fill = ev.target.fill;

    label2.text = ev.target.dataItem.category;
  }
});

// Add a legend
// Acharts.legend = new am4charts.Legend();
// Avar watermark = charts.createChild(am4core.Label);
// Awatermark.text = "Kaynak: [bold]HMB[/]";
// Awatermark.align = "center";
// Awatermark.fillOpacity = 0.5;
//charts.exporting.menu = new am4core.ExportMenu();
//chart.exporting.export("png");
//let imgData = await chart.exporting.getImage("png");


}
export default configs;
