/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
//import typography from "assets/theme/base/typography";

function configs(labels, datasets) {
  return {
    options: {
      xAxis: {
            tickPositioner: function(min, max){
             var interval = this.options.tickInterval,
                 ticks = [],
                 count = 0;

            while(min < max) {
               if(min%2==0){
                   ticks.push(min);
                }
                ticks.push(min);
                min += interval;
                count ++;
            }

            ticks.push(max);

            ticks.info = {
                unitName: 'day',
                count: 2,
                higherRanks: {},
		        totalRange: interval * count
            }
            return ticks;
        },
        categories: labels,
        gridLineDashStyle: "dash",
        gridLineWidth: 1,
        labels: {style:{fontSize: 12}},
        tickInterval:37,
      },
      yAxis: {
            tickPositioner: function () {
            let tick = Math.floor(this.dataMin);
            const positions = [],
                increment = Math.ceil((this.dataMax - this.dataMin))/25;

            if (this.dataMax !== null && this.dataMin !== null) {
                for (tick;
                    tick - increment <= this.dataMax;
                    tick += increment) {
                    positions.push(tick);
                }
            }
            return positions;
        },
      title: { text: null},
      labels: {
            formatter: function () {
                    return  '%'+(100*this.value).toFixed(0) ;
                },
                style:{fontSize: 9},
        },
        gridLineDashStyle: "dash",
        gridLineWidth: 1,
        lineWidth: 1,
        tickWidth: 2,
        tickInterval: 400,

      },
      series: datasets,
      tooltip: {
        pointFormatter: function() {
          return   '<span style="color:'+this.series.color+'"> <b>'+ this.series.name +'</b></span>:% <b>'+ (100*this.y).toFixed(2)  + '</b><br/>';
        },
        shared: true,
        valueDecimals: 1,
    },
      chart: {
        type: "areaspline",
        height: 390,

      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        x: 0,
        y: 15,
    },
      colors: ["#280659", "#900c3e","#1D7874", "#C70039", "#D54800", "#FF5733"],
      fontSize:0.4,
      plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
                formatter: function(label) {
                    if (this.point.index === this.series.points.length - 1)
                        //console.log(this.point)
                        return  '<span style="color:'+"red"+'">%<b>'+ (100*this.y).toFixed(2) +'</b></span><br/>';
                        //(100*this.y).toFixed(2)
          //'<span style="color:'+this.series.color+'"> %<b>'+ (100*this.y).toFixed(2) +'</b></span> <br/>';
                }
            },
          },
          areaspline: { fillOpacity: 0.3},
      },
      title: {text: null},
      credits: { enabled: false},
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                enable: false,
              },
              yAxis: {
                gridLineDashStyle: "longdash",
                lineWidth: 1,
                tickWidth: 1,
                labels: {
                  align: "left",
                  x: 0,
                  y: -5,
                },
                title: {
                  text: null,
                },
              },
              subtitle: {
                text: null,
              },
              credits: {
                enabled: false,
              },
            },
          },
        ],
      },
    },
  };
}

export default configs;
