// @mui material components
import Grid from "@mui/material/Grid";
//Graphs
import Summary from "layouts/realsector/firms/components/Summary/components/Summary";
//import rasyolarData from "layouts/realsector/firms/data/rasyolar";
//import rawData from "layouts/realsector/firms/components/BalanceSheet/data/rawDataTmp";
import rasyolarData from "layouts/realsector/firms/data/rasyolar";


function SummaryTemplate(stock) {
const data=rasyolarData(stock.stock)
  return (
         <Summary data={data}/>
  );
}
export default SummaryTemplate;
