/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import get_trades__index_data from "layouts/api";
import thinBarChartData from "layouts/nationalaccount/tradebalance/data/thinBarChartData";

function getField(array, field) {
var result;
var values = Object.keys(array).map(function(key){
        if (key == field) {
          result=array[key];
	    }
});
return result
}

function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}


function create_dataset(index,miktarendeks,miktarlabel,birimendeks,birimlabel){
  const CHART_COLORS = [
    "rgb(29, 120, 116,0.1)",
    "rgba(144,12,62,0.1)",
    "#eb8a40",
    "#f0a04b",
    "#f5b858",
    "#f9cf63",
    "#fde76e",
    "#fced86",
    "#ffffb7",
    "#fefeeb"
  ];
  const CHART_COLORS2 = [
    "#1D7874",
    "#900c3e",
    "#eb8a40",
    "#f0a04b",
    "#f5b858",
    "#f9cf63",
    "#fde76e",
    "#fced86",
    "#ffffb7",
    "#fefeeb"
  ];
    const CHART_COLORS3 = [
    "#280659",
    "#900c3e",
    "#e77235",
    "#eb8a40",
    "#f0a04b",
    "#f5b858",
    "#f9cf63",
    "#fde76e",
    "#fced86",
    "#ffffb7",
    "#fefeeb"
  ];
  const CHART_COLORS4 = [
     "rgba(40,6,89,0.3)",
     "rgba(144,12,62,0.3)",
    "#e77235",
    "#eb8a40",
    "#f0a04b",
    "#f5b858",
    "#f9cf63",
    "#fde76e",
    "#fced86",
    "#ffffb7",
    "#fefeeb"
  ];
     const indexdata={
        labels:index,
        spend_per_channel: {
        miktar: [{
        label: miktarlabel,
        data: miktarendeks
      }],
    birim: [{
        label:birimlabel,
        data: birimendeks
      }]
      }
        }

    const miktardata = indexdata.spend_per_channel.miktar.map(
    (spend, index) => {
      return {
        label: spend.label,
        borderColor: CHART_COLORS2[index],
        backgroundColor: CHART_COLORS[index],
        data: spend.data,
        stack: 1,
        type:"line"
      };
    }
  );

     const birimdata = indexdata.spend_per_channel.birim.map((sale, index) => {
    return {
      label: sale.label,
      borderColor: CHART_COLORS3[index],
      backgroundColor: CHART_COLORS4[index],
      data: sale.data,
      stack: 2,
      type: "bar"
    };
  });

  const newdataset = [miktardata, birimdata];
  const channeldata = newdataset.flat();

  const datasets = {
    labels:index,
    datasets: channeldata
  };

 return datasets
}

function Trades(params) {

   const defaultData= {
    "monthly_ithalat_data": {
        "labels": [
            "2013-01-31",
            "2013-02-28",
            "2013-03-31",
            "2013-04-30",
            "2013-05-31",
        ],
        "datasets": [
            {
                "label": "İthalat",
                "backgroundColor": "#e35b2c",
                "data": [
                    87.7355890902,
                    89.6039401546,
                    95.8637063069,
                    105.9135074998,
                    108.297431938,
                ],
                "stack": 1,
                "type": "line"
            },
            {
                "label": "İthalat_Birim",
                "backgroundColor": "#e35b2c",
                "data": [
                    125.266085621,
                    127.5517401403,
                    124.948544186,
                    125.6389515733,
                    123.6537022196,
                ],
                "stack": 2,
                "type": "bar"
            }
        ]
    },
    "three_monthly_ithalat_data": {
        "labels": [
            "2013-01-31",
            "2013-02-28",
            "2013-03-31",
            "2013-04-30",
            "2013-05-31",
        ],
        "datasets": [
            {
                "label": "İthalat",
                "backgroundColor": "#e35b2c",
                "data": [
                    91.0677451839,
                    104.57781071416667,
                    99.45803133433333,
                    99.7287723006,
                    91.87687218566667,
                ],
                "stack": 1,
                "type": "line"
            },
            {
                "label": "İthalat_Birim",
                "backgroundColor": "#e35b2c",
                "data": [
                    125.92212331576667,
                    123.91481684383332,
                    121.32009484513333,
                    123.82894338530001,
                    122.77344324583333,
                ],
                "stack": 2,
                "type": "bar"
            }
        ]
    },
    "monthly_ihracat_data": {
        "labels": [
            "2013-01-31",
            "2013-02-28",
            "2013-03-31",
            "2013-04-30",
            "2013-05-31",
        ],
        "datasets": [
            {
                "label": "İthalat",
                "backgroundColor": "#e35b2c",
                "data": [
                    87.7355890902,
                    89.6039401546,
                    95.8637063069,
                    105.9135074998,
                    108.297431938,
                ],
                "stack": 1,
                "type": "line"
            },
            {
                "label": "İthalat_Birim",
                "backgroundColor": "#e35b2c",
                "data": [
                    125.266085621,
                    127.5517401403,
                    124.948544186,
                    125.6389515733,
                    123.6537022196,
                ],
                "stack": 2,
                "type": "bar"
            }
        ]
    },
    "three_monthly_ihracat_data": {
        "labels": [
            "2013-01-31",
            "2013-02-28",
            "2013-03-31",
            "2013-04-30",
            "2013-05-31",
        ],
        "datasets": [
            {
                "label": "İthalat",
                "backgroundColor": "#e35b2c",
                "data": [
                    91.0677451839,
                    104.57781071416667,
                    99.45803133433333,
                    99.7287723006,
                    91.87687218566667,
                ],
                "stack": 1,
                "type": "line"
            },
            {
                "label": "İthalat_Birim",
                "backgroundColor": "#e35b2c",
                "data": [
                    125.92212331576667,
                    123.91481684383332,
                    121.32009484513333,
                    123.82894338530001,
                    122.77344324583333,
                ],
                "stack": 2,
                "type": "bar"
            }
        ]
    },
}
    const [data, setData] = useState(defaultData);

    //const dataFetchedRef = useRef(false);
    const fetchData = async () => {
        const config={"func":"trades_index_data","params":params}
        const response = await get_trades__index_data(config);
        //const tmpdata = await transpose(response.data);
        const tmpdata = await response.data;
        const monthlyithalat=getField(tmpdata,"monthly_indices")

        const monthlybirimithalat=getField(monthlyithalat,"İthalat Birim Değer Endeks")
        const monthlymiktarithalat=getField(monthlyithalat,"İthalat Miktar Değer Endeks")

        const monthlybirimihracat=getField(monthlyithalat,"İhracat Birim Değer Endeks")
        const monthlymiktarihracat=getField(monthlyithalat,"İhracat Miktar Değer Endeks")

        const monthindex=getField(tmpdata,"monthindex")
        const threemonthlyithalat=getField(tmpdata,"3monthly_indices")

        const threemonthlybirimithalat=getField(threemonthlyithalat,"İthalat Birim Değer Endeks")
        const threemonthlymiktarithalat=getField(threemonthlyithalat,"İthalat Miktar Değer Endeks")

        const threemonthlybirimihracat=getField(threemonthlyithalat,"İhracat Birim Değer Endeks")
        const threemonthlymiktarihracat=getField(threemonthlyithalat,"İhracat Miktar Değer Endeks")

        const threemonthindex=getField(tmpdata,"3monthindex")

        const monthly_ithalat_data=create_dataset(monthindex,monthlymiktarithalat,"İthalat Miktar",monthlybirimithalat,"İthalat Birim")
        const three_monthly_ithalat_data=create_dataset(threemonthindex,threemonthlymiktarithalat,"İthalat Miktar",threemonthlybirimithalat,"İthalat Birim")

        const monthly_ihracat_data=create_dataset(monthindex,monthlymiktarihracat,"İhracat Miktar",monthlybirimihracat,"İhracat Birim")
        const three_monthly_ihracat_data=create_dataset(threemonthindex,threemonthlymiktarihracat,"İhracat Miktar",threemonthlybirimihracat,"İhracat Birim")

        //const ithalat=getField(tmpdata,"İthalat")
        //const ticaretdenge=getField(tmpdata,"Dış Ticaret Dengesi")
        //const altın=create_dataset(tmpdata,"Parasal Olmayan Altın")
        //const date = tmpdata.date;
        //const sonayihracat = ihracat[ihracat.length - 1];
        //const sonayithalat = ithalat[ithalat.length - 1];
        //const sonayticaretdenge = ticaretdenge[ticaretdenge.length - 1];
        //const date = transposedata.date;
	    setData({monthly_ithalat_data:monthly_ithalat_data,three_monthly_ithalat_data:three_monthly_ithalat_data,
	    monthly_ihracat_data:monthly_ihracat_data,three_monthly_ihracat_data:three_monthly_ihracat_data});
    }

    useEffect(() => {
            fetchData();
    },[]);

  return data
}

export default Trades;
