/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import get_domestic_debt_data from "layouts/api";

function getField(array, field) {
var result;
var values = Object.keys(array).map(function(key){
        if (key == field) {
          result=array[key];
	    }
});
return result
}

function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}


function domesticDebt(params) {

   const defaultData= { ortalamavade: [{'title': 'Aylık Ortalama Vade (Ay)', 'values': [ 100,10,5,145,200,40,210]}],
                        birikimlivade: [{'title': 'Birikimli Vade (Ay)', 'values': [ 100,10,5,145,200,40,210]}],
                        labels: [ "","","","","","",""],
    }
    const [data, setData] = useState(defaultData);

    //const dataFetchedRef = useRef(false);
    const fetchData = async () => {
        const config={"func":"domestic_debt","params":params}
        const response = await get_domestic_debt_data(config);
        const tmpdata = await transpose(response.data);
        const ortalamavade=getField(tmpdata,'Aylık Ortalama Vade (Ay)')
        const birikimlivade=getField(tmpdata,'Birikimli Vade (Ay)')
        const date = tmpdata.date;
        //const date = transposedata.date;
	    setData({ortalamavade:ortalamavade,birikimlivade:birikimlivade,labels:date});
    }

    useEffect(() => {
            fetchData();
    },[]);
  return data
}

export default domesticDebt;
