
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";


// Soft UI Dashboard PRO React icons
import { MdOutlineCalendarMonth } from "react-icons/md";
import { BsCalendar2MonthFill } from "react-icons/bs";
import { BsCalendar2DayFill } from "react-icons/bs";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

import Footer from "examples/Footer";

//Graphs

//Data

// Soft UI Dashboard PRO React example components
import CreditCardGraph from "layouts/financialsector/banking/creditcard/components/CreditCardGraph";
import CreditCardGraphPerTxn from "layouts/financialsector/banking/creditcard/components/CreditCardGraphPerTxn";

// Data
import creditcarddata from "layouts/financialsector/banking/creditcard/data/creditcarddata";
import creditcarddatapertxn from "layouts/financialsector/banking/creditcard/data/creditcarddatapertxn";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



function CreditCard() {
//const titlename=stock.stock + " Nakit Akımları (1000 TL)"

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabsOrientation2, setTabsOrientation2] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [tabValue2, setTabValue2] = useState(0);
  const [balance, setBalance] = useState(true);


  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);


  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation2() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation2("vertical")
        : setTabsOrientation2("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation2);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation2();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation2);
  }, [tabsOrientation2]);

const handleSetTabValue = (event, newValue) => setTabValue(newValue);
const handleSetTabValue2 = (event, newValue) => setTabValue2(newValue);

const handleSetBalance = () => {
  setBalance(!balance)
  };

const params="weekly"
const params2="monthly"
const params3="yearly"

const params4="tutar"
const params5="txn"

const params6="weekly"
const params7="monthly"
const params8="yearly"


//const data=reservedata(params)
//const data2=reservedata(params2)
//const data3=reservedata(params3)
  return (

      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
           <SoftBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
           <SoftTypography variant="caption" fontWeight="bold">
                {balance ? "Tutar": "Adet"}
              </SoftTypography>
              <SoftBox mx={1}>
                <Switch checked={balance} onChange={handleSetBalance} />
              </SoftBox>
            </SoftBox>

            <Grid item xs={12} lg={12}>
            <Item>
            <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ backgroundColor: "transparent" }}
              >
                <Tab label="Haftalık" icon={<BsCalendar2DayFill />} />
                <Tab label="Aylık" icon={<BsCalendar2MonthFill />} />
                <Tab label="Yıllık" icon={<MdOutlineCalendarMonth />} />
              </Tabs>
            </AppBar>
          </Grid>

      <SoftBox p={1} mt={0} width="100%" height="34.5rem">

        <CreditCardGraph data={ creditcarddata(params)}  value={tabValue} index={0}    balance={balance}/>
        <CreditCardGraph data={ creditcarddata(params2)}  value={tabValue} index={1}   balance={balance}/>
        <CreditCardGraph data={ creditcarddata(params3)}  value={tabValue} index={2}   balance={balance}/>

      </SoftBox>
             </Item>
            </Grid>
          </Grid>
        </SoftBox>
    </SoftBox>

  );
}
export default CreditCard;
