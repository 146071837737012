/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import breakpoints from "assets/theme/base/breakpoints";

import Footer from "examples/Footer";

import InflationTemplate from "layouts/dashboards/default/components/inflationTemplate";
import GDPTemplate from "layouts/dashboards/default/components/gdpTemplate";
import PayrollTemplate from "layouts/dashboards/default/components/payrollTemplate";
import BOPTemplate from "layouts/dashboards/default/components/bopTemplate";


function Default() {
  const { values } = breakpoints;
  const { size } = typography;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Grid container spacing={3} mb={1}>
          <Grid item xs={12} lg={6}>
                 <InflationTemplate/>
          </Grid>
          <Grid item xs={12} lg={6} >
                 <PayrollTemplate/>
            </Grid>
        </Grid>
       <Grid container spacing={3} mb={2}>
          <Grid item xs={12} lg={6}>
           <GDPTemplate/>
        </Grid>
         <Grid item xs={12} lg={6} >
          <BOPTemplate/>
            </Grid>
        </Grid>
      </SoftBox>
     <Footer />
    </DashboardLayout>
  );
}

export default Default;
