import { ChartOptions, ChartData } from "chart.js";

// const params=["07112019","30032021","23032023","22032024","05022025","30052040","14012041"]

export const getChartData2 = (
  data1,data2,data3,data4,data5,labels
): ChartData => ({
  labels: labels,
  datasets: [
     {
      label: "Yatırım Malları",
      borderColor:  "#280659",
      backgroundColor: "rgba(40,6,89,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data1,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "1",
    },
      {
      label: "Ara Mallar",
      borderColor:  "#900c3e",
      backgroundColor: "rgba(144,12,62,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data2,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "1",
    },
    {
      label: "Tüketim Malları",
      borderColor: "#1D7874",
      backgroundColor: "rgb(29, 120, 116,0.3)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data3,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "1",
    },
    {
      label: "Diğerleri",
      borderColor:  "#D4AF00",
      backgroundColor: "rgba(212,175,0,0.6)",
      type: "bar",
      fill: true,
      lineTension: 0.6,
      data: data4,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      stack: "1",
    },
     {
      label: "Toplam",
      borderColor: "#1D7874",
      backgroundColor: "rgb(29, 120, 116,0.03)",
      type: "line",
      fill: true,
      lineTension: 0.6,
      data: data5,
      pointRadius: 1,
      borderWidth:1.8,
      borderRadius:1,
      hidden: false,
      //stack: "1",
    }
  ]
});

export const chartOptions2: ChartOptions =  {
    interaction:
    {mode:'index'},
  elements: {
    line: {
      tension: 0.1
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
  //stacked100: { enable: true, replaceTooltipLabel: false },
  legend: {display: true,
  position:'bottom',
          labels: {
          boxWidth: 10,
          usePointStyle: true,
        }},
    filler: {
      propagate: true
    }
  },
  scales: {
    xAxes: [
      {
        display: true,
        ticks: {
          autoSkip: false,
        }
      },
    ],
    yAxes: {
      suggestedMin: 0,
      suggestedMax: 100,
       ticks: {
          // forces step size to be 50 units
          stepSize: 5
        }
    }
  },

  };

  export const chartOptions3: ChartOptions =  {
    interaction:
    {mode:'index'},
  elements: {
    line: {
      tension: 0.1
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
  //stacked100: { enable: true, replaceTooltipLabel: false },
  legend: {display: true,
  position:'bottom',
          labels: {
          boxWidth: 10,
          usePointStyle: true,
        }},
    filler: {
      propagate: true
    }
  },
  scales: {
    xAxes: [
      {
        stacked:true,
        display: true,
        ticks: {
          autoSkip: false,
        }
      },
    ],
    yAxes: [
      {
      stacked:true,
      display: true,
        ticks: {
          beginAtZero: true,
        }
      }
    ]
  },

  };

