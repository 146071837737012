/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import get_trades_data from "layouts/api";
import thinBarChartData from "layouts/nationalaccount/tradebalance/data/thinBarChartData";

function getField(array, field) {
var result;
var values = Object.keys(array).map(function(key){
        if (key == field) {
          result=array[key];
	    }
});
return result
}

function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}


function create_dataset(data,label){
var colors= ["color5"]
const labels=data.date
const tmpdata =getField(data,"Parasal Olmayan Altın")

const datasets={
  labels: labels,
  datasets: {
    label: label ,
    backgroundColors: colors,
    data: tmpdata,
  },
};

 return datasets
}

function Trades(params) {

   const defaultData= { "sonayihracat": [{'title': 'Sabit', 'values': [ 100,10,5,145,200,40,210]}],
                        "sonayithalat": [{'title': 'Değişken', 'values': [ 100,10,5,145,200,40,210]}],
                         "sonayticaretdenge": [{'title': 'Tüfe', 'values': [ 100,10,5,145,200,40,210]}],
                        "labels": [ "","","","","","",""],
                        "altın":{thinBarChartData}
    }
    const [data, setData] = useState(defaultData);

    //const dataFetchedRef = useRef(false);
    const fetchData = async () => {
        const config={"func":"trades_data","params":params}
        const response = await get_trades_data(config);
        const tmpdata = await transpose(response.data);
        const ihracat=getField(tmpdata,"İhracat")
        const ithalat=getField(tmpdata,"İthalat")
        const ticaretdenge=getField(tmpdata,"Dış Ticaret Dengesi")
        const altın=create_dataset(tmpdata,"Parasal Olmayan Altın")
        const date = tmpdata.date;
        const sonayihracat = ihracat[ihracat.length - 1];
        const sonayithalat = ithalat[ithalat.length - 1];
        const sonayticaretdenge = ticaretdenge[ticaretdenge.length - 1];
        //const date = transposedata.date;
	    setData({ihracat:ihracat,ithalat:ithalat,ticaretdenge:ticaretdenge,altın:altın,
	             sonayihracat:sonayihracat,sonayithalat:sonayithalat,sonayticaretdenge:sonayticaretdenge,
	             labels:date});
    }

    useEffect(() => {
            fetchData();
    },[]);
  return data
}

export default Trades;
