/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
//import typography from "assets/theme/base/typography";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

 function toggleAxes(ev){
   var axis=ev.target.yAxis;
   var disabled=true;
   axis.series.each(function(series){
   if (!series.isHiding && !series.isHidden){
   disabled=false;
   }
   });
   axis.disabled=disabled
   }

function configs(charts, datasets,check) {
  am4core.useTheme(am4themes_animated);
  charts.colors.list = [
    am4core.color("#280659"),
    am4core.color("#900c3e"),
    am4core.color("#C70039"),
    am4core.color("#D54800"),
    am4core.color("#FF5733"),
  ];
  charts.paddingRight = 20;
  charts.colors.step = 1;
  charts.fontSize = 12;

  var data = [];
  for (let [key, value] of Object.entries(datasets)) {
    data.push(value);
  }
  var dateAxis = charts.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.grid.template.location = 0;

 if (Object.keys(datasets[0]).length ==3 && check==true) {

  var keys = Object.keys(datasets[0]);

  var valueAxis = charts.yAxes.push(new am4charts.ValueAxis());
  valueAxis.renderer.labels.template.fill=am4core.color("#280659");
  var valueAxis2 = charts.yAxes.push(new am4charts.ValueAxis());
  valueAxis2.renderer.labels.template.fill=am4core.color("#900c3e");
  valueAxis2.renderer.opposite=true

  valueAxis.tooltip.disabled = true;
  valueAxis.renderer.minWidth = 35;
  var keys = Object.keys(data[0]);
  charts.data = data;
    //console.log(value);
      var series = charts.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = keys[1];
      series.name = keys[1];
      series.fillOpacity = 0.2;
      series.yAxis=valueAxis
      series.events.on("hidden",toggleAxes);
      series.events.on("shown",toggleAxes);

      var segment = series.segments.template;
      segment.interactionsEnabled = true;

      var hoverState = segment.states.create("hover");
      hoverState.properties.strokeWidth = 2;

      var dimmed = segment.states.create("dimmed");
      dimmed.properties.stroke = am4core.color("#dadada");

      series.tooltipText = "Tarih: {dateX}\n {component.name}: [bold]{valueY}[/]";
      series.tooltip.fontSize=12

      var series2 = charts.series.push(new am4charts.LineSeries());

      series2.dataFields.dateX = "date";
      series2.dataFields.valueY = keys[2];
      series2.name = keys[2];
      series2.fillOpacity = 0.2;
      series2.yAxis=valueAxis2
      series2.events.on("hidden",toggleAxes);
      series2.events.on("shown",toggleAxes);

      var segment = series2.segments.template;
      segment.interactionsEnabled = true;

      var hoverState = segment.states.create("hover");
      hoverState.properties.strokeWidth = 2;

      var dimmed = segment.states.create("dimmed");
      dimmed.properties.stroke = am4core.color("#dadada");

      series2.tooltipText = "Tarih: {dateX}\n {component.name}: [bold]{valueY}[/]";
      series2.tooltip.fontSize=12


  } else {
    var valueAxis = charts.yAxes.push(new am4charts.ValueAxis());

  valueAxis.tooltip.disabled = true;
  valueAxis.renderer.minWidth = 35;
  var keys = Object.keys(data[0]);
  charts.data = data;
  keys.forEach(function (obj) {
    //console.log(value);
    if (obj != "date") {

      var series = charts.series.push(new am4charts.LineSeries());

      series.dataFields.dateX = "date";
      series.dataFields.valueY = obj;
      series.name = obj;
      series.fillOpacity = 0.4;

      series.tooltipText = "Tarih: {dateX}\n {component.name}: [bold]{valueY}[/]";
      series.tooltip.fontSize=12

    }
  });

  }


  var valueAxis = charts.yAxes.push(new am4charts.ValueAxis());
  valueAxis.tooltip.disabled = true;
  valueAxis.renderer.minWidth = 35;
  var keys = Object.keys(data[0]);
  charts.data = data;


  charts.legend = new am4charts.Legend();
  charts.legend.useDefaultMarker = true;
  let marker = charts.legend.markers.template.children.getIndex(0);
  marker.cornerRadius(12, 12, 12, 12);
  marker.strokeWidth = 2;
  marker.strokeOpacity = 1;
  marker.stroke = am4core.color("#ccc");
  charts.legend.fontSize = 12;

  charts.cursor = new am4charts.XYCursor();
  dateAxis.cursorTooltipEnabled = false;
  charts.cursor.maxTooltipDistance = -1;
  charts.scrollbarX = new am4charts.XYChartScrollbar();
  charts.scrollbarX.parent = charts.bottomAxesContainer;
  charts.scrollbarX.showSystemTooltip = true;
  charts.scrollbarX.minHeight = 18;

  charts.legend.markers.template.states.create("dimmed").properties.opacity = 0.3;
  charts.legend.labels.template.states.create("dimmed").properties.opacity = 0.3;
  // Add scrollbar
   charts.scrollbarX = new am4charts.XYChartScrollbar();
   charts.scrollbarX.parent = charts.bottomAxesContainer;

   dateAxis.start = 0.5;
   dateAxis.end = 1;
   dateAxis.keepSelection = true;

   charts.exporting.menu = new am4core.ExportMenu();
   charts.exporting.filePrefix = "data";
   charts.exporting.menu.align="left"
   charts.exporting.menu.vertical="top"

}

export default configs;
