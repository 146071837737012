/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const reportsDoughnutChartData = {
  labels: ["Yatırım malları", "Ara Mallar", "Tüketim malları", "Diğerleri"],
  datasets: {
    label: "Dış Ticaret",
    backgroundColors: ["color1","color2", "color3","color4","color5", "info", "success", "warning"],
    data: [2612.2, 9928.9, 7337.3, 112.9],
  },
};

export default reportsDoughnutChartData;
