// @mui material components
import Grid from "@mui/material/Grid";
//Graphs
import BS from "layouts/realsector/firms/components/BalanceSheet/components/BS";
//import rawData from "layouts/realsector/firms/components/BalanceSheet/data/rawDataTmp";

function BalanceSheetTemplate(stock) {

  return (
         <BS stock={stock}/>
  );
}
export default BalanceSheetTemplate;
