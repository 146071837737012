import { useState } from "react";
import {useEffect } from "react";

import Select from "react-select";

import Checkbox from "@mui/material/Checkbox";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";
import SoftEditor from "components/SoftEditor";
import SoftDropzone from "components/SoftDropzone";

import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Templates
import MainTemplate from "layouts/financialsector/fundsinfo/components/MainTemplate";

import { graphOptions,groupedOptions,menuOption,creditcardOptions } from "layouts/financialsector/fundanalysis/data";

import  menuData  from "layouts/financialsector/fundanalysis/data/indexData";


const { values } = breakpoints;


import SoftButton from "components/SoftButton";

function convertDate(date) {
  var yyyy = date.getFullYear().toString();
  var mm = (date.getMonth()+1).toString();
  var dd  = date.getDate().toString();

  var mmChars = mm.split('');
  var ddChars = dd.split('');

  return yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]);
}


function FundsInfo() {


  const lastFriday = new Date().getDate() + (6 - new Date().getDay() - 1) - 7 ;
  const end_date = new Date()
  end_date.setDate(lastFriday);

  ///const end_date2 =end_date;

 // var tmp_date2 =Date(end_date2.setMonth(end_date2.getMonth() - 1));
  //console.log(end_date2)

  var tmp_date = new Date();
  tmp_date.setDate(lastFriday);
  //var start_date=new Date().getDate() + (6 - new Date().getDay() - 1) - 1 ;
  var start_date = new Date(tmp_date.setMonth(tmp_date.getMonth() - 1));
  //start=new Date(date.setMonth(date.getMonth() - 1))
  //new Date()
  //console.log(convertDate(end_date))

  const [startDate, setStartDate] = useState(convertDate(start_date));
  const [endDate, setEndDate] = useState(convertDate(end_date));
  //const [startDate2, setStartDate2] = useState(new Date(date.setMonth(date.getMonth() - 1)));

  //const handleSetStartDate2 = (newDate) => setStartDate(newDate[0]);
  //const handleSetEndDate = (newDate2) => setEndDate(newDate2[0]);
  //console.log(startDate,endDate)

  const handleSetStartDate = (event, newdt) => {
  //console.log(event,newdt)
  setStartDate(newdt)
  };


  const handleSetEndDate = (event, newdt) => {
  //console.log(event,newdt)
  setEndDate(newdt)
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="10%"
                  width="30%"
                >
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Start Date
                        </SoftTypography>
                      </SoftBox>
                      <SoftDatePicker value={startDate} onChange={handleSetStartDate} />
                    </SoftBox>
                  </Grid>
                  <Grid item xs={6}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          End Date
                        </SoftTypography>
                      </SoftBox>
                      <SoftDatePicker value={endDate} onChange={handleSetEndDate} />
                    </SoftBox>
                  </Grid>
                </Grid>

        </SoftBox>
       <SoftBox>
       <SoftBox>
            <MainTemplate startdate={startDate} enddate={endDate} />
        </SoftBox>

        </SoftBox>
     <Footer />
   </DashboardLayout>
  );
}
export default FundsInfo;
