import { Row } from "@silevis/reactgrid";

import {
  DısVarlıklar,
  Nakitİslemler,
  DegerlemeHesabı,
  DısYukumluluk,
  IcYukumluluk,
  RezervPara,
  DigerMBPara,
  Labels,
  InputVariables,
  MonthlyValues,
  OutputVariables
} from "layouts/financialsector/tcmb/analytic/components/BalanceSheet/components/interfaces";
import { months } from "layouts/financialsector/tcmb/analytic/components/BalanceSheet/components/helpers";
import {
  emptyTextCell,
  nonEditable,
  textCell,
  monthHeaderCell,
  bottomLine,
  numberCell,
  showZero,
  noSideBorders
} from "layouts/financialsector/tcmb/analytic/components/BalanceSheet/components/cells";

export const HEADER_ROW_ID = "header";
export const MONTHSTOTAL_ROW_ID = "monthsTotal";
export const MONTHSTOTAL_ROW_ID2= "monthsTotal2";
export const MONTHSTOTAL_ROW_ID3= "monthsTotal3";


const ROW_HEIGHT = 21;
const HEADING_ROW_HEIGHT = 30;

function sumGroupValues(values: MonthlyValues): number {
  return values.reduce(
    (prev, curr) => (isNaN(prev) ? 0 : prev) + (isNaN(curr) ? 0 : curr)
  );
}



function getHeaderRow(
  groups: (Labels)[],
): Row [] {
  const labels_data=groups
  const tmp_labels = labels_data.map((_, idx) => nonEditable(monthHeaderCell(labels_data[idx], "justify-content-center")));
  const labels = [nonEditable(emptyTextCell)].concat(tmp_labels)
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells:labels,
  };
}


function getMonthsDetailSumRow(
  title: string,
  monthlyLiabilitySum1: MonthlyValues,
): Row {
  const monthsTotalCell = (value: number) =>
    bottomLine(
      nonEditable(showZero(numberCell(value, "text-md-darkblue disabled font-bold")))
    );
  return {
    rowId: `${title}MONTHSTOTAL_ROW_ID`,
    height: HEADING_ROW_HEIGHT,
    cells: [
      bottomLine(nonEditable(textCell(title, "text-lg-darkblue font-bold"))),
      ...months().map((_, idx) =>
        nonEditable(monthsTotalCell(monthlyLiabilitySum1[idx]))
      ),
    ],
  };
}


function getMonthsLiabilitySumRow(
  title: string,
  monthlyLiabilitySum2: MonthlyValues,
): Row {
  const monthsTotalCell = (value: number) =>
    bottomLine(
      nonEditable(showZero(numberCell(value, "text-md-red disabled font-bold")))
    );
  return {
    rowId: `${title}MONTHSTOTAL_ROW_ID2`,
    height: HEADING_ROW_HEIGHT,
    cells: [
      bottomLine(nonEditable(textCell(title, "text-lg-red font-bold"))),
      ...months().map((_, idx) =>
        nonEditable(monthsTotalCell(monthlyLiabilitySum2[idx]))
      ),
    ],
  };
}



function getMonthsInflowOuflowRezervParaDiffRow(
  title: string,
  monthlyInflowOuflowRezervParaDiffs: MonthlyValues,
  //yearlyGroupsDiff: number
): Row {
  const monthsTotalCell = (value: number) =>
    bottomLine(
      nonEditable(showZero(numberCell(value, "text-md-darkblue disabled font-bold")))
    );
  return {
    rowId: MONTHSTOTAL_ROW_ID3,
    height: HEADING_ROW_HEIGHT,
    cells: [
      bottomLine(nonEditable(textCell(title, "text-md-darkblue font-bold"))),
      ...months().map((_, idx) =>
        nonEditable(monthsTotalCell(monthlyInflowOuflowRezervParaDiffs[idx]))
      ),
    ],
  };
}


function getGroupRows(
  title: "Rezerv Para" | "Diğer Merkez Bankası Parası" | "İç Yükümlülükler"  | "İç Varlıklar" ,
  summaryTitle: string,
  groups: ( IcYukumluluk | RezervPara | DigerMBPara)[],
  monthlyGroupTotals: MonthlyValues,
): Row[] {
  return [
        {
      rowId: `${title}Summary`,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell(summaryTitle, `padding-left-lg font-bold text-${
                  title === "Rezerv Para" ? "green" : title === "Diğer Merkez Bankası Parası" ? "green" : title === "İç Yükümlülükler" ? "green"  : title === "İç Varlıklar" ? "green" : "darkblue"
                }`)),
        ...months().map((_, idx) =>
          nonEditable(
            showZero(
              numberCell(
                monthlyGroupTotals[idx],
                `font-bold disabled text-${
                  title === "Rezerv Para"  ? "green" : title === "Diğer Merkez Bankası Parası" ? "green" : title === "İç Yükümlülükler" ? "green" : title === "İç Varlıklar" ? "green" : "darkblue"
                }`
              )
            )
          )
        ),
      ]
    },
    ...groups.map(({ title, values }) => ({
      rowId: title,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell(title, "padding-center-lg")),
        ...values.map((_, idx) => nonEditable(showZero(numberCell(values[idx])))),
      ]
    })),
  ];
}

function getSingleTotalRow(
  title: "Dış Yükümlülükler"  | "Dış Varlıklar" | "Değerleme Hesabı" ,
  summaryTitle: string,
  groups: (DısYukumluluk | DısVarlıklar | DegerlemeHesabı )[],
  //yearlyGroupTotal: number
): Row[] {
  return [
    ...groups.map(({ title, values }) => ({
      rowId: `${title}Header`,
      height: HEADING_ROW_HEIGHT,
      cells: [
             nonEditable(textCell(summaryTitle, `padding-left-lg font-bold text-${
                  title === "Dış Yükümlülükler" ? "green" : title === "Dış Varlıklar" ? "green" : title === "Değerleme Hesabı" ? "green" : "darkblue"
                }`)),
        ...values.map((_, idx) => nonEditable(numberCell(values[idx],
                `font-bold disabled text-${
                  title === "Dış Yükümlülükler"  ? "green" : title === "Dış Varlıklar" ? "green" : title === "Değerleme Hesabı" ? "green" : "darkblue"
                }`))),
      ],
    })),
  ];
}


export function getRows({
  dısvarlıklar,
  monthlyDısVarlıklarTotals,
  nakitislemler,
  monthlyNakitİslemlerTotals,
  degerlemehesabı,
  monthlyAssetSum,
  monthlyDegerlemeHesabıTotals,
  dısyukumluluk,
  monthlyDısYukumlulukTotals,
  icyukumluluk,
  monthlyIcYukumlulukTotals,
  monthlyInflowOuflowDiffs,
  monthlyLiabilitySum1,
  rezervpara,
  monthlyRezervParaTotals,
  digermbpara,
  monthlyDigerMBParaTotals,
  monthlyLiabilitySum2,
  monthlyLiabilitySum,
  labels
}: InputVariables & OutputVariables): Row[] {
  return [
   getHeaderRow(labels),
       getMonthsLiabilitySumRow(
      "AKTİFLER",
      monthlyAssetSum,
    ),
   ...getSingleTotalRow(
      "Dış Varlıklar",
       "Dış Varlıklar",
       dısvarlıklar,
     ),
     ...getGroupRows(
      "İç Varlıklar",
      "İç Varlıklar",
      nakitislemler,
      monthlyNakitİslemlerTotals,
    ),
     ...getSingleTotalRow(
      "Değerleme Hesabı",
       "Değerleme Hesabı",
       degerlemehesabı,
     ),
    getMonthsLiabilitySumRow(
      "PASİFLER",
      monthlyLiabilitySum,
    ),
    getMonthsDetailSumRow(
      "Toplam Döviz Yükümlülükleri",
      monthlyLiabilitySum1,
    ),
    ...getSingleTotalRow(
       "Dış Yükümlülükler",
       "Dış Yükümlülükler",
       dısyukumluluk,
     ),
    ...getGroupRows(
      "İç Yükümlülükler",
      "İç Yükümlülükler",
      icyukumluluk,
      monthlyIcYukumlulukTotals,
    ),
    getMonthsDetailSumRow(
      "Merkez Bankası Parası",
      monthlyLiabilitySum2,
    ),
    ...getGroupRows(
      "Rezerv Para",
      "Rezerv Para",
      rezervpara,
      monthlyRezervParaTotals,
    ),
    ...getGroupRows(
      "Diğer Merkez Bankası Parası",
      "Diğer Merkez Bankası Parası",
      digermbpara,
      monthlyDigerMBParaTotals,
    ),
  ];
}
