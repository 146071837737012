/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"
import get_credit_data from "layouts/api";

function transpose(data) {
  let result = {};
  for (let row of data) {
    for (let [key, value] of Object.entries(row)) {
      result[key] = result[key] || [];
      result[key].push(value);
    }
  }
  return result;
}

function loanNotionalData(params) {
    const config={"func":"credit_notional","params":params}
    const result = get_credit_data(config).then((response) => {
    const data = transpose(response.data);
    const date = data.date;
    const resultdata = [];
    for (let [key, value] of Object.entries(data)) {
      if (key != "date") {
        resultdata.push({ name: key, data: value });
        //i=i+1
      }
    }
    return {
      labels: date,
      datasets: resultdata,
    };
  });
  //console.log(result)
  return result;
}

export default loanNotionalData;
