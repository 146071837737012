import { useState } from "react";
import {useEffect } from "react";

import Select from "react-select";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";
import SoftEditor from "components/SoftEditor";
import SoftDropzone from "components/SoftDropzone";

import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Templates
import MainTemplate from "layouts/realsector/firms/components/MainTemplate";

import get_pdf_file from "layouts/api_pdf";

import { stocksOptions } from "layouts/realsector/firms/data";
const defaultStock = { value: "TUPRS", label: "TUPRS(*)" }
const { values } = breakpoints;


import SoftButton from "components/SoftButton";


const keys =  ["AEFES","ASUZU","ARCLK","ASELS","BIMAS","BIZIM","CRFSA", "CCOLA","DOAS","ERGLI",
               "FROTO","SAHOL","ISDMR","KCHOL","MGROS","OTKAR","OTOKC","PGSUS","SOKM","TAVHL",
               "TCELL","TUPRS","THYAO","TTKOM","TTRAK","VESBE","VESTL"];

function validate (stock) {
        if(keys.includes(stock)) {
           return true
        } else {
           return false
        }
}

function Firms() {
const [stock, setStock] = useState(defaultStock.value);

//const [chartData, setchartData] = useState({liquidityratios: [{'name': 'Cari Oran', 'data': [ 100,10,5,145,200,40,210,]}],
//                                        labels: [{'name': 'Date', 'data': [ "","","","","","",""]}] });

const handleChange = (value) => {
    const stock = value.value;
    setStock(stock);
   };

const handlePDFDownload = () => {
     const config={"params":stock};
     get_pdf_file(config);
}


  return (
    <DashboardLayout>
      <DashboardNavbar />

        <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="10%"
                  width="30%"
                >
         <SoftBox mb={1} ml={0.5} mt={1} lineHeight={0} display="inline-block">
         </SoftBox>

         <Grid container spacing={3} mb={1}>
          <Grid item xs={12} lg={6}>
          <SoftSelect
                    options={stocksOptions}
                    defaultValue={defaultStock}
                    //theme={customTheme}
                    maxMenuHeight={190}
                    onChange={handleChange}
             />
          </Grid>
          <Grid item xs={12} lg={6} >
            <SoftButton  disabled={!validate(stock)}  onClick={() => handlePDFDownload()}>
              AI Report
            </SoftButton>
            </Grid>
        </Grid>
        </SoftBox>
       <SoftBox>
            <MainTemplate stock={stock}/>
        </SoftBox>
     <Footer />
   </DashboardLayout>
  );
}
export default Firms;
