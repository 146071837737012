// @mui material components
import Grid from "@mui/material/Grid";
// Data
import incomeStatementsData from "layouts/realsector/firms/data/incomeStatementsData";
//Graphs
import DefaultHighSankeyChart from "examples/Charts/HighChartsSankey/DefaultHighSankeyChart";


function IncomeStatementsTemplate(stock) {
const titlename=stock.stock + " Gelir-Gider Grafiği (1000 TL)"
  return (
                  <Grid container spacing={0} container direction="column" justifyContent="flex-start"alignItems="stretch" sx={{ mb: 1 }}>
                        <DefaultHighSankeyChart
                             title={titlename}
                             description=""
                             chart={incomeStatementsData(stock.stock)}
                     />
                  </Grid>
  );
}
export default IncomeStatementsTemplate;
