import { useState } from "react";
import {useEffect } from "react";

import Select from "react-select";

import Checkbox from "@mui/material/Checkbox";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";
import SoftEditor from "components/SoftEditor";
import SoftDropzone from "components/SoftDropzone";

import breakpoints from "assets/theme/base/breakpoints";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Templates
import MainTemplate from "layouts/data/components/MainTemplate";


import { graphOptions,groupedOptions,menuOption } from "layouts/data/data";

import  menuData  from "layouts/data/data/indexData";


const defaultItem2 = {
    "value": "TR0030011XXXXXX1100",
    "label": "Sektör-TP Krediler(TL)"
}

const defaultItem3 = {
    "value": "",
    "label": "Seçiniz"
}

const defaultGraph = { value: "line", label: "Line" }

const { values } = breakpoints;


import SoftButton from "components/SoftButton";


function validate (item2) {
        if(keys.includes(item2)) {
           return true
        } else {
           return false
        }
}

function getField(array, field) {
var result;
var values = Object.keys(array).map(function(key){
     if(typeof(array[key][field])!="undefined"){
         result=array[key][field]
       }
});
return result
}


function Firms() {
const menu_list=menuData("params")
const [item2, setItem2] = useState(defaultItem2.value);
const [graph, setGraph] = useState(defaultGraph.value);
const [label, setLabel] = useState(defaultItem2.label);
const [menu, setMenu] = useState();

const [isChecked, setChecked] = useState(false);

const handleChange = (value) => {
    const item2 = [];
    const label=[];
    for (let i = 0; i < value.length; i++) {
        item2.push(value[i].value)
        label.push(value[i].label)
    }
    setItem2(item2);
    setLabel(label)
   };

 const handleChange2 = (value) => {
    const graph = value.value;
    setGraph(graph);
   };



   const handleMenuChange = (value) => {
        setMenu(getField(menu_list,value.label) )
       };

 const handleChange3 = () => {
    setChecked(!isChecked);
   };
  return (
    <DashboardLayout>
      <DashboardNavbar />

        <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="10%"
                  width="100%"
                >
         <SoftBox mb={1} ml={0.5} mt={1} lineHeight={0} display="inline-block">
         </SoftBox>

         <Grid container spacing={3} mb={1}>
         <Grid item xs={12} lg={3.4}>
          <SoftSelect
                    options={menuOption}
                    defaultValue={defaultItem3}
                    //theme={customTheme}
                    maxMenuHeight={190}
                    onChange={handleMenuChange}
            />
          </Grid>
          <Grid item xs={12} lg={6.5}>
          <SoftSelect
                    options={menu}
                    defaultValue={defaultItem2}
                    //theme={customTheme}
                    maxMenuHeight={190}
                    onChange={handleChange}
                    isMulti
            />
          </Grid>
         <Grid item xs={12} lg={1.6}>
            <SoftSelect
                    options={graphOptions}
                    defaultValue={defaultGraph}
                    //theme={customTheme}
                    maxMenuHeight={190}
                    onChange={handleChange2}
            />
          </Grid>
                   <Grid item xs={12} lg={0.5}>
            <Checkbox
                    checked={isChecked}
                    onChange={handleChange3}
                    //inputProps={{'aria-label':'controlled'}}
                    //label="Dual Axis"
            />
          </Grid>
        </Grid>

        </SoftBox>
       <SoftBox>
            <MainTemplate value={item2} label={label} graph={graph} check={isChecked} />
        </SoftBox>
     <Footer />
   </DashboardLayout>
  );
}
export default Firms;
