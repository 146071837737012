
import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Switch from "@mui/material/Switch";
import { TbListDetails } from "react-icons/tb";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard PRO React example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard PRO React icons
import { MdOutlineCalendarMonth } from "react-icons/md";
import { BsCalendar2MonthFill } from "react-icons/bs";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import curved0 from "assets/images/curved-images/curved0.jpg";


//Graphs
import BS from "layouts/nationalaccount/currentaccount/components/BalanceSheet/components/BS";

//Data
import budgetData from "layouts/nationalaccount/currentaccount/data/currentaccountdata";

//import balanceSheetData from "layouts/realsector/firms/data/balancesheet";
//import rawData from "layouts/realsector/firms/components/BalanceSheet/data/rawDataTmp";

            /*
              <SoftTypography variant="caption" fontWeight="bold">
                {nominal ? "Nominal": "GDP"}
              </SoftTypography>
              <SoftBox mx={1}>
                <Switch checked={nominal} onChange={handleSetNominal} />
              </SoftBox>
              */

function BSTemplate() {
//const titlename=stock.stock + " Nakit Akımları (1000 TL)"
 const [summary, setSummary] = useState(true);
 const [nominal, setNominal] = useState(true);


  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(1);
  const [graphValue, setgraphValue] = useState("1-0");



  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

//const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const handleSetSummary = () => setSummary(!summary);
  const handleSetNominal = () => {
  setNominal(!nominal)
  setTabValue(1)
  };

  const handleSetTabValue = (event, newValue) => {
  setTabValue(newValue)
  if (newValue==0) {
  setNominal(true)
  }
  };

  return (
          <div >
          <Grid container spacing={3} alignItems="center" mb={3} >
          <Grid item>
            <SoftBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <SoftTypography variant="caption" fontWeight="bold">
                {summary ? "Özet": "Detay"}
              </SoftTypography>
              <SoftBox mx={1}>
                <Switch checked={summary} onChange={handleSetSummary} />
              </SoftBox>
            </SoftBox>
          </Grid>
                    <Grid item>
            <SoftBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
            </SoftBox>
          </Grid>

          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ backgroundColor: "transparent" }}
              >
                <Tab label="Aylık" icon={<BsCalendar2MonthFill />} />
                <Tab label="Yıllık" icon={<MdOutlineCalendarMonth />} />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
         <BS data={tabValue==0 ? budgetData("monthly") : nominal==true ? budgetData("yearly")  : budgetData("gdp") } summary={summary}/>
         </div>
  );
}
export default BSTemplate;
