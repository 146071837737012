/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard React base styles
//import typography from "assets/theme/base/typography";

import Highcharts from "highcharts";

function configs(dataset,data,colour,labels) {
  return {
    options : {
    chart: {
      height: 215,
      width: 540,
      },
    legend: {
            enabled: false,
            align: 'right',
            verticalAlign: 'middle'
        },
    credits: { enabled: false},
    title: {
        text: dataset.title,
         style: {
      fontSize: '12px'
   }
      },
    xAxis: {
        type: "categories",
        categories: labels,
        crosshair: true,
        gridLineDashStyle: "dash",
        gridLineWidth: 1,
        labels: {
                overflow: 'justify',
                rotation: 0.1,
                align: 'center',
                step: 10,
                enabled: true
            },
        //events: {
        //  afterSetExtremes: function (event) {
        //    Highcharts.charts.forEach((chart) => {
        //     chart.xAxis[0].setExtremes(event.min, event.max);
        //    });
        //  }
       // }
      },
    plotOptions: {
        series: {
            marker: {
                enabled: false,
                states: {
                    hover: {
                        enabled: false
                    }
                }
            },

        },
        areaspline: { fillOpacity: 0.35},
        },
    yAxis: {
            tickPositioner: function () {
            let tick = Math.floor(this.dataMin);
            const positions = [],
                increment = Math.ceil((this.dataMax - this.dataMin) / 6);

            if (this.dataMax !== null && this.dataMin !== null) {
                for (tick;
                    tick - increment <= this.dataMax;
                    tick += increment) {
                    positions.push(tick);
                }
            }
            return positions;
        },
        title: {
          text: "%",
           style: {
           fontSize: '10px'
   }
        },
                gridLineDashStyle: "dash",
        gridLineWidth: 1,
      },
    series: [
        {
          name: dataset.title,
          type: "areaspline",
          data: data,
          color: colour,
          stickyTracking:true,
        },

      ],

      tooltip: {
                    positioner: function () {
                        return {
                            // right aligned
                            x: this.chart.chartWidth - this.label.width,
                            y: 10 // align to title
                        };
                    },
                    borderWidth: 0,
                    backgroundColor: 'none',
                    pointFormatter: function() {
                         return   '<span style="color:'+this.series.color+'"> <b>'+ this.series.xAxis.categories[this.x] +'</b></span>: <b>'+ this.y.toFixed(2)  + '</b><br/>';
                     },
                    headerFormat: '',
                    shadow: false,
                    style: {
                        fontSize: '14px'
                    },
                    valueDecimals: dataset.valueDecimals
                },
    }
      }
}

export default configs;
