/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Fade from "@mui/material/Fade";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

import LineCharts from "examples/Charts/LineCharts/DefaultLineCharts";

function TradeGraph({ data, value, index,chartoptions }) {
  const { size } = typography;
  return (
    value === index && (
      <Fade in timeout={850}>
        <SoftBox width="100%" height="100%" position="relative" borderRadius="lg">
         <LineCharts
                                    title=""
                                    description= ""
                                    chart={data}
                                    height= "22.125rem"
                                    chartoptions={chartoptions}
                                />
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="absolute"
            top={0}
            left={0}
            width="calc(100% - 1rem)"
          >
            <SoftBox p={2}>
            </SoftBox>
            <SoftBadge
              color="secondary"
              variant="contained"
              size="lg"
            />
          </SoftBox>
        </SoftBox>
      </Fade>
    )
  );
}

// Typechecking props for the CreditGraphs
TradeGraph.propTypes = {
  data: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default TradeGraph;
